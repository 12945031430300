import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import {
  Button,
  DotLoading,
  Tooltip,
  Text,
  Icon,
  LinkButton,
  Progress,
  Price as PriceComponent,
  BackLink,
  ItemPanel, TextColor,
  TextType,
} from 'new-ui';

import { ProgressSpeed } from 'new-ui/src/components/Progress/types';

import { getText, getTextArray } from '../../../i18n';

import { UseStoresInterface, withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import AirlineItem from '../../components/AirlineItem';
import HotelItem from '../../components/HotelItem';
import TransferItem from '../../components/TransferItem';
import TrainItem from '../../components/TrainItem';
import EmployeeSimpleList from '../../components/EmployeeSimpleList';
import ItemLayout from '../../components/ItemLayout';
import ApproveDialog from '../../components/ApproveDialog';
import AeroexpressItem from '../../components/AeroexpressItem';
import Timer from './components/timer';
import AnalogRooms from './components/analogRooms';
import { ApprovalSchemeCartDialog } from '../../components/ApprovalSchemeCartDialog';
import { sendReturnCartAnalytics, sendSuccessCartAnalytics } from './components/analytics';
import { BookingErrorDialog } from '../../components/BookingErrorDialog';

import { isSmartAgent } from '../../bi/utils/env';
import toDecline from '../../bi/utils/toDecline';
import { prepareCustomCheckinCheckoutWithDate } from '../../bi/utils/hotel';
import { parseSearchString, stringifySearchParams } from '../../bi/utils/convertSearchParams';
import { formatDate, MINUTE_SECONDS_PATTERN, momentObject } from '../../bi/utils/formatDate';
import MoneyFormat from '../../bi/utils/money';
import { Amplitude, MainAnalytic } from '../../bi/utils/analytics';

import {
  CART_APPROVE_TYPES,
  CART_STATUS,
} from '../../bi/constants/cart';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import { PATTERN } from '../../bi/constants/dateFormats';
import { AGGREGATORS_ACCOUNT } from '../../bi/constants/accounts';
import {
  BOOKING_TIMEOUT,
  CARTBOOKINGSTATUS,
  PROGRESSDEFAULTVALUE, RIGHTS,
  SPEED, TIMERDEFAULTVALUE, TIMERUPDATEINTERVAL,
  UPDATE_INTERVAL,
  WAITING_TRAIN_TIMER,
} from '../../bi/constants/booking';

import { PAYMENT_METHODS, PaymentMethodType } from '../../bi/types/paymentMethod';
import {
  BOOKINGSTEP,
  EBookingItemStatus,
  EBookingItemStatusChanges,
  IReservationStore,
  IStatus,
} from '../../bi/types/booking';

import CartService from '../../bi/services/cart';
import BookingService from '../../bi/services/booking';
import FormatService from '../../bi/services/format';
import WorkspaceService from '../../bi/services/workspace';
import UserSessionService from '../../bi/services/userSession';
import ChatService from '../../bi/services/chat';
import EmployeeService from '../../bi/services/employee';
import HotelsService from '../../bi/services/hotels';
import AccountSettingsService from '../../bi/services/accountSettings';
import ApprovalSchemesService from '../../bi/services/approvalSchemes';
import CloudPaymentService from '../../bi/services/cloudPayment';
import AppService from '../../bi/services/app';
import { IMatch } from '../../bi/types/shared';
import { IPassportView } from '../../bi/services/employee/types';
import { IProjects } from '../../bi/types/employees';
import { ITravelPolicyListItem } from '../../bi/types/travelPolicy';
import { AirRoute } from '../../bi/types/airline';

import {
  catchAndStopEvent,
  filteredApprovers,
  getProgress,
  isItemReady, isTpDisrupted,
  mergeItemsWithStatuses, toggleItemLoading,
  updateCustomFields,
} from '../../bi/utils/booking';

import { CartsType, ICartWpResult, IPrepareCartWpResult, IPrepareItem } from '../../bi/types/cart';

import styles from './index.module.css';

const CONFLICT_FORMS = getTextArray('booking:conflictDeclines');
const RUBLES = getTextArray('booking:rubles');

const LABELS = {
  BOOKING: getText('booking:title'),
  WAITING: getText('booking:waiting'),
  TICKETSNOTAVAILABLE: getText('booking:ticketsNotAvailable'),
  TRAVELPOLICYAPPROVAL: getText('booking:travelPolicyApproval'),
  EXITMESSAGE: getText('booking:exitMessage'),
  AVAILABILITYCONFIRMATION: getText('booking:availabilityConfirmation'),
  BUYING: getText('booking:buying'),
  CHECKED: getText('booking:checked'),
  BOOKINGERROR: getText('booking:bookingError'),
  UNAVAILABLEPLACE: getText('booking:unavailablePlace'),
  CANCELED: getText('booking:canceled'),
  CURRENCY: getText('booking:currency'),
  BACKTOCART: getText('booking:backToCart'),
  CONTINUEBOOKING: getText('booking:continueBooking'),
  SENDTOAPPROVE: getText('booking:sendToApprove'),
  CANCELBOOKING: getText('booking:cancelBooking'),
  BACK_TO_SEARCH: getText('booking:backToSearch'),
  TO_BOOK: getText('booking:toBook'),
  DISRUPTED_TP: getText('booking:disruptedTp'),
  FOR_AUTOMATIC_CANCELLATION: getText('booking:forAutomaticCancellation'),
  REQUEST_ERROR: {
    WITH_TYPE: (serviceType: string, price: string, currency: string, message: string) =>
      getText('booking:requestError.withType', { serviceType, price, currency, message }),
    WITHOUT_TYPE: (message: string) => getText('booking:requestError.withoutType', { message }),
  },
  COMMON_ERROR_DIALOG: {
    TITLE: getText('booking:commonErrorDialog.title'),
    TO_TP_HELPER: getText('booking:commonErrorDialog.toTpHelper'),
  },
  BOOKING_ERROR_DIALOG: {
    TITLE: getText('booking:bookingErrorDialog.title'),
  },
  PRICE_ACCEPTED_STATUS: {
    ACCEPTED: getText('booking:priceAcceptedStatus.accepted'),
    OLD: getText('booking:priceAcceptedStatus.old'),
  },
  PRICE_CHANGED_STATUS: {
    UP_ON: getText('booking:priceChangedStatus.upOn'),
    OK: getText('booking:priceChangedStatus.ok'),
    CANCEL: getText('booking:priceChangedStatus.cancel'),
  },
  CART: getText('booking:cart'),
  VARIANT_NON_AVAILABLE: getText('booking:variantNonAvailable'),
  APPROVE_TEXTS: {
    BUTTON: getText('booking:approveTexts.button'),
    TEXT: getText('booking:approveTexts.label'),
  },
};

interface IStores extends Pick<UseStoresInterface, 'travelApprovalsStore' | 'travelApprovalStore' | 'bookingStore'> {}

interface IBookingProps {
  history: RouteComponentProps['history'];
  cartService: CartService;
  bookingService:BookingService;
  formatService:FormatService;
  workspaceService:WorkspaceService;
  userSessionService:UserSessionService;
  chatService:ChatService;
  employeeService:EmployeeService;
  hotelsService:HotelsService;
  accountSettingsService:AccountSettingsService;
  approvalSchemesService:ApprovalSchemesService;
  appService:AppService;
  isTravelApproval: boolean;
  match: IMatch;
  aggregationId: number | null;
  cloudPaymentService: CloudPaymentService;
  // eslint-disable-next-line react/no-unused-prop-types
  stores: IStores;
}

interface IBookingBaseState {
  documentType: Partial<IPassportView>;
  isMulti: boolean;
  projects: IProjects[];
  travelPolicies: ITravelPolicyListItem;
  approve: {
    showDialog: boolean;
    schemeApproveDialog: boolean;
    approversList: any[];
    chosenApprovers: any[];
    comment: string;
  },
  showErrorDialog: boolean;
  errorMessages: string[];
  cart: IPrepareCartWpResult;
  loading: boolean;
  isApplyingChanges: boolean;
  progressValue: number;
  progressSpeed: ProgressSpeed;
  employeeId: number;
  login: string;
  scrolledToItemWithConflict: boolean;
  waitingTrain: boolean;
  isCancelling: boolean;
}

type IBookingState = IBookingBaseState & IReservationStore;

@withStores([
  MOBX_STORES.TRAVEL_APPROVALS,
  MOBX_STORES.TRAVEL_APPROVAL_STORE,
  MOBX_STORES.BOOKING,
])
@observer
class Booking extends Component<IBookingProps, IBookingState> {
  getPersonalBonusCards = this.props.accountSettingsService.getPersonalBonusCards();
  showApproversCKR = this.props.aggregationId === AGGREGATORS_ACCOUNT.CKR;
  interval: NodeJS.Timeout;
  waitingTrainTimer: NodeJS.Timeout;
  unsubscribeFn: () => void;

  constructor(props: IBookingProps) {
    super(props);

    const {
      enums: {
        documents: documentType,
      },
      projects,
      travelPolicies,
    } = props.userSessionService.get();
    const { Rights: { EmployeeId, Login }, Companies } = props.workspaceService.get();
    const isMulti = Companies.length > 1;

    this.state = {
      ...props.bookingService.getState(),
      documentType,
      isMulti,
      projects,
      travelPolicies,
      approve: {
        showDialog: false,
        schemeApproveDialog: false,
        approversList: [],
        chosenApprovers: [],
        comment: '',
      },
      showErrorDialog: false,
      errorMessages: [],
      cart: {
        Id: 0,
        Items: [],
      },
      loading: false,
      isApplyingChanges: false,
      progressValue: 0,
      progressSpeed: SPEED.Slow,
      employeeId: EmployeeId,
      login: Login,
      scrolledToItemWithConflict: false,
      waitingTrain: false,
    };
  }

  itemRef: HTMLDivElement | null = null;

  componentDidUpdate() {
    const { status, statuses, bookingId, scrolledToItemWithConflict } = this.state;
    const hasChanges = statuses.some(
      ({ Changes }) =>
        Changes === EBookingItemStatusChanges.NotEnoughPlaces
          || Changes === EBookingItemStatusChanges.PriceChanges
          || Changes === EBookingItemStatusChanges.SoldOut,
    );
    const isWaiting = status === CARTBOOKINGSTATUS.WaitingToContinue;
    const isFailed = status === CARTBOOKINGSTATUS.Failed;
    const isBooked = status === CARTBOOKINGSTATUS.Booked;
    const isMeta = status === CARTBOOKINGSTATUS.Metadata;

    if (this.itemRef && !scrolledToItemWithConflict) {
      window.scroll({ top: this.itemRef.offsetTop - 130, behavior: 'smooth' });

      this.setState({
        scrolledToItemWithConflict: true,
      });
    }

    window.onbeforeunload =
      bookingId && !(hasChanges || isWaiting) && !isFailed && !isBooked && !isMeta
        ? (e) => {
          e.preventDefault();

          return '';
        }
        : null;
  }

  componentDidMount() {
    const {
      bookingService,
      approvalSchemesService: {
        loadList,
        loadAdmins,
      },
    } = this.props;

    this.interval = setInterval(this.updateStatus, UPDATE_INTERVAL);
    this.unsubscribeFn = bookingService.subscribe(this.updateState);

    this.setState({ loading: true });

    Promise.all([loadList(), loadAdmins()])
      .then(() => { this.setState({ loading: false }); });

    this.startBooking();
  }

  componentWillUnmount() {
    this.unsubscribeFn();
    clearInterval(this.interval);
    clearTimeout(this.waitingTrainTimer);

    this.updateWaitingTrainTimer(false);
    this.props.bookingService.resetStore();
  }

  startBooking = async () => {
    const {
      cartService: { getCartById },
      cloudPaymentService: { currentPaymentMethod },
      match: { params: { id } },
      history,
      bookingService: {
        setCartItem,
        startBookingPaymentMethods,
      },
    } = this.props;

    try {
      const cart: ICartWpResult = await getCartById(id);

      setCartItem(cart);

      if (currentPaymentMethod !== PAYMENT_METHODS.EMPLOYEE_CARD) {
        await startBookingPaymentMethods(cart, currentPaymentMethod);
      }

      this.setState({
        progressValue: PROGRESSDEFAULTVALUE,
      });
    } catch (error) {
      history.push(ROUTES.CART.MAIN);
    }
  };

  updateStatus = () => this.props.bookingService.getBookingStatusPaymentMethods();

  updateState = (state: IReservationStore) => {
    const {
      cart: { Items: oldItems },
      progressValue: prevProgressValue,
    } = this.state;

    const {
      cart,
      cart: { Items: newItems },
      status,
      statuses,
      trip,
      bookingId,
      errorMessages,
      startDate,
      errors,
      cartItemByAnalogRooms,
      ShowTravelHelperButton,
    } = state;

    const Items = updateCustomFields(oldItems, newItems);
    const {
      progressValue,
      progressSpeed,
    } = getProgress(statuses, prevProgressValue);

    this.setState(
      {
        status,
        statuses,
        trip,
        bookingId,
        startDate,
        errors,
        cart: {
          ...cart,
          Items,
        },
        errorMessages,
        progressValue,
        progressSpeed: progressSpeed as ProgressSpeed,
        cartItemByAnalogRooms,
        ShowTravelHelperButton,
      },
      () => {
        const isReady = statuses.every(isItemReady);
        const hasErrors = statuses.some(
          ({ BookingStep }) => BookingStep === EBookingItemStatus.BookingError
              || BookingStep === EBookingItemStatus.ReservationError,
        ) || state.errors.length > 0 || state.errorMessages.length > 0;
        const hasUnhandledChanges = statuses.some(
          ({ Changes }) => Changes === EBookingItemStatusChanges.NotEnoughPlaces,
        );

        const isTrain = cart.Items.some(({ ServiceType }) => ServiceType === SERVICETYPE.TRAIN);

        if (isTrain) {
          this.waitingTrainTimer = setTimeout(() => this.updateWaitingTrainTimer(true), WAITING_TRAIN_TIMER);
        }

        if (isReady && (hasErrors || hasUnhandledChanges)) {
          clearInterval(this.interval);
          clearTimeout(this.waitingTrainTimer);

          this.updateWaitingTrainTimer(false);
          this.setErrorDialog(true);

          return;
        }

        if (status === CARTBOOKINGSTATUS.Failed) {
          sendReturnCartAnalytics(MainAnalytic);
          clearInterval(this.interval);
          clearTimeout(this.waitingTrainTimer);

          this.updateWaitingTrainTimer(false);
          this.setErrorDialog(true);

          return;
        }

        if (status === CARTBOOKINGSTATUS.Booked || status === CARTBOOKINGSTATUS.Metadata) {
          clearInterval(this.interval);
          clearTimeout(this.waitingTrainTimer);

          this.updateWaitingTrainTimer(false);
          this.showSuccess();
        }
      },
    );
  };

  updateWaitingTrainTimer = (value: boolean) => this.setState({
    waitingTrain: value,
  });

  // @ts-ignore
  getAllApprovers = () => this.props.employeeService.getAllApprovers().then((allApprovers) => {
    this.changeApprove(filteredApprovers(allApprovers, this.state.login));
  });

  showSuccess = () => {
    const {
      cart: { Items: cartItems, BookId },
      trip: { TripId: id, InvoiceNumber: invoiceNumber },
      bookingId,
    } = this.state;

    sendSuccessCartAnalytics(Amplitude, cartItems);

    this.props.history.push(
      `${ROUTES.TRIP.MAIN}/${id}${ROUTES.TRIP.DOCUMENTS}?id=${id}&invoiceNumber=${invoiceNumber}&bookId=${bookingId || BookId}`,
    );
  };

  applyChanges = (itemId: number, apply = true, rateId: string | null = null) => {
    const { loading, cart, cart: { Items: cartItems } } = this.state;

    if (loading) {
      return;
    }

    const newItems = toggleItemLoading(itemId, cartItems);

    this.setState(
      {
        loading: true,
        isApplyingChanges: true,
        cart: {
          ...cart,
          Items: newItems,
        },
      },
      async () => {
        const {
          cartService: { getCartById },
          bookingService: { applyChanges, updateCart, applyChangesHotel },
          match: { params: { id } },
        } = this.props;

        try {
          if (rateId) {
            await applyChangesHotel(itemId, rateId);
          } else {
            await applyChanges(id, itemId, apply);
          }

          const newCart = await getCartById(id);
          updateCart(newCart);

          if (apply) {
            await this.updateStatus();
          }
        } finally {
          const { cart: { Items: newCartItems } } = this.state;
          const items = toggleItemLoading(itemId, newCartItems);
          this.setState({
            loading: false,
            isApplyingChanges: false,
            cart: {
              ...cart,
              Items: items,
            },
          });
        }
      },
    );
  };

  continueBooking = () => {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({ loading: true }, async () => {
      const {
        match: {
          params: { id },
        },
        bookingService: { continueBookingPaymentMethods },
      } = this.props;

      try {
        await continueBookingPaymentMethods(id);
        await this.updateStatus();
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  cancelBooking = (route = ROUTES.CART.MAIN) => {
    const { isCancelling } = this.state;

    if (isCancelling) {
      return;
    }

    this.setState({
      loading: true,
      isCancelling: true,
    }, async () => {
      const {
        match: { params: { id } },
        bookingService: { cancelBookingPaymentMethods },
        history,
      } = this.props;

      try {
        await cancelBookingPaymentMethods(id);
      } finally {
        this.setState({
          loading: false,
        }, () => history.push(route));
      }
    });
  };

  loadAnalogRooms = (cartItemId: number, rateId: string) =>
    this.props.bookingService.loadAnalogRooms(cartItemId, rateId);
  handleChangeAnalogRoom = (roomId: string, cartItemId: number, value: string) =>
    this.props.bookingService.updateAnalogRooms(roomId, cartItemId, value);

  getTimerValue = () => {
    const { startDate } = this.state;
    const { formatService } = this.props;

    const endDate = formatService.addTime(startDate, BOOKING_TIMEOUT);
    const dateNow = formatService.dateObject(Date.now());
    const diff = formatService.subtractDate(dateNow, endDate);

    // @ts-ignore
    if (diff < 0) {
      return TIMERDEFAULTVALUE;
    }

    return diff.format(MINUTE_SECONDS_PATTERN);
  };

  setApproveDialog = (value: boolean) =>
    this.setState({
      approve: {
        ...this.state.approve,
        showDialog: value,
        comment: '',
      },
    });

  setErrorDialog = (value: boolean) => this.setState({ showErrorDialog: value });

  handleApproversListChange = (email: string, chooseOne = false) => {
    const {
      approve,
      approve: { chosenApprovers = [] },
    } = this.state;

    if (chooseOne) {
      this.setState({
        approve: { ...approve, chosenApprovers: [email] },
      });
    }

    const index = chosenApprovers.indexOf(email);
    const newChosenApprovers = index >= 0 ? [
      ...chosenApprovers.slice(0, index),
      ...chosenApprovers.slice(index + 1),
    ] : [
      ...chosenApprovers,
      email,
    ];

    this.setState({
      approve: { ...approve, chosenApprovers: newChosenApprovers },
    });
  };

  handleSendToApprove = (
    model: { CartId: number, StepsSettings?: any },
    isPreSteps?: boolean,
    requestId?: string,
    CanSkipApproval?: boolean,
  ) => {
    const { CartId, StepsSettings } = model;

    const { cartService, history } = this.props;
    const {
      approve: { chosenApprovers: Users, comment: Comment },
      loading,
    } = this.state;

    if (loading) {
      return null;
    }

    if (CanSkipApproval) return this.continueBooking();

    return this.setState({ loading: true }, async () => {
      try {
        await cartService.sendToApprove({
          CartId,
          Users,
          Message: {
            Comment,
          },
          StepsSettings,
          Type: CART_APPROVE_TYPES.DEFAULT,
        },
        isPreSteps,
        requestId);

        history.push(ROUTES.CART.MAIN);
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  updateApproveComment = ({ target: { value: comment } }: React.ChangeEvent<HTMLTextAreaElement>) =>
    this.setState({
      approve: { ...this.state.approve, comment },
    });

  changeApprove = (approversList: any[]) => {
    const { approve } = this.state;

    this.setState({
      approve: {
        ...approve,
        approversList,
        chosenApprovers:
          approversList.length === 1 ? [approversList[0].Email] : [],
      } }, () => this.setApproveDialog(true),
    );
  };

  prepareSendToApproveDialog = () => {
    const { employeeService } = this.props;
    const { employeeId, login } = this.state;

    employeeService.getApproversForUser(employeeId)
    // @ts-ignore
      .then((list) => {
        if (!list.length) {
          return this.getAllApprovers();
        }

        return this.changeApprove(filteredApprovers(list, login));
      })
      .catch(this.getAllApprovers);
  };

  toggleSchemeApproveDialog = () => {
    this.setState((prevState) => ({
      approve: {
        ...this.state.approve,
        schemeApproveDialog: !prevState.approve.schemeApproveDialog,
      },
    }));
  };

  handleClickErrorDialog = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.ERROR_PAID);

    this.cancelBooking();
  };

  handleSendRequest = () => {
    const { errors } = this.state;
    const { bookingService, chatService, workspaceService: { isDemo } } = this.props;
    const error = errors.map(({ CartItem, Message }) => {
      const data = JSON.parse(CartItem.Data);
      const serviceType = bookingService.getServiceName(CartItem.ServiceType, data);

      return CartItem
      // @ts-ignore
        ? LABELS.REQUEST_ERROR.WITH_TYPE(serviceType, CartItem.Price, LABELS.CURRENCY, Message)
        : LABELS.REQUEST_ERROR.WITHOUT_TYPE(Message);
    }).join('; ');

    if (!isDemo) {
      chatService.requestError(error);
    }

    MainAnalytic.send(
      MainAnalytic.CATEGORY.PAYMENT,
      MainAnalytic.ACTIONS.PAYMENT.REQUESTSINTRAVELASSISTANT,
    );
  };

  preparedHotelSearchRoute = (data: any) => {
    const {
      hotel: {
        ClassificatorId,
        City,
        RegionId,
      },
      Rate: {
        CheckinDate,
        CheckoutDate,
        CustomCheckInDate,
        CustomCheckOutDate,
      },
      GuestsCount,
    } = JSON.parse(data);

    const queryParams = {
      CheckinDate: formatDate(CheckinDate, PATTERN.YEARMONTHDAY),
      CheckoutDate: formatDate(CheckoutDate, PATTERN.YEARMONTHDAY),
      TravellersCount: GuestsCount,
      GuestsCount: 1,
      RegionName: City,
      RegionId,
      AddToHistory: false,
      ...prepareCustomCheckinCheckoutWithDate(CheckinDate, CheckoutDate, CustomCheckInDate, CustomCheckOutDate),
    };

    const convertParams = stringifySearchParams(queryParams);

    return `/search/hotel/${ClassificatorId}?${convertParams}`;
  };

  preparedHotelsSearchRoute = (data: any) => {
    const {
      hotel: {
        City,
        RegionId,
      },
      Rate: {
        CheckinDate,
        CheckoutDate,
        CustomCheckInDate,
        CustomCheckOutDate,
      },
      GuestsCount,
    } = JSON.parse(data);

    const { hotelsService } = this.props;
    const checkin = momentObject(CheckinDate);
    const checkout = momentObject(CheckoutDate);

    // @ts-ignore
    const searchObject = hotelsService.getSearchObject({
      RegionId,
      RegionName: City,
      Name: '',
    }, {
      dateFrom: checkin,
      dateTo: checkout,
      travelers: GuestsCount,
      timeFrom: CustomCheckInDate ? checkin : null,
      timeTo: CustomCheckOutDate ? checkout : null,
    });
    const searchString = stringifySearchParams(searchObject);

    return { pathname: ROUTES.SEARCH.HOTEL, search: searchString };
  };

  renderCommonPrice = (priceOrigin: number, newPrice: null | number = null) => {
    const newPriceHtml = newPrice && (this.renderPriceWithCurrency(newPrice));

    const oldPriceClassNames = [];

    if (newPrice) {
      oldPriceClassNames.push(styles.old);
    }

    return (
      <div className={ styles.price_wrap }>
        <div className={ oldPriceClassNames.join(' ') }>
          { this.renderPriceWithCurrency(priceOrigin) }
        </div>
        <div className={ styles.new }>
          { newPriceHtml }
        </div>
      </div>
    );
  };

  renderPrice = ({
    bookingStatus,
    Price,
    isCanceled,
    Id,
  }: IPrepareItem,
  // @ts-ignore
  cartItemByAnalogRooms,
  ) => {
    const {
      PriceOrigin = 0,
      AdditionalPrice = 0,
      NewPrice,
      Changes,
    } = bookingStatus as IStatus;
    const priceCertificate = AdditionalPrice || 0;
    const hasPriceChange = Changes === EBookingItemStatusChanges.PriceChanges;
    const hasSoldOut = Changes === EBookingItemStatusChanges.SoldOut;

    if (isCanceled) {
      return this.renderPriceWithCurrency(Price + priceCertificate);
    }

    if (hasPriceChange) {
      return this.renderCommonPrice(PriceOrigin + priceCertificate, NewPrice);
    }

    if (hasSoldOut) {
      const analogRooms = cartItemByAnalogRooms[Id] ? cartItemByAnalogRooms[Id] : [];

      const newPrice = analogRooms && analogRooms.length
      // @ts-ignore
        ? analogRooms.find(({ checked }) => checked).Price
        : null;

      return this.renderCommonPrice(PriceOrigin + priceCertificate, newPrice);
    }

    return this.renderPriceWithCurrency(Price + priceCertificate);
  };

  renderTpTooltip = (items: IPrepareItem[]) => {
    const travelPolicies = [
      ...new Set(items
        .reduce<string[]>((acc, { TravelPolicy }) => [...acc, ...Object.keys(TravelPolicy)
        .map(id => TravelPolicy[id])], [],
      )),
    ];

    return (
      <div className={ styles.tooltip_wrapper }>
        { travelPolicies.map((policy, index) => (
          <Text
            type='NORMAL_14_130'
            key={ index }
            color='white'
          >
            { policy }
          </Text>),
        ) }
      </div>
    );
  };

  renderTpNotification = () => {
    const { cart: { Items: items }, travelPolicies } = this.state;
    const itemsWithTpDisrupted: IPrepareItem[] = items
      .filter((item) => isTpDisrupted(item) && !item.isCanceled);

    if (!itemsWithTpDisrupted.length) {
      return null;
    }

    const policiesNames = itemsWithTpDisrupted.reduce<string[]>((acc, { TravelPolicy }) => {
      const currentPolicies = Object.keys(TravelPolicy);

      return !currentPolicies.length
        ? acc
        : [...acc, ...currentPolicies.filter((policyId) => !acc.includes(policyId))];
    }, [])
    // @ts-ignore
      .reduce<string[]>((acc, { id }) => {
      // @ts-ignore
      const policy = travelPolicies.find(({ Id }) => id === Id);

      return policy ? [...acc, policy.Name] : acc;
    }, []).join(', ');

    return (
      <Tooltip className={ styles.tp } renderContent={ () => this.renderTpTooltip(itemsWithTpDisrupted) }>
        <LinkButton>{LABELS.DISRUPTED_TP} { policiesNames }</LinkButton>
      </Tooltip>
    );
  };

  renderActions = (item: IPrepareItem, cartItemByAnalogRooms: any) => (
    <div className={ styles['item-actions'] }>
      {this.renderPrice(item, cartItemByAnalogRooms)}
      {this.renderStatus(item, cartItemByAnalogRooms)}
    </div>
  );

  renderCartItem = ({
    Data,
    ServiceType,
    bookingStatus,
  }: IPrepareItem) => {
    const itemData = JSON.parse(Data);
    const { Metadata, Routes } = itemData;

    switch (ServiceType) {
      case SERVICETYPE.AIR:
        return (
          <ul className={ styles['reset-ul'] }>
            { Routes.map((route: AirRoute) => (
              <AirlineItem
                key={ route.ID }
                route={ route }
                meta={ Metadata }
              />
            )) }
          </ul>
        );

      case SERVICETYPE.HOTEL:
        return (
          <HotelItem
            soldOut={ bookingStatus?.Changes === EBookingItemStatusChanges.SoldOut }
            item={ itemData }
          />
        );

      case SERVICETYPE.TRANSFER:
        return <TransferItem item={ itemData } />;

      case SERVICETYPE.TRAIN:
        return <TrainItem item={ itemData } />;

      case SERVICETYPE.AEROEXPRESS:
        return <AeroexpressItem item={ itemData } />;
    }

    return null;
  };

  renderTimer = () => (
    <Timer
      getCurrentValue={ this.getTimerValue }
      updateInterval={ TIMERUPDATEINTERVAL }
    />
  );

  renderNotifications = () => {
    const { statuses } = this.state;
    const notificationsCount = statuses.filter(
      ({ Changes, BookingStep }) =>
        Changes !== EBookingItemStatusChanges.NoChanges ||
        BookingStep === EBookingItemStatus.ReservationError,
    ).length;
    const conflicts = toDecline(notificationsCount, CONFLICT_FORMS);

    return (
      !!notificationsCount && (
        <div className={ styles.notifications }>
          <Icon type='warning' />
          <Text className={ styles.text }>
            {notificationsCount} {conflicts}
          </Text>
        </div>
      )
    );
  };

  renderSoldOutHotelItem = ({
    bookingStatus,
    ServiceType,
    Id,
    Data,
  }: IPrepareItem,
  cartItemByAnalogRooms: { [cartItemId: number | string]: { Guid: string }[] },
  ) => {
    if (ServiceType !== SERVICETYPE.HOTEL
        || !bookingStatus?.Changes
        || bookingStatus?.Changes !== EBookingItemStatusChanges.SoldOut
    ) return null;

    const { Rate: { Id: rateId } } = JSON.parse(Data);

    return (
      <AnalogRooms
        cartItemId={ Id }
          // @ts-ignore
        cartItemByAnalogRooms={ cartItemByAnalogRooms }
        onChange={ this.handleChangeAnalogRoom }
        onLoadAnalogRooms={ () => this.loadAnalogRooms(Id, rateId) }
      />
    );
  };

  renderItems = () => {
    const {
      cart: {
        Items: cartItems,
      },
      statuses,
      projects,
      documentType,
      isMulti,
      cartItemByAnalogRooms,
    } = this.state;
    const { appService } = this.props;

    const itemsWithStatuses = mergeItemsWithStatuses(cartItems, statuses);

    return (
      <>
        {itemsWithStatuses.map((item) => {
          const setRef = (ref: HTMLDivElement | null) => {
            if (this.itemRef) return;

            const { bookingStatus } = item;
            const isReady = statuses.length > 0 && statuses.every(isItemReady);
            const hasChanges = bookingStatus?.Changes === EBookingItemStatusChanges.NotEnoughPlaces
              || bookingStatus?.Changes === EBookingItemStatusChanges.PriceChanges
              || bookingStatus?.Changes === EBookingItemStatusChanges.SoldOut;

            if (!isReady || !hasChanges) return;

            this.itemRef = ref;
          };

          const employeesHtml = (
            <div className={ styles.actions }>
              <EmployeeSimpleList
                item={ item }
                allowedEmployees={ item.Employees }
                isMulti={ isMulti }
                documentType={ documentType }
                projects={ projects }
                isPersonalBonusCards={ this.getPersonalBonusCards }
              />
              { this.renderSoldOutHotelItem(item, cartItemByAnalogRooms) }
            </div>
          );

          return (
            <ItemPanel
              key={ item.Id }
              ref={ setRef }
              renderHeader={ () => null }
              className={ `${styles.item} ${item.isCanceled ? styles.cancelled : ''}` }
            >
              <div className={ styles.content }>
                <ItemLayout
                  serviceType={ item.ServiceType }
                  html={ this.renderActions(item, cartItemByAnalogRooms) }
                  employeesHtml={ employeesHtml }
                  appService={ appService }
                >
                  {this.renderCartItem(item)}
                </ItemLayout>
              </div>
            </ItemPanel>
          );
        })}
      </>
    );
  };

  renderOverlay = () => {
    const { progressSpeed, progressValue, waitingTrain } = this.state;

    const waitingTrainHtml = waitingTrain && (
      <Text className={ styles.subtitle } type='NORMAL_18'>
        { LABELS.WAITING }
      </Text>
    );

    return (
      <div
        onClick={ catchAndStopEvent }
        className={ styles.overlay }
      >
        <div className={ styles.panel }>
          <Progress speed={ progressSpeed } value={ progressValue } animation />
          <div className={ styles.title }>
            <Text qaAttr={ QA_ATTRIBUTES.cart.booking.inProgress } type='NORMAL_18'>
              { LABELS.BOOKING }
            </Text>
            { waitingTrainHtml }
          </div>
        </div>
      </div>
    );
  };

  renderBookingButton = ({
    label = '',
    onClick = () => {},
    className = '',
    loading = false,
    type = 'primary',
    qaAttrButton = '',
  }) => (
    <Button
        // @ts-ignore
      type={ type }
      onClick={ () => onClick() }
      className={ className }
      loading={ loading }
      qaAttr={ qaAttrButton }
    >
      { label }
    </Button>
  );

  renderActionsPanel = () => {
    const {
      statuses,
      cart: { Status: cartStatus, Items: cartItems },
      isApplyingChanges,
      isCancelling,
      loading,
    } = this.state;
    const {
      workspaceService: {
        rights: { BuyTripPersonal },
      },
    } = this.props;

    const onlyAfterApprove = BuyTripPersonal === RIGHTS.ONLYAFTERAPPROVE;
    const travelPolicy = BuyTripPersonal === RIGHTS.TRAVELPOLICY;
    const schemeApprove = BuyTripPersonal === RIGHTS.APPROVAL_SCHEME;

    const isCorporateAccount = this.props.cloudPaymentService.store.paymentMethod === PaymentMethodType.organizationAccount;

    const isApprovedCart = cartStatus === CART_STATUS.AUTHORISED || !isCorporateAccount;

    const hasNoChanges = statuses.every(
      ({ Changes }) => Changes === EBookingItemStatusChanges.NoChanges,
    );
    const hasNoError = !statuses.some(
      ({ BookingStep }) => BookingStep === EBookingItemStatus.ReservationError,
    );
    const needTravelPolicyApprove = travelPolicy && cartItems.some((item) => isTpDisrupted(item) && !item.isCanceled);
    const needApproval = !isApprovedCart && (onlyAfterApprove || needTravelPolicyApprove || schemeApprove);

    const showContinueButton = hasNoChanges && !isApplyingChanges && hasNoError;

    const autoDeclineTimerHtml = this.renderTimer();
    const notificationsHtml = this.renderNotifications();
    const tpNotificationHtml = this.renderTpNotification();
    const cancelButtonHtml = this.renderBookingButton({
      label: LABELS.CANCELBOOKING,
      onClick: this.cancelBooking,
      loading: isCancelling,
      className: styles.cancel,
    });
    const sendToApproveButtonHtml = this.renderBookingButton({
      label: LABELS.SENDTOAPPROVE,
      onClick: schemeApprove ? this.toggleSchemeApproveDialog : this.prepareSendToApproveDialog,
      type: 'secondary',
      loading,
    });
    const continueButtonHtml = this.renderBookingButton({
      label: LABELS.CONTINUEBOOKING,
      onClick: this.continueBooking,
      type: 'secondary',
      loading,
      qaAttrButton: QA_ATTRIBUTES.cart.booking.continueButton,
    });
    const buttonHtml = needApproval ? (
      <>
        { sendToApproveButtonHtml }
        { tpNotificationHtml }
      </>
    ) : (
      continueButtonHtml
    );

    return (
      <div className={ styles.booking_actions }>
        <div className={ styles.content }>
          { cancelButtonHtml }
          { showContinueButton ? buttonHtml : (
            <div className={ styles.autodecline }>
              <Text type='bold_32'>
                {autoDeclineTimerHtml}
              </Text>
              <Text type='NORMAL_14_130' className={ styles.hint }>
                {LABELS.FOR_AUTOMATIC_CANCELLATION}
              </Text>
            </div>
          )}
          {notificationsHtml}
        </div>
      </div>
    );
  };

  renderApproveDialog = () => {
    const {
      approve,
      cart: { Id, Items },
      showErrorDialog,
    } = this.state;

    const showApprovalDialog = !showErrorDialog && approve.showDialog;

    const hasDisruptedTp = Items.some(isTpDisrupted);
    const header = hasDisruptedTp ? LABELS.TRAVELPOLICYAPPROVAL : '';

    return (
      <ApproveDialog
        show={ showApprovalDialog }
        cartId={ Id as number }
        header={ header }
        approve={ approve }
        updateApproveComment={ this.updateApproveComment }
        handleApproversListChange={ this.handleApproversListChange }
        handleSendToApprove={ (cartId: number) => this.handleSendToApprove({ CartId: cartId }) }
        onClose={ this.setApproveDialog }
        showApproversCKR={ this.showApproversCKR }
      />
    );
  };

  emptyComment = (comment: string) => !comment.trim();

  renderSchemeApproveDialog = () => {
    const {
      approvalSchemesService,
      userSessionService,
      workspaceService,
      isTravelApproval,
      cartService,
      match: { params: { id: matchParamsId } },
    } = this.props;
    const { approve: { schemeApproveDialog } } = this.state;

    if (!schemeApproveDialog) return null;

    const searchParams = parseSearchString(location.search);

    // @ts-ignore
    const cart = cartService.get().carts.find((cartParam) => cartParam.id === Number(matchParamsId));

    return (
      <ApprovalSchemeCartDialog
        workspaceService={ workspaceService }
        userSessionService={ userSessionService }
        approvalSchemesService={ approvalSchemesService }
        cart={ cart as CartsType }
        isTravelApproval={ isTravelApproval }
        showApproversCKR={ this.showApproversCKR }
        showDialog={ schemeApproveDialog }
        toggleShowDialog={ this.toggleSchemeApproveDialog }
        handleSendToApprove={ this.continueBooking }
        emptyComment={ this.emptyComment }
        chosenApprovedRequestId={ searchParams?.chosenApprovedRequestId }
        approveTexts={ LABELS.APPROVE_TEXTS }
        isBooking
      />
    );
  };

  renderPriceWithCurrency = (
    price: number,
    color: TextColor = 'default',
    type: TextType = 'bold_24',
    currencyType: TextType = 'NORMAL_16',
  ) => (
    <PriceComponent
      value={ price }
      color={ color }
      type={ type }
      typeCurrency={ currencyType }
      marginSmall
    />
  );

  renderDefaultStatus = (text: string) => (
    <div className={ styles.status_wrapper }>
      <Text type='NORMAL_14'>{ text }</Text>
    </div>
  );

  renderCanceledStatus = (text: string) => (
    <div className={ styles.status_wrapper }>
      <Icon type='closeButton' />
      <Text className={ styles.text } type='NORMAL_14'>{ text }</Text>
    </div>
  );

  renderLoadingStatus = (text = '') => (
    <div className={ styles.status_wrapper }>
      <DotLoading />
      { text && (<Text className={ styles.text } type='NORMAL_14'>{ text }</Text>) }
    </div>
  );

  renderReadyStatus = (text: string) => (
    <div className={ styles.status_wrapper }>
      <Icon type='checkMark' color='green' />
      <Text className={ styles.text }>
        { text }
      </Text>
    </div>
  );

  renderPriceAcceptedStatus = (oldPrice: number) => (
    <div className={ styles.changes_accepted }>
      { this.renderReadyStatus(LABELS.PRICE_ACCEPTED_STATUS.ACCEPTED) }
      <Text
        className={ styles.hint }
        type='NORMAL_12'
        color='gray'
      >
        <span>{LABELS.PRICE_ACCEPTED_STATUS.OLD}:&nbsp;</span>
        <span>{ this.renderPriceWithCurrency(
          oldPrice,
          'gray',
          'NORMAL_12',
          'NORMAL_12',
        ) }
        </span>
      </Text>
    </div>
  );

  renderPriceChangesStatus = (id: number, rawDiff: number) => {
    const preparedDiffValue = MoneyFormat.moneyWithDecimal(rawDiff);
    const roundedValue = Math.round(rawDiff);

    const declinedCurrency = toDecline(roundedValue, RUBLES);

    return (
      <div className={ styles.changes_wrapper }>
        <div className={ styles.info }>
          <Icon type='warning' className={ styles.icon } />
          <Text className={ styles.text } type='NORMAL_12_120'>
            {LABELS.PRICE_CHANGED_STATUS.UP_ON} { preparedDiffValue } { declinedCurrency }
          </Text>
        </div>
        <div className={ styles.buttons }>
          <Button
            type='secondary'
            onClick={ () => this.applyChanges(id) }
            className={ styles.accept }
            qaAttr={ QA_ATTRIBUTES.cart.booking.priceChangedOk }
          >
            {LABELS.PRICE_CHANGED_STATUS.OK}
          </Button>
          <Button
            type='primary'
            onClick={ () => this.applyChanges(id, false) }
            className={ styles.decline }
          >
            {LABELS.PRICE_CHANGED_STATUS.CANCEL}
          </Button>
        </div>
      </div>
    );
  };

  renderSoldOutStatus = (id: number, roomId: string, Data: any) => {
    const applyHtml = roomId && (
      <Button
        type='secondary'
        onClick={ () => this.applyChanges(id, true, roomId) }
        className={ styles.accept }
      >
        { LABELS.TO_BOOK }
      </Button>
    );

    const preparedRoute = (data: any) => (
      roomId ? this.preparedHotelSearchRoute(data) : this.preparedHotelsSearchRoute(data)
    );

    return (
      <div className={ styles.changes_wrapper }>
        <div className={ styles.info }>
          <Icon type='warning' className={ styles.icon } />
          <Text className={ styles.text } type='NORMAL_12_120'>
            {LABELS.VARIANT_NON_AVAILABLE}
          </Text>
        </div>
        <div className={ styles.buttons }>
          {applyHtml}
          <Button
            type='primary'
              // @ts-ignore
            onClick={ () => this.cancelBooking(preparedRoute(Data)) }
            className={ styles.decline }
          >
            { LABELS.BACK_TO_SEARCH }
          </Button>
        </div>
      </div>
    );
  };

  renderStatus = ({
    Id,
    isCanceled,
    // @ts-ignore
    isLoading, // TODO нет в моделе !!((((
    Data,
    bookingStatus,
  }: IPrepareItem,
  // @ts-ignore
  cartItemByAnalogRooms,
  ) => {
    const {
      Changes = EBookingItemStatusChanges.NoChanges,
      BookingStep = BOOKINGSTEP.Unknown,
      NewPrice = 0,
      PriceOrigin = 0,
    } = bookingStatus as IStatus;
    const priceDiff = NewPrice - PriceOrigin;

    if (isCanceled) {
      return this.renderCanceledStatus(LABELS.CANCELED);
    }

    if (isLoading) {
      return this.renderLoadingStatus();
    }

    if (Changes === EBookingItemStatusChanges.PriceChanges) {
      return this.renderPriceChangesStatus(Id, priceDiff);
    }

    if (Changes === EBookingItemStatusChanges.SoldOut) {
      const roomId = cartItemByAnalogRooms[Id] && cartItemByAnalogRooms[Id].length
      // @ts-ignore
        ? cartItemByAnalogRooms[Id].find(({ checked }) => checked).Guid
        : null;

      return this.renderSoldOutStatus(Id, roomId, Data);
    }

    if (Changes === EBookingItemStatusChanges.NotEnoughPlaces) {
      return this.renderDefaultStatus(LABELS.UNAVAILABLEPLACE);
    }

    if (BookingStep === BOOKINGSTEP.BookingError || BookingStep === BOOKINGSTEP.ReservationError) {
      return this.renderDefaultStatus(LABELS.BOOKINGERROR);
    }

    if (Changes === EBookingItemStatusChanges.NoChanges && priceDiff > 0) {
      return this.renderPriceAcceptedStatus(PriceOrigin);
    }

    if (
      BookingStep === BOOKINGSTEP.Booked ||
      BookingStep === BOOKINGSTEP.Reserved ||
      BookingStep === BOOKINGSTEP.ReadyToBook
    ) {
      return this.renderReadyStatus(LABELS.CHECKED);
    }

    return this.renderLoadingStatus(LABELS.AVAILABILITYCONFIRMATION);
  };

  render() {
    const {
      statuses,
      status,
      showErrorDialog,
      errorMessages,
      errors,
      isCancelling,
      ShowTravelHelperButton,
    } = this.state;

    const hasChanges = statuses.some(
      ({ Changes }) =>
        Changes === EBookingItemStatusChanges.NotEnoughPlaces
          || Changes === EBookingItemStatusChanges.PriceChanges
          || Changes === EBookingItemStatusChanges.SoldOut,
    );
    const areItemsReady =
      statuses.length > 0 && statuses.every(isItemReady);

    const isInProgress = status === CARTBOOKINGSTATUS.InProgress;
    const isWaiting = status === CARTBOOKINGSTATUS.WaitingToContinue;

    const itemsHtml = this.renderItems();
    const overlayHtml = this.renderOverlay();
    const actionsPanelHtml = this.renderActionsPanel();
    const approveDialogHtml = this.renderApproveDialog();
    const schemeApproveDialogHtml = this.renderSchemeApproveDialog();

    const titleHtml = <Text type='bold_32' className={ styles.header }>{LABELS.BUYING}</Text>;

    const cartLinkHtml = (
      <BackLink
        alternativeDesign={ isSmartAgent }
        text={ LABELS.CART }
        link={ ROUTES.CART.MAIN }
      />
    );
    const promptHtml = (
      <Prompt
        when={
          isInProgress &&
          !!this.state.bookingId &&
          !areItemsReady
        }
        message={ LABELS.EXITMESSAGE }
      />
    );

    const showOverlay = !showErrorDialog
        && ((isInProgress && !hasChanges && areItemsReady) || (!isWaiting && !areItemsReady));
    const showActionsPanel = !showErrorDialog && areItemsReady && (isWaiting || hasChanges);

    return (
      <>
        {promptHtml}
        <BookingErrorDialog
          errorMessages={ errorMessages }
          errors={ errors }
          isCancelling={ isCancelling }
          showErrorDialog={ showErrorDialog }
          statuses={ statuses }
          onClickErrorDialog={ this.handleClickErrorDialog }
          onSendRequest={ this.handleSendRequest }
          ShowTravelHelperButton={ ShowTravelHelperButton }
        />
        {approveDialogHtml}
        {schemeApproveDialogHtml}
        {showOverlay && overlayHtml}
        {showActionsPanel && actionsPanelHtml}
        <div className={ styles.wrapper }>
          {cartLinkHtml}
          {titleHtml}
          {itemsHtml}
        </div>
      </>
    );
  }
}

export { Booking };
