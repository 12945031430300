import React, { useState, useEffect } from 'react';

import AirlineService from '../../../../bi/services/airline';

import { SearchHistory } from '../../../../components/SearchHistory';
import { AirlinesHistoryItem } from '../AirlinesHistoryItem';
import { AirlineHistoryItem } from '../../../../bi/types/airline';

interface IAirlineHistoryProps {
  airlineService: AirlineService,
  onClick(): void,
  qaAttrs: {
    text: string,
    wrapper: string,
  } | null,
}

const AirlineHistory = ({
  airlineService,
  onClick,
  qaAttrs = null,
}: IAirlineHistoryProps) => {
  const [items, setItems] = useState<AirlineHistoryItem[]>([]);

  const updateHistory = ({ items: itemsNew }: { items: AirlineHistoryItem[] }) => setItems(itemsNew);

  useEffect(() => {
    airlineService.getHistory();

    const unsubscribeFn = airlineService.subscribeHistory(updateHistory);

    return () => {
      unsubscribeFn();
    };
  }, []);

  if (!items.length) return null;

  return (
    <SearchHistory
      items={ items }
      onClick={ onClick }
      renderItem={ (i: AirlineHistoryItem) => <AirlinesHistoryItem item={ i } /> }
      qaAttrLabel={ qaAttrs?.text || '' }
      qaAttrWrapper={ qaAttrs?.wrapper || '' }
    />
  );
};

export default AirlineHistory;
