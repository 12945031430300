import { StyledWrapper, Text } from 'new-ui';
import React from 'react';

import ROUTES from '../../../../bi/constants/routes';

import { getText } from '../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  CUSTOM_BANNER_START: getText('smartdesk:customBannerStart'),
  CUSTOM_BANNER_END: getText('smartdesk:customBannerEnd'),
};

interface CustomBannerProps {
  isExternalLink: boolean;
}

export const CustomBanner = ({ isExternalLink }: CustomBannerProps) => {
  if (!isExternalLink) {
    return null;
  }

  return (
    <StyledWrapper className={ styles.custom_banner }>
      <Text type='NORMAL_16' >
        { LABELS.CUSTOM_BANNER_START }
        <a href={ ROUTES.CUSTOM_BUSSINES_TRIPS_OGMK } target='_blank' rel='noreferrer'>
          { LABELS.CUSTOM_BANNER_END }
        </a>
      </Text>
    </StyledWrapper>
  );
};
