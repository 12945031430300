import React, { FC, useEffect, useState, useRef } from 'react';
import { Text, Textarea, Button } from 'new-ui';

import { getText } from '../../../i18n/index';

import { MainAnalytic } from '../../bi/utils/analytics';
import { prepareAirlineNames, prepareDataFlight, prepareDataPerson } from '../../bi/utils/airlineAdditionalServices';

import { ADDITIONAL_TYPES, ADDITIONAL_UNIQ, PATH } from '../../bi/constants/airlineAdditionalServices';

import {
  AirlineAdditionalChatRequestProps,
  IDetails,
  TicketDataInterface,
} from '../../bi/types/airlineAdditionalServices';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('components:additionalServices.chatRequest.title'),
  BUTTON: getText('components:additionalServices.chatRequest.button'),
  PLACEHOLDER: getText('components:additionalServices.chatRequest.placeholder'),
  DEFAULT_MESSAGE_AIR: getText('components:additionalServices.chatRequest.defaultMessageAir'),
  DEFAULT_EXTRA_BAGGAGE: getText('components:additionalServices.chatRequest.defaultExtraBaggage'),
  DEFAULT_VIP_HALL: getText('components:additionalServices.chatRequest.defaultVipHall'),
  DEFAULT_ESCORT: getText('components:additionalServices.chatRequest.defaultEscort'),
  ADDITIONAL_TITLE: getText('components:additionalServices.title'),
};

const DEFAULT_MESSAGES = {
  [ADDITIONAL_TYPES.CHOOSE_SEAT]: LABELS.DEFAULT_MESSAGE_AIR,
  [ADDITIONAL_TYPES.EXTRA_BAGGAGE]: LABELS.DEFAULT_EXTRA_BAGGAGE,
  [ADDITIONAL_TYPES.VIP_HALL]: LABELS.DEFAULT_VIP_HALL,
  [`${ADDITIONAL_TYPES.VIP_HALL}Direction`]: LABELS.DEFAULT_VIP_HALL,
  [`${ADDITIONAL_TYPES.VIP_HALL}Route`]: LABELS.DEFAULT_VIP_HALL,
  [ADDITIONAL_TYPES.ESCORT]: LABELS.DEFAULT_ESCORT,
  [`${ADDITIONAL_TYPES.ESCORT}Direction`]: LABELS.DEFAULT_ESCORT,
  [`${ADDITIONAL_TYPES.ESCORT}Route`]: LABELS.DEFAULT_ESCORT,
  [ADDITIONAL_UNIQ.OTHER]: LABELS.PLACEHOLDER,
};

const getDefaultMessage = (type: string) => {
  if (!type || !Object.keys(DEFAULT_MESSAGES).includes(type)) {
    return LABELS.PLACEHOLDER;
  }

  return DEFAULT_MESSAGES[type];
};

const getAnalyticType = (type: string) => {
  switch (type) {
    case `${ADDITIONAL_TYPES.VIP_HALL}Direction`:
      return MainAnalytic.ACTIONS.VIP_HALL.VIP_AIRPORT_REQUEST_SENT;

    case `${ADDITIONAL_TYPES.ESCORT}Direction`:
      return MainAnalytic.ACTIONS.VIP_HALL.ESCORT_AIRPORT_REQUEST_SENT;

    case `${ADDITIONAL_TYPES.VIP_HALL}Route`:
      return MainAnalytic.ACTIONS.VIP_HALL.VIP_ROUTE_REQUEST_SENT;

    case `${ADDITIONAL_TYPES.ESCORT}Route`:
      return MainAnalytic.ACTIONS.VIP_HALL.ESCORT_REQUEST_SENT;

    case ADDITIONAL_TYPES.CHOOSE_SEAT:
      return MainAnalytic.ACTIONS.VIP_HALL.AIRTRIPDETAIL_SEATREQUEST_SENT;

    case ADDITIONAL_TYPES.EXTRA_BAGGAGE:
      return MainAnalytic.ACTIONS.VIP_HALL.AIRTRIPDETAIL_LUGGAGEREQUEST_SENT;

    case ADDITIONAL_UNIQ.ORDER_FLIGHT_CERTIFICATE:
      return MainAnalytic.ACTIONS.VIP_HALL.AIRTRIPDETAIL_REFERENCEREQUEST_SENT;

    default: return MainAnalytic.ACTIONS.VIP_HALL.AIRTRIPDETAIL_OTHERREQUEST_SENT;
  }
};

const AirlineAdditionalChatRequest: FC<AirlineAdditionalChatRequestProps> = ({
  breadCrumbsService,
  match: { path, params: { ticketId, additionalType } },
  chatService,
  productAnalyticsProtocol,
  workspaceService,
  orderService,
}) => {
  const [detailsData, setDetailsData] = useState<IDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(getDefaultMessage(additionalType));
  const isSendedMessage = useRef<boolean>(false);

  const ticket = detailsData?.OrderItems.find(item => item.Id === +ticketId);
  const ticketData: TicketDataInterface = ticket && JSON.parse(ticket.ActualVersion.JsonData);
  useEffect(() => {
    const { details } = orderService.get();

    setDetailsData(details);

    breadCrumbsService.setCurrentPath(path);

    return () => {
      if (!isSendedMessage.current) {
        MainAnalytic.sendFirebase(getAnalyticType(additionalType), {
          action: 'no',
        });
      }

      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const handleChangeLoading = (value: boolean) => setIsLoading(value);

  const handleChange = (value: string) => setMessage(value);

  const handleSend = async () => {
    let prepareTicket = '';

    ticketData.Routes.forEach(route => {
      const { firstSegment, lastSegment, departureData, arrivalData } = prepareDataFlight(route);

      prepareTicket += `${firstSegment?.DepartureCity} – ${lastSegment?.ArrivalCity}, ${departureData} – ${arrivalData}, ${prepareAirlineNames(route.Segments, route.Segments[0])}\n`;
    });

    const preparedMessage = `${LABELS.ADDITIONAL_TITLE} #${ticketData.Tickets[0]}\n${prepareDataPerson(ticketData, ticket)}\n${prepareTicket}`;

    MainAnalytic.sendFirebase(getAnalyticType(additionalType), {
      action: 'yes',
    });

    handleChangeLoading(true);

    isSendedMessage.current = true;

    try {
      await chatService.requestAdditionalService(`${preparedMessage}\n${message}`, detailsData?.TripId.toString());

      handleChangeLoading(false);
      handleChange('');

      chatService.show();
      productAnalyticsProtocol.clickIntercom();

      MainAnalytic.send(MainAnalytic.CATEGORY.ADDITIONALSERVICES, MainAnalytic.ACTIONS.ADDITIONALSERVICES.ORDER, { label: LABELS.TITLE });
    } catch (err) {
      handleChangeLoading(false);
    }
  };

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24' className={ styles.title }>{ LABELS.TITLE }</Text>
      <Textarea
        noneResize
        className={ styles.message }
        placeholder={ LABELS.PLACEHOLDER }
        value={ message }
        debounceMs={ 0 }
        onChange={ handleChange }
      />
      <div className={ styles.actions }>
        <Button
          type='secondary'
          disabled={ message.length < 1 || workspaceService.isDemo }
          loading={ isLoading }
          onClick={ handleSend }
        >
          { LABELS.BUTTON }
        </Button>
      </div>
    </div>
  );
};

export { AirlineAdditionalChatRequest };
