import { Moment } from 'moment';

import { formatDate, getMoment } from '../../utils/formatDate';
import { mapGuids } from '../../utils/integration1S';
import { MainAnalytic } from '../../utils/analytics';

import Api from '../../api';

import { FIELDS_DATE, PATTERN } from '../../constants/dateFormats';
import {
  AnalyticFilterExpense,
  APPROVER,
  ApproveStatus,
  ERROR_CODE,
  FIELDS_FILTERS,
  LOADINGS_FIELD,
  PREPAYMENTS,
  STATUS_PAGE,
  VALIDATION_DOC_DOWNLOAD,
} from '../../constants/expenseReport';
import FORMATS from '../../constants/formats';

import { expenseReportsStore } from './stores/expenseReports';
import { expenseReportStore } from './stores/expenseReport';
import { settingsExpenseStore } from './stores/settingsExpense';
import { expenseReportApproveStore } from './stores/approve';

import type {
  DocumentsType,
  EmployeeType,
  ExpenseReportsType,
  ExpensesType,
  PrepaymentsType,
  ReportApprovalData,
  ReportItemType,
  SettingsExpenseType,
  TripType,
} from './types';
import { AnalyticAddExpense } from './types';

const STATUSES = {
  FOR_NEW_REPORT: 0,
  FOR_SEND_TO_APPROVE: 4,
  FOR_SEND_TO_DRAFT: 8,
};

class ExpenseReports {
  api: Api;
  storeReport = expenseReportStore;
  storeReports = expenseReportsStore;
  storeSettingsExpense = settingsExpenseStore;
  storeApprove = expenseReportApproveStore;
  isDataLoaded = false;
  companyIdData: number | null = null;
  constructor(api: Api) {
    this.api = api;
  }

  setLoadingReports = (value = false): void => this.storeReports.setLoading(value);

  setLoadingDialogReports = (value = false): void => this.storeReports.setLoadingDialog(value);

  setLoadingSettings = (value = false): void => this.storeSettingsExpense.setLoading(value);

  setLoadingButtonSettings = (value = false): void => this.storeSettingsExpense.setLoadingButton(value);

  setLoadingReport = (value = false, type = ''): void => this.storeReport.setLoading(value, type);

  setValidationDownloadDoc = (value: string, index: number): void => this.storeReport.setValidationDownloadDoc(value, index);

  setDialog = (value = false, type = ''): void => this.storeReport.setDialog(value, type);

  approveExpenseReport = async (field: string | number, comment: string, email: string) => {
    if (field === 0) {
      this.setLoadingReport(true, LOADINGS_FIELD.SEND_BUTTON);
    } else {
      this.setLoadingReport(true, LOADINGS_FIELD.DECLINED_BUTTON);
    }

    const { dataForSave } = this.storeReport;
    const { approveId } = this.storeApprove;

    const data = {
      Resolution: ({
        0: APPROVER.APPROVED,
        6: APPROVER.DECLINE,
      }[field]),
      Message: { Comment: comment, Email: email },
    };

    try {
      this.storeReport.updateForcedPrompt(false);

      await this.api.expenseReports.updateReport(dataForSave.Id, dataForSave);
      await this.api.expenseReports.approveExpenseReport(data, approveId);
    } finally {
      this.setLoadingReport(false, LOADINGS_FIELD.SEND_BUTTON);
    }
  };

  getApproversSteps = async (empId: number, trips: TripType[]) => {
    try {
      const updateTrips = trips.map(({ TripId }) => TripId);

      const DepartmentIds = (
        this.storeReport.report?.DepartmentIds &&
        this.storeReport.report.DepartmentIds.length > 0 &&
        trips.length === 0
      ) ? this.storeReport.report.DepartmentIds
        : null;

      const { ApprovalStepApprovers, StepsResolution } = await this.api.expenseReports.getApproversSteps(empId, DepartmentIds, updateTrips);

      this.storeReports.setApprovers(ApprovalStepApprovers);
      this.storeReport.setApproveResolutions(StepsResolution);
    } catch {
      this.storeReports.setApprovers([]);
    }
  };

  getApproversForSendOnApprove = async () => {
    try {
      const res = await this.api.approvalExpenseReports.getApproversForExpenseReports();

      this.storeReports.setUsualApprovers(res);
    } catch {
      this.storeReports.setUsualApprovers([]);
    }
  };

  getApprovers = async (empId: number) => {
    try {
      const approvers = await this.api.expenseReports.getApprovers(empId);

      this.storeReports.setUsualApprovers(approvers);
    } catch {
      await this.getApproversForSendOnApprove();
    }
  };

  loadExpenseReports = async (name: string, arg: boolean | undefined): Promise<void> => {
    this.setLoadingReports(true);

    try {
      const res: ExpenseReportsType = await this.api.expenseReports.getExpenseReports(arg);
      const integration: any = await this.api.requests.getIntegration();

      const enabledIntegration = mapGuids(integration).length > 0;

      this.storeReports.setSources(res);
      this.storeReports.setIntegration1S(enabledIntegration);
      this.setFilters(name);
      this.setLoadingReports();
    } catch (e) {
      this.storeReports.setSources({ OnlyOwn: false, Reports: [] });
      this.storeReports.setIntegration1S(false);
      this.setLoadingReports();
    }
  };

  getExpenseReport = (id: number) => this.api.expenseReports.getExpenseReport(id);

  deleteExpenseReport = async (id: number): Promise<void> => {
    this.setLoadingReport(true, LOADINGS_FIELD.DELETE_BUTTON);

    return this.api.expenseReports.deleteExpenseReport(id)
      .then(
        () => {
          this.setLoadingReport(false, LOADINGS_FIELD.DELETE_BUTTON);

          return Promise.resolve();
        },
        () => {
          this.setLoadingReport(false, LOADINGS_FIELD.DELETE_BUTTON);

          return Promise.reject();
        },
      );
  };

  cancelApprovedExpenseReport = async (id: number, arg: boolean | undefined) => {
    this.setLoadingReport(true, LOADINGS_FIELD.DELETE_BUTTON);

    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.REPORTING.EXPENSE_CANCEL_APPROVAL_PRESSED,
    );

    try {
      if (arg) {
        await this.api.expenseReports.cancelApprovedExpenseReportDraft(id);
      } else {
        await this.api.expenseReports.cancelApprovedExpenseReport(id);
      }
    } finally {
      this.setLoadingReport(false, LOADINGS_FIELD.DELETE_BUTTON);
    }
  };

  sendReportToApprove = async ({ StepSettings, Users, Comment, Email, EmployeeId }: ReportApprovalData, scheme: boolean) => {
    this.setLoadingReport(true, LOADINGS_FIELD.SEND_BUTTON);
    this.storeReport.updateForcedPrompt(false);

    const { dataForSave, employee: { Id: id }, edit } = this.storeReport;

    const newReport = {
      ...dataForSave,
      Status: STATUSES.FOR_SEND_TO_APPROVE,
    };

    try {
      if (edit) {
        await this.api.expenseReports.updateReport(dataForSave.Id, newReport);
      } else {
        const { Id } = await this.api.expenseReports.saveNewReport(id, newReport);

        this.storeReport.setReportId(Id);
      }

      const date = formatDate(getMoment(), PATTERN.YEARMONTHDAY);

      const { reportId } = this.storeReport;
      const expenseReportId = edit ? dataForSave.Id : reportId;

      const preparedModel = {
        Message: {
          Email,
          Comment,
          EmployeeId,
          Date: date,
        },
        ExpenseReportId: expenseReportId,
        EventDate: date,
      };

      const modelForBackend = scheme ? {
        ...preparedModel,
        StepsSettings: StepSettings,
        Users: [],
      } : {
        ...preparedModel,
        Users,
        StepSettings: [],
      };

      await this.api.expenseReports.sendReportForApprove(modelForBackend);
    } finally {
      this.setLoadingReport(false, LOADINGS_FIELD.SEND_BUTTON);
    }
  };

  getSettingsExpense = () => this.api.expenseReports.getSettingsExpense() as Promise<SettingsExpenseType>;

  getPrefiledValues = async () => {
    const response = await this.api.expenseReports.getPrefiledValues();

    this.storeReport.setPrefiledValue(response);
  };

  loadSettingsExpense = async (): Promise<void> => {
    this.setLoadingSettings(true);

    try {
      const res = await this.getSettingsExpense();

      this.storeSettingsExpense.setSettings(res);
      this.setLoadingSettings();
    } catch (e) {
      this.setLoadingSettings();
    }
  };

  setPaginate = (page: number): void => this.storeReports.setCurrentPaging(page);

  setDataLoaded = (value: boolean): void => {
    this.isDataLoaded = value;
  };

  getTrips = async (employeeId: number | undefined, companyId: number): Promise<void> => {
    this.setLoadingDialogReports(true);

    try {
      const res = await this.api.expenseReports.getTrips(employeeId, companyId);

      const responses = await this.api.expenseReports.getDepartmentsByEmployee(employeeId, companyId);
      this.companyIdData = companyId;
      this.storeReports.setDepartment(responses);

      this.storeReports.setTrips(res);
      this.setLoadingDialogReports();
    } catch (e) {
      this.storeReports.setDepartmentReset();
      this.storeReports.setTrips([]);
      this.setLoadingDialogReports();
    }
  };

  setEmployee = (item: EmployeeType): void | Promise<void> => {
    if (this.storeReports.dialog.employee !== null) {
      this.setClearDialog(false);
    }

    this.storeReports.setEmployee(item);

    const { Companies, Id } = item;

    if (Companies.length > 1) {
      return this.storeReports.setCompanies(Companies);
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_EMPLOYEE_CHOSEN);

    return this.getTrips(Id, Companies[0].CompanyId);
  };

  setCompany = (value: number): Promise<void> => {
    this.storeReports.setCompany(value);

    return this.getTrips(this.storeReports.dialog.employee.Id, value);
  };

  setClearDialog = (value: boolean): void => this.storeReports.setClearDialog(value);

  updateCheckbox = (value: boolean): void => {
    this.storeReports.setCheckbox(value);
  };

  updateDepbox = (value: boolean | any): void => {
    const departments = this.storeReports.dialog.departments;

    this.storeReport.setdepId(value.nested);
    this.storeReport.setDepartments(departments);
  };

  getupdateDepbox = (): number[] | undefined => {
    const res = this.storeReport.report.DepartmentIds;

    return res;
  };

  updateCheckboxTrip = async (value: boolean, id: number) => {
    this.storeReports.setCheckboxTrip(value, id);

    const { employee, currentCompany } = this.storeReports.dialog;

    const selectedTrips = this.storeReports.dialog.trips
      .filter(trip => trip.selected)
      .map(trip => trip.TripId);

    let current = currentCompany;

    if (selectedTrips.length > 0) {
      if (currentCompany === null) {
        current = '';
      }

      const response = await this.api.expenseReports.getDepartmentsByEmployeeAndCompany(current, employee.Id, selectedTrips);

      if (response) {
        const resp: number[] = response.map((res: any) => res.Id);

        this.storeReport.setdepId(resp);
        this.storeReports.setDepartment(response);
        this.setDataLoaded(true);
      }
    } else {
      this.storeReports.setDepartmentReset();
      this.storeReport.setDepartmentReset();

      const responses = await this.api.expenseReports.getDepartmentsByEmployee(employee.Id, this.companyIdData);

      if (responses) {
        this.storeReports.setDepartment(responses);
        this.setDataLoaded(false);
      }
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_TRIPS_CHOSEN);
  };

  openAllTrips = (): void => this.storeReports.openAllTrips();

  updateDate = (field: string, value: Moment | null): void => {
    if (field === FIELDS_DATE.START_DATE) {
      return this.storeReports.setStartDate(value);
    }

    return this.storeReports.setEndDate(value);
  };

  loadSettingsForReport = async (): Promise<void> => {
    try {
      const res = await this.getSettingsExpense();

      this.storeReport.setSettings(res);
      this.setLoadingReport(false, LOADINGS_FIELD.LOADING);
    } catch (e) {
      this.setLoadingReport(false, LOADINGS_FIELD.LOADING);
    }
  };

  getDepartments = (employeeId: number | undefined, companyId: number) => this.api.expenseReports.getDepartmentsByEmployee(employeeId, companyId);

  openReport = (item: ReportItemType | any = null, employee: EmployeeType | null = null): void => {
    const isIntegration1S = this.storeReports.isIntegration1S;

    if (item) {
      return this.storeReport.setReport(item, isIntegration1S);
    }

    const report = this.storeReports.dataForCreateReport;

    const companyId = this.storeReports.dialog.currentCompany || employee?.Companies[0].CompanyId;
    const depId = this.storeReport.report.DepartmentIds;
    const departments = this.storeReport.report.Data.Departments;

    return this.storeReport.setNewReport(report, isIntegration1S, employee, companyId, depId, departments);
  };

  updatePrepayments = (prepayment: PrepaymentsType, ind: number | null): void => {
    if (prepayment.Type === PREPAYMENTS.DAILY_EXPENSES) {
      return this.storeReport.setPrepaymentDailyExpenses(prepayment);
    }

    return this.storeReport.setPrepayment(prepayment, ind);
  };

  openDeleteDocumentsItemDialog = (index: number | null, value: boolean):void => {
    this.storeReport.setDocItemIndex(index);
    this.storeReport.setDeleteDocumentsItemDialog(value);
  };

  deletePrepaymentsItem = (index: number): void => this.storeReport.deletePrepaymentsItem(index);

  deleteDocumentsItem = async () => {
    const { docItemIndex } = this.storeReport;
    this.setLoadingReport(true, LOADINGS_FIELD.DELETE_ITEM_DOC);

    try {
      await this.storeReport.deleteDocumentsItem(docItemIndex);
    } finally {
      this.setLoadingReport(false, LOADINGS_FIELD.DELETE_ITEM_DOC);
      this.openDeleteDocumentsItemDialog(null, false);
    }
  };

  deleteExpensesItem = (index: number): void => this.storeReport.deleteExpensesItem(index);

  downloadDocumentsItem = async (guid: string, ind: number): Promise<void> => {
    const { dataForSave: { Id } } = this.storeReport;

    this.setLoadingReport(true, LOADINGS_FIELD.DOWNLOAD_ITEM_DOC);
    this.storeReport.setDocItemIndex(ind);

    try {
      await this.api.expenseReports.downloadDocumentsItem(Id, guid);

      this.setLoadingReport(false, LOADINGS_FIELD.DOWNLOAD_ITEM_DOC);
    } catch {
      this.setLoadingReport(false, LOADINGS_FIELD.DOWNLOAD_ITEM_DOC);
      this.storeReport.setDocItemIndex(null);
    }
  };

  downloadArchiveDoc = async (): Promise<void> => {
    const { dataForSave: { Id } } = this.storeReport;

    this.setLoadingReport(true, LOADINGS_FIELD.DOWNLOAD_ARCHIVE_DOCS);

    try {
      await this.api.expenseReports.downloadArchiveDocs(Id);

      this.setLoadingReport(false, LOADINGS_FIELD.DOWNLOAD_ARCHIVE_DOCS);
    } catch {
      this.setLoadingReport(false, LOADINGS_FIELD.DOWNLOAD_ARCHIVE_DOCS);
    }
  };

  updateExpenses = (expense: ExpensesType | any, ind: number | null | undefined): void => {
    this.storeReport.setExpense(expense, ind);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_CONSUMPTION_PRESSED(AnalyticAddExpense.SAVE),
    );
  };

  updateDocuments = (document: DocumentsType | any, ind: number | null): void => this.storeReport.setDocument(document, ind);

  updateRuExpense = (value: number): void => this.storeSettingsExpense.setRuExpense(value);

  updateAbroadExpense = (value: number): void => this.storeSettingsExpense.setAbroadExpense(value);

  updateRewritable = (value: boolean): void => this.storeSettingsExpense.setCheckbox(value);

  saveSettings = async (): Promise<void> => {
    this.setLoadingButtonSettings(true);

    const data = this.storeSettingsExpense.sources;

    try {
      await this.api.expenseReports.saveSettings(data);

      this.setLoadingButtonSettings();
      this.storeSettingsExpense.setValid(false);
    } catch (e) {
      this.setLoadingButtonSettings();
    }
  };

  saveReport = (): Promise<void> => {
    this.setLoadingReport(true, LOADINGS_FIELD.SAVE_BUTTON);
    this.storeReport.updateForcedPrompt(false);

    const { edit, employee: { Id }, dataForSave } = this.storeReport;

    const updateData = {
      ...dataForSave,
      Status: STATUSES.FOR_NEW_REPORT,
    };

    if (edit) {
      return this.api.expenseReports.updateReport(dataForSave.Id, updateData)
        .then((r: string) => {
          this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

          return Promise.resolve(r);
        },
        () => {
          this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

          return Promise.reject();
        });
    }

    return this.api.expenseReports.saveNewReport(Id, dataForSave)
      .then((r: string) => {
        this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

        return Promise.resolve(r);
      },
      () => {
        this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

        return Promise.reject();
      });
  };

  saveReportDraft = (): Promise<void> => {
    this.setLoadingReport(true, LOADINGS_FIELD.SAVE_BUTTON);
    this.storeReport.updateForcedPrompt(false);

    const { edit, employee: { Id }, dataForSave } = this.storeReport;

    const updateData = {
      ...dataForSave,
      Status: STATUSES.FOR_SEND_TO_DRAFT,
    };

    if (edit) {
      return this.api.expenseReports.updateReport(dataForSave.Id, updateData)
        .then((r: string) => {
          this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

          return Promise.resolve(r);
        },
        () => {
          this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

          return Promise.reject();
        });
    }

    return this.api.expenseReports.saveNewReport(Id, updateData)
      .then((r: string) => {
        this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

        return Promise.resolve(r);
      },
      () => {
        this.setLoadingReport(false, LOADINGS_FIELD.SAVE_BUTTON);

        return Promise.reject();
      });
  };

  downloadReport = (file: string, id: number): Promise<void> => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.EXPENSE_DOWNLOAD_PRESSED);

    return this.api.expenseReports.downloadReport(id, file);
  };

  sendEmail = (email: string, id: number, errorLocale: string): Promise<void> => this.api.expenseReports.sendEmail(id, email, FORMATS.PDF, errorLocale);

  getEmployee = (id: number): Promise<void> => {
    this.setLoadingDialogReports(true);

    return this.api.employee.getEmployee(id)
      .then((res: EmployeeType) => {
        this.setLoadingDialogReports(false);

        return this.setEmployee(res);
      });
  };

  getEmployeeById = (id: number) => this.api.employee.getEmployee(id);

  setFilters = (name: string): void => this.storeReports.setFilters(name);

  updateFilters = (field: string, values: number[]): void => {
    switch (field) {
      case FIELDS_FILTERS.EMPLOYEES: {
        this.storeReports.updateEmployeesFilter(values);
        MainAnalytic.sendAmplitudeArrayArgs(
          MainAnalytic
            .ACTIONS
            .REPORTING
            .EXPENSE_FILTER_APPLIED(AnalyticFilterExpense.EMPLOYEE),
        );

        break;
      }
      case FIELDS_FILTERS.COMPANIES: {
        this.storeReports.updateCompaniesFilter(values);

        break;
      }
      case FIELDS_FILTERS.MINE: {
        this.storeReports.updateMineCheckboxFilter(values);

        break;
      }
      case FIELDS_FILTERS.STATUS: {
        this.storeReports.updateStatusFilter(values);
        MainAnalytic.sendAmplitudeArrayArgs(
          MainAnalytic
            .ACTIONS
            .REPORTING
            .EXPENSE_FILTER_APPLIED(AnalyticFilterExpense.STATUSES),
        );

        break;
      }
    }

    this.setPaginate(1);

    return this.storeReports.updateFilters();
  };

  updateApprovesLoading = (value: boolean) => this.storeApprove.setLoading(value);

  getApproves = async () => {
    this.updateApprovesLoading(true);

    try {
      const res = await this.api.expenseReports.getExpenseReportsApproves();

      this.storeApprove.setApproves(res);
      this.updateApprovesLoading(false);
    } catch (e) {
      this.storeApprove.setApproves([]);
      this.updateApprovesLoading(false);
    }
  };

  updateApproveId = (id: number) => this.storeApprove.setApproveId(id);

  getStatusFromBackend = (status: ApproveStatus) => ({
    [ApproveStatus.WAITING_APPROVE]: () => STATUS_PAGE.WAITING_APPROVE,
    [ApproveStatus.DECLINED]: () => STATUS_PAGE.NOT_APPROVE,
    [ApproveStatus.APPROVED]: () => STATUS_PAGE.APPROVE,
    [ApproveStatus.ACCEPTED]: () => STATUS_PAGE.CREATE,
    [ApproveStatus.WAITING_1C]: () => STATUS_PAGE.CREATE,
    [ApproveStatus.ERROR_SEND_1C]: () => STATUS_PAGE.CREATE,
    [ApproveStatus.SEND_TO_1C]: () => STATUS_PAGE.CREATE,
    [ApproveStatus.DELETED]: () => STATUS_PAGE.NOT_APPROVE,
    [ApproveStatus.DRAFT]: () => STATUS_PAGE.CREATE,
  }[status]());

  setExpenseReportStatusPage = (status: string) => this.storeReport.setStatusPage(status);

  resetFilters = (): void => {
    this.storeReports.updateEmployeesFilter([]);
    this.storeReports.updateCompaniesFilter([]);
    this.storeReports.updateStatusFilter([]);
    this.storeReports.updateMineCheckboxFilter(false);
    this.storeReports.resetFilters();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.EXPENSE_FILTER_CANCELED);
  };

  sendFile = async (file: File, progressHandler: boolean, ind: number): Promise<void> => {
    this.setLoadingReport(true, LOADINGS_FIELD.ADD_NEW_DOC_LOADER);

    try {
      const res = await this.api.expenseReports.sendFileExpenseReport(file, progressHandler);

      this.updateDocuments(res, ind);
    } catch (e) {
      const error = e as { response?: { statusCode?: number } };
      const code = error?.response?.statusCode || ERROR_CODE.INTERNAL_SERVER_ERROR;

      this.setValidationDownloadDoc(VALIDATION_DOC_DOWNLOAD[code], ind);
    } finally {
      this.setLoadingReport(false, LOADINGS_FIELD.ADD_NEW_DOC_LOADER);
    }
  };

  checkEmptyPage = (): boolean => !this.storeReport.isDailyExpenses;

  resetStoreReports = (): void => this.storeReports.reset();

  resetStoreReport = (): void => this.storeReport.reset();

  resetStoreSettings = (): void => this.storeSettingsExpense.reset();
}

export default ExpenseReports;
