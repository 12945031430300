import React from 'react';
import { BackLink, Button, StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import Strip from '../../../../../../../images/mice/Strip.svg';

import MoneyFormat from '../../../../../../bi/utils/money';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('event:calculator.final.title'),
  PRICE: getText('event:calculator.final.price'),
  BUTTON: getText('event:calculator.final.button'),
  SUB_LABEL: getText('event:calculator.final.subLabel'),
  OFFER: getText('event:calculator.final.offer'),
  DOWN: getText('event:calculator.final.down'),
};

interface ICompleteCalculator {
  value: number,
  onOpenAddEvent(value: boolean): void,
  onSetIsComplete(): void,
}

const CompleteCalculator = ({
  value,
  onOpenAddEvent,
  onSetIsComplete,
}: ICompleteCalculator) => (
  <div className={ styles.wrapper }>
    <BackLink
      text={ LABELS.DOWN }
      onClick={ onSetIsComplete }
    />
    <Text type='bold_32' className={ styles.title }>
      { LABELS.TITLE }
    </Text>
    <StyledWrapper className={ styles.styled }>
      <Text type='bold_18' className={ styles.text }>
        { LABELS.PRICE }
        <span className={ styles.must }>*</span>
      </Text>
      <Text type='bold_24'>
        { MoneyFormat.moneyWithDecimal(value) } ₽
      </Text>
      <Button type='secondary' onClick={ () => onOpenAddEvent(true) }>
        { LABELS.BUTTON }
      </Button>
    </StyledWrapper>
    <div className={ styles.sub_text }>
      <Text type='NORMAL_14'>
        <span className={ styles.must }>*</span>
        { LABELS.SUB_LABEL }
      </Text>
    </div>
    <Text type='bold_14' className={ styles.offer }>
      { LABELS.OFFER }
    </Text>
    <img src={ Strip } alt=''/>
  </div>
);

export { CompleteCalculator };
