import React, {
  useEffect,
  useRef,
  useState,
  DragEvent,
  MouseEvent,
} from 'react';
import { observer } from 'mobx-react';
import { History } from 'history';
import {
  Button,
  Checkbox,
  Dialog,
  DotLoading,
  IconButton,
  Input,
  LinkButton,
  Text,
  Tooltip,
  PageLoader,
  BackLink,
  NoResults,
} from 'new-ui';

import { getText, getTextArray } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import ApprovalERSchemesService from '../../bi/services/approvalERSchemes';
import SidePanelService from '../../bi/services/sidePanel';
import PopupsService from '../../bi/services/popups';
import Workspace from '../../bi/services/workspace';

import { getEmployeeFullName } from '../../bi/utils/employees';
import toDecline from '../../bi/utils/toDecline';
import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import { POPUP_GROUP_TYPES, POPUP_POSITIONS } from '../../bi/constants/popups';
import { ERROR_CODE } from '../../bi/constants/app';

import {
  APPROVAL_SCHEME_STEP_FIELDS,
  APPROVAL_SCHEME_TYPES,
  APPROVAL_STEP_DRAGGABLE_ITEM_TYPES,
  DEFAULT_APPROVAL_SCHEME_FORM_STEP,
  StatusScheme,
  APPROVAL_TYPES,
} from '../../bi/constants/approvalSchemes';
import TEXTS from '../../bi/constants/texts';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { DraggableItem } from '../../components/DraggableItem';
import { EmployeeCloneItem } from '../../components/EmployeeCloneItem';
import { AddButton } from '../../components/AddButton';
import { ApprovalSchemeStep } from '../../components/ApproveSchemeCommon/approvalSchemeStep';

import { ApprovalSchemeFAQ } from './components/FAQ';

import { IRoles } from '../../bi/services/approvalSchemes/types';
import { ApprovalERSchemeStep, TApprovalERSchemeSteps } from '../../bi/services/approvalERSchemes/types';

import styles from './styles.module.css';

const LABELS = {
  HOW_IT_WORKS: getText('approvalScheme:howItWorks'),
  SCHEME_CREATION: getText('approvalScheme:schemeCreation'),
  EMPLOYEE_SEARCH: getText('approvalScheme:employeeSearch'),
  ADD_STEP: getText('approvalScheme:addStep'),
  APPLY_FOR_ALL: getText('approvalScheme:applyForAll'),
  SUBMIT: getText('common:save'),
  APPLY_SCHEME: getText('approvalScheme:applyScheme'),
  CANCEL: getText('common:undo'),
  SCHEME_NAME: getText('approvalScheme:schemeName'),
  DROP_CART: getText('approvalScheme:dropCart'),
  FORM_NOT_VALID: getText('approvalScheme:formNotValid'),
  EMPLOYEE_DECLINES: getTextArray('approvalScheme:employeeDeclines'),
  ADMIN_DECLINES: getTextArray('approvalScheme:adminDeclines'),
  ERROR_DIALOG: {
    EDIT: {
      TITLE: getText('approvalScheme:errorDialog.edit.title'),
      TEXT: getText('approvalScheme:errorDialog.edit.text'),
    },
    SAVE: {
      TITLE: getText('approvalScheme:errorDialog.save.title'),
      TEXT: getText('approvalScheme:errorDialog.save.text'),
    },
  },
  POPUP: {
    TITLE: getText('approvalScheme:popup.title'),
    DESCRIPTION: getText('approvalScheme:popup.description'),
  },
  APPROVALS: {
    TITLE: getText('approvalScheme:approveSchemeExpenseReport.title'),
    SUBTITLE: getText('approvalScheme:approveSchemeExpenseReport.subTitle'),
  },
  SUBMIT_DIALOG: {
    ONE_TEXT: {
      ONE: getText('approvalScheme:submitDialog.oneText.one'),
      TWO: getText('approvalScheme:submitDialog.oneText.two'),
      THREE: getText('approvalScheme:submitDialog.oneText.three'),
    },
    TWO_TEXT: {
      ONE: getText('approvalScheme:submitDialog.twoText.one'),
      TWO: getText('approvalScheme:submitDialog.twoText.two'),
    },
    THREE: getText('approvalScheme:submitDialog.threeText'),
  },
  HINT: {
    SHOW: getText('approvalScheme:hint.show'),
    NOT_SHOW: getText('approvalScheme:hint.notShow'),
  },
  EMPLOYEE_LIST: {
    TITLE: getText('approvalScheme:employeeERList.title'),
    BUTTON: getText('approvalScheme:employeeERList.button'),
  },
};

const ROLES_TAB = '1.2';
const ANIMATION_TIMEOUT = 1200;

interface IApprovalERSchemePageProps {
  history: History;
  approvalERSchemesService: ApprovalERSchemesService;
  workspaceService: Workspace;
  sidePanelService: SidePanelService;
  popupsService: PopupsService;
}

type TPayloadHandleChangeForm =
  Record<string, boolean | string | ApprovalERSchemeStep[]> | TApprovalERSchemeSteps;

const ApprovalERSchemePage = observer(({
  history,
  approvalERSchemesService,
  workspaceService,
  sidePanelService,
  popupsService,
}: IApprovalERSchemePageProps) => {
  const inputRef = useRef(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [draggableItem, setDraggableItem] = useState<{ id: null | string, type: string }>({
    id: null,
    type: '',
  });
  const [removableItem, setRemovableItem] = useState<{
    cb:() => void,
    show: boolean,
    showPanel: boolean,
  }>({
        cb: () => {},
        show: false,
        showPanel: false,
      });
  const [showSubmitDialog, setSHowSubmitDialog] = useState<boolean>(false);
  const [animateHint, setAnimateHint] = useState<boolean>(false);
  const [errorDialog, setErrorDialog] = useState<{ show: boolean, code: number | null }>({
    show: false,
    code: null,
  });
  const isDemo = useRef(workspaceService.isDemo);

  const {
    approvalERSchemesStore,
    settingSchemeStore,
  } = useStores([MOBX_STORES.APPROVAL_ER_SCHEMES, MOBX_STORES.SETTING_SCHEME]);

  const {
    form,
    isFormValid,
    schemeLoading,
    form: {
      Name,
      ApplyForAll,
      Steps,
      Employees,
      Roles,
      Utils: {
        admins: {
          value: admins,
          loading: aLoading,
        },
        submitLoading,
      },
      Roles: {
        loading: rLoading,
        value: rValue,
      },
      Employees: {
        loading: eLoading,
        value: eValue,
        users,
      },
    },
  } = approvalERSchemesStore;

  const { isApprovalUnavailable } = settingSchemeStore;

  const {
    updateForm,
    loadingAdmins,
    submitForm,
    updateFaq,
    loadingRoles,
    loadingApprovers,
  } = approvalERSchemesService;

  useEffect(() => {
    const { status } = approvalERSchemesStore;

    loadingRoles();
    loadingApprovers(async (approversIds) => {
      const { POPUP: { TITLE, DESCRIPTION } } = LABELS;
      const emails = await workspaceService.getUsersEmailsByUserIds(approversIds);

      popupsService.addPopup({
        title: TITLE,
        description: `${DESCRIPTION} \n\n${emails.join(', ')}`,
        cancel: { action: () => {}, label: '' },
        submit: { action: () => {}, label: '' },
        position: POPUP_POSITIONS.TOP_RIGHT,
        group: POPUP_GROUP_TYPES.SEARCH_RESULT,
        show: true,
      });
    });

    if (status === StatusScheme.CREATE) {
      approvalERSchemesService.createScheme();
    }

    return approvalERSchemesService.clearStoresList;
  }, [approvalERSchemesService, approvalERSchemesStore, loadingApprovers, loadingRoles, popupsService, workspaceService]);

  const getErrorDialogContent = (header: string, text: string) => (
    <div className={ styles.error }>
      <Text
        className={ styles.header }
        type='bold_20'
      >
        { header }
      </Text>
      <Text>
        { text }
      </Text>
    </div>
  );

  const prepareErrorDialogContent = (code: number) => {
    const { ERROR_DIALOG: { SAVE, EDIT } } = LABELS;

    switch (code) {
      case ERROR_CODE.CONFLICT:
        return getErrorDialogContent(SAVE.TITLE, SAVE.TEXT);
      case ERROR_CODE.DEPENDENCY:
        return getErrorDialogContent(EDIT.TITLE, EDIT.TEXT);
      default:
        return <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />;
    }
  };

  const handleSetSubmitDialog = (value: boolean) => setSHowSubmitDialog(value);

  const handleClickAddApproverPanel = () => {
    if (!animateHint) {
      setAnimateHint(true);
      setTimeout(() => setAnimateHint(false), ANIMATION_TIMEOUT);
    }
  };

  const handleGoBack = () => history.push(ROUTES.SETTINGS.APPROVAL_SCHEMES_EXPENSE_REPORTS);

  const handleGoEmployees = () => history.push(ROUTES.SETTINGS.EMPLOYEES);

  const handleChangeForm = (payload: TPayloadHandleChangeForm) => updateForm({ ...form, ...payload });

  const handleSelectAllEmployees = (value: boolean) => {
    handleChangeForm({ [APPROVAL_SCHEME_STEP_FIELDS.APPLY_FOR_ALL]: value });
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REPORT_SCHEME_CHECKBOX);
  };

  const handleStepChange = (payload: ApprovalERSchemeStep, index: number) => {
    const newSteps = [...Steps];
    newSteps[index] = { ...payload };

    handleChangeForm({ Steps: newSteps });
  };

  const handleSetRemovableApprover = (value: Record<string, boolean>) => setRemovableItem({ ...removableItem, ...value });

  const handleAddStep = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REPORT_SCHEME_ADD_STEP);

    handleChangeForm({
      Steps: [...Steps,
        {
          ...DEFAULT_APPROVAL_SCHEME_FORM_STEP as unknown as ApprovalERSchemeStep,
          Type: APPROVAL_SCHEME_TYPES.EVERY.value,
        }],
    });
  };

  const handleRemoveStep = (i: number) => {
    const newSteps = [...Steps.slice(0, i), ...Steps.slice(i + 1)];

    handleChangeForm({ Steps: newSteps });
  };

  const handleDragStart = (id: string, type: string) => setDraggableItem({ id, type });

  const handleDragEnd = () => setDraggableItem({ id: null, type: '' });

  const handleSetErrorDialog = (value: Record<string, boolean | number | null>) => setErrorDialog({ ...errorDialog, ...value });

  const handleSubmitForm = (needCheck: MouseEvent<HTMLButtonElement> | boolean = true) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REPORT_SCHEME_SAVE_BUTTON);

    if (ApplyForAll && needCheck) {
      loadingAdmins();
      handleSetSubmitDialog(true);

      return;
    }

    submitForm().then(handleGoBack, ({ status }: { status: number }) => handleSetErrorDialog({ code: status, show: true }));
  };

  const handleApplyScheme = () => {
    handleSubmitForm(false);
    handleSetSubmitDialog(false);
  };

  const renderFaq = () => {
    const { faq: { id: faqId } } = approvalERSchemesStore;

    return (
      <ApprovalSchemeFAQ
        openedTab={ faqId as string }
      />
    );
  };

  const handleSetFaqDialog = () => {
    updateFaq({ id: null });
    sidePanelService.setShow(true);
    sidePanelService.setRenderFn(renderFaq);
  };

  const handleChangeFaqTab = (id: number | string) => {
    updateFaq({ id });
    sidePanelService.setShow(true);
    sidePanelService.setRenderFn(renderFaq);
  };

  const renderRoles = () => {
    if (rLoading && !eLoading) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
        </div>
      );
    }

    if (!rValue) {
      return null;
    }

    return (
      <div className={ styles.roles }>
        { rValue.map(({ Id, Name: rName }) => (
          <div key={ Id } className={ styles.item }>
            <DraggableItem
              isRed
              text={ rName }
              onDragStart={ () => handleDragStart(Id, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.ROLE) }
              onDragEnd={ handleDragEnd }
            />
            <IconButton
              className={ styles.icon }
              onClick={ () => handleChangeFaqTab(ROLES_TAB) }
              iconType='question'
            />
          </div>
        )) }
      </div>
    );
  };

  const renderEmployeesList = () => {
    if (eLoading) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
        </div>
      );
    }

    if (!eValue.length) {
      return (
        <div className={ styles['empty-employees'] }>
          <Text>
            { LABELS.EMPLOYEE_LIST.TITLE }
          </Text>
          &nbsp;
          <LinkButton
            theme='blue-without-border'
            onClick={ handleGoEmployees }
          >
            { LABELS.EMPLOYEE_LIST.BUTTON }
          </LinkButton>
        </div>
      );
    }

    const listContent = eValue.reduce((r, e) => {
      const { Rights: { UserId } } = e;
      const trimmedInput = searchValue.trim().toLowerCase();

      if (!trimmedInput || (trimmedInput && getEmployeeFullName(e).toLowerCase().includes(trimmedInput))) {
        return [...r, (
          <DraggableItem
            key={ UserId }
            onDragStart={ () => handleDragStart(UserId, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.EMPLOYEE) }
            onDragEnd={ handleDragEnd }
            renderContent={ () => <EmployeeCloneItem value={ e } /> }
          />
        )];
      }

      return r;
    }, []);

    return (
      <div className={ styles.employees }>
        <div className={ styles.search }>
          <Input
            isCleansing
            theme='light-shadow'
            className={ styles.input }
            value={ searchValue }
            onChange={ setSearchValue }
            placeholder={ LABELS.EMPLOYEE_SEARCH }
          />
        </div>
        { listContent }
      </div>
    );
  };

  const renderApprovalSteps = () => {
    if (isApprovalUnavailable) return null;

    const listContent = Steps.map((s, i) => (
      <ApprovalSchemeStep
        key={ `approval_expense_report_${i}` }
        ind={ i + 1 }
        value={ s }
        employees={ Employees }
        roles={ Roles as IRoles }
        field={ APPROVAL_TYPES.REPORT }
        draggableItem={ draggableItem }
        renderEmployee={ e => <EmployeeCloneItem value={ e } /> }
        onChange={ value => handleStepChange(value, i) }
        onRemove={ () => handleRemoveStep(i) }
        onClickAddApprover={ handleClickAddApproverPanel }
        onDragRemovableApprover={ (cb) => handleSetRemovableApprover({ cb, show: true }) }
        onDragEndRemovableApprover={ () => handleSetRemovableApprover({ show: false, showPanel: false }) }
      />
    ));

    return (
      <div className={ styles.steps }>
        <Text
          type='bold_18'
          qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.subTitle }
        >
          { LABELS.APPROVALS.TITLE }
        </Text>
        <Text type='NORMAL_14_130' color='gray' className={ styles.subtitle }>
          { LABELS.APPROVALS.SUBTITLE }
        </Text>
        { listContent }
        <AddButton
          className={ styles.add }
          text={ LABELS.ADD_STEP }
          onClick={ handleAddStep }
          qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.addStep }
        />
      </div>
    );
  };

  const renderBottomPanel = () => {
    const tooltipText = !isFormValid ? LABELS.FORM_NOT_VALID : TEXTS.NOT_FOR_DEMO;

    const submitContent = (
      <Tooltip
        show={ isDemo.current || !isFormValid }
        renderContent={ () => (
          <Text
            className={ styles.tooltip }
            type='NORMAL_14_130'
            color='white'
          >
            { tooltipText }
          </Text>
        ) }
      >
        <Button
          type='secondary'
          loading={ submitLoading }
          disabled={ !isFormValid || isDemo.current }
          onClick={ handleSubmitForm }
        >
          { LABELS.SUBMIT }
        </Button>
      </Tooltip>
    );

    const checkboxContent = (
      <Checkbox
        className={ styles.checkbox }
        onChange={ value => handleSelectAllEmployees(value) }
        value={ ApplyForAll }
      >
        { LABELS.APPLY_FOR_ALL }
      </Checkbox>
    );

    return (
      <div className={ styles.bottom }>
        <div className={ styles.actions }>
          { submitContent }
          { checkboxContent }
        </div>
      </div>
    );
  };

  const renderSubmitDialog = () => {
    const employeesText = users && `${users.length} ${toDecline(users, LABELS.EMPLOYEE_DECLINES)}`;
    const adminsText = admins && `${toDecline(admins.length, LABELS.ADMIN_DECLINES)} (${admins.map(({ Email }) => Email).join(', ')})`;

    return (
      <Dialog
        show={ showSubmitDialog }
        onChange={ () => handleSetSubmitDialog(false) }
        showClosing
      >
        <div className={ styles.dialog_wrapper }>
          <div className={ styles.texts }>
            <Text type='NORMAL_16_130'>
              {LABELS.SUBMIT_DIALOG.ONE_TEXT.ONE}
              <b>{ Name }</b>
              {LABELS.SUBMIT_DIALOG.ONE_TEXT.TWO}
              <b>{ employeesText }</b>
              {LABELS.SUBMIT_DIALOG.ONE_TEXT.THREE}
            </Text>
            <Text type='NORMAL_16_130'>
              {LABELS.SUBMIT_DIALOG.TWO_TEXT.ONE}
              <b>{ adminsText }</b>
              {LABELS.SUBMIT_DIALOG.TWO_TEXT.TWO}
            </Text>
            <br/>
            <Text type='NORMAL_16_130'>
              {LABELS.SUBMIT_DIALOG.THREE}
            </Text>
          </div>
          <div className={ styles.actions }>
            <Button
              loading={ aLoading }
              type='secondary'
              onClick={ handleApplyScheme }
            >
              { LABELS.APPLY_SCHEME }
            </Button>
            <LinkButton
              theme='blue-without-border'
              className={ styles.cancel }
              onClick={ () => handleSetSubmitDialog(false) }
            >
              <Text
                type='NORMAL_14'
                color='accent'
              >
                { LABELS.CANCEL }
              </Text>
            </LinkButton>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderErrorDialog = () => {
    const { show, code } = errorDialog;

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ () => handleSetErrorDialog({ show: false, code: null }) }
      >
        <div className={ styles.dialog_wrapper }>
          { prepareErrorDialogContent(code as number) }
        </div>
      </Dialog>
    );
  };

  const renderSidePanel = () => {
    const { show, cb, showPanel } = removableItem;

    const hintClassNames = [styles.info];

    const sideClassNames = [styles.side];

    if (animateHint) {
      hintClassNames.push(styles.animated);
    }

    if (show) {
      sideClassNames.push(styles.deletable);
    }

    const containerDraggableProps = show && !showPanel ? {
      onDragEnter: () => handleSetRemovableApprover({ showPanel: true }),
      onDragOver: (e: DragEvent) => e.preventDefault(),
    } : {};

    const droppablePanelDraggableProps = {
      onDragLeave: () => handleSetRemovableApprover({ showPanel: false }),
      onDragOver: (e: DragEvent) => e.preventDefault(),
      onDrop: () => {
        cb();
        handleSetRemovableApprover({ show: false, showPanel: false });
      },
    };

    const droppablePanel = showPanel && (
      <div
        className={ styles.droppable }
        { ...droppablePanelDraggableProps }
      >
        <Text type='normal_20'>
          { LABELS.DROP_CART }
        </Text>
      </div>
    );

    const hintContent = show ? LABELS.HINT.SHOW : LABELS.HINT.NOT_SHOW;
    const panel = showPanel ? {} : containerDraggableProps;

    return (
      <div className={ styles['side-wrapper'] }>
        <div
          className={ sideClassNames.join(' ') }
          { ...panel }
        >
          <Text
            type='NORMAL_14_130'
            color='gray'
            className={ hintClassNames.join(' ') }
          >
            { hintContent }
          </Text>
          { renderRoles() }
          { renderEmployeesList() }
          { droppablePanel }
        </div>
      </div>
    );
  };

  if (schemeLoading) {
    return <PageLoader />;
  }

  const header = Name || LABELS.SCHEME_CREATION;

  return (
    <div className={ styles.wrapper }>
      <BackLink
        alternativeDesign={ isSmartAgent }
        qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.back }
        link={ ROUTES.SETTINGS.APPROVAL_SCHEMES_EXPENSE_REPORTS }
      />
      <div className={ styles.content }>
        <Text
          qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.title }
          type='bold_32'
        >
          { header }
        </Text>
        <LinkButton
          theme='blue-without-border'
          className={ styles['how-to'] }
          onClick={ handleSetFaqDialog }
          qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.howWorks }
        >
          { LABELS.HOW_IT_WORKS }
        </LinkButton>
        <Input
          ref={ inputRef }
          // TODO: Input не ожидает autoFocus
          // @ts-ignore
          autoFocus={ !Name }
          className={ styles.name }
          value={ Name }
          placeholder={ LABELS.SCHEME_NAME }
          onChange={ value => handleChangeForm({ Name: value }) }
          qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.schemeName }
        />
        <div className={ styles.form }>
          <div>
            { renderApprovalSteps() }
          </div>
          { renderSidePanel() }
          { renderBottomPanel() }
        </div>
      </div>
      { renderSubmitDialog() }
      { renderErrorDialog() }
    </div>
  );
});

export { ApprovalERSchemePage };
