import { Moment } from 'moment';

import Api from '../../api';

import { approvalSchemesStore } from '../approvalSchemes/stores/schemes';
import { travelApprovalsStore } from './stores/travelApprovals';
import { travelApprovalStore } from './stores/travelApproval';
import { approvesStore } from './stores/approves';

import { bound, withIM } from '../utils/dectrators';
import { formatDate, momentObject } from '../../utils/formatDate';
import NetworkStatesStore from '../utils/network/networkStatesStore';

import {
  ApproveStatus,
  DestinationType,
  EmployeeProjectsType,
  PurposeType,
  RequestApprovesType,
  RequestGetApproverListType,
  TravelApprovalEmployeeType,
  TravelApprovalSchema,
  TravelApprovalType,
} from './consts';
import { APPROVAL_STATUS_PAGE } from '../../constants/travelApproval';
import { PATTERN } from '../../constants/dateFormats';
import STATUS from '../../constants/employee';

import { PurposeInterface } from '../../types/travelApproval';
import { IAnalyticsType } from '../customAnalytics/types';
import { EmployeeType } from '../employee/consts';
import { ISendRequestModelArg } from '../../../page/ApprovalRequest/types';

class TravelApproval {
  netStore = new NetworkStatesStore<'assignRequest'>({ contextRecord: true });

  api: Api['travelApproval'];
  apiApprovalSchemes: Api['approvalSchemes'];
  apiEmployee: Api['employee'];

  travelApprovalsStore = travelApprovalsStore;
  travelApprovalStore = travelApprovalStore;
  approvalSchemesStore = approvalSchemesStore;
  approvesStore = approvesStore;

  constructor(api: Api) {
    this.api = api.travelApproval;
    this.apiApprovalSchemes = api.approvalSchemes;
    this.apiEmployee = api.employee;
  }

  updateLoading = (value: boolean) => this.travelApprovalsStore.setLoading(value);

  updateValidateLoading = (value: boolean) => this.travelApprovalsStore.setValidateLoading(value);

  updateValidateLoadingEmployee = (value: boolean) => this.travelApprovalsStore.setValidateLoadingEmployee(value);

  setLoadingSendButton = (value: boolean) => this.travelApprovalStore.setLoadingSendButton(value);

  updateApprovalLoading = (value: boolean) => this.travelApprovalStore.setLoading(value);

  updateApprovesLoading = (value: boolean) => this.approvesStore.setLoading(value);

  getAccountAdmin = () => this.api.getAdmin();

  setAccountAdmin = () => {
    this.updateApprovalLoading(true);
    this.getAccountAdmin()
      .then((res: EmployeeType) => {
        this.travelApprovalStore.setAdmin(res);
        this.setLoadingSendButton(false);
        this.updateApprovalLoading(false);
      })
      .catch(() => {
        this.updateApprovalLoading(false);
      });
  };

  assignRequestCatch = () => {
    this.travelApprovalsStore.setValidateApprovedRequest({
      AvailableBalance: null,
      CartItemValidationStatuses: null,
      Valid: false,
    });
  };

  @bound
  @withIM(o =>
    o.netStore.withLoaderFlowCoroutine('assignRequest', o.assignRequestCatch),
  )
  async assignRequest(reqId: number, cartId: number) {
    try {
      const res = await this.api.assignRequest(reqId, cartId);
      this.travelApprovalsStore.setValidateApprovedRequest(res);
    } catch (e) {
      this.assignRequestCatch();
    }
  }

  assignApprovalRequestInCart = async (reqId: number, cartId: number) => {
    this.updateValidateLoading(true);

    this.travelApprovalsStore.setChosenApprovedRequest(reqId);

    await this.assignRequest(reqId, cartId);

    this.updateValidateLoading(false);
  };

  assignApprovalRequestInCartByEmployee = async (reqId: number, cartId: number) => {
    this.updateValidateLoadingEmployee(true);

    await this.assignRequest(reqId, cartId);

    this.updateValidateLoadingEmployee(false);
  };

  getTravelApprovalCart = async (normalCart: { id: number; }, UserId: string | number) => {
    await this.getTravelApprovalsInCart(normalCart, UserId);
    this.setAccountAdmin();
  };

  getTravelApprovalsInCart = async (cart: { id: number; }, UserId: number | string) => {
    this.updateValidateLoading(true);

    try {
      const res = await this.api.getTravelApprovalsByUserId(UserId) as TravelApprovalType[];

      this.travelApprovalsStore.setSources(res);

      const checkStatusRes = res.filter(({ Status }) => Status === 'Accepted');

      if (checkStatusRes.length === 1 && cart.id !== undefined && checkStatusRes[0].Id) {
        await this.assignRequest(checkStatusRes[0].Id, cart.id);
      }

      this.updateValidateLoading(false);
    } catch (e) {
      this.travelApprovalsStore.setSources([]);
      this.updateValidateLoading(false);
    }
  };

  loadTravelApprovalsPopUp = async (approvalScheme: TravelApprovalType) => {
    const RequestId = approvalScheme.Approves && approvalScheme.Approves[0] ? approvalScheme.Approves[0].RequestId : approvalScheme.Id;

    try {
      const res = await this.api.getApprovedPopUp(RequestId);

      this.travelApprovalStore.setApprovalPopUp(res);
    } catch (error) {
      this.travelApprovalStore.setApprovalPopUp(null);
    }
  };

  setTravelApproval = (approvalScheme?: TravelApprovalType | null) => {
    this.travelApprovalStore.setTravelApproval(
      approvalScheme || TravelApprovalSchema.getDefault(),
    );
  };

  setUsersCustomAnalytics = (userAnalytics: IAnalyticsType[]) => this.travelApprovalStore.setUsersCustomAnalytics(userAnalytics);

  setCustomAnalytic = (customAnalytic: IAnalyticsType) => this.travelApprovalStore.setCustomAnalytic(customAnalytic);

  setTripTags = (tripTags: number[]) => this.travelApprovalStore.setTripTags(tripTags);

  unSetCustomAnalytic = (customAnalytic: number) => this.travelApprovalStore.unSetCustomAnalytic(customAnalytic);

  setTravelApprovalStatusPage = (status: string) => this.travelApprovalStore.setStatusPage(status);

  sendRequest = async (item: ISendRequestModelArg, ApprovalSchemeId: number) => {
    const {
      travelApproval: {
        StartDate, EndDate, Budget, Analytics, Purpose, Destinations, Employees, Comment, Tags,
      },
      purposes,
    } = this.travelApprovalStore;

    this.setShowApproveDialog(false);
    this.updateApprovalLoading(true);

    const updateEmployees = Employees.map(({ Id, Name, Surname, Patronymic, CompanyId, DepartmentId, ProjectId }) => ({
      Id,
      Name,
      Surname,
      Patronymic,
      CompanyId,
      DepartmentId,
      ProjectId,
    }));

    const finderPurpose = purposes.find(({ value }) => value === Purpose as unknown as number);
    const preparePurpose = finderPurpose ? {
      Id: Purpose,
      Name: finderPurpose.label,
    } : {
      Id: 0,
      Name: Purpose,
    };

    const allDestinationsEmpty = Destinations.every(destination => !destination.Id);
    const updateDestination = allDestinationsEmpty ? [] : Destinations.map(({ Id, Name }) => ({ SourceId: Id, Name }));
    const updatedPurpose = preparePurpose?.Name ? preparePurpose : null;

    const updateModel = {
      StartDate: StartDate ? formatDate(StartDate, PATTERN.YEAR_MONTH_DAY_TIME) : null,
      EndDate: EndDate ? formatDate(EndDate, PATTERN.YEAR_MONTH_DAY_TIME) : null,
      Budget: Number(Budget),
      Analytics,
      Destinations: updateDestination,
      Employees: updateEmployees,
      Purpose: updatedPurpose,
      ApprovalSchemeId,
      ApprovalComment: item.ApprovalComment,
      Comment,
      StepsSettings: item.StepsSettings,
      Tags,
    };

    try {
      await this.api.sendApprovalRequest(updateModel);
    } catch {}
  };

  loadEmployeesWithRights = (): Promise<EmployeeProjectsType[]> => this.apiEmployee.getWithRights();

  setEmployeesWithRights = () => {
    this.updateApprovalLoading(true);
    this.loadEmployeesWithRights()
      .then((employees) => {
        const userIds = employees.filter(employee => employee.Status === STATUS.ACCESS.USER).map(({ Id }) => Id);
        const allEmployeeIds = this.travelApprovalStore.travelApproval?.Employees.map(({ Id }) => Id) || [];

        this.loadEmployeeCards([...allEmployeeIds, ...userIds] as number[])
          .then((employeesWithProjects) => {
            this.travelApprovalStore.setUsers(employeesWithProjects);
            this.updateApprovalLoading(false);
          });
      });
  };

  setSuggestValue = (value: DestinationType, index: number) => this.travelApprovalStore.setSuggestCityValue(value, index);

  addIntermediateCity = () => this.travelApprovalStore.addIntermediateCity();

  deleteIntermediateCity = (Id: number) => this.travelApprovalStore.deleteIntermediateCity(Id);

  setRemoveSuggestValue = (suggest: object, index: number) => this.travelApprovalStore.setRemoveSuggestValue(suggest, index);

  changeStartDate = (value: Moment | string | null) => this.travelApprovalStore.setStartDate(momentObject(value));

  changeEndDate = (value: string) => this.travelApprovalStore.setEndDate(value);

  inputBudgetChange = (value: number) => this.travelApprovalStore.setBudget(value);

  purposeChange = (value: PurposeType) => this.travelApprovalStore.setPurpose(value);

  commentChange = (value: string) => this.travelApprovalStore.setComment(value);

  addEmployee = (item: TravelApprovalEmployeeType, index: number) => this.travelApprovalStore.setEmployee(item, index);

  addProject = (projectId: number | null, employeeId: number) => {
    this.travelApprovalStore.setTravelApprovalProject(projectId, employeeId);
  };

  updateProject = async (projectId: number | null, employeeId: number, requestId: number | string) => {
    try {
      await this.api.updateEmployeeProject(requestId, projectId, employeeId);
      this.travelApprovalStore.setTravelApprovalProject(projectId, employeeId);
    } catch (e) {
      return null;
    }

    return null;
  };

  loadEmployeeCards = async (employeesIds: number[]) => {
    const projectsEmployees = await this
      .apiEmployee
      .getEmployeeListWithProjects(employeesIds) as EmployeeProjectsType[];
    this.travelApprovalStore.setProjectsEmployees(projectsEmployees);

    return projectsEmployees;
  };

  removeEmployee = (item: TravelApprovalEmployeeType) => this.travelApprovalStore.deleteEmployee(item);

  addEmployeeCompany = (item: number, index: number) => this.travelApprovalStore.addEmployeeCompany(item, index);

  addMoreEmployee = () => this.travelApprovalStore.addMoreEmployee();

  setShowApproveDialog = (value: boolean) => this.travelApprovalStore.setShowApproveDialog(value);

  getApproverScheme = () => {
    this.approvalSchemesStore.updateApproverListLoading(true);
    const data = this.travelApprovalStore.travelApproval.Employees.map(employee => ({
      Id: employee.Id,
      CompanyId: employee.CompanyId,
      DepartmentId: employee.DepartmentId,
      ProjectId: employee.ProjectId,
    }));

    this.apiApprovalSchemes.loadApprovers(data)
      .then((scheme: RequestGetApproverListType) => {
        this.travelApprovalStore.setScheme(scheme);
        this.approvalSchemesStore.updateApproverListLoading(false);
      });
  };

  setResetStore = () => this.travelApprovalStore.setResetStore();

  setResetTravelApprovalsForCartStore = () => this.travelApprovalsStore.setResetForCart();

  setResetTravelApprovalsStore = () => this.travelApprovalsStore.resetStore();

  getRequest = (id: number | string) => this.api.getRequest(id) as Promise<TravelApprovalType>;

  getStatusFromBackend = (status: ApproveStatus) => ({
    [ApproveStatus.waitingApprove]: () => APPROVAL_STATUS_PAGE.WAITING_APPROVE,
    [ApproveStatus.declined]: () => APPROVAL_STATUS_PAGE.NOT_APPROVE,
    [ApproveStatus.accepted]: () => APPROVAL_STATUS_PAGE.APPROVE,
    [ApproveStatus.archived]: () => APPROVAL_STATUS_PAGE.NOT_APPROVE,
    [ApproveStatus.autoAccepted]: () => APPROVAL_STATUS_PAGE.APPROVE,
    [ApproveStatus.autoDeclined]: () => APPROVAL_STATUS_PAGE.NOT_APPROVE,
  }[status]());

  approveRequest = (data: { Id: number; Resolution: string; ApprovalComment: string; }) => this.api.approveRequest(data);

  preparePurpose = (purposes: PurposeInterface[], purpose: PurposeInterface | null | string) => {
    if (!purpose) return null;

    let preparePurpose = null;

    const finderPurpose = purposes.find(({ label, value }) => {
      if (typeof purpose === 'object' && purpose !== null) {
        return label === purpose?.Name;
      }

      return value === purpose;
    });

    if (typeof purpose === 'string') {
      preparePurpose = {
        Id: 0,
        Name: purpose,
      };
    }

    if (typeof purpose === 'object') {
      preparePurpose = finderPurpose?.Id ? {
        Id: purpose.Id,
        Name: finderPurpose?.label,
      } : {
        Id: purpose.value,
        Name: purpose?.Name,
      };
    }

    if (Number.isInteger(purpose)) {
      preparePurpose = {
        Id: purpose,
        Name: finderPurpose?.label,
      };
    }

    return preparePurpose?.Name ? preparePurpose : null;
  };

  updateApprovalRequest = async (_comment: string, id: number) => {
    const {
      travelApproval: {
        StartDate, EndDate, Budget, Purpose, Destinations, Id: requestId, Comment, Analytics, Tags,
      },
      purposes,
    } = this.travelApprovalStore;

    const preparedPurpose = this.preparePurpose(purposes, Purpose);

    const hasNonEmptyDestination = Destinations.some(destination => destination.SourceId || destination.Id);
    const destinationsFilterd = Destinations.filter(destination => destination.SourceId || destination.Id);
    const updateDestination = hasNonEmptyDestination ? destinationsFilterd.map(({ SourceId, Name, Id }) => ({ SourceId: SourceId || Id, Name })) :
      [];

    const updateModel = {
      Analytics,
      StartDate: StartDate ? formatDate(StartDate, PATTERN.YEAR_MONTH_DAY_TIME) : null,
      EndDate: EndDate ? formatDate(EndDate, PATTERN.YEAR_MONTH_DAY_TIME) : null,
      Budget: Number(Budget),
      Destinations: updateDestination,
      Purpose: preparedPurpose,
      Tags,
      Comment,
    };

    try {
      await this.api.updateApprovalRequest(updateModel, requestId, id);
    } catch {}
  };

  getApproveRequestExists = async (id: number | null) => {
    try {
      const res = await this.api.getApproveRequestExists(id);

      this.travelApprovalsStore.setRequestExists(res);
    } catch (e) {
      this.travelApprovalsStore.setRequestExists(false);
    }
  };

  getApproves = async () => {
    this.updateApprovesLoading(true);

    try {
      const res = await this.api.getApproves() as RequestApprovesType[];

      this.approvesStore.setApproves(res);
      this.updateApprovesLoading(false);
    } catch (e) {
      this.approvesStore.setApproves([]);
      this.updateApprovesLoading(false);
    }
  };
}

export default TravelApproval;
