import React, { RefObject } from 'react';
import { Suggest, Text, IconButton } from 'new-ui';
import { SuggestItems } from 'new-ui/src/components/Suggest/types';

import { getText } from '../../../../../i18n';

import { FieldLabel } from '../../../../components/FieldLabel';

import { HOTEL_FORM_REQUEST } from '../../../../bi/constants/hotel';
import { DestinationType, TravelApprovalType } from '../../../../bi/services/travelApproval/consts';

import styles from './index.module.css';

const LABELS = {
  CITY: getText('components:travelApproval.city'),
  INTERMEDIATE_CITY: getText('approvalScheme:intermediateCity'),
  ADD_INTERMEDIATE_CITY: getText('components:travelApproval.addIntermediateCity'),
};

interface ISelectCityProps {
  travelApproval: TravelApprovalType,
  region: any,
  onSuggestSelected(value: DestinationType, index: number): void,
  onGetSuggests(query: string): void,
  onDeleteSelected(suggest: object, index: number): void,
  onAddIntermediateCity(): void,
  onDeleteIntermediateCity(Id: number): void,
  disabledField: boolean,
  suggestRef: RefObject<HTMLInputElement>,
  isRequired: string | number | undefined | boolean | null,
  qaAttrCity: any,
}

const SelectCity = ({
  travelApproval,
  region,
  onSuggestSelected,
  onGetSuggests,
  onDeleteSelected,
  disabledField,
  suggestRef,
  onAddIntermediateCity,
  onDeleteIntermediateCity,
  isRequired,
  qaAttrCity,
}: ISelectCityProps) => {
  const handleSuggestSelected = (suggest: DestinationType, index: number) => {
    if (suggest !== null) {
      onSuggestSelected(suggest, index);
    }
  };

  const handleDeleteSelected = (suggest: DestinationType, index: number) => {
    onDeleteSelected(suggest, index);
  };

  const handleClose = (suggest: DestinationType, index: number, suggests: SuggestItems[]) => {
    if (!suggests.length) {
      handleDeleteSelected(suggest, index);
    } else {
      // @ts-ignore
      handleSuggestSelected({
        FullName: '',
        Name: '',
        Id: null,
      }, index);
    }
  };

  const renderSuggestionItem = ({ Name, FullName }: DestinationType) => {
    const hintHtml = FullName && (
      <Text
        type='NORMAL_12'
        className={ styles.hint }
        color='gray'
      >
        { FullName }
      </Text>
    );

    return (
      <>
        <Text>{ Name }</Text>
        { hintHtml }
      </>
    );
  };

  const renderDeleteIntermediateCityIcon = (_Id: number | null, index: number) => {
    if (index === 0 || disabledField) return null;

    return (
      <IconButton
        iconType='closeButton'
        className={ styles.button }
        theme='default-red'
        onClick={ () => onDeleteIntermediateCity(index) }
      />
    );
  };

  const renderAddIntermediateCityIcon = (index: number) => {
    if (disabledField) return null;

    if (travelApproval.Destinations && travelApproval.Destinations.length - 1 !== index) return null;

    return (
      <div className={ styles.add_city }>
        <IconButton
          iconType={ 'plusRound' }
          theme={ 'default-red' }
          onClick={ onAddIntermediateCity }
        >
          { LABELS.ADD_INTERMEDIATE_CITY }
        </IconButton>
      </div>
    );
  };

  const renderCity = ({ Name, Id }: DestinationType, i: number) => {
    const filterSuggests = region.suggests.filter((it: { title: string }) => it.title === HOTEL_FORM_REQUEST.REGIONS);
    const suggests = filterSuggests.length > 0 ? filterSuggests[0].items : filterSuggests;
    const name = Name?.split(',')[0];
    const inputStyles = [styles.input];
    const label = i === 0 ? LABELS.CITY : `${LABELS.INTERMEDIATE_CITY} ${i}`;

    if (disabledField) {
      inputStyles.push(styles.input_disabled);
    }

    return (
      <div className={ styles.flex_column } key={ `city_${i}_${Name}` }>
        <div className={ `${styles.item} ${styles.suggest}` }>
          <FieldLabel text={ label } mustHave={ !!isRequired } />
          <div className={ styles.flex_row }>
            <Suggest
              theme='border'
              ref={ suggestRef }
              withLabel={ false }
              shouldFocusItem={ () => false }
              value={ name }
              items={ suggests }
              // @ts-ignore
              onSelect={ (suggest: DestinationType) => handleSuggestSelected(suggest, i) }
              // @ts-ignore
              onClose={ (suggest: DestinationType) => handleClose(suggest, i, suggests) }
              onChange={ query => onGetSuggests(query) }
              // @ts-ignore
              renderItem={ item => renderSuggestionItem(item) }
              inputClassName={ inputStyles.join(' ') }
              contentClassName={ styles.input_content }
              disabled={ disabledField }
              qaAttr={ qaAttrCity?.input || '' }
              qaAttrFirstEl={ qaAttrCity?.suggestFirst || '' }
            />
            {renderDeleteIntermediateCityIcon(Id, i)}
          </div>
        </div>
        {renderAddIntermediateCityIcon(i)}
      </div>
    );
  };

  return travelApproval.Destinations.map((item, i) => renderCity(item, i));
};

export { SelectCity };
