import React, { useEffect, useRef } from 'react';
import { Text, PageLoader, EmptyPanel } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getText } from '../../../i18n';

import { stringifySearchParams } from '../../bi/utils/convertSearchParams';
import { MainAnalytic } from '../../bi/utils/analytics';
import { getDataForEmptyPanel } from '../../bi/utils/searchPanel';
import { isSmartAgent } from '../../bi/utils/env';

import {
  SERVICETYPE,
  SERVICETYPERUMN,
} from '../../bi/constants/serviceType';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { STICKY } from '../../bi/constants/stickySearch';

import { FilterTabs } from '../../components/FilterTabs';
import StickySearchPanel from '../../components/StickySearchPanel';

import TransferItem from './components/TransferItem';
import { TrainItem } from './components/TrainItem';
import HotelItem from './components/HotelItem';
import { AirlineItem } from './components/AirlineItem';
import { FavoriteItemWrap } from './components/FavoriteItemWrap';

import FavoritesService from '../../bi/services/favorites';
import NotificationService from '../../bi/services/notification';
import TransferService from '../../bi/services/transfer';
import AirlineService from '../../bi/services/airline';
import TrainService from '../../bi/services/trains';
import HotelsService from '../../bi/services/hotels';
import FeatureFlags from '../../bi/services/featureFlags';
import AccountSettings from '../../bi/services/accountSettings';
import { UiSettingsProtocol } from '../../bi/protocols/uiSettings';
import { IFavoritesStore } from '../../bi/services/favorites/store';
import { IFavorite } from '../../bi/types/favorites';

import styles from './style.module.css';

const LABELS = {
  HEADER: getText('favorites:title'),
  QUICK_SEARCH: getText('favorites:fastSearch'),
  LOADING: getText('favorites:loader'),
  EMPTY: getText('favorites:empty'),
  REMOVE: getText('favorites:notifications.remove'),
};

const FAVORITE_ITEM = {
  [SERVICETYPE.AIR]: AirlineItem,
  [SERVICETYPE.TRAIN]: TrainItem,
  [SERVICETYPE.TRANSFER]: TransferItem,
  [SERVICETYPE.HOTEL]: HotelItem,
};

const FAVORITE_ITEM_QA_ATTRS = {
  [SERVICETYPE.AIR]: QA_ATTRIBUTES.favorites.airline,
  [SERVICETYPE.TRAIN]: QA_ATTRIBUTES.favorites.train,
  [SERVICETYPE.HOTEL]: QA_ATTRIBUTES.favorites.hotel,
};

interface IFavoritesProps {
  store: IFavoritesStore;
  favoritesService: FavoritesService;
  notificationService: NotificationService;
  transferService: TransferService;
  airlineService: AirlineService;
  trainService: TrainService;
  hotelService: HotelsService;
  featureFlagsService: FeatureFlags;
  accountSettingsService: AccountSettings;
  history: RouteComponentProps['history'];
  uiSettingsProtocol: UiSettingsProtocol;
}

const Favorites = observer(({
  store,
  favoritesService,
  notificationService,
  transferService,
  airlineService,
  trainService,
  hotelService,
  accountSettingsService,
  history,
  uiSettingsProtocol,
}: IFavoritesProps) => {
  const { loading, cache, favorites, tabs, searchInput, currentTab } = store;
  const isNoBookingTaxi = useRef<boolean>(accountSettingsService.getBookingTaxi());

  useEffect(() => {
    favoritesService.load();
  }, []);

  const getOtherProps = (type: string) => {
    if (type === SERVICETYPE.TRAIN) {
      return {
        trainService,
      };
    }

    return null;
  };

  const handleGoToSearch = (favorite: any, data: any, Hash: string) => {
    let service = null;
    let uri = null;

    switch (favorite.Type) {
      case SERVICETYPE.AIR: {
        service = airlineService;
        uri = ROUTES.SEARCH.AIR;
        break;
      }
      case SERVICETYPE.HOTEL: {
        service = hotelService;
        uri = ROUTES.SEARCH.HOTEL_FAVORITE(Hash);
        break;
      }
      case SERVICETYPE.TRAIN: {
        service = trainService;
        uri = ROUTES.SEARCH.TRAIN_RESULTS;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        service = transferService;
        uri = ROUTES.SEARCH.TRANSFER;
        break;
      }
    }

    if (service) {
      let searchParams;
      service.setNewSearch(); // TODO проверить

      switch (favorite.Type) {
        case SERVICETYPE.HOTEL: {
          searchParams = {
            // @ts-ignore
            ...service.setSearchHotelFavoriteObject(data),
            is_favorite: true,
          };
          break;
        }
        case SERVICETYPE.TRANSFER: {
          searchParams = {
            // @ts-ignore
            ...service.getSearchObjectByFavorites(favorite.Item),
            is_favorite: true,
          };
          break;
        }

        default: {
          searchParams = {
            ...service.getSearchObject(favorite.Item, data),
            is_favorite: true,
          };
          break;
        }
      }

      const searchString = stringifySearchParams(searchParams);
      history.push({
        // @ts-ignore
        pathname: uri,
        search: searchString,
      });

      MainAnalytic.send(
        MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.SEARCH, {
          label: SERVICETYPERUMN[favorite.Type],
        });
    }
  };

  const handleRemoveItem = (favoriteId: string, favorite: IFavorite) => {
    favoritesService.remove(favoriteId).then(() => {
      notificationService.send({
        message: LABELS.REMOVE,
        // @ts-ignore
        level: 'success',
        qaAttr: QA_ATTRIBUTES.favorites.notification,
      });

      MainAnalytic.send(
        MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.REMOVEFROMFAVORITES,
        { label: SERVICETYPERUMN[favorite.Type] },
      );
    });
  };

  const handleOpenDialog = (type: string) => {
    MainAnalytic.send(
      MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.GOTOSEARCH,
      { label: SERVICETYPERUMN[type] },
    );
  };

  const renderEmptyPage = () => (
    <EmptyPanel
      // @ts-ignore
      searchMenuItems={ getDataForEmptyPanel(uiSettingsProtocol.searchServiceTypeForMenu, isNoBookingTaxi.current) }
      title={ LABELS.EMPTY }
      iconType='menuFavorite'
      alternativeDesign={ isSmartAgent }
      qaAttr={ QA_ATTRIBUTES.favorites.empty }
      qaAttrSearchPanel={ QA_ATTRIBUTES.favorites.searchPanel }
    />
  );

  const renderItems = () =>
    favorites.map((favorite) => {
      const { Type, Id, Item, Hash } = favorite;
      const FavoriteItem = FAVORITE_ITEM[Type];
      const qaAttrs = FAVORITE_ITEM_QA_ATTRS[Type];

      const dialogProps =
        Type === SERVICETYPE.AIR
          ? {
            // @ts-ignore
            isBothSide: Item.Routes.length > 1,
          }
          : null;

      return (
        <FavoriteItemWrap
          key={ Id }
          id={ Id }
          type={ Type }
          dialogProps={ dialogProps }
          onSearch={ (data: any) => handleGoToSearch(favorite, data, Hash) }
          onRemoveItem={ () => handleRemoveItem(Id, favorite) }
          onOpenDialog={ handleOpenDialog }
          qaAttrChooseDates={ QA_ATTRIBUTES.favorites.chooseDates }
          qaAttrDelete={ QA_ATTRIBUTES.favorites.delete }
          qaAttrDeleteDialog={ QA_ATTRIBUTES.favorites.deleteDialog }
          qaAttrDialog={ QA_ATTRIBUTES.favorites.chooseDateDialog }
          qaAttrIcon={ QA_ATTRIBUTES.favorites.wrapIcon }
          { ...getOtherProps(Type) }
        >
          {/* @ts-ignore */}
          <FavoriteItem favorite={ favorite } qaAttrs={ qaAttrs } />
        </FavoriteItemWrap>
      );
    });

  const renderFilters = () => (
    <FilterTabs
      tabs={ tabs }
      currentTab={ currentTab }
      onClick={ favoritesService.setCurrentTab }
      qaAttr={ QA_ATTRIBUTES.favorites.filter }
    />
  );

  const renderSearch = () => (
    <StickySearchPanel
      adjustment={ STICKY.ADJUSTMENT }
      initial={ tabs.length > 2 ? 313 : 228 }
      style={ STICKY.STYLES }
      query={ searchInput }
      placeholder={ LABELS.QUICK_SEARCH }
      onChangeInput={ favoritesService.setSearchInput }
      qaAttr={ QA_ATTRIBUTES.favorites.search }
    />
  );

  if (loading) {
    return <PageLoader text={ LABELS.LOADING } />;
  }

  if (!cache.length && !favorites.length) {
    return renderEmptyPage();
  }

  const filtersHtml = !!tabs.length && renderFilters();
  const searchHtml = !!cache.length && renderSearch();

  return (
    <div className={ styles.wrapper }>
      <Text
        type='bold_32'
        className={ styles.header }
        qaAttr={ QA_ATTRIBUTES.favorites.header }
      >
        {LABELS.HEADER}
      </Text>
      {filtersHtml}
      {searchHtml}
      <div className={ styles.list }>
        {renderItems()}
      </div>
    </div>
  );
});

export default Favorites;
