import React from 'react';
import { Button, Text, Icon } from 'new-ui';
import clsx from 'clsx';

import { getText } from '../../../../../i18n';

import ROUTES from '../../../../bi/constants/routes';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('smartdesk:autoreg'),
  GO: getText('smartdesk:goAutoreg'),
};

interface ITravelPopup {
  isPopup: boolean,
}

const TravelPanel = ({ isPopup }: ITravelPopup) => {
  const handleGo = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.AUTO_REGISTRATION);
    window.open(ROUTES.SMARTAGENT_AUTOREG, '_blank');
  };

  const wrapper = clsx(styles.wrapper, {
    [styles.wrapper_z]: isPopup,
  });

  return (
    <div className={ wrapper }>
      <div className={ styles.sub_wrapper }>
        <Icon
          className={ styles.icon }
          type='infoSa'
          alternativeDesign={ isSmartAgent }
        />
        <Text type='NORMAL_16' className={ styles.text }>
          { LABELS.TITLE }
        </Text>
      </div>
      <Button
        className={ styles.button }
        onClick={ handleGo }
      >
        { LABELS.GO }
      </Button>
    </div>
  );
};

export default TravelPanel;
