import { ServiceReturnType } from '../../context/services';

class Chat {
  services: ServiceReturnType;

  constructor(services: ServiceReturnType) {
    this.services = services;
  }

  prepareChatService = () => {
    const { CarrotQuestChat } = this.services.FeatureFlags.getFeatureFlags();

    if (CarrotQuestChat) {
      this.services.ChatDecorator.setNewChat();
    }
  };
}

export { Chat };
