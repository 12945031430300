import { TITLES, CHARTS_ANALYTICS } from '../../constants/chartsAnalytics';

import { ServiceReturnType } from '../../context/services';

import { IAnalyticsType } from '../../services/customAnalytics/types';
import { ICompanies } from '../../services/checkout/types';

class ChartsAnalytics {
  services: ServiceReturnType;

  constructor(services: ServiceReturnType) {
    this.services = services;
  }

  preparedCompanies = () => {
    const companies = this.services.Workspace.get().Companies;

    this.services.ChartsAnalytics.preparedCompanies(companies);
  };

  preparedData = async () => {
    await this.getCustomAnalytics();
    await this.getDepartments();
    this.preparedMenu(); // здесь из фф приходит список меню, которое можно показать, как откроется this.getCustomAnalytics();, перетащить логику туда
    this.preparedCompanies();
    this.services.ChartsAnalytics.preparedDates(null);
  };

  preparedMenu = () => {
    // const showDepartmentsMenu = this.ChartsAnalytics.getShowDepartmentsMenu();
    // const departmentsInMenu = showDepartmentsMenu ? MENU_KEYS.STRUCTURE.DEPARTMENTS : null;
    // const departmentsInMenu = false;
    const openMenu = this.services.FeatureFlags.getOpenMenuChartsAnalytics();

    this.services.ChartsAnalytics.setMenu(openMenu as string[]);
  };

  getDepartments = async () => {
    try {
      const departments = await this.services.Departments.justLoadList();

      this.services.ChartsAnalytics.setShowDepartments(departments.some((i: ICompanies) => i.Departments.length));
    } catch {
      this.services.ChartsAnalytics.setShowDepartments(false);
    }
  };

  getCustomAnalytics = async () => {
    try {
      const customAnalytics = await this.services.CustomAnalytics.getCustomAnalytics();

      if (customAnalytics.length) {
        const mappedCustomAnalytics = customAnalytics.map(({
          Name, Id,
        }: IAnalyticsType) => ({
          id: CHARTS_ANALYTICS(Id as unknown as string),
          title: TITLES.STRUCTURE.CUSTOM_ANALYTICS(Name),
        }));

        this.services.ChartsAnalytics.setCustomAnalyticsToMenu(mappedCustomAnalytics);
        this.services.ChartsAnalytics.setCustomAnalytics(customAnalytics);
      }
    } catch {
      this.services.ChartsAnalytics.setCustomAnalyticsToMenu([]);
    }
  };
}

export { ChartsAnalytics };
