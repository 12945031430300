export const isAccountCreatedWithin7Days = (dateString: string | null) => {
  if (!dateString) {
    return false;
  }

  const date = new Date(dateString);

  if (!dateString || isNaN(date.getTime())) {
    return false;
  }

  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - 8);

  return date >= sevenDaysAgo && date <= currentDate;
};
