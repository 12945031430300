import React from 'react';
import { DefaultBarChart, Table, Icon, Text, Tooltip } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';
import { ContentType } from '../../../../../../bi/services/chartsAnalytics/types';

import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import { TYPE } from '../../../../../../bi/constants/chartsAnalytics';

import styles from '../styles.module.css';

const LABELS = {
  INFO_NAME: getText('chartsAnalytics:airline.infoName'),
  FIRST_TEXT_TOOLTIP: getText('chartsAnalytics:airline.textTooltip.first'),
  SECOND_TEXT_TOOLTIP: getText('chartsAnalytics:airline.textTooltip.second'),
  THIRD_TEXT_TOOLTIP: getText('chartsAnalytics:airline.textTooltip.third'),
};

interface SummaryFirstPageProps {
  values: number[],
  labels: string[],
  legend?: string,
  horizontal?: boolean,
  labelsHeader: string[],
  content: ContentType[],
  showTooltip?: boolean,
  chartsAnalyticsService: ChartsAnalytics,
  redirectToSubPage(): void,
  dates?: any,
}

const SummaryFirstPage = ({
  values,
  labels,
  legend,
  dates,
  content,
  labelsHeader,
  chartsAnalyticsService,
  redirectToSubPage,
  showTooltip = false,
  horizontal = false,
}: SummaryFirstPageProps) => {
  const handleOpenNestedTablePage = (subPageInfo: any, isCanClick = false, serviceType: string) => {
    if (!isCanClick) {
      return;
    }

    const { type } = subPageInfo;
    const analyticType = ({
      [TYPE.AIR]: MainAnalytic.ACTIONS.ANALYTICS.AIRLINE_SUMMARY_OPENED,
      [TYPE.TRAIN]: MainAnalytic.ACTIONS.ANALYTICS.TRAIN_SUMMARY_OPENED,
      [TYPE.HOTEL]: MainAnalytic.ACTIONS.ANALYTICS.HOTEL_SUMMARY_OPENED,
      [TYPE.TAXI]: MainAnalytic.ACTIONS.ANALYTICS.TAXI_SUMMARY_OPENED,
      [TYPE.TRANSFER]: MainAnalytic.ACTIONS.ANALYTICS.TRANSFER_SUMMARY_OPENED,
      [TYPE.AEROEXPRESS]: MainAnalytic.ACTIONS.ANALYTICS.AEROEXPRESS_SUMMARY_OPENED,
      [TYPE.MICE]: MainAnalytic.ACTIONS.ANALYTICS.MICE_SUMMARY_OPENED,
    })[serviceType.toLowerCase()];

    MainAnalytic.sendAmplitude(analyticType, { summary: type });
    chartsAnalyticsService.changeSubPageInfo(subPageInfo);
    chartsAnalyticsService.changeSummaryType(serviceType);
    redirectToSubPage();
  };

  const handleOpenNestedTablePageChart = (barValue: any) => {
    if (dates) {
      chartsAnalyticsService.updateDatesByChart(barValue, dates);
    }

    handleOpenNestedTablePage(content[0].subPageInfo, true, content[0].serviceType);
  };

  const mapperData = (value: string, indexData: number) => {
    if (indexData === 0) return null;

    return (
      <td key={ indexData }>{ value }</td>
    );
  };

  const classNames = (isCanClick: boolean) => (isCanClick ? styles.cursor : '');

  const updateLabelsHeader = (string: string, indexRow: number) => {
    if (showTooltip && indexRow === 0) {
      return (
        <div className={ styles['labels-header'] }>
          { string }
          <Tooltip renderContent={ () => (
            <div className={ styles['tooltip-wrapper'] }>
              <Text type='NORMAL_14_130' color='white' className={ styles.row }>{ LABELS.FIRST_TEXT_TOOLTIP }</Text>
              <Text type='NORMAL_14_130' color='white' className={ styles.row }>{ LABELS.SECOND_TEXT_TOOLTIP }</Text>
              <Text type='NORMAL_14_130' color='white' className={ styles.row }>{ LABELS.THIRD_TEXT_TOOLTIP }</Text>
            </div>
          ) }
          >
            <Icon className={ styles.icon } type='question' />
          </Tooltip>
        </div>
      );
    }

    return string;
  };

  return (
    <>
      <div className={ styles['chart-wrapper'] }>
        <DefaultBarChart
          values={ values }
          barLabels={ labels }
          legend={ legend }
          getCurrentBarValue={ (value) => handleOpenNestedTablePageChart(value) }
          horizontal={ horizontal }
        />
      </div>
      <div className={ styles.table }>
        <Table
          labelsHeader={ labelsHeader }
        >
          { content.map(({ data, subPageInfo, serviceType }, indexRow) => (
            <tr key={ indexRow } className={ classNames(!!data[1]) } onClick={ () => handleOpenNestedTablePage(subPageInfo, !!data[1], serviceType) }>
              <td>{ updateLabelsHeader(data[0], indexRow) }</td>
              { data.map((value, indexData) => mapperData(value, indexData))}
            </tr>
          )) }
        </Table>
      </div>
    </>
  );
};

export { SummaryFirstPage };
