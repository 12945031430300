import React from 'react';
import { SearchPanel, SearchMenuItemTypes } from 'new-ui';

import { HeroTitle } from '../HeroTitle';
import { CustomBanner } from '../CustomBanner';

import { isSmartAgent } from '../../../../bi/utils/env';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { IMenuItem } from '../../../../bi/types/app';

import styles from './index.module.css';

const SEARCH_PANEL_THEME = 'rectangle';

interface IPanelProps {
  customHeroTitle: boolean;
  isExternalLink: boolean;
  searchItems: IMenuItem[];
  handleSendToAmplitude(type: SearchMenuItemTypes): void;
}

export const Panel = ({
  customHeroTitle,
  isExternalLink,
  searchItems,
  handleSendToAmplitude,
}: IPanelProps) => {
  const clasess = [styles.menu];

  if (isSmartAgent) {
    clasess.push(styles.flex_menu);
  }

  const searchTheme = isSmartAgent && SEARCH_PANEL_THEME;

  const renderHeroTitleWorkplace = !isSmartAgent && <HeroTitle customHeroTitle={ customHeroTitle } />;
  const renderCustomBannerWorkplace = !isSmartAgent && <CustomBanner isExternalLink={ isExternalLink } />;

  return (
    <>
      <div className={ styles.location }>
        { renderHeroTitleWorkplace }
        { renderCustomBannerWorkplace }
      </div>
      <div className={ clasess.join(' ') }>
        <SearchPanel
          alternativeDesign={ isSmartAgent }
          theme={ searchTheme as 'rectangle' }
            // @ts-ignore
          searchMenuItems={ searchItems }
          qaAttr={ QA_ATTRIBUTES.smartdesk.searchPanel }
          onClick={ handleSendToAmplitude }
        />
      </div>
    </>
  );
};
