import { ServiceReturnType } from '../../context/services';

import { GET_AEROEXPRESS_ADDED_TO_CART_TEXT } from '../../services/aeroexpress/const';
import { QA_ATTRIBUTES } from '../../constants/attributesForTests';

import { ICountAndComplex, IRoutes } from '../../types/airline';

class PopupsProtocol {
  services: ServiceReturnType;

  constructor(services: ServiceReturnType) {
    this.services = services;
  }

  offerAeroexpress = (
    airlineTrip: IRoutes,
    { count, isComplex }: ICountAndComplex,
    tripId: number | null = null,
    requestItemId: number | null = null,
  ) => {
    this.services.Popups.offerAeroexpressPurchase(
      airlineTrip,
      { count, isComplex },
      async (aeroexpressSearchModel) => {
        this.services.Aeroexpress.updateSearchSettings(aeroexpressSearchModel);
        await this.services.Aeroexpress.search();

        const searchIds = this.services.Aeroexpress.store.searchResult.items.reduce((r, { id }) => ([...r, id]), []);

        searchIds.forEach(k =>
          this.services.Aeroexpress.addToCart({ SearchId: k, RequestId: requestItemId || null, TripId: tripId })
            .then(() => {
              this.services.Notification.send({
                message: GET_AEROEXPRESS_ADDED_TO_CART_TEXT,
                type: 'success',
                qaAttr: QA_ATTRIBUTES.search.aeroexpress.popup.aeroexpress.notification,
              });

              this.services.Cart.load();
              this.services.Aeroexpress.setNewSearch();
            }),
        );
      });
  };
}

export default PopupsProtocol;
