import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Checkbox, IconButton, Text, Button, ItemPanel } from 'new-ui';

import { getText } from '../../../../../i18n';

import { stringifySearchParams } from '../../../../bi/utils/convertSearchParams';
import parseJsonString from '../../../../bi/utils/parseJsonString';
import {
  addUtcOffset,
  formatDate,
  getMoment,
} from '../../../../bi/utils/formatDate';
import { isSmartAgent, svgColor } from '../../../../bi/utils/env';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import MoneyFormat from '../../../../bi/utils/money';
import { parsedAllItems, isAirlineCompanySatisfying } from '../../../../bi/utils/airline';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import ANIMATION from '../../../../bi/constants/animation';
import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { PHANTOM_STATUS_ENUM } from '../../../../bi/constants/note';

import ItemLayout from '../../../../components/ItemLayout';
import AirlineItem from '../../../../components/AirlineItem';
import HotelItem from '../../../../components/HotelItem';
import TrainItem from '../../../../components/TrainItem';
import TransferItem from '../../../../components/TransferItem';
import { TripTags } from '../../../../components/TripTags';
import EmployeeSimpleList from '../../../../components/EmployeeSimpleList';
import { RoundIconWithTooltip } from '../../../../components/RoundIconWithTooltip';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import DialogRules from '../../../../components/RulesDialog';
import { BookMarkAddingTrip } from '../../../../components/BookMarkAddingTrip';
import LayoutTimer from '../../../../components/layoutTimer';
import { RemoveItemDialog } from '../../../../components/RemoveItemDialog/RemoveItemDialog';

import ActionAir from '../AirAction';
import { BookingForm } from '../BookingForm';

import { NoteItemProps, NoteItemState, UpdateCompanyResponse } from './types';
import { AirRoute } from '../../../../bi/types/airline';
import { ITripTag } from '../../../../bi/services/tripTags/types';

import styles from './index.module.css';

const LOCATION = 'CARD';

const TIMEOUT = 1000;

const LABELS = {
  TO_CART: getText('note:toCart'),
  REMOVE: getText('note:remove'),
  REQUES_NUMBER: getText('note:item.requestNumber'),
  REPEAT_SEARCH: getText('note:item.repeatSearch'),
  C: 'С',
  CONFIRM_ACTION: getText('note:item.confirmAction'),
  DIALOG_DELETE_DESCRIPTION: getText('note:item.deleteMultiDescription'),
  DIALOG_TO_CART_DESCRIPTION: getText('note:item.toCartMultiDescription'),
  ADDED_DATE: (date: string, time: string) => getText('note:addedDate', { date, time }),
  MOVED_TO_CART: (date: string, time: string, person: string) => getText('note:movedToCart', { date, time, person }),
  REMOVED_TO_CART: getText('note:removedFromCart'),
  YES: getText('common:yes'),
  NO: getText('common:no'),
  RULES_TARIFF: getText('cart:normalCart.rulesTariff'),
  ADD_TAX: getText('hotels:hotelResult.item.rate.andTax'),
};

class NoteItem extends Component<NoteItemProps, NoteItemState> {
  static defaultProps = {
    foul: false,
    isPhantom: false,
    selected: false,
    projects: [],
    tripsByTripIds: [],
    allItemsAnimatedClass: '',
    moveToCart: () => {},
    deleteItem: () => {},
    onItemSelect: () => {},
    handleTimeIsUp: () => {},
    onCreateReservation: () => {},
    onMoveNoteToCart: () => {},
    onRemoveServiceNote: () => {},
    onRemoveFoulService: () => {},
    updateItems: () => {},
    allItemsLoading: false,
    multiItems: [],
  };

  constructor(props: NoteItemProps) {
    super(props);

    const { item } = this.props;

    const selectedTripTags = item.Tags
      ? item.Tags.map(({ Id }:{ Id: number }) => Id)
      : [];

    this.state = {
      animatedClass: '',
      loading: false,
      selectedTripTags,
      showDeleteDialog: false,
      showToCartDialog: false,
      showRulesAirDialog: false,
      showRemoveItemDialog: false,
    };
  }

  toggleRemoveItemDialog = (value: boolean) => {
    this.setState({
      showRemoveItemDialog: value,
    });
  };

  handleDeleteItemDialog = () => {
    this.toggleRemoveItemDialog(false);
    this.deleteItem();
  };

  shouldShowRemoveDialog = (
    isReserved: boolean,
    routes: AirRoute[],
    foul: boolean,
  ) =>
    isReserved && isAirlineCompanySatisfying(routes) && !foul;

  handleRemoveItemClick = () => {
    const {
      item: {
        ServiceType,
        Data,
        IsReserved,
      },
      foul,
      multiItems,
    } = this.props;

    if (ServiceType !== SERVICETYPE.AIR) return this.deleteItem();

    const {
      Routes: routes,
      MultiTripInfo: multiTrip,
    } = parseJsonString(Data);

    const isMultiTrip = !!multiTrip?.Id;

    const showRemoveItemDialog = this.shouldShowRemoveDialog(
      IsReserved,
      routes,
      foul,
    );

    if (isMultiTrip) {
      const shouldShowDialog = multiItems.some(({
        Data: multiItemData,
        IsReserved: multiItemIsReserved,
      }) => {
        const { Routes: multiItemRoutes } = parseJsonString(multiItemData);

        return this.shouldShowRemoveDialog(
          multiItemIsReserved,
          multiItemRoutes,
          foul,
        );
      });

      return shouldShowDialog
        ? this.toggleRemoveItemDialog(true)
        : this.deleteItem();
    }

    return showRemoveItemDialog
      ? this.toggleRemoveItemDialog(true)
      : this.deleteItem();
  };

  onLoadingEnd = () => this.setState({ loading: false });

  handleAnimationEnd = () => this.setState({ animatedClass: '' });

  moveToCart = () => {
    const { moveToCart, item, updateItems } = this.props;
    this.setState({
      loading: true,
    }, async () => {
      try {
        await moveToCart(item);
        this.afterMoveToCart();
      } catch {
        this.onLoadingEnd();
        await updateItems();
      }
    });
  };

  afterMoveToCart = () => {
    const { onMoveNoteToCart, updateItems } = this.props;

    this.setState({
      animatedClass: ANIMATION.MOVETOCART,
    }, async () => {
      setTimeout(async () => {
        this.onLoadingEnd();
        await updateItems();
      }, TIMEOUT);
    });

    onMoveNoteToCart(LOCATION);
  };

  handleMoveToCart = () => {
    const { item, item: { Data, IsReserved, ServiceType } } = this.props;

    const { loading } = this.state;

    if (loading) {
      return;
    }

    const isReservedHotel = isSmartAgent && IsReserved && !!item?.BookDeadline && ServiceType === SERVICETYPE.HOTEL;

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.NOTE.NOTE_TO_CART_PRESSED(ServiceType, isReservedHotel));

    const { MultiTripInfo } = parseJsonString(Data);
    const isMultiTrip = !!MultiTripInfo?.Id;

    if (isMultiTrip) {
      this.setToCartDialog(true);
    } else {
      this.moveToCart();
    }
  };

  deleteItem = () => {
    const { deleteItem, updateItems } = this.props;
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    }, async () => {
      try {
        await deleteItem(true);
        this.afterDeleteItem();
      } catch {
        this.onLoadingEnd();
        await updateItems();
      }
    });
  };

  afterDeleteItem = () => {
    const { foul, onRemoveServiceNote, onRemoveFoulService, updateItems } = this.props;

    this.setState({ animatedClass: ANIMATION.DELETE_CART }, () => {
      setTimeout(async () => {
        this.onLoadingEnd();
        await updateItems();

        if (foul) {
          onRemoveFoulService();
        } else {
          onRemoveServiceNote(LOCATION);
        }
      }, TIMEOUT);
    });
  };

  handleDeleteItem = () => {
    const { item, item: { Data, ServiceType, IsReserved } } = this.props;
    const { loading } = this.state;

    if (loading) {
      return;
    }

    const isReservedHotel = isSmartAgent && IsReserved && !!item?.BookDeadline && ServiceType === SERVICETYPE.HOTEL;

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.NOTE.NOTE_DELETE(ServiceType, isReservedHotel));

    const { MultiTripInfo } = parseJsonString(Data);
    const isMultiTrip = !!MultiTripInfo?.Id;

    if (isMultiTrip) {
      this.setDeleteDialog(true);
    } else {
      this.handleRemoveItemClick();
    }
  };

  handleSearch = () => {
    const { item: { Data }, airlineService } = this.props;

    const itemData = parseJsonString(Data);
    const uri = ROUTES.SEARCH.AIR;

    const { mappedItem, data } = airlineService.prepareSearchData(itemData);

    const searchParams = { ...airlineService.getSearchObject(mappedItem, data) };

    const searchString = stringifySearchParams(searchParams);

    this.props.history.push({ pathname: uri, search: searchString });
  };

  handleUpdateTripTags = (tags: any) => {
    const {
      notepadService,
      tripTagsService,
      tripTagsService: { store: { mappedTripTags } },
      item: { Id },
    } = this.props;

    this.setState({ loading: true });

    if (isSmartAgent) {
      if (!mappedTripTags) {
        return Promise.resolve().then(() => this.onLoadingEnd());
      }

      const newTags = tags.reduce((arr: number[], id: number) => {
        const tagItem = mappedTripTags.find(({ value }) => value === id);

        return tagItem && tagItem.label ? [...arr, { Id: tagItem.value, label: tagItem.label }] : arr;
      }, []);

      if (tripTagsService.updateNoteItemTripTags) {
        return tripTagsService.updateNoteItemTripTags(Id, tags).then(() =>
          notepadService.updateTags(Id, newTags)).then(() =>
          this.onLoadingEnd());
      }

      return Promise.resolve().then(() => this.onLoadingEnd());
    }

    if (tripTagsService.updateNoteItemTripTags) {
      return tripTagsService.updateNoteItemTripTags(Id, tags).then(() =>
        this.onLoadingEnd());
    }

    return Promise.resolve().then(() => this.onLoadingEnd());
  };

  handleChangeSelectedTripTags = (selectedTripTags: ITripTag[]) => {
    if (selectedTripTags.length) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.ADD_TAG_NOTE);
    }

    this.setState({ selectedTripTags });
  };

  selectItem = (value: boolean) => {
    const { onItemSelect } = this.props;
    onItemSelect(value);

    if (value) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.SELECT_ITEM_NOTE);
    }
  };

  renderItem = () => {
    const {
      item,
      item: {
        Data,
        Metadata,
        IsReserved,
      },
      aggregationId,
      hotelsService,
      favoritesService,
      notificationService,
      getReservedHotelSmartagent,
    } = this.props;

    const itemData = parseJsonString(Data);
    const itemMetadata = parseJsonString(Metadata) || {};
    const PNR = itemMetadata.PNR;
    const isMultiTrip = !!itemData?.MultiTripInfo?.Id;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        return itemData.Routes.map((route: AirRoute) => (
          <AirlineItem
            key={ route.ID }
            route={ route }
            meta={ itemData.Metadata }
            pnr={ PNR }
            isCart
            isMultiTrip={ isMultiTrip }
          />
        ));
      }
      case SERVICETYPE.TRAIN: {
        return <TrainItem item={ itemData } />;
      }
      case SERVICETYPE.HOTEL: {
        const { AdditionalData, CanBeReserved, ReserveDeadline } = item;

        const isReserved = isSmartAgent && IsReserved && !!item?.BookDeadline;

        return (
          <HotelItem
            hotelItemFullInfo
            item={ itemData }
            additionalData={ AdditionalData }
            aggregationId={ aggregationId }
            hotelsService={ hotelsService }
            notificationService={ notificationService }
            reservedHotelSmartagent={ getReservedHotelSmartagent }
            favoritesService={ favoritesService }
            canBeReserved={ CanBeReserved }
            reservedDeadline={ ReserveDeadline }
            isReserved={ isReserved }
          />
        );
      }
      case SERVICETYPE.TRANSFER: {
        return <TransferItem item={ itemData } />;
      }
    }

    return null;
  };

  renderSearchLink = () => {
    const { item: { Data, ServiceType } } = this.props;

    if (ServiceType === SERVICETYPE.AIR) {
      const itemData = parseJsonString(Data);
      const route = itemData.Routes[0].Segments[0];
      const dateFrom = route.DepartureTime_DateTime;

      if (getMoment().isBefore(dateFrom)) {
        return (
          <IconButton
            iconType='arrowsChange'
            className={ styles.action }
            onClick={ this.handleSearch }
          >
            <Text type='NORMAL_14'>
              { LABELS.REPEAT_SEARCH }
            </Text>
          </IconButton>
        );
      }
    }

    return null;
  };

  renderDeleteLink = () => (
    <IconButton
      iconType='trashcan'
      iconColor={ svgColor }
      qaAttr={ QA_ATTRIBUTES.note.item.delete }
      className={ styles.action }
      onClick={ this.handleDeleteItem }
    >
      <Text type='NORMAL_14'>
        { LABELS.REMOVE }
      </Text>
    </IconButton>
  );

  renderToCartLink = () => (
    <IconButton
      iconType='menuCart'
      iconColor={ svgColor }
      qaAttr={ QA_ATTRIBUTES.note.item.toCart }
      className={ styles.action }
      onClick={ this.handleMoveToCart }
    >
      <Text type='NORMAL_14'>
        { LABELS.TO_CART }
      </Text>
    </IconButton>
  );

  renderCheckbox = () => {
    const { selected } = this.props;

    return (
      <Checkbox
        qaAttr={ QA_ATTRIBUTES.note.item.checkbox }
        className={ styles.action }
        value={ selected }
        onChange={ this.selectItem }
      />
    );
  };

  renderToRequestLink = () => {
    const { item } = this.props;

    return item.RequestId && (
      <NavLink
        className={ styles.action }
        to={ `/requests/${item.RequestId}` }
      >
        <Text type='NORMAL_14'>
          { LABELS.REQUES_NUMBER }{ item.RequestId }
        </Text>
      </NavLink>
    );
  };

  renderFoulsHeaderItem = () => {
    const { userId, item: { OwnerId } } = this.props;

    const checkAlienTrip = userId === OwnerId
      ? this.renderDeleteLink()
      : null;

    return (
      <div className={ styles.fouls }>
        { this.renderSearchLink() }
        { checkAlienTrip }
      </div>
    );
  };

  renderPhantomHeaderItem = () => {
    const {
      item: {
        Status,
        ChangeDate,
        Owner: { RegistrationName },
      },
    } = this.props;

    const isDeletedTrip = Status === PHANTOM_STATUS_ENUM.PHANTOM_DELETED;
    const needDeleteButton = isDeletedTrip && this.renderDeleteLink();
    const fullDate = addUtcOffset(ChangeDate);
    const textDate = LABELS.MOVED_TO_CART(
      formatDate(fullDate, PATTERN.DAY_OF_MONTH_LONG),
      formatDate(fullDate, PATTERN.HOURSE_AND_MINUTES),
      RegistrationName,
    );
    const renderRemoteText = isDeletedTrip
      ? (
        <span className={ styles.removed }>
          { LABELS.REMOVED_TO_CART}
        </span>
      )
      : null;

    return (
      <div className={ styles.phantom }>
        <div className={ styles.phantom_info }>
          <div>
            <span>
              {textDate}.
            </span>
            {renderRemoteText}
          </div>
        </div>
        <div className={ styles.content }>
          { this.renderSearchLink() }
          { needDeleteButton }
        </div>
      </div>
    );
  };

  renderHeaderItem = () => {
    const { item, userId } = this.props;

    const checkAlienTrip = userId === item.OwnerId
      ? this.renderDeleteLink()
      : null;

    return (
      <div className={ styles.normal }>
        <div />
        <div className={ styles.actions }>
          { this.renderToRequestLink() }
          { this.renderToCartLink() }
          { checkAlienTrip }
          { this.renderCheckbox() }
        </div>
      </div>
    );
  };

  renderHeader = () => {
    const { foul, isPhantom } = this.props;

    const content = () => {
      if (foul) return this.renderFoulsHeaderItem();

      if (isPhantom) return this.renderPhantomHeaderItem();

      return this.renderHeaderItem();
    };

    return (
      <div className={ styles.header }>
        { content() }
      </div>
    );
  };

  renderLayoutHead = () => {
    const {
      item,
      item: {
        TripId,
        IsReserved,
        ServiceType,
      },
      tripsByTripIds,
      serverTime,
      handleTimeIsUp,
      foul,
      isPhantom,
    } = this.props;

    const addedOnTrip = TripId
      ? <BookMarkAddingTrip
          trips={ tripsByTripIds }
          tripId={ TripId }
          serviceType={ ServiceType }
      />
      : null;

    const timerContent = () => {
      if (!IsReserved || foul || isPhantom || (ServiceType !== SERVICETYPE.AIR && ServiceType !== SERVICETYPE.HOTEL) || !item.BookDeadline) {
        return null;
      }

      return (
        <LayoutTimer
          bookDeadline={ item.BookDeadline }
          time={ serverTime }
          onTimeIsUp={ () => handleTimeIsUp(item) }
          type={ ServiceType }
        />
      );
    };

    return (
      <div className={ styles.layout_header }>
        { timerContent() }
        { addedOnTrip }
      </div>
    );
  };

  renderVatHtml = () => {
    const { item: { ServiceType } } = this.props;

    if (ServiceType !== SERVICETYPE.HOTEL) return null;

    const { Rate: { Price: { HasVAT, VAT } } } = JSON.parse(this.props.item.Data);

    if (!HasVAT) return null;

    const moneyFormatVat = MoneyFormat.moneyWithDecimal(Number(VAT), true);

    return (
      <div className={ styles.vat }>
        <Text className={ styles.label } type='NORMAL_12' color='gray'>
          { LABELS.ADD_TAX }: { moneyFormatVat }
        </Text>
      </div>
    );
  };

  renderOption = () => {
    const {
      item,
      item: {
        ServiceType,
        CanBeReserved,
      },
      history,
      foul,
      onCreateReservation,
      workspaceService,
      getReservedHotelSmartagent,
    } = this.props;

    const reservedHotel = ServiceType === SERVICETYPE.HOTEL && getReservedHotelSmartagent;

    if (ServiceType === SERVICETYPE.AIR || reservedHotel) {
      return (
        <div>
          { this.renderVatHtml() }
          <div className={ styles['book-air-wrapper'] }>
            <ActionAir
              item={ item }
              history={ history }
              foul={ foul }
              moveToCart={ this.handleMoveToCart }
              onCreateReservation={ onCreateReservation }
              workspaceService={ workspaceService }
              reservedHotel={ CanBeReserved }
            />
          </div>
        </div>
      );
    }

    if (ServiceType === SERVICETYPE.HOTEL) {
      return this.renderVatHtml();
    }

    return null;
  };

  renderTripTags = () => {
    const { tripTagsService, workspaceService } = this.props;
    const { selectedTripTags } = this.state;

    return (
      <TripTags
        tripTagsService={ tripTagsService }
        onUpdateTags={ this.handleUpdateTripTags }
        onChangeTags={ this.handleChangeSelectedTripTags }
        className={ styles['trip-tags'] }
        selectedTags={ selectedTripTags }
        store={ tripTagsService.store }
        workspaceService={ workspaceService }
      />
    );
  };

  renderCreator = () => {
    const { isPhantom, userId,
      item: { AddDate, OwnerId,
        Owner: { RegistrationName },
      },
    } = this.props;
    const fullDate = addUtcOffset(AddDate);
    const textDate = LABELS.ADDED_DATE(formatDate(fullDate, PATTERN.DAY_OF_MONTH_LONG), formatDate(fullDate, PATTERN.HOURSE_AND_MINUTES));
    const textName = !isPhantom ? `, ${RegistrationName}` : '';

    const ownerName = isSmartAgent && (userId !== OwnerId) && (
      <Text type='NORMAL_14'>
        { textName }
      </Text>
    );

    return (
      <div className={ styles.wrapper }>
        <div className={ styles.creator } >
          <Text type='NORMAL_14' color='gray'>
            {textDate}
          </Text>
          {ownerName}
        </div>
      </div>
    );
  };

  renderAirAFlag = () => {
    const { item: { Data } } = this.props;
    const { AFlag } = JSON.parse(Data);

    if (!AFlag) {
      return null;
    }

    return (
      <RoundIconWithTooltip
        label={ LABELS.C }
        className={ styles.a_flag }
      />
    );
  };

  setDeleteDialog = (value: boolean) => this.setState({ showDeleteDialog: value });

  setToCartDialog = (value: boolean) => this.setState({ showToCartDialog: value });

  renderToCartDialog = () => {
    const { showToCartDialog } = this.state;

    return (
      <ConfirmDialog
        visible={ showToCartDialog }
        title={ LABELS.CONFIRM_ACTION }
        description={ LABELS.DIALOG_TO_CART_DESCRIPTION }
        onChangeVisible={ this.setToCartDialog }
        onClick={ () => {
          this.setToCartDialog(false);
          this.moveToCart();
        } }
        onClose={ () => this.setToCartDialog(false) }
        visibleAction
      />
    );
  };

  handleAddCompany = (employeeId: string, companyId: string) => {
    this.setState({ loading: true });

    const { item, notepadService } = this.props;

    return notepadService.updateNoteCompany(item.Id, employeeId, companyId).then((res: UpdateCompanyResponse) => {
      this.onLoadingEnd();

      return res;
    });
  };

  handleGetRules = (itemId: number) => this.props.notepadService.cancellationInfo(itemId);

  renderDeleteDialog = () => {
    const { showDeleteDialog } = this.state;

    return (
      <ConfirmDialog
        visible={ showDeleteDialog }
        title={ LABELS.CONFIRM_ACTION }
        description={ LABELS.DIALOG_DELETE_DESCRIPTION }
        onChangeVisible={ this.setDeleteDialog }
        onClick={ () => {
          this.setDeleteDialog(false);
          this.handleRemoveItemClick();
        } }
        onClose={ () => this.setDeleteDialog(false) }
        visibleAction
      />
    );
  };

  renderRemoveItemDialog = () => {
    const { item, multiItems, item: { Data } } = this.props;
    const { showRemoveItemDialog } = this.state;

    if (item.ServiceType !== SERVICETYPE.AIR) return null;

    const { MultiTripInfo } = parseJsonString(Data);

    const isMultiTrip = !!MultiTripInfo?.Id;

    const resultItem = isMultiTrip ? multiItems : item;

    const parsedItems = parsedAllItems(resultItem, false, isMultiTrip);

    return (
      <RemoveItemDialog
        items={ parsedItems }
        showRemoveItemDialog={ showRemoveItemDialog }
        onClose={ () => this.toggleRemoveItemDialog(false) }
        onDelete={ () => this.handleDeleteItemDialog() }
      />
    );
  };

  renderRulesButtonAirAndTrain = () => {
    const { item: { ServiceType } } = this.props;

    if (ServiceType !== SERVICETYPE.AIR && ServiceType !== SERVICETYPE.TRAIN) {
      return null;
    }

    return (
      <Button
        className={ styles.rules_button }
        type='textual'
        onClick={ () => this.setState({ showRulesAirDialog: true }) }
      >
        { LABELS.RULES_TARIFF }
      </Button>
    );
  };

  renderRulesDialog = () => {
    const { showRulesAirDialog } = this.state;
    const { item } = this.props;

    return (
      <DialogRules
        show={ showRulesAirDialog }
        items={ [item] }
        onClose={ () => this.setState({ showRulesAirDialog: false }) }
        onGetRules={ this.handleGetRules }
      />
    );
  };

  renderBookingForm = (isReserved: boolean) => {
    const { item: { Id }, notepadService: { downloadBookingVoucher, downloadDefaultBookingVoucher } } = this.props;

    if (!isSmartAgent || !isReserved) {
      return null;
    }

    return (
      <BookingForm
        id={ Id }
        onDownloadButton={ downloadDefaultBookingVoucher }
        onDownloadVoucherLocaleButton={ downloadBookingVoucher }
      />
    );
  };

  render() {
    const {
      item,
      documentType,
      foul,
      isPhantom,
      isPersonalBonusCards,
      formatService,
      projects,
      workspaceService,
      onSelectCompany,
      allItemsAnimatedClass,
      notepadService: {
        setAdditionalFeeSmartagentNotepad,
        removeAdditionalFeeSmartagentNote,
      },
      allItemsLoading,
      getReservedHotelSmartagent,
      appService,
    } = this.props;
    const { animatedClass, loading } = this.state;

    const { Companies } = workspaceService.get();
    const { EmployeeNoteItems, Employees, IsReserved, Price, ServiceType, AgentFee, Id } = item;

    const isMulti = Companies.length > 1;
    const isReserved = !foul && IsReserved;
    const isEmployeeSimpleList = !isPhantom
      && IsReserved
      && (ServiceType === SERVICETYPE.AIR || (ServiceType === SERVICETYPE.HOTEL && getReservedHotelSmartagent));

    const employeeHtml = isEmployeeSimpleList && (
      <EmployeeSimpleList
        item={ { ...item, EmployeeCartItems: EmployeeNoteItems } }
        allowedEmployees={ Employees }
        projects={ projects }
        documentType={ documentType }
        isMulti={ isMulti }
        formatService={ formatService }
        isPersonalBonusCards={ isPersonalBonusCards }
        onAddCompany={ this.handleAddCompany }
        onSelectCompany={ onSelectCompany }
      />
    );

    const itemContentClassNames = [styles.content];

    if (foul || isPhantom) {
      itemContentClassNames.push(styles.disabled_service);
    }

    const airAFlagHtml = ServiceType === SERVICETYPE.AIR && this.renderAirAFlag();
    const creatorHtml = isSmartAgent && this.renderCreator();
    const isReservedHotel = isReserved && ServiceType === SERVICETYPE.HOTEL && isSmartAgent && !!item?.BookDeadline;

    const reservedType = isReserved && (ServiceType === SERVICETYPE.AIR || isReservedHotel)
      ? ServiceType
      : '';

    return (
      <>
        <ItemPanel
          className={ styles.wrapper }
          renderHeader={ this.renderHeader }
          renderLayoutHead={ this.renderLayoutHead }
          animationClass={ animatedClass || allItemsAnimatedClass }
          loading={ loading || allItemsLoading }
          onAnimationEnd={ this.handleAnimationEnd }
          reserved={ reservedType }
          qaAttrLoader={ QA_ATTRIBUTES.note.item.loader }
        >
          <div className={ itemContentClassNames.join(' ') }>
            <ItemLayout
              price={ Price }
              serviceType={ ServiceType }
              renderOption={ this.renderOption }
              disabled={ foul }
              absoluteHtml={ airAFlagHtml }
              qaAttrPrice={ QA_ATTRIBUTES.note.item.price }
              feeSmartagent={ AgentFee }
              itemId={ Id }
              appService={ appService }
              setAdditionalFeeSmartagent={ setAdditionalFeeSmartagentNotepad }
              removeAdditionalFeeSmartagentNote={ removeAdditionalFeeSmartagentNote }
              isAdditionalFee
              isNoteItem
            >
              { this.renderItem() }
              { this.renderRulesButtonAirAndTrain() }
            </ItemLayout>
            <div className={ styles.wrapper_actions }>
              <div className={ styles.actions }>
                { employeeHtml }
                { this.renderTripTags() }
                { creatorHtml }
              </div>
              { this.renderBookingForm(isReservedHotel) }
            </div>
          </div>
        </ItemPanel>
        { this.renderRemoveItemDialog() }
        { this.renderDeleteDialog() }
        { this.renderToCartDialog() }
        { this.renderRulesDialog() }
      </>
    );
  }
}

export default NoteItem;
