import CONFIG from '../../../../config';
import debounce from '../../api/debounce';

import { SettingsStore, SettingsStoreType } from './stores/settings';
import { FiltersStore, FiltersStoreType } from './stores/filters';
import { CompaniesType, EmployeesType, IFeeConstruction } from './types';
import { GroupedDepartmentStatus } from '../departments/types';

import { getText } from '../../../../i18n';

import { replaceItem } from '../../utils/mapperAccountSettings';
import { isSmartAgent } from '../../utils/env';
import getAccountId from '../../utils/getAccountId';

import { FileVoucherType } from '../../types/voucherSettings';
import { ItemEasy } from '../../types/filters';
import { ICompaniesMapper } from '../customAnalytics/types';

import STATUS from '../../constants/employee';
import {
  DEFAULT_FEE_CONSTRUCTION,
  VALIDATION_DOC_DOWNLOAD,
} from '../../constants/settings';
import { ERROR_CODE } from '../../constants/app';

const DEBOUNCE_TIME_200 = 200;
const DEBOUNCE_TIME_300 = 300;

const BYTES_IN_MB = 1048576;
const SIZE_FILE = 5;

const FORMAT = {
  PNG: 'image/png',
  JPG: 'image/jpeg',
  SVG: 'image/svg+xml',
};

const LABELS = {
  ERRORS: {
    BAD_FORMAT: getText('settings:vouchers:validationDownloadDocument:errors:badFormat'),
    TO_LARGE: getText('settings:vouchers:validationDownloadDocument:errors:tooLarge'),
  },
  LOAD_FILES: getText('settings:vouchers:logo:load'),
};

class Settings {
  api: any;
  xhr: null | any;
  settingsStore: SettingsStoreType;
  filtersStore: FiltersStoreType;
  employeeDebounce: any;
  employeesSearchDebounce: any;
  employeesSearchProgressive: any;
  constructor(api: any) {
    this.api = api;
    this.xhr = null;
    this.settingsStore = SettingsStore;
    this.filtersStore = FiltersStore;

    this.employeeDebounce = debounce(this.api.settings.employeeAirline, DEBOUNCE_TIME_200);
    this.employeesSearchDebounce = debounce(this.api.settings.employeesSettingsSearch, DEBOUNCE_TIME_200);
    this.employeesSearchProgressive = debounce(this.api.settings.employeesSettingsSearchProgressive, DEBOUNCE_TIME_300);
  }

  employeeAirline = (value: string) => this.employeeDebounce(value);

  changePassword = ({ currentPassword, newPassword, newConfirmPassword }:
  { currentPassword: string, newPassword: string, newConfirmPassword: string }) => {
    const form = {
      old_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: newConfirmPassword,
    };

    return this.api.settings.changePassword(form);
  };

  hasDisabledPaxUser = (user: string) => CONFIG.WORKSPACE.PAX.DISABLED.includes(user);

  setEmployeesWaitingResponse = (waitingResponse: boolean) => this.settingsStore.setWaitingResponse(waitingResponse);

  setResetFilers = (field: string, value: string, step: number) => this.settingsStore.resetFilters(field, value, step);

  setEmployeeFlag = (isEmployee = false) => this.settingsStore.setEmployeeFlag(isEmployee);

  setEmployeesCount = (count: number) => this.settingsStore.setEmployeesCount(count);

  setEmployees = (list: EmployeesType) => this.settingsStore.setEmployees(list);

  getEmployeesCount = (data: number) => this.api.settings.getEmployeesCount(data);

  setCompanyOrder = (data: number[]) => this.api.settings.setCompanyOrder(data);

  getEmployeesList = () =>
    this.api.employee.getOptimizedList()
      .then((list: any[]) => {
        this.settingsStore.setEmployees(list);

        return list;
      });

  getCompaniesWithCostCenter = (accountId: number) => this.api.accountSettings.getCompaniesWithCostCenter(accountId)
    .then((res: number[]) => this.settingsStore.setCompaniesWithCostCenter(res));

  getProhibitionVouchers = (accountId: number) => this.api.accountSettings.getProhibitionVouchers(accountId)
    .then((res: CompaniesType) => this.settingsStore.setProhibitionVouchers(res));

  updateCompaniesWithCostCenter = (
    companies: CompaniesType | ICompaniesMapper[],
  ) => this.api.accountSettings.updateCompaniesWithCostCenter(companies);

  updateCostCenters = async (
    companies: CompaniesType | ICompaniesMapper[],
    accountId: number,
  ) => {
    try {
      await this.updateCompaniesWithCostCenter(companies);
      await this.getCompaniesWithCostCenter(accountId);
    } catch {}
  };

  changePageEmployeesOnly = (page: number) => this.settingsStore.setPageEmployees(page);

  getDataSearchEmployeesAndChangePagination = (filterField: string) => {
    this.changePagination(filterField);

    return this.getDataSearchEmployees();
  };

  changePagination = (filterField: string) => {
    if (filterField) {
      this.changePageEmployeesOnly(1);
    }
  };

  changeFilters = (field: string, value: string | number) => this.settingsStore.setEmployeesFilters(field, value);

  changeCountItemsPerPage = (step: number) => this.settingsStore.setItemPerPage(step);

  getDataSearchEmployees = () => {
    const {
      filter: { select, companyId, search },
      paginate: { currentPage, itemsPerPage },
      isEmployee,
    } = this.settingsStore;

    let filters = {};

    switch (select) {
      case STATUS.ACCESS.USER:
        filters = { ...filters, Status: STATUS.ACCESS.USER };
        break;
      case STATUS.ACCESS.ACTIVE:
        filters = { ...filters, Status: STATUS.ACCESS.ACTIVE };
        break;
      case STATUS.NO_DEPARTMENT:
        filters = { ...filters, WithoutDepartment: true };
        break;
      case STATUS.ACTIVE_SIGNATURE:
        filters = { ...filters, ActiveSignature: true };
        break;
      case STATUS.ARCHIVE:
        filters = { ...filters, Status: STATUS.ARCHIVE };
        break;
    }

    if (companyId !== 0) {
      filters = { ...filters, CompanyId: companyId };
    }

    if (search !== '') {
      filters = { ...filters, SearchString: search };
    }

    return {
      Page: currentPage,
      Step: itemsPerPage,
      Filters: filters,
      isEmployee,
    };
  };

  clearStoreSettingsEmployee = () => this.settingsStore.defaultStore();

  getCompanies = () => this.api.settings.getCompanies().then((list: CompaniesType) => this.settingsStore.setCompanies(list));

  getCompany = (id: number) => this.api.settings.getCompany(id).then((list: CompaniesType) => this.settingsStore.setCompany(list));

  getSettingsVoucher = async () => {
    try {
      this.settingsStore.setSuccessUpload(false);

      const res = await this.api.settings.getVoucherSetting();

      await this.settingsStore.setVoucherSettings(res);

      this.settingsStore.setSuccessUpload(true);
    } catch {
      this.settingsStore.setSuccessUpload(false);
    }
  };

  getFeeConstruction = async () => {
    this.settingsStore.setFeeWaitingResponse(true);

    try {
      const res = await this.api.settings.getFeeConstruction();

      const prepareRes = () => {
        const preparedRes: IFeeConstruction[] = [];

        res.forEach((resItem: IFeeConstruction) => {
          const existingItemIndex = preparedRes.findIndex((item) => item.ServiceType === resItem.ServiceType);

          if (existingItemIndex !== -1) {
            preparedRes[existingItemIndex] = resItem;
          } else {
            preparedRes.push(resItem);
          }
        });

        DEFAULT_FEE_CONSTRUCTION.forEach((defaultItem) => {
          if (!preparedRes.some((item: IFeeConstruction) => item.ServiceType === defaultItem.ServiceType)) {
            preparedRes.push(defaultItem);
          }
        });

        return preparedRes;
      };

      this.settingsStore.setFeeConstruction(prepareRes());

      this.settingsStore.setFeeWaitingResponse(false);
    } catch {
      this.settingsStore.setFeeWaitingResponse(false);
    }
  };

  updateFeeConstruction = async (data: IFeeConstruction) => {
    this.settingsStore.setFeeWaitingResponse(true);
    const { feeConstruction } = this.settingsStore;

    const preparedDate = replaceItem(feeConstruction, data);

    try {
      await this.api.settings.updateFeeConstruction(data);

      this.settingsStore.setFeeConstruction(preparedDate);
      this.settingsStore.setFeeWaitingResponse(false);

      await this.getFeeConstruction();
    } catch {
      this.settingsStore.setFeeWaitingResponse(false);
    }
  };

  deleteFeeConstruction = async (data: IFeeConstruction) => {
    this.settingsStore.setFeeWaitingResponse(true);
    const { feeConstruction } = this.settingsStore;

    const preparedDate = replaceItem(feeConstruction, data);

    try {
      await this.api.settings.removeFeeConstruction(data.ServiceType);

      this.settingsStore.setFeeConstruction(preparedDate);
      this.settingsStore.setFeeWaitingResponse(false);

      await this.getFeeConstruction();
    } catch {
      this.settingsStore.setFeeWaitingResponse(false);
    }
  };

  getBrandName = async () => {
    try {
      const accountId = getAccountId();

      const data = await this.api.settings.getBrandName(accountId);

      this.settingsStore.getBrandName(data);
    } catch (e) {
      this.settingsStore.setBrandNameError();
    }
  };

  getInterVoucherSettings = () => this.settingsStore.getInterVoucherSettings();

  setSettingsVoucher = (
    AdditionalInfo = '',
    ContactInfo = '',
    LogoFileId = '',
    OriginalFileName = '',
    OriginalFileSize = '',
  ) => {
    try {
      this.settingsStore.setSuccessUpload(false);

      this.api.settings.setVoucherSettings({
        AdditionalInfo,
        ContactInfo,
        LogoFileId,
      });

      this.settingsStore.setVoucherSettings({
        AdditionalInfo,
        ContactInfo,
        LogoFileId,
        OriginalFileName,
        OriginalFileSize,
      });

      this.settingsStore.setSuccessUpload(true);
    } catch {
      this.settingsStore.setSuccessUpload(false);
    }
  };

  setInterIsLogo = (value: boolean) => this.settingsStore.setInterIsLogo(value);

  setInterLogo = (res = '') => this.settingsStore.setInterLogo(res);

  setValidationDownload = (isError: boolean, value = '') => this.settingsStore.setValidationDownload(isError, value);

  setShowEditVoucher = (value: boolean) => this.settingsStore.setShowEditVoucher(value);

  sendFileVouchersLogo = async (file: FileVoucherType): Promise<void> => {
    try {
      const res = await this.api.settings.sendFileVouchersLogo(file);

      await this.setInterLogo(res);

      this.setValidationDownload(false);
    } catch (e) {
      // @ts-ignore
      const code = e?.response?.statusCode || ERROR_CODE.INTERNAL_SERVER_ERROR;

      this.setValidationDownload(true, VALIDATION_DOC_DOWNLOAD[code]);
      this.setInterIsLogo(false);
    }
  };

  setEditFile = (data: { FileName: string, FileSize: string }) => this.settingsStore.setEditFile(data);

  setIsLogo = (value: boolean) => this.settingsStore.setIsLogo(value);

  setShowUpload = (value: boolean) => this.settingsStore.setShowUpload(value);

  uploadImage = async (file: FileVoucherType) => {
    const { type, size } = file;

    if ((type !== FORMAT.JPG) && (type !== FORMAT.PNG) && (type !== FORMAT.SVG)) {
      this.settingsStore.setResetFile();
      this.setValidationDownload(true, LABELS.ERRORS.BAD_FORMAT);
      this.setInterIsLogo(false);
      this.setShowUpload(false);

      this.settingsStore.setIsValid(true);

      return;
    }

    if ((size / BYTES_IN_MB) > SIZE_FILE) {
      this.settingsStore.setResetFile();
      this.setValidationDownload(true, LABELS.ERRORS.TO_LARGE);
      this.setInterIsLogo(false);

      this.settingsStore.setIsValid(true);

      this.setShowUpload(false);

      return;
    }

    this.settingsStore.setLoadProgress(LABELS.LOAD_FILES);

    await this.sendFileVouchersLogo(file);

    this.setShowUpload(false);
    this.settingsStore.setLoadProgress();
    this.settingsStore.setIsValid(false);
  };

  setShowPreview = (value: boolean) => this.settingsStore.setShowPreview(value);

  getPreview = async (AdditionalInfo: string, ContactInfo: string, LogoFileId: string) => {
    this.settingsStore.setLoaderPreview(true);
    try {
      const res = await this.api.settings.getVoucherPreview({ AdditionalInfo, ContactInfo, LogoFileId });

      await this.settingsStore.setItemPreview(res);

      this.setShowPreview(true);
      this.settingsStore.setLoaderPreview(false);
    } catch {
      this.settingsStore.setLoaderPreview(true);
    }
  };

  loadTestVoucher = async (serviceType: string, body: any) => {
    const guid = await this.api.settings.downloadTestVoucher(serviceType, body);

    await this.api.settings.onDownloadFile(guid);
  };
  // в AnalyticPage вызывается без аргумента, добавил пустую строку
  setInterNameCostCenter = (value: string = '') => this.settingsStore.setInterNameCostCenter(value);

  getFiltersPayload = () => {
    this.filtersStore.setLoadingResponse(true);

    Promise.all([
      this.api.userSession.getGroupedDepartments(GroupedDepartmentStatus.active),
      this.api.userSession.getProjects(),
      this.api.tripTags.loadAccountTripTags(),
      this.api.userSession.getTravelPolicies(),
      this.api.userSession.getCitizenship(),
    ])
      .then((resolve) => this.filtersStore.mappedResponse(resolve))
      .catch(() => this.setErrorResponse(true))
      .finally(() => this.filtersStore.setLoadingResponse(false));
  };

  editSearchString = (value: string) => {
    this.filtersStore.editSearchString(value);

    this.loadSettingsEmployeesPage(this.filtersStore.filterValues);
  };

  resetTags = () => {
    this.filtersStore.resetTags();

    this.loadSettingsEmployeesPage(this.filtersStore.filterValues);
  };

  editPage = (value: number) => {
    this.filtersStore.editPage(value);

    this.loadSettingsEmployeesPage(this.filtersStore.filterValues);
  };

  setFilters = (value: number | number[], type: string) => {
    this.filtersStore.setFilters(value, type);
  };

  setErrorResponse = (value: boolean) => {
    this.filtersStore.setErrorResponse(value);
  };

  resetSelectedState = () => {
    this.filtersStore.resetSelected();
  };

  calculateFilterCounter = () => {
    this.filtersStore.calculateFilterCounter();
  };

  setWaitingResponseEmployee = (value: boolean) => {
    this.filtersStore.setWaitingResponseEmployee(value);
  };

  setErrorResponseEmployee = (value: boolean) => {
    this.filtersStore.setErrorResponseEmployee(value);
  };

  loadSettingsEmployeesPage = async (data: any) => {
    try {
      this.setWaitingResponseEmployee(true);
      this.setErrorResponseEmployee(false);

      if (!isSmartAgent) {
        const { Count, Employees } = await this.employeesSearchProgressive(data);
        this.setEmployeesCount(Count);
        this.setEmployees(Employees);
      }

      this.setWaitingResponseEmployee(false);
    } catch {
      this.setErrorResponseEmployee(true);
    }
  };

  applyTags = () => {
    this.filtersStore.applyTags();

    this.loadSettingsEmployeesPage(this.filtersStore.filterValues);
  };

  deleteTag = (value: ItemEasy, type: string) => {
    this.filtersStore.deleteTag(value, type);

    this.loadSettingsEmployeesPage(this.filtersStore.filterValues);
  };
}

export default Settings;
