import moment, { Moment } from 'moment';

import trimTimeZone from '../../utils/trimTimezone';

import ACTION from './action';
import Store from '../../store';

import { SERVICETYPE } from '../../constants/serviceType';
import PASSPORTS from '../../constants/passport';
import { CART_STATUS } from '../../constants/cart';

import parseUnix from '../../utils/parseDateTime';
import { getDataForCart } from '../../utils/cart';
import { formatRangeDateWithSimplicity } from '../../utils/formatDate';

import {
  CartsType,
  ICartItemEmployeeData,
  ICartWpResult,
  IItem,
  PreselectedRequest1c,
  ReservationFailedItems,
  TripsToAddOrder,
  Request1cList,
  ICartItemsUpdate,
  IStatusUpdates,
} from '../../types/cart';
import { IInfoByTripIds } from '../notepad/types';
import { TripData } from '../../types/trips';

const maxSymbols = 70;
const maxSymbolsInTheName = 28;

export interface ICartState {
  carts: CartsType[],
  loading: boolean,
  reservationFailedItems: ReservationFailedItems[],
  companiesWithCostCenter: any, // По сути никогда не перезаписывается
  tripsByTripIds: IInfoByTripIds[],
  tripsToAddOrder: TripsToAddOrder[],
  verificationCode: {
    hasVerificationCode: boolean,
    isVerificationCodeError: boolean,
    verificationCodeIsTrue: boolean,
    autoVerification: boolean,
  },
  underage: {
    loading: boolean,
  },
  firstNonTotallyFilledItem: number | null,
  preselectedRequest1c: PreselectedRequest1c | null,
  autocompletedItems: {
    [key: number]: string,
  },
  request1cList: Request1cList[],
  request1cListLoading: boolean,
  alreadySentIds: number[],
  itemsToUpdate: ICartItemsUpdate[],
  isFinishedUpdateItems: boolean,
}

const DEFAULTSTATE: ICartState = {
  carts: [],
  loading: true,
  reservationFailedItems: [],
  companiesWithCostCenter: [],
  tripsByTripIds: [],
  tripsToAddOrder: [],
  verificationCode: {
    hasVerificationCode: false,
    isVerificationCodeError: false,
    verificationCodeIsTrue: false,
    autoVerification: false,
  },
  underage: {
    loading: false,
  },
  firstNonTotallyFilledItem: null,
  preselectedRequest1c: null,
  autocompletedItems: {},
  // Почему request1cList объект - непонятно
  // @ts-ignore
  request1cList: {},
  request1cListLoading: false,
  alreadySentIds: [],
  itemsToUpdate: [],
  isFinishedUpdateItems: false,
};

const updateItem = (item: IItem, list: IItem[]) => {
  const index = list.findIndex(i => i.Id === item.Id);
  const newList = list;
  newList[index] = item;

  return newList;
};

const updateItems = (newItems: IItem[], allItems: IItem[]) => allItems.map(item => {
  const foundedItem = newItems.find(newItem => newItem.Id === item.Id);

  if (foundedItem) {
    return foundedItem;
  }

  return item;
});

const updateFouls = (list: IItem[], serverTime: Moment | string) => {
  const fouls: IItem[] = []; // тухлые брони

  list.forEach((item) => {
    if (item.IsReserved
      && item.ServiceType === SERVICETYPE.AIR
      && moment(trimTimeZone(item.BookDeadline as string)).isBefore(serverTime)) {
      fouls.push(item);
    }
  });

  return fouls;
};

const checkForeignPassportsFouls = (employee: ICartItemEmployeeData, fouls: number[]): boolean => {
  const documents = employee.Employee.Documents.filter(document =>
    employee.Documents.AllowedDocumentTypes.includes(document.Type));

  const currentDocument = documents.find(document => document.Selected);
  const currentDocumentId = currentDocument ? currentDocument.Id : null;

  if (fouls.length > 0) {
    if (documents && documents.length === 1) {
      return !!fouls.find(foul => foul === documents[0].Id);
    }

    if (documents && documents.length > 1 && currentDocumentId) {
      return !!fouls.find(foul => foul === currentDocumentId);
    }

    if (documents && documents.length > 1 && !currentDocumentId) {
      return documents.every(document => fouls.find(foul => foul === document.Id));
    }
  }

  return false;
};

const updateForeignPassportsFouls = (sources: IItem[]) => {
  if (sources && sources.length > 0) {
    return sources.map((src) => {
      const source = {
        ...src,
        RequestItem: {
          Id: src?.RequestItem?.Id,
          RequestId: src?.RequestItem?.RequestId,
        },
      };

      if (source.ServiceType === SERVICETYPE.AIR || source.ServiceType === SERVICETYPE.TRAIN) {
        const clonedSourceEmployees = source.Employees.map((employee) => {
          const documents = employee.Employee.Documents.filter(document =>
            employee.Documents.AllowedDocumentTypes.includes(document.Type));

          if (documents && documents.length > 0) {
            let arrivalDate: Moment;

            if (source.ServiceType === SERVICETYPE.AIR) {
              const routesData = JSON.parse(source.Data).Routes;
              const lastRoute = routesData[routesData.length - 1];
              const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

              arrivalDate = parseUnix(lastSegment.ArrivalTime);
            } else if (source.ServiceType === SERVICETYPE.TRAIN) {
              const data = JSON.parse(source.Data);

              arrivalDate = moment(data.DateArrive);
            }

            const fouls: number[] = [];

            documents.forEach((document) => {
              const isCurrentDocumentFoul = arrivalDate
                ? (document.Type === PASSPORTS.FOREIGN_PASSPORT.TYPE
                || document.Type === PASSPORTS.DOMESTIC_PASSPORT.TYPE)
                && document.DueDate && arrivalDate.isAfter(document.DueDate)
                : false;

              if (isCurrentDocumentFoul) {
                fouls.push(document.Id);
              }
            });

            return {
              ...employee,
              errors: {
                selectedForeignPassportFoul: checkForeignPassportsFouls(employee, fouls),
              },
            };
          }

          return employee;
        });

        return { ...source, Employees: [...clonedSourceEmployees] };
      }

      return source;
    });
  }

  return sources;
};

const getIndexOfNormalCart = (list: CartsType[]) => {
  const selectedNormalCarts = list.find(cart => cart.status === CART_STATUS.NORMAL);

  return selectedNormalCarts ? list.indexOf(selectedNormalCarts) : -1;
};

const reducer = (
  action: {
    type: keyof typeof ACTION,
    payload: any,
  },
  state: ICartState,
) => {
  let newState = { ...state };

  switch (action.type) {
    case ACTION.CARTLOAD: {
      newState.loading = true;

      break;
    }

    case ACTION.UPDATE_ITEM_TP: {
      if (!newState.carts || !newState.carts.length) {
        break;
      }

      const normalCartIndex = getIndexOfNormalCart(newState.carts);
      const newItem = newState.carts[normalCartIndex];

      if (!newItem) {
        break;
      }

      newItem.items = updateItem(action.payload, newItem.sources);
      newItem.sources = updateForeignPassportsFouls(newItem.items);
      newState.carts[normalCartIndex] = newItem;

      break;
    }

    case ACTION.UPDATE_TRIP_BY_IDS: {
      newState.tripsByTripIds = action.payload;

      break;
    }

    case ACTION.UPDATE: {
      newState.carts = [];
      action.payload.forEach((item: ICartWpResult) => {
        if (item.Items.length) {
          const cart = {} as CartsType;
          const fouls = updateFouls(item.Items, item.ServerTime);
          cart.sources = updateForeignPassportsFouls(item.Items);

          const { minDate, maxDate, unlock, price, project } = getDataForCart(cart.sources, state.companiesWithCostCenter);
          cart.name = item.Name;
          cart.status = item.Status;
          cart.id = item.Id as number;
          cart.approve = item.Approve;
          cart.fouls = fouls;
          cart.minDate = minDate;
          cart.maxDate = maxDate;
          cart.unlock = unlock;
          cart.price = price;
          cart.serverTime = moment(item.ServerTime);
          cart.project = project;
          cart.userAnalytics = item.UserAnalytics || [];
          cart.isOffline = item.IsOffline;

          newState.carts.push(cart);
        }
      });

      newState.loading = false;
      break;
    }

    case ACTION.UPDATEITEM: {
      if (!newState.carts || !newState.carts.length) {
        break;
      }

      const normalCartIndex = getIndexOfNormalCart(newState.carts);

      const newItem = newState.carts[normalCartIndex];

      if (!newItem) {
        break;
      }

      newItem.items = updateItem(action.payload, newItem.sources);
      newItem.sources = updateForeignPassportsFouls(newItem.items);

      const { minDate, maxDate, unlock, price, project } = getDataForCart(newItem.sources, state.companiesWithCostCenter);
      newItem.minDate = minDate;
      newItem.maxDate = maxDate;
      newItem.unlock = unlock;
      newItem.price = price;
      newItem.project = project;

      newState.carts[normalCartIndex] = newItem;
      break;
    }

    case ACTION.UPDATE_ITEMS: {
      if (!newState.carts || !newState.carts.length) {
        break;
      }

      const normalCartIndex = getIndexOfNormalCart(newState.carts);

      const newItem = newState.carts[normalCartIndex];

      if (!newItem) {
        break;
      }

      newItem.items = updateItems(action.payload, newItem.sources);
      newItem.sources = updateForeignPassportsFouls(newItem.items);

      const { minDate, maxDate, unlock, price, project } = getDataForCart(newItem.sources, state.companiesWithCostCenter);
      newItem.minDate = minDate;
      newItem.maxDate = maxDate;
      newItem.unlock = unlock;
      newItem.price = price;
      newItem.project = project;

      newState.carts[normalCartIndex] = newItem;
      break;
    }

    case ACTION.UPDATEFOUL: {
      const { Id } = action.payload;
      const newItem = newState.carts.find((cart: CartsType) => cart.sources.some(source => source.Id === Id));

      if (newItem && newItem.fouls) {
        newItem.fouls = [...newItem.fouls, action.payload];
      }

      break;
    }

    case ACTION.UPDATENAME: {
      const normalCartIndex = getIndexOfNormalCart(newState.carts);

      newState.carts[normalCartIndex].name = action.payload;
      break;
    }

    case ACTION.UPDATEERROR: {
      const normalCartIndex = getIndexOfNormalCart(newState.carts);

      newState.carts[normalCartIndex].sources = action.payload;
      newState.loading = false;
      break;
    }

    // Нигде не используется
    case ACTION.SET_COMPANIES_WITH_COST_CENTER: {
      newState.companiesWithCostCenter = action.payload;

      break;
    }

    case ACTION.REMOVECARTITEM: {
      newState.carts = newState.carts.map((cart: CartsType) => {
        if (cart.id === action.payload.cartId) {
          const selectedCart = cart.sources.find(item => item.Id === action.payload.id);
          const index = selectedCart ? cart.sources.indexOf(selectedCart) : -1;

          if (index !== -1) {
            cart.sources.splice(index, 1);
          }
        }

        const { minDate, maxDate, unlock, price, project } = getDataForCart(cart.sources, state.companiesWithCostCenter);
        const serverTime = cart.ServerTime || cart.serverTime;
        const fouls = updateFouls(cart.sources, serverTime);

        return {
          ...cart,
          fouls,
          minDate,
          maxDate,
          unlock,
          price,
          project,
        };
      });

      break;
    }

    case ACTION.REMOVECART: {
      const selectedCart = newState.carts.find((cart: CartsType) => cart.id === action.payload);
      const index = selectedCart ? newState.carts.indexOf(selectedCart) : -1;

      if (index !== -1) {
        newState.carts.splice(index, 1);
      }

      break;
    }

    case ACTION.UPDATERESERVATIONFAILEDITEMS: {
      newState.reservationFailedItems = action.payload;

      break;
    }

    case ACTION.UPDATEVERIFICATIONCODERROR: {
      newState.verificationCode.isVerificationCodeError = action.payload;

      break;
    }

    case ACTION.GETVERIFICATIONCODE: {
      const { Available, AutoVerification } = action.payload;

      newState.verificationCode.hasVerificationCode = Available;
      newState.verificationCode.isVerificationCodeError = !Available;
      newState.verificationCode.autoVerification = AutoVerification;
      newState.verificationCode.verificationCodeIsTrue = false;

      break;
    }

    case ACTION.CHECKVERIFICATIONCODE: {
      const { Success, AutoVerification } = action.payload;

      newState.verificationCode.autoVerification = AutoVerification;
      newState.verificationCode.verificationCodeIsTrue = Success;

      break;
    }

    case ACTION.UPDATEUNDERAGE: {
      newState.underage = {
        ...newState.underage,
        ...action.payload,
      };

      break;
    }

    case ACTION.RESET: {
      newState = { ...DEFAULTSTATE };

      newState.preselectedRequest1c = state.preselectedRequest1c;

      break;
    }

    case ACTION.SET_FIRST_NON_TOTALLY_FILLED_ITEM: {
      newState.firstNonTotallyFilledItem = action.payload;

      break;
    }

    case ACTION.RESET_FIRST_NON_TOTALLY_FILLED_ITEM: {
      newState.firstNonTotallyFilledItem = null;

      break;
    }

    case ACTION.SET_TRIPS_TO_ADD_ORDER: {
      newState.tripsToAddOrder = action.payload.map(({ Name, CheckInDate, CheckOutDate, EmployeesFull, Id }: TripData) => {
        const formationString = () => {
          const date = `${formatRangeDateWithSimplicity(CheckInDate, CheckOutDate)}`;
          const employees = EmployeesFull.map(({ FullName }) => FullName).join(' ');
          let name = Name;

          if ((name + date).length > maxSymbols) {
            name = `${name.substring(0, maxSymbolsInTheName)}...`;
          }

          return `${name}, ${date}, ${employees}`;
        };

        return { value: Id, label: formationString() };
      },
      );

      break;
    }

    case ACTION.UPDATE_RULES: {
      const { itemId, cartId, rules } = action.payload;

      const currentCart = newState.carts.find(({ id }) => id === cartId);
      const currentItem = currentCart && currentCart.sources.find(({ Id }) => Id === itemId);

      if (currentItem) {
        currentItem.CancellationInfo = rules;
      }

      break;
    }

    case ACTION.SET_REQUESTS_1C_LIST: {
      const {
        payload: { id, list },
      } = action;

      newState.request1cList[id] = list;

      break;
    }

    case ACTION.SET_PRESELECT_REQUEST_1C: {
      newState.preselectedRequest1c = action.payload;

      break;
    }

    case ACTION.SET_AUTOCOMPLETED_ITEMS: {
      newState.autocompletedItems = {
        ...state.autocompletedItems,
        ...action.payload,
      };

      break;
    }

    case ACTION.SET_IS_ALREADY_SENT: {
      newState.alreadySentIds.push(action.payload);

      break;
    }

    case ACTION.ITEMS_TO_UPDATE: {
      newState.itemsToUpdate = action.payload;
      newState.isFinishedUpdateItems = !action.payload
        .find(({ State: { Status } }: { State: { Status: string } }) => Status !== IStatusUpdates.Finished);
    }
  }

  return newState;
};

// @ts-ignore
const createStore = () => new Store(reducer, DEFAULTSTATE);

export default createStore;
