import React from 'react';
import { Icon, Input } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  CODE_INPUT: getText('cart:codeInput'),
};

interface ICartActionsCodeInput {
  verificationCode: { verificationCodeIsTrue: boolean },
  verificationCodeValue: string,
  handleCheckCode(value: string): void,
}

const CartActionsCodeInput = ({
  verificationCode,
  verificationCodeValue,
  handleCheckCode,
}: ICartActionsCodeInput) => {
  const renderIcon = () => {
    if (!verificationCode.verificationCodeIsTrue) {
      return null;
    }

    return (
      <Icon
        className={ styles.check }
        type='checkMark'
        color='green'
      />
    );
  };

  return (
    <div className={ styles.wrapper }>
      { LABELS.CODE_INPUT }
      <Input
        className={ styles.input }
        value={ verificationCodeValue }
        onChange={ handleCheckCode }
      />
      { renderIcon() }
    </div>
  );
};

export { CartActionsCodeInput };
