import { ServiceReturnType } from '../../context/services';

import { FILTER_KEYS } from '../../constants/employee';

import { isSmartAgent } from '../../utils/env';

import { IRequestBody } from '../../types/filters';

const STEP = 50;

class SettingsProtocol {
  services: ServiceReturnType;

  constructor(services: ServiceReturnType) {
    this.services = services;
  }

  loadSettingsEmployeesPage = (field: string) => {
    const data = this.services.Settings.getDataSearchEmployeesAndChangePagination(field);

    Promise.all([
      this.getEmployeesCount(field),
      this.services.Settings.employeesSearchDebounce({ ...data, isSmartAgent }),
      this.services.Settings.getSettingsVoucher(),
    ])
      .then((list) => {
        this.services.Settings.setEmployeesCount(list[0]);
        this.services.Settings.setEmployees(list[1]);
      })
      .finally(() => this.services.Settings.setEmployeesWaitingResponse(false));
  };

  getFilterEmployees = async (field: string, value: string | number) => {
    await this.services.Settings.setEmployeesWaitingResponse(true);

    if (field) {
      this.services.Settings.changeFilters(field, value);
    }

    return this.loadSettingsEmployeesPage(field);
  };

  getFilterEmployeesProgressive = async (data: IRequestBody) => {
    await this.services.Settings.setEmployeesWaitingResponse(true);

    return this.services.Settings.loadSettingsEmployeesPage(data);
  };

  getEmployeesCount = (field: string) => {
    const data = this.services.Settings.getDataSearchEmployeesAndChangePagination(field);

    // @ts-ignore
    return this.services.Settings.getEmployeesCount({ ...data, isSmartAgent });
  };

  getEmployeesPaginationListNew = (flag: boolean, data: IRequestBody) => {
    this.services.Settings.setEmployeesWaitingResponse(true);

    if (flag) {
      this.getFilterEmployeesProgressive(data);

      return;
    }

    this.getFilterEmployees(FILTER_KEYS.COMPANY_ID, 0);
  };

  changePageEmployees = (page: number) => {
    this.services.Settings.changePageEmployeesOnly(page);
    // @ts-ignore
    this.getFilterEmployees();
  };

  resetFilters = (field: string, value: string, step: number = STEP) => {
    this.services.Settings.setResetFilers(field, value, step);
    // @ts-ignore
    this.getFilterEmployees();
  };
}

export { SettingsProtocol };
