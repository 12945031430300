import React, { FC, useState, useRef, useEffect } from 'react';
import { Icon, Text, IconButton } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import AirlineItem from '../../../../../../components/AirlineItem';
import { AirlineCacheItem, Metadata } from '../../../../../../bi/types/airline';
import { TicketDataInterface } from '../../../../../../components/ChangeAirTripHeader/types';

import scrollToTop from '../../../../../utils/scrollToTop';

import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  OLD_TICKET: getText('components:changeAirTrip.confirmationChange.oldTicket'),
  NEW_TICKET: getText('components:changeAirTrip.confirmationChange.newTicket'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionResult: {
        approve: {
          oldTicket: oldTicketTest,
          newTicket: newTicketTest,
        },
      },
    },
  },
} = QA_ATTRIBUTES;

interface AirItemApproveInterface {
  isNewTicket?: boolean;
  itemData: TicketDataInterface | AirlineCacheItem | null;
  metaNewTicket?: TicketDataInterface;
}

const AirItemApprove: FC<AirItemApproveInterface> = ({
  isNewTicket = false,
  itemData,
  metaNewTicket = {},
}) => {
  const [displayFare, setDisplayFare] = useState(false);

  const typeTicketTitleRef = useRef(isNewTicket ? LABELS.NEW_TICKET : LABELS.OLD_TICKET);
  const typeTicketMetaDataRef = useRef(isNewTicket ? metaNewTicket : itemData);
  const typeTicketClassStylesRef = useRef(isNewTicket ? styles.wrapper_newTicket : styles.wrapper_oldTicket);
  const typeTicketQARef = useRef(isNewTicket ? newTicketTest : oldTicketTest);

  useEffect(() => {
    scrollToTop();
  }, []);

  const typeIcon = displayFare ? 'arrowsUpRound' : 'arrowsDownRound';

  const handelDisplayFare = () => setDisplayFare(!displayFare);

  return (
    <div className={ `${styles.wrapper} ${typeTicketClassStylesRef.current}` }>
      <div className={ styles.header }>
        <Text
          qaAttr={ typeTicketQARef.current.title }
          className={ styles.title }
          type='bold_14'
        >
          { typeTicketTitleRef.current }
        </Text>
      </div>
      <div className={ styles.content }>
        <Icon qaAttr={ typeTicketQARef.current.icon } className={ styles.icon } type='itemAir'/>
        <div className={ styles.wrapper_ticket }>
          { itemData?.Routes.map((route, index) => (
            <div key={ `$airline_item_change_${index}` } className={ styles.item_airline }>
              <AirlineItem
                airTripChange
                fromTrip={ !isNewTicket }
                isCart={ isNewTicket }
                displayFareAirTripChange={ displayFare }
                route={ route }
                meta={ typeTicketMetaDataRef.current as Metadata }
                qaAttr={ typeTicketQARef.current.data }
              />
            </div>
          ))}
        </div>
        <div className={ styles.arrow }>
          <IconButton
            className={ styles.icon_arrow }
            iconType={ typeIcon }
            onClick={ handelDisplayFare }
            qaAttr={ typeTicketQARef.current.showFare }
          />
        </div>
      </div>
    </div>
  );
};

export { AirItemApprove };
