import { getText } from '../../../../../i18n';

import {
  ONLYRUCHARS,
  ONLYENCHARS,
  RUCHARSDOUBLESPACEHYPHEN,
  ENCHARSDOUBLESPACEHYPHEN,
  RUCHARS,
} from '../../../constants/regExp';
import DOCUMENTSFIELDS, { IFieldUser } from '../../../constants/documentsFields';
import { LANGUAGES } from '../../../constants/employee';
import { checkUaWithDomesticPassport } from '../../../utils/documents';

import { IDocumentUser } from '../types';

const ERRORS = {
  EMPTY: getText('services:employee.utils.validate.empty'),
  EMPTY_TIME_LIMIT: getText('services:employee.utils.validate.emptyTimeLimit'),
  NINE_SYMBOLS_NUMBER: getText('services:employee.utils.validate.nineSymbolsNumber'),
  ONLY_RU: getText('services:employee.utils.validate.onlyRu'),
  ONLY_EN: getText('services:employee.utils.validate.onlyEn'),
  ONLY_RU_AND_HYPHEN: getText('services:employee.utils.validate.onlyRuAndHyphen'),
  ONLY_RU_SPACE_AND_HYPHEN: getText('services:employee.utils.validate.onlyRuSpaceAndHyphen'),
  ONLY_EN_AND_HYPHEN: getText('services:employee.utils.validate.onlyEnAndHyphen'),
  ONLY_EN_SPACE_AND_HYPHEN: getText('services:employee.utils.validate.onlyEnSpaceAndHyphen'),
  ONLY_EN_AND_SPACE: getText('services:employee.utils.validate.onlyEnAndSpace'),
  NOT_TO_THE_END: getText('services:employee.utils.validate.notToTheEnd'),
  ONLY_LAT_AND_NUMBERS: getText('services:employee.utils.validate.onlyLatAndNumber'),
  DOUBLE: getText('services:employee.utils.validate.double'),
  ONE_FORMAT: getText('services:employee.utils.validate.oneFillingFormat'),
  UNSUPPORTED_CHARS: getText('services:employee.utils.validate.unsupportedChars'),
};
const getInputLanguage = (value: string) => {
  if (value.length > 0) {
    if (ENCHARSDOUBLESPACEHYPHEN.test(value)) return LANGUAGES.EN;

    if (RUCHARSDOUBLESPACEHYPHEN.test(value)) return LANGUAGES.RU;
  }

  return LANGUAGES.ERR;
};

const validateEmpty = (
  value: string,
  isDoubleDoc: boolean,
) => {
  if (isDoubleDoc) {
    return value.trim() && ERRORS.DOUBLE;
  }

  return (value.trim && value.trim() === '') || !value
    ? ERRORS.EMPTY
    : '';
};

const validateNumberRuPassport = (
  value: string,
  isDoubleDoc: boolean,
) => {
  value.trim();

  if (isDoubleDoc) {
    return ERRORS.DOUBLE;
  }

  return value.length !== 10
    ? ERRORS.NOT_TO_THE_END
    : '';
};

const validateNumberCertificate = (value: string) => {
  const valueWithoutSpace = value.replace(/\s/g, '');
  const valueLength = valueWithoutSpace.length;
  const lastPathValueNotNumber = isNaN(
    valueWithoutSpace.slice(valueLength - 6, valueLength) as unknown as number,
  );

  return lastPathValueNotNumber
    ? ERRORS.NOT_TO_THE_END
    : '';
};

const validateNumberForeignPassport = (
  value: string,
  isDoubleDoc: boolean,
) => {
  value.trim();

  if (isDoubleDoc) {
    return ERRORS.DOUBLE;
  }

  return value.length !== 9
    ? ERRORS.NINE_SYMBOLS_NUMBER
    : '';
};

const validateDueDateDomesticPassport = (
  value: string,
  isDoubleDoc: boolean,
  { Type }: IDocumentUser,
  code: string,
) => {
  if (checkUaWithDomesticPassport(code, Type)) {
    if (isDoubleDoc) {
      return ERRORS.DOUBLE;
    }

    return '';
  }

  return validateEmpty(value, isDoubleDoc);
};

const validateOnlyRu = (value: string) => (value.length > 0 && !ONLYRUCHARS.test(value) ? ERRORS.ONLY_RU : '');

const validateOnlyEn = (value: string) => (value.length > 0 && !ONLYENCHARS.test(value) ? ERRORS.ONLY_EN : '');

const validateOnlyRuSpaceHyphen = (value: string) => (
  value.length > 0 && !RUCHARSDOUBLESPACEHYPHEN.test(value) ? ERRORS.ONLY_RU_SPACE_AND_HYPHEN : ''
);

const validateOnlyEnSpaceHyphen = (value: string) => (
  value.length > 0 && !ENCHARSDOUBLESPACEHYPHEN.test(value) ? ERRORS.ONLY_EN_SPACE_AND_HYPHEN : ''
);

const validateOnlyOneLang = (value: string) => {
  let lastPassedIndex = -1;

  const validations = [
    [validateOnlyRu, validateOnlyRuSpaceHyphen],
    [validateOnlyEn, validateOnlyEnSpaceHyphen],
  ];

  validations.every(([validate], index) => {
    const result = validate(value[0] || '') === '';

    if (result) lastPassedIndex = index;

    return !result;
  });

  if (lastPassedIndex === -1) {
    if (value.length === 0) return '';

    return ERRORS.UNSUPPORTED_CHARS;
  }

  return validations[lastPassedIndex][1](value);
};

const validateEnRuSpaceHyphen = (
  value: string,
  _isDoubleDoc: boolean,
  mayCyrillic: boolean,
  inputLanguage: string[],
  mayLatin: boolean,
) => {
  const noDuplicate = (arr: string[], lang: string) => arr.includes(lang);
  let isDifferentLanguage = true;

  if (mayCyrillic && value.length > 0) {
    if (ENCHARSDOUBLESPACEHYPHEN.test(value[0]) && mayLatin) {
      isDifferentLanguage = noDuplicate(Object.values(inputLanguage), LANGUAGES.RU);

      if (isDifferentLanguage) {
        return ERRORS.ONE_FORMAT;
      }

      return !ENCHARSDOUBLESPACEHYPHEN.test(value) ? ERRORS.ONLY_EN_SPACE_AND_HYPHEN : '';
    }

    if (ENCHARSDOUBLESPACEHYPHEN.test(value[0]) && !mayLatin) {
      return ERRORS.ONLY_RU_SPACE_AND_HYPHEN;
    }

    if (RUCHARSDOUBLESPACEHYPHEN.test(value[0]) && mayCyrillic) {
      isDifferentLanguage = noDuplicate(Object.values(inputLanguage), LANGUAGES.EN);

      if (isDifferentLanguage) {
        return ERRORS.ONE_FORMAT;
      }

      return !RUCHARSDOUBLESPACEHYPHEN.test(value) ? ERRORS.ONLY_RU_SPACE_AND_HYPHEN : '';
    }

    if (RUCHARSDOUBLESPACEHYPHEN.test(value[0]) && !mayCyrillic) {
      return ERRORS.ONLY_EN_SPACE_AND_HYPHEN;
    }
  }

  return value.length > 0 && !ENCHARSDOUBLESPACEHYPHEN.test(value)
    ? ERRORS.ONLY_EN_SPACE_AND_HYPHEN
    : '';
};

const validateExcludeRuChars = (
  value: string,
  isDoubleDoc: boolean,
) => {
  if (isDoubleDoc) {
    return ERRORS.DOUBLE;
  }

  return value.length > 0 && value.search(RUCHARS) > -1
    ? ERRORS.ONLY_LAT_AND_NUMBERS
    : '';
};

const ruPassportRules = {
  [DOCUMENTSFIELDS.NUMBER]: validateNumberRuPassport,
  [DOCUMENTSFIELDS.NAME]: validateEmpty,
  [DOCUMENTSFIELDS.SURNAME]: validateEmpty,
  [DOCUMENTSFIELDS.FIRSTNAME]: validateEmpty,
  [DOCUMENTSFIELDS.MIDDLENAME]: validateEmpty,
  [DOCUMENTSFIELDS.LASTNAME]: validateEmpty,
};

const birthCertificateRules = {
  [DOCUMENTSFIELDS.NUMBER]: validateNumberCertificate,
  [DOCUMENTSFIELDS.NAME]: validateEmpty,
  [DOCUMENTSFIELDS.SURNAME]: validateEmpty,
  [DOCUMENTSFIELDS.PATRONYMIC]: validateOnlyRu,
};

const foreignPassportRules = {
  [DOCUMENTSFIELDS.NUMBER]: validateNumberForeignPassport,
  [DOCUMENTSFIELDS.DUEDATE]: validateEmpty,
};

const domesticPassportRules = {
  [DOCUMENTSFIELDS.SURNAME]: validateEmpty,
  [DOCUMENTSFIELDS.NAME]: validateEmpty,
  [DOCUMENTSFIELDS.NUMBER]: validateEmpty,
  [DOCUMENTSFIELDS.DUEDATE]: validateDueDateDomesticPassport,
  [DOCUMENTSFIELDS.FIRSTNAME]: validateEmpty,
  [DOCUMENTSFIELDS.LASTNAME]: validateEmpty,
};

const ruPassportRulesOnInput = {
  [DOCUMENTSFIELDS.PATRONYMIC]: validateOnlyRu,
  [DOCUMENTSFIELDS.SURNAME]: validateOnlyRuSpaceHyphen,
  [DOCUMENTSFIELDS.NAME]: validateOnlyRuSpaceHyphen,
  [DOCUMENTSFIELDS.MIDDLENAME]: validateOnlyEn,
  [DOCUMENTSFIELDS.FIRSTNAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.LASTNAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.NUMBER]: validateEmpty,
};

const birthCertificateRulesOnInput = {
  [DOCUMENTSFIELDS.NAME]: validateOnlyRuSpaceHyphen,
  [DOCUMENTSFIELDS.PATRONYMIC]: validateOnlyRuSpaceHyphen,
  [DOCUMENTSFIELDS.SURNAME]: validateOnlyRuSpaceHyphen,
  [DOCUMENTSFIELDS.NUMBER]: validateExcludeRuChars,
};

const foreignPassportRulesOnInput = {
  [DOCUMENTSFIELDS.SURNAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.NAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.PATRONYMIC]: validateOnlyOneLang,
  [DOCUMENTSFIELDS.LASTNAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.FIRSTNAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.MIDDLENAME]: validateOnlyEn,
  [DOCUMENTSFIELDS.NUMBER]: validateEmpty,
};

const domesticPassportRulesOnInput = {
  [DOCUMENTSFIELDS.NAME]: validateEnRuSpaceHyphen,
  [DOCUMENTSFIELDS.SURNAME]: validateEnRuSpaceHyphen,
  [DOCUMENTSFIELDS.PATRONYMIC]: validateEnRuSpaceHyphen,
  [DOCUMENTSFIELDS.FIRSTNAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.LASTNAME]: validateOnlyEnSpaceHyphen,
  [DOCUMENTSFIELDS.NUMBER]: validateExcludeRuChars,
};

const ruPassport = (
  document: IDocumentUser,
  field: keyof IFieldUser,
  _code: string,
  isDoubleDoc: boolean,
): string => {
  const ruleKey = field as keyof typeof ruPassportRules;

  return ruPassportRules[ruleKey]
    ? ruPassportRules[ruleKey](document[ruleKey], isDoubleDoc)
    : '';
};

const birthCertificate = (
  document: IDocumentUser,
  field: keyof IFieldUser,
) => {
  const ruleKey = field as keyof typeof birthCertificateRules;

  return birthCertificateRules[ruleKey]
    ? birthCertificateRules[ruleKey](document[ruleKey], false)
    : '';
};

const foreignPassport = (
  document: IDocumentUser,
  field: keyof IFieldUser,
  _code: string,
  isDoubleDoc: boolean,
) => {
  const ruleKey = field as keyof typeof foreignPassportRules;

  return foreignPassportRules[ruleKey]
    ? foreignPassportRules[ruleKey](document[ruleKey], isDoubleDoc)
    : '';
};

const domesticPassport = (
  document: IDocumentUser,
  field: keyof IFieldUser,
  code: string,
  isDoubleDoc: boolean,
) => {
  const ruleKey = field as keyof typeof domesticPassportRules;

  return domesticPassportRules[ruleKey]
    ? domesticPassportRules[ruleKey](document[ruleKey], isDoubleDoc, document, code)
    : '';
};

const ruPassportOnInput = (
  field: keyof IFieldUser,
  value: string,
  isDoubleDoc: boolean = false,
) => {
  const ruleKey = field as keyof typeof ruPassportRulesOnInput;

  return ruPassportRulesOnInput[ruleKey]
    ? ruPassportRulesOnInput[ruleKey](value, isDoubleDoc)
    : '';
};

const foreignPassportOnInput = (
  field: keyof IFieldUser,
  value: string,
  isDoubleDoc: boolean,
) => {
  const ruleKey = field as keyof typeof foreignPassportRulesOnInput;

  return foreignPassportRulesOnInput[ruleKey]
    ? foreignPassportRulesOnInput[ruleKey](value, isDoubleDoc)
    : '';
};

const domesticPassportOnInput = (
  field: keyof IFieldUser,
  value: string,
  isDoubleDoc: boolean,
  mayCyrillic: boolean,
  inputLanguage: string[],
  mayLatin: boolean,
) => {
  const ruleKey = field as keyof typeof domesticPassportRulesOnInput;

  return domesticPassportRulesOnInput[ruleKey]
    ? domesticPassportRulesOnInput[ruleKey](
      value,
      isDoubleDoc,
      mayCyrillic,
      inputLanguage,
      mayLatin,
    )
    : '';
};

const birthCertificateOnInput = (
  field: keyof IFieldUser,
  value: string,
) => {
  const ruleKey = field as keyof typeof birthCertificateRulesOnInput;

  return birthCertificateRulesOnInput[ruleKey]
    ? birthCertificateRulesOnInput[ruleKey](value, false)
    : '';
};

const validateField = {
  DomesticPassport: domesticPassport,
  ForeignPassport: foreignPassport,
  RuPassport: ruPassport,
  BirthCertificate: birthCertificate,
};

const validateValue = {
  DomesticPassport: domesticPassportOnInput,
  ForeignPassport: foreignPassportOnInput,
  RuPassport: ruPassportOnInput,
  BirthCertificate: birthCertificateOnInput,
};

const mainInputLanguage = {
  [DOCUMENTSFIELDS.NAME]: getInputLanguage,
  [DOCUMENTSFIELDS.SURNAME]: getInputLanguage,
  [DOCUMENTSFIELDS.PATRONYMIC]: getInputLanguage,
  [DOCUMENTSFIELDS.FIRSTNAME]: getInputLanguage,
  [DOCUMENTSFIELDS.LASTNAME]: getInputLanguage,
  [DOCUMENTSFIELDS.NUMBER]: getInputLanguage,
};

export { validateField, validateValue, mainInputLanguage };
