import React from 'react';
import { Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import logo from '../../../../../svg/logos/smartway.svg';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './index.module.css';

const LABELS = {
  START_SEARCH: getText('smartdesk:startSearch'),
  CUSTOM_START_SEARCH: getText('smartdesk:customStartSearch'),
};

interface HeroTitleProps {
  customHeroTitle: boolean;
}

export const HeroTitle = ({ customHeroTitle }: HeroTitleProps) => {
  const titleText = customHeroTitle
    ? LABELS.CUSTOM_START_SEARCH
    : LABELS.START_SEARCH;
  const titleImg = customHeroTitle && (
  <img
    src={ logo }
    className={ styles.logo }
    alt='Smartway'
  />
  );

  return (
    <Text
      qaAttr={ QA_ATTRIBUTES.smartdesk.startSearch }
      type='bold_32'
    >
      { titleText }
      { titleImg }
    </Text>
  );
};
