import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Text, Textarea, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { getDateDescriptor } from '../../../bi/utils/formatDate';

import { checkFieldsForTooltip, disabledApprovalButton } from '../../../bi/utils/approval';
import { MainAnalytic } from '../../../bi/utils/analytics';

import { APPROVE_STATUS } from '../../../bi/constants/travelApproval';

import { AddresserInfoItem } from '../../../components/AddresserInfoItem';

import { SpendingBlock } from './SpendingBlock';
import GeneralBlock from './GeneralBlock';
import { Comments } from './Comments';

import TravelApproval from '../../../bi/services/travelApproval';
import FeatureFlagsService from '../../../bi/services/featureFlags';
import AccountSettingsService from '../../../bi/services/accountSettings';
import TripTags from '../../../bi/services/tripTags';
import { IAnalyticsType } from '../../../bi/services/customAnalytics/types';
import { RequestInterface } from '../../../bi/types/applicationScheme';
import Hotels from '../../../bi/services/hotels';
import Workspace from '../../../bi/services/workspace';
import UserSession from '../../../bi/services/userSession';
import CustomAnalyticsService from '../../../bi/services/customAnalytics';
import { IEmployees } from '../../../bi/services/employee/types';
import { TravelApprovalEmployeeType } from '../../../bi/services/travelApproval/consts';
import { Region } from '../../../bi/services/hotels/types';

import styles from '../styles/approverContent.module.css';

const LABELS = {
  APPROVE: getText('components:travelApproval.approve'),
  CONFIRM_ACTION: getText('components:travelApproval.confirmAction'),
  DECLINE: getText('components:travelApproval.decline'),
  COMMENT_FOR_APPROVE: getText('components:travelApproval.commentForApprove'),
  COMMENT_FOR_DECLINE: getText('components:travelApproval.commentForDecline'),
  REQUEST: getText('components:travelApproval.requestFrom'),
  REQUIRED_FIELDS: getText('components:travelApproval.requiredFields'),
  YOU_ARE_IN_DEMO: getText('components:travelApproval.youAreInDemo'),
  CREATE_REQUEST_NOT_AVAILABLE: getText('components:travelApproval.createRequestNotAvailable'),
};

interface ApproverContentProps {
  disabled: boolean,
  disabledToUpdateField: boolean,
  isNew: boolean,
  isMultiCompany: boolean,
  mayApprove: boolean,
  readOnly: boolean,
  isDemo: boolean,
  employeeId: number,
  accountEmail: string,
  currentUserId: string,
  userAnalytics: IAnalyticsType,
  selectedAnalytics: number[],
  region: Region,
  applicationSchemeTemplate: RequestInterface,
  accountSettingsService: AccountSettingsService,
  featureFlagsService: FeatureFlagsService,
  travelApprovalService: TravelApproval,
  tripTagsService: TripTags,
  hotelsService: Hotels,
  workspaceService: Workspace,
  userSessionService: UserSession,
  customAnalyticsService: CustomAnalyticsService,
  onApproveRequest(field: string, comment: string): Promise<void>,
  getEmployees(value: string, flag?: boolean): Promise<IEmployees[]>;
  onAddEmployee(item: TravelApprovalEmployeeType, index: number): Promise<void>,
  onRemoveEmployee(item: TravelApprovalEmployeeType, index: number): void,
  onAddEmployeeCompany(item: number, index: number): void,
  onAddProject(projectId: number | null, employeeId: number): void,
  onAddCustomAnalytic(analyticsId: number, value: string): void,
}

const ApproverContent = observer(({
  region,
  readOnly,
  isMultiCompany,
  isDemo,
  employeeId,
  disabled,
  applicationSchemeTemplate,
  disabledToUpdateField,
  currentUserId = '',
  accountEmail = '',
  userAnalytics,
  selectedAnalytics,
  onApproveRequest,
  getEmployees,
  onAddEmployee,
  onRemoveEmployee,
  onAddEmployeeCompany,
  mayApprove = false,
  isNew,
  customAnalyticsService,
  userSessionService,
  featureFlagsService,
  onAddProject,
  onAddCustomAnalytic,
  workspaceService,
  travelApprovalService,
  tripTagsService,
  hotelsService,
}: ApproverContentProps) => {
  const {
    travelApprovalStore,
    settingsStore,
  } = useStores([MOBX_STORES.TRAVEL_APPROVAL_STORE, MOBX_STORES.SETTINGS_STORE]);

  const [approveDialog, setApproveDialog] = useState(false);
  const [declineDialog, setDeclineDialog] = useState(false);
  const [comment, setComment] = useState('');

  const {
    travelApproval,
    approvalPopUp,
    travelApproval: { Employees, Budget, Destinations, StartDate, EndDate, Purpose, Comment, Approves },
    purposes,
    employeesWithRights,
    loadingSendButton,
  } = travelApprovalStore;
  const { companiesWithCostCenter } = settingsStore;

  const destinationNull = Destinations?.length ?
    Destinations.some(destination => destination.SourceId === null || destination.Id === null) : false;
  const destination = (applicationSchemeTemplate && applicationSchemeTemplate.IsRequiredDestinationCity) ? !destinationNull : true;

  const datesIsValid = StartDate && EndDate;
  const { disabled: disableApproveButton } = disabledApprovalButton(
    Employees,
    Budget,
    destination,
    datesIsValid,
    Purpose,
    Comment,
    applicationSchemeTemplate,
    true,
    companiesWithCostCenter,
    userAnalytics,
    selectedAnalytics,
  );

  const handleApprove = (field: string) => {
    if (field === APPROVE_STATUS.ACCEPTED) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_APPROVE_BUTTON_PRESSED);

      setApproveDialog(false);
    }

    if (field === APPROVE_STATUS.DECLINED) {
      setDeclineDialog(false);
    }

    setComment('');

    return onApproveRequest(field, comment);
  };

  const handleCloseApproveDialog = () => {
    setApproveDialog(false);
    setComment('');
  };

  const handleCloseDeclineDialog = () => {
    setDeclineDialog(false);
    setComment('');
  };

  const renderApproveDialog = () => {
    if (!approveDialog) return null;

    return (
      <Dialog
        showClosing
        show={ approveDialog }
        onChange={ () => handleCloseApproveDialog() }
      >
        <div className={ styles['dialog-content'] }>
          <Text type='bold_20' className={ styles['dialog-header'] }>
            { LABELS.CONFIRM_ACTION }
          </Text>
          <Textarea
            placeholder={ LABELS.COMMENT_FOR_APPROVE }
            value={ comment }
            onChange={ setComment }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              type='primary'
              onClick={ () => handleApprove(APPROVE_STATUS.ACCEPTED) }
            >
              { LABELS.APPROVE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderDeclineDialog = () => {
    if (!declineDialog) return null;

    return (
      <Dialog
        showClosing
        show={ declineDialog }
        onChange={ () => handleCloseDeclineDialog() }
      >
        <div className={ styles['dialog-content'] }>
          <Text type='bold_20' className={ styles['dialog-header'] }>
            { LABELS.CONFIRM_ACTION }
          </Text>
          <Textarea
            placeholder={ LABELS.COMMENT_FOR_DECLINE }
            value={ comment }
            onChange={ setComment }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              type='primary'
              onClick={ () => handleApprove(APPROVE_STATUS.DECLINED) }
            >
              { LABELS.DECLINE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const tooltip = () => {
    const nonFilledFields = checkFieldsForTooltip(
      Employees,
      Budget,
      Destinations[0].SourceId || Destinations[0].Id,
      datesIsValid,
      Purpose,
      Comment,
      applicationSchemeTemplate,
      true,
      companiesWithCostCenter,
      userAnalytics,
      selectedAnalytics,
    );
    const tooltipText = nonFilledFields.join(', ');

    const textHtml = nonFilledFields.length ? (
      <Text
        color='white'
        type='NORMAL_14_130'
        className={ styles['error-text'] }
      >
        { tooltipText }
      </Text>
    ) : null;

    if (isDemo) {
      return (
        <div className={ styles['tooltip-wrapper'] }>
          <Text color='white' type='NORMAL_14_130'>
            { LABELS.YOU_ARE_IN_DEMO }
          </Text>
          <Text color='white' type='NORMAL_14_130'>
            { LABELS.CREATE_REQUEST_NOT_AVAILABLE }
          </Text>
        </div>
      );
    }

    return (
      <div className={ styles['tooltip-wrapper'] }>
        <Text color='white' type='bold_16'>
          { LABELS.REQUIRED_FIELDS }
        </Text>
        { textHtml }
      </div>
    );
  };

  const renderPanel = () => {
    const { Status } = travelApproval;

    if (Status !== APPROVE_STATUS.WAITING_APPROVE || !mayApprove) return null;

    return (
      <div className={ styles.panel }>
        <div className={ styles.panel_content }>
          <Tooltip
            show={ isDemo || disableApproveButton || !destination }
            renderContent={ tooltip }
          >
            <Button
              loading={ loadingSendButton }
              disabled={ isDemo || loadingSendButton || !destination || disableApproveButton }
              type='secondary'
              className={ styles.panel_button }
              onClick={ () => setApproveDialog(true) }
            >
              { LABELS.APPROVE }
            </Button>
          </Tooltip>
          <Tooltip
            show={ isDemo }
            renderContent={ tooltip }
          >
            <Button
              disabled={ isDemo }
              type='textual-medium'
              onClick={ () => setDeclineDialog(true) }
            >
              { LABELS.DECLINE }
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const header = !!Destinations.length && Destinations[0].Name?.split(',')[0];
  const firstComment = !!Approves.length && Approves[0]?.Messages?.[0];
  const { Email, Name, Date } = firstComment || {};
  const date = getDateDescriptor(Date).toLowerCase();

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_32'>{ header }</Text>
      <div className={ styles.request }>
        <AddresserInfoItem
          date={ date }
          email={ Email }
          name={ Name }
          label={ LABELS.REQUEST }
          typeText='NORMAL_16'
        />
      </div>
      <Comments userId={ currentUserId } accountEmail={ accountEmail } />
      <SpendingBlock />
      <GeneralBlock
        applicationSchemeTemplate={ applicationSchemeTemplate }
        travelApproval={ travelApproval }
        approvalPopUp={ approvalPopUp }
        purposes={ purposes }
        region={ region }
        readOnly={ readOnly }
        isMulti={ isMultiCompany }
        employeesWithRights={ employeesWithRights }
        employeeId={ employeeId }
        disabledField={ disabled }
        disabledToUpdateField={ disabledToUpdateField }
        // @ts-ignore
        getEmployees={ getEmployees }
        onAddEmployee={ onAddEmployee }
        onRemoveEmployee={ onRemoveEmployee }
        onAddEmployeeCompany={ onAddEmployeeCompany }
        isNew={ isNew }
        userSessionService={ userSessionService }
        onAddProject={ onAddProject }
        featureFlagsService={ featureFlagsService }
        customAnalyticsService={ customAnalyticsService }
        workspaceService={ workspaceService }
        tripTagsService={ tripTagsService }
        hotelsService={ hotelsService }
        travelApprovalService={ travelApprovalService }
        onAddCustomAnalytic={ onAddCustomAnalytic }
      />
      { renderPanel() }
      { renderApproveDialog() }
      { renderDeclineDialog() }
    </div>
  );
});

export { ApproverContent };
