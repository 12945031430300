import React from 'react';
import { Button, Icon, IconButton, Select, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import { getEmployeeFullNameWithSimplicity } from '../../../bi/utils/employees';
import { checkAddCompany, getEmployeeCompaniesWithDepartments } from '../../../bi/utils/approval';
import { isDepartmentId } from '../../../bi/utils/validation';

import { EmployeeSuggest } from '../../../components/EmployeeSuggest';
import ProjectSelect from '../../../components/EmployeeList/ProjectSelect';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';
import { COMPANY_DEPARTMENT_DIVIDER } from '../../../bi/constants/cart';
import {
  ApproveStatus,
  ProjectEditableType,
  TravelApprovalEmployeeType,
} from '../../../bi/services/travelApproval/consts';

import { ICompany, IEmployees } from '../../../bi/services/employee/types';

import styles from './styles/styles.module.css';
import { EmployeeType } from '../../../bi/services/employee/consts';

const LABELS = {
  CHOOSE_COMPANY: getText('components:travelApproval.selectCompany'),
  CHOOSE_DEPARTMENT: getText('components:travelApproval.selectDepartment'),
  CHOOSE_EMPLOYEE: getText('components:travelApproval.selectEmployee'),
  PROJECT_LABEL: getText('components:employeeList.project.label'),
  PROJECT_NOT_SELECTED: getText('components:employeeList.project.notIncluded'),
};

interface SelectEmployeeProps {
  item: EmployeeType | null;
  items: Array<EmployeeType>;
  readOnly?: boolean;
  disabledField?: boolean;
  getEmployees(value: string, flag?: boolean): Promise<EmployeeType[]>;
  onAddEmployee(item: TravelApprovalEmployeeType, index?: number): Promise<void>,
  onRemoveEmployee(item: TravelApprovalEmployeeType): void,
  onAddProject(projectId: number | null, employeeId: number): void,
  onAddCompany(item: number, index: string | number): void,
  number: number;
  index: number;
  isMulti: boolean;
  projects: Array<any>;
  projectEditable: ProjectEditableType;
  employees: Array<IEmployees>;
  approvalStatus: string;
  updateLock?: boolean;
}

interface SelectEmployeeState {
  hasAdd: boolean,
  inputValue: EmployeeType | string,
  employee: EmployeeType,
  projects: Array<any>,
  showSelectProject: boolean,
  selectedProject: any,
}

class SelectEmployee extends React.Component<SelectEmployeeProps, SelectEmployeeState> {
  static defaultProps = {
    item: null,
    items: [],
    readOnly: false,
    disabledField: false,
    getEmployees: () => {},
    onAddEmployee: () => {},
    onRemoveEmployee: () => {},
    onAddCompany: () => {},
    projects: [],
    employees: [],
  };

  constructor(props: SelectEmployeeProps) {
    super(props);

    const { item, projects } = props;

    const employee = item || null;
    const selectedProject = item?.ProjectId;

    this.state = {
      hasAdd: false,
      inputValue: '',
      // @ts-ignore
      employee,
      projects,
      showSelectProject: false,
      selectedProject,
    };
  }

  componentDidUpdate(prevProps: SelectEmployeeProps) {
    const { item, projects } = this.props;

    if (prevProps.item !== item) {
      this.setState({
        // @ts-ignore
        employee: item,
      });
    }

    if (prevProps.projects !== projects) {
      this.setState({
        projects,
      });
    }
  }

  handleChangeCompany = (value: number) => {
    const { onAddCompany } = this.props;
    onAddCompany(this.state.employee.Id as number, value);
  };

  prepareCompanyAndDepartmentValue = ({ CompanyId }: ICompany, DepartmentId: number) => {
    if (DepartmentId) {
      return `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${DepartmentId}`;
    }

    return CompanyId;
  };

  handleSwitchEmpty = () => {
    this.setState({
      hasAdd: !this.state.hasAdd,
    });
  };

  handleInputChange = (value: string) => this.handleFetchRequest(value);

  handleFetchRequest = (value: string) => {
    const { items, getEmployees } = this.props;

    return getEmployees(value, false).then((res: EmployeeType[]) =>
      res.filter(employee =>
        !items.some(item => item.Id === employee.Id),
      ));
  };

  handleAddEmployee = (suggestion: EmployeeType) => {
    const {
      onAddEmployee,
      onAddCompany,
      isMulti,
      index,
    } = this.props;

    return onAddEmployee(suggestion).then(() => {
      const { CompanyId, Departments } = suggestion?.Companies?.[0];

      if (suggestion && checkAddCompany(isMulti, suggestion?.Companies?.length as number, index)) {
        if (Departments?.length === 1) {
          const departmentId = Departments[0].Id;
          onAddCompany(suggestion?.Id as number, `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${departmentId}`);
        } else {
          onAddCompany(suggestion?.Id as number, CompanyId);
        }
      }

      this.setState({
        inputValue: this.handleGetValue(suggestion) as string,
        hasAdd: false,
      });
    });
  };

  handleGetValue = (suggestion: TravelApprovalEmployeeType & { addNewEmployee?: boolean }) => {
    if (!suggestion.addNewEmployee) {
      return getEmployeeFullNameWithSimplicity(suggestion, true);
    }

    return null;
  };

  handleRemoveEmployee = () => {
    const {
      onRemoveEmployee,
    } = this.props;
    const { employee } = this.state;

    onRemoveEmployee(employee);
  };

  handleChangeProject = (projectId: number, employeeId: number) => {
    const { onAddProject } = this.props;

    onAddProject(projectId, employeeId);
    this.showSelectProjectFn();
    this.handleSelectProject(projectId);
  };

  handleClearProject = (employeeId: number) => {
    const { onAddProject } = this.props;

    onAddProject(null, employeeId);
    this.handleSelectProject(null);
  };

  showSelectProjectFn = () => {
    if (!this.props.disabledField) this.setState({ showSelectProject: !this.state.showSelectProject });
  };

  handleSelectProject = (projectId: number | null) => this.setState({ selectedProject: projectId });

  renderProject = () => {
    const {
      projects,
      employee: { ProjectId, Id: employeeId },
      showSelectProject,
      selectedProject: editableProject,
    } = this.state;
    const { projectEditable, employees, disabledField, updateLock } = this.props;

    if (!projects.length) return null;

    const employeeProjectId = employees?.length ? employees.find(({ Id }) => Id === employeeId)?.ProjectId : ProjectId;
    const projectIdForFound = employeeProjectId ?? ProjectId;
    let selectedProject = projects.find(({ Id }) => projectIdForFound === Id);

    if (!projectEditable[employeeId as unknown as keyof ProjectEditableType]) {
      return (
        <div className={ styles['space-between'] }>
          <Text color='gray'>{LABELS.PROJECT_LABEL}: </Text>{ selectedProject?.Name }
        </div>
      );
    }

    if (disabledField && updateLock) {
      const label = selectedProject ? selectedProject?.Name : LABELS.PROJECT_NOT_SELECTED;

      return (
        <div className={ styles['space-between'] }>
          <Text color='gray'>{LABELS.PROJECT_LABEL}: { label }</Text>
        </div>
      );
    }

    if (showSelectProject) {
      return (
        <ProjectSelect
          projects={ projects }
          onSelect={ (projectId: number) => this.handleChangeProject(projectId, employeeId as number) }
          showAdd={ false }
          projectName={ selectedProject?.Name }
        />
      );
    }

    selectedProject = projects.find(({ Id }) => editableProject === Id);
    const label = editableProject ? selectedProject?.Name : LABELS.PROJECT_NOT_SELECTED;

    const deleteProjectButton = editableProject && !disabledField && (
      <IconButton
        iconType='closeButton'
        onClick={ () => this.handleClearProject(employeeId as number) }
        className={ styles.remove }
      />
    );

    return (
      <div className={ styles['space-between'] }>
        <Text color='gray'>{LABELS.PROJECT_LABEL}:</Text>
        <Button
          className={ styles.button }
          onClick={ this.showSelectProjectFn }
          type='textual'
        >
          &nbsp;{ label }
        </Button>
        { deleteProjectButton }

      </div>
    );
  };

  renderCompanyName = (companyName: string) => (
    <Text color='gray' className={ styles.company }>
      { companyName }
    </Text>
  );

  renderSingleCompany = ({ ShortCompanyName, CompanyName, Departments }: ICompany, list: any[], value: string) => {
    const { disabledField } = this.props;

    if (Departments.length <= 1) {
      return this.renderCompanyName(list[0].label);
    }

    const selectClassNames = [styles.company];

    if (!isDepartmentId(value) && list.length > 1) {
      selectClassNames.push(styles.highlight);
    }

    if (Departments.length && !disabledField) {
      return (
        <div className={ selectClassNames.join(' ') }>
          <Select
            placeholder={ LABELS.CHOOSE_DEPARTMENT }
            qaAttrLabel={ QA_ATTRIBUTES.approvalRequest.department.choose }
            excludeValue
            value={ value }
            items={ list }
            onChange={ this.handleChangeCompany }
            className={ styles.select }
          />
        </div>
      );
    }

    const defaultCompanyName = ShortCompanyName || CompanyName;

    if (defaultCompanyName) return this.renderCompanyName(defaultCompanyName);

    return <div className={ styles['employee-company'] } />;
  };

  renderNumber = () => {
    const { number } = this.props;

    return (
      <Text className={ styles.number } color='gray' type='NORMAL_16_130'>
        { number }.
      </Text>
    );
  };

  renderCompanies() {
    const { disabledField } = this.props;
    const { employee: { Companies: employeeCompanies, CompanyId, DepartmentId } } = this.state;

    const employeeCompaniesWithDepartments = getEmployeeCompaniesWithDepartments(employeeCompanies || []);

    const selectCompany = CompanyId;
    const selectDepartment = DepartmentId;
    const currentItem = employeeCompanies?.find(item => item.CompanyId === selectCompany) || null;
    const value = currentItem ? this.prepareCompanyAndDepartmentValue(currentItem, selectDepartment as number) : null;

    if (disabledField) {
      let companyName = null;
      let departmentName = null;

      if (currentItem) {
        companyName = currentItem?.ShortCompanyName || currentItem?.CompanyName;
      }

      if (DepartmentId && currentItem) {
        departmentName = currentItem?.Departments?.find(({ Id }: { Id: number }) => Id === DepartmentId);
      }

      const name = CompanyId && DepartmentId ? `${companyName}(${departmentName?.Name})` : companyName;

      return this.renderCompanyName(name);
    }

    if (employeeCompanies && employeeCompanies.length === 1) {
      return this.renderSingleCompany(employeeCompanies[0], employeeCompaniesWithDepartments, value as string);
    }

    return (
      <div className={ styles.company }>
        <Select
          placeholder={ LABELS.CHOOSE_COMPANY }
          excludeValue
          value={ value as string }
          qaAttrLabel={ QA_ATTRIBUTES.approvalRequest.company.choose }
          items={ employeeCompaniesWithDepartments }
          onChange={ this.handleChangeCompany }
          className={ styles.select }
        />
      </div>
    );
  }

  renderItem = () => {
    const { readOnly, disabledField, approvalStatus, updateLock } = this.props;
    const { employee } = this.state;

    const deleteContent = !updateLock
      && !readOnly
      && !disabledField
      && approvalStatus === ApproveStatus.waitingApprove
      && (
        <IconButton
          onClick={ this.handleRemoveEmployee }
          iconType='closeButton'
          className={ styles.remove }
        />
      );

    const employeeContent = (
      <div className={ styles.employee }>
        <div className={ styles.employee_name }>
          { this.renderNumber() }
          <Text type='SEMIBOLD_16'>
            { this.handleGetValue(employee) }
          </Text>
          { deleteContent }
        </div>
      </div>);

    const companiesContent = this.renderCompanies();
    const projectsContent = this.renderProject();

    const content = companiesContent ? (
      <div className={ styles['two-rows'] }>
        <div className={ styles['space-between'] }>
          { employeeContent }
          { companiesContent }
          { projectsContent }
        </div>
      </div>
    ) : (
      <div className={ styles['two-rows'] }>
        <div className={ styles['space-between'] }>
          { employeeContent }
          { projectsContent }
        </div>
      </div>
    );

    return (
      <div className={ styles['employee-container'] }>
        { content }
      </div>
    );
  };

  renderEnterEmployee = () => {
    const { inputValue } = this.state;

    return (
      <div className={ styles['enter-container'] }>
        { this.renderNumber() }
        <div className={ styles.suggest }>
          <EmployeeSuggest
            onFetch={ this.handleInputChange }
            // @ts-ignore
            onSelect={ this.handleAddEmployee }
            // @ts-ignore
            value={ inputValue }
            autoFocus
            withFetching
            qaAttrInputEmployee={ QA_ATTRIBUTES.approvalRequest.selectEmployee.input }
            qaAttrFirstEl={ QA_ATTRIBUTES.approvalRequest.selectEmployee.firstEl }
            qaAttrSelectEmployeeLoading={ QA_ATTRIBUTES.approvalRequest.selectEmployee.loading }
          />
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { hasAdd, employee } = this.state;

    if (employee) {
      return this.renderItem();
    }

    if (hasAdd) {
      return this.renderEnterEmployee();
    }

    return (
      <div className={ styles['default-container'] }>
        { this.renderNumber() }
        <div className={ styles['add-employee'] }>
          <Button
            onClick={ this.handleSwitchEmpty }
            type='textual'
            className={ styles.button }
            qaAttr={ QA_ATTRIBUTES.approvalRequest.selectEmployee.button }
          >
            <Icon type='addEmployee' className={ styles.icon } />
            { LABELS.CHOOSE_EMPLOYEE }
          </Button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={ styles.wrapper }>
        { this.renderContent() }
      </div>
    );
  }
}

export default SelectEmployee;
