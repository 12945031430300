import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, RadioButton, Text, PageLoader } from 'new-ui';

import { getText } from '../../../i18n';

import Employee from '../../bi/services/employee';

import { FormPassport } from './components/FormPassport';

import { employeeMayHavePassport } from '../../bi/utils/employees';
import { isSmartAgent } from '../../bi/utils/env';

import { PATH } from '../../bi/constants/changeAirTrip';
import { createCheckboxPassport } from '../../bi/utils/changeAirTrip';
import PASSPORTS from '../../bi/constants/passport';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import COUNTRIES from '../../bi/constants/countries';
import ROUTES from '../../bi/constants/routes';

import { AirlineMatch } from '../../bi/types/airline';
import { UserSessionService } from '../../bi/types/userSession';
import { WorkspaceService } from '../../bi/types/workspace';
import { BreadCrumbsService } from '../../bi/types/breadCrumbs';
import {
  OrderService,
  OrderItem,
  ChangeType,
  ListDocument,
} from '../../bi/types/order';
import { ChatService } from '../../bi/types/chat';
import {
  EmployeesDocument,
  EmployeeService,
  EmployeeStoreModule,
} from '../../bi/types/employees';
import { NotificationService } from '../../bi/types/notification';

import styles from './index.module.css';

const LABELS = {
  NEXT: getText('components:changeAirTrip.next'),
  BACK: getText('components:changeAirTrip.back'),
  CHOOSE_DOCUMENT: getText('components:changeAirTrip.changeDocument.chooseDocument'),
  LOADER: getText('components:changeAirTrip.loader'),
};

const {
  changeTrip: {
    air: {
      byPassport: {
        loading: loadingTest,
        checkbox,
        nextButton,
        selectionText,
      },
    },
  },
} = QA_ATTRIBUTES;

interface ChangeAirTripPassportProps {
  employeeService: EmployeeService,
  userSessionService: UserSessionService,
  workspaceService: WorkspaceService,
  breadCrumbsService: BreadCrumbsService,
  orderService: OrderService,
  chatService: ChatService,
  notificationService: NotificationService,
  match: AirlineMatch,
  history: RouteComponentProps['history'],
}

const ChangeAirTripPassport = ({
  employeeService,
  userSessionService,
  workspaceService,
  breadCrumbsService,
  orderService,
  chatService,
  notificationService,
  match: {
    params: {
      tripId,
      ticketId,
      personId,
    },
    path,
  },
  history,
}: ChangeAirTripPassportProps) => {
  const [tripItem, setTripItem] = useState<OrderItem | null>(null);
  const [changeType, setChangeType] = useState<ChangeType | null>(null);
  const [currentDocument, setCurrentDocument] = useState(false);
  const [stateEmploy, setStateEmployee] = useState<EmployeeStoreModule | null>(null);

  const ticketData = tripItem && JSON.parse(tripItem.ActualVersion.JsonData);

  const loadEmployeeServiceInfo = (documents: { [key: string]: string }) => employeeService.loadEmployee(personId, documents);

  const updateStateEmployee = (state: EmployeeStoreModule) => {
    setStateEmployee(state);
  };

  useEffect(() => {
    // @ts-ignore
    const unsubscribeFn = employeeService.subscribe(updateStateEmployee);
    breadCrumbsService.setCurrentPath(path);
    const { details } = orderService.get();
    const { enums: { documents } } = userSessionService.get();
    loadEmployeeServiceInfo(documents);

    const currentTripData = details.OrderItems.find(ticket => ticket.Id === +ticketId);

    if (currentTripData) setTripItem(currentTripData);

    if (currentTripData?.ActualVersion.Employees[0].Code !== COUNTRIES.RU.SHORTNAME) {
      setChangeType({ ...PASSPORTS.DOMESTIC_PASSPORT, INDEX: 1 });
      setCurrentDocument(true);
    }

    // используем стор на другой странице
    employeeService.notValidationPatronymic();

    return () => {
      unsubscribeFn();
      employeeService.resetNotValidationPatronymic();
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const handelBack = () => history.push(ROUTES.CHANGE_AIR_TRIP.SELECT_CHANGE(tripId, ticketId));

  const renderDocumentOptions = () => {
    if (!stateEmploy) return null;

    const { employee: { Documents, Birthday } } = stateEmploy;

    const mayHavePassport = !Birthday || employeeMayHavePassport(Birthday);
    const listDocument: ListDocument[] = createCheckboxPassport(
      !mayHavePassport,
      Documents as EmployeesDocument[],
      tripItem?.ActualVersion?.AllowedDocumentTypes,
    );

    return listDocument.map((item, index) => (
      <RadioButton
        key={ `checkbox_type_documnet_${index}` }
        className={ styles.item }
        value={ item.VALUE }
        alternativeDesign={ isSmartAgent }
        checked={ changeType?.AUXILIARY_TYPE === item.AUXILIARY_TYPE }
        onChange={ () => setChangeType(item) }
        qaAttr={ `${checkbox}-${item.AUXILIARY_TYPE}` }
      >
        {`${item.VALUE} `}
      </RadioButton>
    ));
  };

  const renderChoiceDocument = () => {
    const disabled = !changeType;

    return (
      <div className={ styles.wrapper }>
        <Text qaAttr={ selectionText } type='SEMIBOLD_16' color='blue'>
          {LABELS.CHOOSE_DOCUMENT}
        </Text>
        { renderDocumentOptions() }
        <div className={ styles.action }>
          <Button
            className={ styles.button }
            type='secondary'
            disabled={ disabled }
            onClick={ () => setCurrentDocument(true) }
            qaAttr={ nextButton }
          >
            { LABELS.NEXT }
          </Button>
          <Button
            type='textual'
            onClick={ handelBack }
            qaAttr={ nextButton }
          >
            { LABELS.BACK }
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => (currentDocument ? (
    <FormPassport
      employeeService={ employeeService as unknown as Employee }
      userSessionService={ userSessionService }
      chatService={ chatService }
      notificationService={ notificationService }
      isDemo={ workspaceService.isDemo }
      history={ history }
      tripId={ tripId }
      ticketId={ ticketId }
      tripItem={ tripItem }
      // TODO: не используется в компоненте
      // @ts-ignore
      ticketData={ ticketData }
      selectedDocument={ changeType as ListDocument }
      stateEmploy={ stateEmploy as EmployeeStoreModule }
      setCurrentDocument={ setCurrentDocument }
    />
  ) : renderChoiceDocument());

  if (!stateEmploy || stateEmploy?.loading) {
    return (
      <div data-qa={ loadingTest } className={ styles.loading_wrapper }>
        <PageLoader text={ LABELS.LOADER } />
      </div>
    );
  }

  return renderContent();
};

export { ChangeAirTripPassport };
