import moment, { Moment, MomentInput } from 'moment';

import ACTION from './action';
import Store from '../../store';

import { createDiscountSapsan, mappedOnlyTrainList } from '../../utils/discountSapsan';
import { dateWithoutCurrentYear } from '../../utils/formatDate';
import parseUnix from '../../utils/parseDateTime';

import { SERVICETYPE, SERVICETYPERUMUCH } from '../../constants/serviceType';
import { CHECKOUT } from '../../constants/checkout';

import {
  ICheckoutStore,
  ISortListItem,
  ISourcesItem,
  ITripItemConflicts,
  IValidateratePayload,
  IConflict,
} from './types';

const defaultModule = (): ICheckoutStore => ({
  loading: false,
  accept: false,
  unlock: true,
  sources: [],
  checkoutData: [],
  name: '',
  minDate: moment(),
  maxDate: moment(),
  price: 0,
  serverTime: null,
  id: null,
  discountSapsan: [],
  conflicts: {
    allowed: {
      cartConflicts: [],
      tripConflicts: [],
      duplicateCartTrips: [],
      titleTrip: '',
    },
    disallowed: {
      cartConflicts: [],
      tripConflicts: [],
      duplicateCartTrips: [],
      titleTrip: '',
    },
    duplicateBookings: [],
    taxiConflicts: [],
    insuranceConflicts: [],
    rateConflicts: false,
  },
  userAnalytics: [],
});

const getMinMaxDate = ({ checkin, checkout, minDate, maxDate }: {
  checkin: Moment;
  checkout: Moment;
  minDate: Moment | null;
  maxDate: Moment;
}) => {
  const newMinDate: MomentInput = minDate === null ? checkin : minDate;

  return {
    min: (checkin.isBefore(newMinDate)) ? checkin : newMinDate,
    max: (checkout.isAfter(maxDate)) ? checkout : maxDate,
  };
};

const getDataForCart = (cartItems: ISourcesItem[]) => {
  let minDate: Moment | null = null;
  let maxDate = moment();
  let unlock = true;
  let price = 0;

  cartItems.forEach((item) => {
    const employeesCount = item.EmployeeCartItems.length;
    price += item.Price;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        const data = JSON.parse(item.Data);
        const isCompaniesSelected = item.EmployeeCartItems
          .every(({ CompanyId }) => CompanyId);

        unlock = unlock && employeesCount === data.Metadata.TravellersCount && isCompaniesSelected;

        const firstRoute = data.Routes[0];
        const lastRoute = data.Routes[data.Routes.length - 1];

        const firstSegment = firstRoute.Segments[0];
        const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

        const checkin = parseUnix(firstSegment.DepartureTime_DateTime); // TODO: fucking timzone
        const checkout = parseUnix(lastSegment.ArrivalTime_DateTime); // TODO: fucking timzone

        const { min, max } = getMinMaxDate({
          checkin,
          checkout,
          minDate,
          maxDate,
        });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.HOTEL: {
        const meta = JSON.parse(item.Data);

        unlock = unlock && employeesCount >= 1 && !!item.EmployeeCartItems[0].CompanyId;

        const checkin = parseUnix(meta.checkin);
        const checkout = parseUnix(meta.checkout);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        const meta = JSON.parse(item.Data);

        unlock = unlock && employeesCount >= 1 && !!item.EmployeeCartItems[0].CompanyId;

        const checkin = moment(meta.DateArrival);

        const { min, max } = getMinMaxDate({ checkin, checkout: checkin, minDate, maxDate });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRAIN: {
        const data = JSON.parse(item.Data);
        const isCompaniesSelected = item.EmployeeCartItems.every(({ CompanyId }) => CompanyId);

        unlock = unlock && employeesCount >= 1 && isCompaniesSelected;

        const checkin = moment(data.DateDeparture);
        const checkout = moment(data.DateArrive);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const { Trip: { departure_date: departureDate, backward_date: backwardDate, count } } = JSON.parse(item.Data);

        const isCompaniesSelected = item.EmployeeCartItems.every(({ CompanyId }) => CompanyId);

        unlock = unlock && employeesCount === count && isCompaniesSelected;

        const checkin = moment(departureDate);
        const checkout = moment(backwardDate);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        const data = JSON.parse(item.Data);
        const { StartDate, EndDate } = data;

        const checkin = parseUnix(StartDate);
        const checkout = parseUnix(EndDate);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

        minDate = min;
        maxDate = max;

        break;
      }
    }
  });

  return { minDate, maxDate, unlock, price };
};

const sortConflictItems = (list: ISortListItem[]) => {
  const type = {
    [SERVICETYPE.AIR]: [],
    [SERVICETYPE.TRAIN]: [],
    [SERVICETYPE.HOTEL]: [],
  };

  // @ts-ignore
  list.forEach((item) => type[item.ServiceType || item.serviceType].push(item));
  const { [SERVICETYPE.AIR]: air, [SERVICETYPE.TRAIN]: train, [SERVICETYPE.HOTEL]: hotel } = type;

  return [...air, ...train, ...hotel];
};

const preparedConflictItems = (conflicts: any[], list: ISourcesItem[]): any[] => (
  conflicts.length > 0
    ? list.filter(({ Id }) => conflicts.includes(Id))
    : []
);

const processTripConflicts = (
  TripItems: ITripItemConflicts[],
  targetConflicts: IConflict,
) => {
  const date: { [key: string]: {
    eventDate: string;
    user: string;
  } } = {};
  const type: { [key: string]: string } = {};

  const sortList = TripItems.map(({
    ActualVersion: {
      ServiceType,
      JsonData,
      EventDate,
      TripItemId,
    },
    User: {
      UserName,
      RegistrationName,
      IsPrimaryAccount,
    },
  }) => {
    const user = IsPrimaryAccount || !RegistrationName.length
      ? `${CHECKOUT.DUBLICATE.USER} ${UserName}`
      : RegistrationName;
    date[EventDate] = {
      eventDate: EventDate,
      user,
    };

    const tickets = ServiceType === SERVICETYPE.AIR || ServiceType === SERVICETYPE.TRAIN
      ? SERVICETYPE.AIR
      : ServiceType;
    type[tickets] = tickets;

    return {
      id: TripItemId,
      serviceType: ServiceType,
      jsonData: JsonData,
    };
  });

  Object.assign(targetConflicts, {
    tripConflicts: sortConflictItems(sortList),
  });

  const dates = Object.keys(date);
  const types = Object.keys(type);
  const { eventDate, user } = date[dates[0]];

  const textDate = dates.length > 1
    ? CHECKOUT.DUBLICATE.TRIPS
    : `${dateWithoutCurrentYear(eventDate)} ${user} ${CHECKOUT.DUBLICATE.TRIP}`;

  const textServiceType = types.length > 1
    ? CHECKOUT.DUBLICATE.TICKETSHOTELS
    : SERVICETYPERUMUCH[types[0]];

  Object.assign(targetConflicts, {
    titleTrip: `${textDate} ${textServiceType}`,
  });
};

const reducer = (action: { [key: string]: any, type: string }, state: ICheckoutStore) => {
  let newState: ICheckoutStore = { ...state };

  switch (action.type) {
    case ACTION.CHECKOUTCLEARSTATE: {
      newState = {
        ...defaultModule(),
      };

      break;
    }

    case ACTION.CHECKOUTLOAD: {
      newState.loading = true;
      break;
    }

    case ACTION.UPDATE: {
      newState.sources = action.payload.Items;
      newState.name = action.payload.Name;

      const { minDate, maxDate, unlock, price } = getDataForCart(newState.sources);
      newState.minDate = minDate;
      newState.maxDate = maxDate;
      newState.unlock = unlock;
      newState.price = price;
      newState.id = action.payload.Id;
      newState.serverTime = moment(action.payload.ServerTime);
      newState.userAnalytics = action.payload.UserAnalytics;

      break;
    }

    case ACTION.PREUPDATE: {
      newState.checkoutData = action.payload;
      break;
    }

    case ACTION.CHECKOUTUPDATEACCEPT: {
      newState.accept = action.payload;
      break;
    }

    case ACTION.CHECKOUTUPDATERULES: {
      const currentItem = newState.sources.find(item => item.Id === action.payload.Id);

      if (currentItem) {
        // @ts-ignore
        currentItem.CancellationInfo = action.payload.Rules; // TODO а это работает?????
      }

      break;
    }

    case ACTION.CHECKOUTVALIDATION: {
      if (action.payload === null) {
        newState.loading = false;

        break;
      }

      const {
        AllowedConflicts: {
          CartConflicts: allowedCartConflicts,
          TripConflicts: allowedTripConflicts,
        },
        DisallowedConflicts: {
          CartConflicts: disallowedCartConflicts,
          TripConflicts: disallowedTripConflicts,
        },
        TaxiConflicts,
        InsuranceConflicts,
        AirlineDuplicateBookings,
      } = action.payload;

      newState.conflicts.taxiConflicts = TaxiConflicts;
      newState.conflicts.insuranceConflicts = InsuranceConflicts;

      newState.conflicts.duplicateBookings = AirlineDuplicateBookings;

      // Обработка allowedCartConflicts
      newState.conflicts.allowed.cartConflicts = sortConflictItems(
        preparedConflictItems(
          allowedCartConflicts,
          newState.sources,
        ),
      );

      // Обработка disallowedCartConflicts
      newState.conflicts.disallowed.cartConflicts = sortConflictItems(
        preparedConflictItems(
          disallowedCartConflicts,
          newState.sources,
        ),
      );

      // Обработка allowedTripConflicts
      if (allowedTripConflicts.TripItems.length > 0) {
        processTripConflicts(
          allowedTripConflicts.TripItems,
          newState.conflicts.allowed,
        );
      }

      // Обработка disallowedTripConflicts
      if (disallowedTripConflicts.TripItems.length > 0) {
        processTripConflicts(
          disallowedTripConflicts.TripItems,
          newState.conflicts.disallowed,
        );
      }

      // Обработка allowed.duplicateCartTrips
      newState.conflicts.allowed.duplicateCartTrips = sortConflictItems(
        preparedConflictItems(
          allowedTripConflicts.CartItems,
          newState.sources,
        ),
      );

      // Обработка disallowed.duplicateCartTrips
      newState.conflicts.disallowed.duplicateCartTrips = sortConflictItems(
        preparedConflictItems(
          disallowedTripConflicts.CartItems,
          newState.sources,
        ),
      );

      newState.loading = false;

      break;
    }

    case ACTION.VALIDATERATE: {
      const { Items }: IValidateratePayload = action.payload;

      Items.forEach((item) => {
        const { Data } = item;
        const { Rate } = JSON.parse(Data);

        if (Rate && typeof Rate !== 'string') {
          const { Amenities: { HasCancellation } } = Rate;

          if (!HasCancellation) {
            newState.conflicts.rateConflicts = true;
          }
        }
      });

      break;
    }

    case ACTION.CHECKOUTUPDATENAME: {
      newState.name = action.payload.name;
      break;
    }

    case ACTION.LOADDISCOUNT: {
      newState.discountSapsan = createDiscountSapsan(mappedOnlyTrainList(action.payload));

      break;
    }
  }

  return newState;
};

const createStore = () => new (Store as any)(reducer, defaultModule());
export default createStore;
