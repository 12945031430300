import * as React from 'react';
import { getText } from '../../../../../../../i18n';

import registrationSvg from '../../images/registration.svg';
import confirmationSvg from '../../images/confirmation.svg';
import accumulationSvg from '../../images/accumulation.svg';

import styles from './steps.module.css';

const LABELS = {
  FIRST: getText('settings:bonusProgram.promo.steps.one'),
  SECOND: getText('settings:bonusProgram.promo.steps.two'),
  THIRD: getText('settings:bonusProgram.promo.steps.three'),
  STEP: getText('settings:bonusProgram.promo.steps.step'),
};

const ITEMS: Array<{
  stepNumber: number,
  icon: string,
  title: string,
  description?: string,
}> = [
  {
    stepNumber: 1,
    icon: registrationSvg,
    title: LABELS.FIRST,
  },
  {
    stepNumber: 2,
    icon: confirmationSvg,
    title: LABELS.SECOND,
  },
  {
    stepNumber: 3,
    icon: accumulationSvg,
    title: LABELS.THIRD,
  },
];

const Steps = () => {
  const steps = ITEMS.map(({ stepNumber, icon, title, description }) => (
    <div key={ stepNumber } className={ styles.item }>
      <div className={ styles.header }>
        <img src={ icon } alt={ title } />
        <div className={ styles.step }>{ LABELS.STEP } {stepNumber}</div>
      </div>
      <div className={ styles.name }>{title}</div>
      { description && <div className={ styles.description }>
        { description }
      </div> }
    </div>
  ));

  return (
    <div className={ styles.wrap }>
      { steps }
    </div>
  );
};

export { Steps };
