import React, { FC } from 'react';
import { List, Text, ItemLoader } from 'new-ui';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../bi/apiV2';

import { getText } from '../../../../../i18n';

import Trip from '../../../../components/Trip';

import { TripData } from '../../../../bi/types/trips';

import styles from './index.module.css';

const LABELS = {
  description: getText('smartdesk:recentTrips.description'),
  title: getText('smartdesk:recentTrips.title'),
};

const useTrips = () => useQuery({
  queryKey: ['tripsSmartdesk'],
  queryFn: () => api.smartdesk.getTrips(),
  keepPreviousData: true,
  staleTime: 60000,
});

interface RecentTripsProps {
  onTripClick(Id: number): void;
  showEventSticker: boolean,
  isDispayInsurance: boolean,
  isWhiteLabel: boolean,
  agentMode: boolean,
}

const RecentTrips: FC<RecentTripsProps> = ({
  onTripClick,
  showEventSticker,
  isDispayInsurance,
  isWhiteLabel,
  agentMode,
}) => {
  const { data, isLoading } = useTrips();

  if (!data || isLoading) {
    return (
      <div className={ styles.loading_trips }>
        <ItemLoader />
      </div>
    );
  }

  const renderTrips = () => (
    <List
      renderItem={ (trip: TripData) => (
        <Trip
          key={ trip.Id }
          value={ trip }
          showEventSticker={ showEventSticker }
          isDispayInsurance={ isDispayInsurance }
          isWhiteLabel={ isWhiteLabel }
          agentMode={ agentMode }
        />
      ) }
      items={ data }
      onClickItem={ ({ Id }) => onTripClick(Id) }
    />
  );

  const html = data.length
    ? renderTrips()
    : (
      <Text type='NORMAL_16_140'>
        { LABELS.description }
      </Text>
    );

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24'>
        { LABELS.title }
      </Text>
      <div className={ styles.content }>
        { html }
      </div>
    </div>
  );
};

export default RecentTrips;
