import React from 'react';
import { observer } from 'mobx-react';
import { Text, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { formatDate } from '../../../bi/utils/formatDate';

import { PATTERN } from '../../../bi/constants/dateFormats';
import { APPROVAL_STATUS, APPROVE_STATUS } from '../../../bi/constants/travelApproval';
import { ApprovesType } from '../../../bi/services/travelApproval/consts';

import styles from '../styles/comments.module.css';

const LABELS = {
  WAITING_APPROVE: getText('components:travelApproval.statuses.waitingApprove'),
  APPROVE: getText('components:travelApproval.statuses.approve'),
  NOT_APPROVE: getText('components:travelApproval.statuses.notApprove'),
  COMMENTS: getText('components:travelApproval.comments'),
};

interface ICommentsProps {
  userId: string,
  accountEmail: string
}

const Comments = observer((
  { userId, accountEmail }: ICommentsProps,
) => {
  const {
    travelApprovalStore,
  } = useStores([MOBX_STORES.TRAVEL_APPROVAL_STORE]);

  const {
    travelApproval: { Approves },
  } = travelApprovalStore;

  // @ts-ignore
  const { Date, Email, Name, Comment } = !!Approves.length && Approves[0]?.Messages[0];

  const tooltip = () => (
    <div className={ styles['tooltip-content'] }>
      <Text color='white' type='NORMAL_14_130'>{ Email }</Text>
    </div>
  );

  // @ts-ignore
  const commentSendingForApproval = !!Approves[0]?.Messages.length && !!Comment && (
    <Text type='SEMIBOLD_16'>: { Comment }</Text>
  );

  const approveHtml = (
    { ApproverName, Resolution, Email: ApproveEmail }: ApprovesType,
    i: number,
  ) => {
    const tooltipApprove = () => (
      <div className={ styles['tooltip-content'] }>
        <Text color='white' type='NORMAL_14_130'>{ ApproveEmail }</Text>
      </div>
    );

    const approveColor = (resolution: keyof typeof APPROVAL_STATUS) => (resolution === APPROVAL_STATUS.APPROVE ? 'green' : 'default');
    const checkApproved = (resolution: keyof typeof APPROVAL_STATUS) => ({
      [APPROVE_STATUS.WAITING_APPROVE]: () => LABELS.WAITING_APPROVE,
      [APPROVE_STATUS.ACCEPTED]: () => LABELS.APPROVE,
      [APPROVE_STATUS.DECLINED]: () => LABELS.NOT_APPROVE,
    }[resolution]());

    return (
      <div className={ styles.item } key={ `${ApproverName}_${i}` }>
        <Text>{ `${formatDate(Approves[0].CreatedDate, PATTERN.DATE_WITH_YEAR)} г.` }</Text>
        &nbsp;
        <Tooltip renderContent={ tooltipApprove }>
          <Text
            type='NORMAL_16_130'
            color='default'
            className={ styles.name }
          >
            { ApproverName }:
          </Text>
        </Tooltip>
        &nbsp;
        <Text type='SEMIBOLD_16' color={ approveColor(Resolution as unknown as keyof typeof APPROVAL_STATUS) }>
          { checkApproved(Resolution as unknown as keyof typeof APPROVAL_STATUS) }
        </Text>
      </div>
    );
  };

  const isUser = !!userId && !!userId.length;
  const filteredByUser = (approves: ApprovesType[]) => approves.filter(({ UserId }) => UserId !== userId);
  const filteredByEmail = (approves: ApprovesType[]) => approves.filter(approve => approve.Email !== accountEmail);
  const filteredByUserOrEmail = (approves: ApprovesType[]) => (isUser ? filteredByUser(approves) : filteredByEmail(approves));
  const filteredApproves = !!Approves.length && (isUser || !!accountEmail.length) ?
    filteredByUserOrEmail(Approves) :
    Approves;
  const approveListHtml = !!filteredApproves.length && filteredApproves.map(approveHtml);

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24' className={ styles.header }>{LABELS.COMMENTS}:</Text>
      <div className={ styles['comments-list'] }>
        <div className={ styles.item }>
          <Text>{ formatDate(Date, PATTERN.DATE_WITH_YEAR) } г.</Text>
          &nbsp;
          <Tooltip renderContent={ tooltip }>
            <Text type='NORMAL_16_130' color='default' className={ styles.name }>{ Name }</Text>
          </Tooltip>
          { commentSendingForApproval }
        </div>
        { approveListHtml }
      </div>
    </div>
  );
});

export { Comments };
