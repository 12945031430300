import React, { useEffect, useRef, useState } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { StyledWrapper, Text, SearchMenuItemTypes } from 'new-ui';

import { observer } from 'mobx-react';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import { momentObject } from '../../bi/utils/formatDate';
import { MainAnalytic } from '../../bi/utils/analytics';
import { isSmartAgent } from '../../bi/utils/env';
import getAccountId from '../../bi/utils/getAccountId';

import FORMATS from '../../bi/constants/formats';
import { PAYMENT } from '../../bi/constants/payment';
import ROUTES from '../../bi/constants/routes';
import { DATEFORMATS } from '../../bi/constants/dateFormats';
import { UNIQUE_FORMED_TP } from '../../bi/constants/travelPolicy';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import { DEFAULTMENUTYPE } from '../../bi/constants/app';
import { withoutMentionSmartway } from '../../bi/constants/companiesRules';
import ToDoChangeEvents from './consts';

import Footer from '../../components/Footer';
import { WarningBlock } from '../../components/WarningBlock';
import SmartdeskPanel from '../../components/SmartdeskPanel';
import RecentTrips from './components/RecentTrips';
import { LinkTgAccount } from './components/LinkTgAccount';

import {
  IBalanceDetalis,
  ISmartdeskProps,
  TodosPayload,
  TripIndexProps,
  TripsInCalendarProps,
} from '../../bi/types/smartdesk';
import { ISendProps } from '../../bi/types/notification';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { CustomBanner } from './components/CustomBanner';
import { HeroTitle } from './components/HeroTitle';
import { Panel } from './components/Panel/Panel';

import styles from './index.module.css';

const LABELS = {
  START_SEARCH: getText('smartdesk:startSearch'),
  NOTIFICATIONS: {
    sentOnEmailNotification: (email: string) =>
      getText('smartdesk:sentOnEmailNotification', { email }),
    taskAddSuccess: getText('smartdesk:notifications.taskAddSuccess'),
    taskEditSuccess: getText(
      'smartdesk:notifications.taskEditSuccess',
    ),
    taskRemoveSuccess: getText(
      'smartdesk:notifications.taskRemoveSuccess',
    ),
    allTaskRemoveSuccess: getText('smartdesk:notifications.allTaskRemoveSuccess'),
  },
  CUSTOM_BANNER_BURSERIVCE_START: getText('smartdesk:customBannerBurService.start'),
  CUSTOM_BANNER_BURSERIVCE_LINK: getText('smartdesk:customBannerBurService.link'),
  CUSTOM_BANNER_BURSERIVCE_END: getText('smartdesk:customBannerBurService.end'),
  WARNING_CONTUR: getText('smartdesk:conturWarningSA'),
};

const ANALYTIC_PANEL_SERVICE_TYPE = {
  [DEFAULTMENUTYPE.SEARCHAIRLINE]: ANALYTIC_SERVICE_TYPES.AIR,
  [DEFAULTMENUTYPE.SEARCHTRAIN]: ANALYTIC_SERVICE_TYPES.TRAIN,
  [DEFAULTMENUTYPE.SEARCHHOTEL]: ANALYTIC_SERVICE_TYPES.HOTEL,
  [DEFAULTMENUTYPE.SEARCHTRANSFER]: ANALYTIC_SERVICE_TYPES.TRANSFER,
  [DEFAULTMENUTYPE.SEARCHAEROEXPRESS]: ANALYTIC_SERVICE_TYPES.AERO_EXPRESS,
  [DEFAULTMENUTYPE.SEARCH_TAXI]: ANALYTIC_SERVICE_TYPES.TAXI,
};

interface IList {
  CheckInDate: string,
  Employees: string[],
  Id: number,
  Name: string,
}

const updateListWithNewEmployees = (
  list: IList[],
  index: number,
  newEmployees: string[],
): IList[] => {
  const existingEmployees = new Set(list[index].Employees);

  newEmployees.forEach(e => existingEmployees.add(e));

  const listCopy = [...list];
  listCopy[index].Employees = Array.from(existingEmployees);

  return listCopy;
};

const Smartdesk = observer((props: ISmartdeskProps) => {
  const {
    popupsService,
    travelApprovalService,
    appService,
    history,
    travelPolicyService,
    workspaceService,
    workspaceService: {
      canViewReports,
    },
    uiSettingsProtocol,
    featureFlagsService,
    paymentService,
    toDoService,
    smartdeskService,
    reportService,
    notificationService,
    chatService,
    productAnalyticsProtocol,
    companyId,
  } = props;
  const {
    popupsStore,
    uiSettingsStore,
    uiSettingsStore: {
      settings: { customHeroTitle },
    },
    smartdeskStore: {
      tripsInCalendar,
    },
    cloudPaymentsStore,
  } = useStores([
    MOBX_STORES.CLOUD_PAYMENTS,
    MOBX_STORES.SMARTDESK,
    MOBX_STORES.UI_SETTINGS,
    MOBX_STORES.TRAVEL_APPROVALS,
    MOBX_STORES.POPUPS_STORE,
  ]);
  const searchItems = useRef(uiSettingsProtocol.preparedSearchItems);
  const isExternalLink = useRef(featureFlagsService.getExternalLink());
  const getShowElementForSmartagent = useRef(featureFlagsService.getShowElementForSmartagent());
  const isDispayInsurance = useRef(featureFlagsService.getDisplayInsurance());

  const [calendar, setCalendar] = useState<{
    date: Moment,
    trips: any,
  }>({ date: momentObject(), trips: {} });
  const [todo, setTodo] = useState<{
    Id: number,
    Status: boolean,
    Text: string,
    UserId: string,
  }[]>([]);
  const [invoice, setInvoice] = useState<any | null>(null);
  const [companyFunds, setCompanyFunds] = useState<IBalanceDetalis[]>(appService.get().header.CompanyFunds);
  const [agentMode] = useState<boolean>(appService.get().agentMode);
  const [accountId] = useState<number | null>(getAccountId());

  const unsubscribeCompanyFunds = useRef(() => {});

  const getTodo = () => {
    toDoService.get().then((result: []) => setTodo(result));
  };

  const handleClickToCalendarTrip = () => {
    MainAnalytic.send(
      MainAnalytic.CATEGORY.SMARTDESC, MainAnalytic.ACTIONS.SMARTDESC.GOTOTRIPS,
      { label: MainAnalytic.LABELS.SMARTDESC.CALENDAR },
    );
  };

  const renderEmployees = (item: string[]) => {
    const additionalEmployees = item.length > 1 && (<span> +{ item.length - 1 }</span>);

    return (
      <Text type='NORMAL_14' className={ styles.employees }>
        { item[0] }
        { additionalEmployees }
      </Text>
    );
  };

  const renderTooltip = (item: IList[], trip: IList) => {
    const renderItem = (
      item.map(({ Id, Name, Employees }: IList, ind: number) => (
        <Link
          to={ `${ROUTES.TRIP.MAIN}/${Id}` }
          onClick={ handleClickToCalendarTrip }
          className={ styles.trip }
          key={ `day_${trip.Id}_${ind}` }
        >
          <Text
            type='SEMIBOLD_14'
            className={ styles.name }
          >
            { Name }
          </Text>
          { renderEmployees(Employees) }
        </Link>
      ))
    );

    return (
      <div className={ styles['tooltip-container'] }>
        { renderItem }
      </div>
    );
  };

  const initialCalendar = () => {
    const sortTrips = tripsInCalendar.reduce((r: any, trip: TripsInCalendarProps) => {
      const key = momentObject(trip.CheckInDate).format(DATEFORMATS.DATE);

      if (r[key]) {
        const sameTripIndex = r[key].list.findIndex(
          ({ Id }: TripIndexProps) => Id === trip.Id,
        );

        const newList = (sameTripIndex !== -1 && trip.Employees.length !== 0)
          ? updateListWithNewEmployees(r[key].list, sameTripIndex, trip.Employees)
          : [...r[key].list, trip];

        return {
          ...r,
          [key]: {
            ...r[key],
            list: newList,
          },
        };
      }

      return {
        ...r,
        [key]: {
          list: [trip],
          renderContent: (list: IList[]) => renderTooltip(list, trip),
        },
      };
    }, {});

    setCalendar({
      ...calendar,
      trips: sortTrips,
    });
  };

  const getCalendarTrips = async (month : Moment) => {
    const startDate = month.clone().startOf('month');
    const endDate = month.clone().endOf('month');

    try {
      await smartdeskService.getTripsInCalendar({ startDate, endDate });
    } catch (e) {}
  };

  const getLastInvoice = () => {
    reportService
      .getLastInvoice(companyId)
      .then((value: any) => setInvoice(value));
  };

  useEffect(() => {
    initialCalendar();
  }, [tripsInCalendar]);

  useEffect(() => {
    const {
      popupsState,
      showTravelPopup,
      showSmartagentPopup,
    } = popupsStore;

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.SMARTDESC.MAIN_SCREEN_OPENED);

    unsubscribeCompanyFunds.current = appService.subscribe(
      ({ header: { CompanyFunds } }: any) =>
        setCompanyFunds(CompanyFunds),
    );

    if (!isSmartAgent) {
      getTodo();

      if (canViewReports) {
        getLastInvoice();
      }
    }

    if (!popupsState.travel_policy_ready_made_popup && showTravelPopup && !isSmartAgent) {
      const redirectToUnique = () => {
        travelPolicyService.setFormedTP(UNIQUE_FORMED_TP.OLD);

        MainAnalytic.sendFirebase(
          MainAnalytic.ACTIONS.TRAVEL_POLICY.FORMED_TP_OPENED,
          { formedTP: UNIQUE_FORMED_TP.OLD },
        );
        history.push(ROUTES.TRAVEL_POLICY_UNIQUE);
      };

      popupsService.addTPPopupSecond(redirectToUnique);
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_TP_POPUP);
    }

    if (!popupsState.smartagent_popup && isSmartAgent && showSmartagentPopup) {
      popupsService.addSmartagentPopup();
    }

    getCalendarTrips(calendar.date);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.MAIN_SMARTDESK_OPENED);

    return () => {
      unsubscribeCompanyFunds.current();
      window.onbeforeunload = null;
      travelApprovalService.setResetTravelApprovalsStore();
      popupsService.setShowTravelPopup(false);
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (cloudPaymentsStore.cantLeave) {
        e.preventDefault();

        return '';
      }
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [cloudPaymentsStore.cantLeave]);

  const calcBalance = () => companyFunds.reduce((val: number, current: { Debt: number }) => {
    const debt = current.Debt > 0 ? current.Debt : 0;

    return val + debt;
  }, 0);

  const handleSendToAmplitude = (type: SearchMenuItemTypes) => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_SEARCH_PRESSED(ANALYTIC_PANEL_SERVICE_TYPE[type]),
    );
  };

  const sendNotification = (message: string, type: ISendProps['type'] = 'success') =>
    notificationService.send({ message, type });

  const handleOpenTravelChat = () => {
    chatService.show();
    productAnalyticsProtocol.clickIntercom();
  };

  const handleOnTripClick = (tripId: number) => {
    history.push(`/trip/${tripId}`);
    MainAnalytic.send(
      MainAnalytic.CATEGORY.SMARTDESC,
      MainAnalytic.ACTIONS.SMARTDESC.GOTOTRIPS,
      { label: MainAnalytic.LABELS.SMARTDESC.CURRENTTRIPS },
    );
  };

  const handleChangeCalendarMonth = (value: Moment) => {
    setCalendar({
      date: value,
      trips: {},
    });
    getCalendarTrips(value);
  };

  const handleChangeTodo = ({ type, payload }: TodosPayload) => {
    const {
      taskAddSuccess,
      taskEditSuccess,
      taskRemoveSuccess,
      allTaskRemoveSuccess,
    } = LABELS.NOTIFICATIONS;

    let promise = null;
    switch (type) {
      case ToDoChangeEvents.ADD: {
        const { Text: T, isModal } = payload;
        promise = toDoService
          .saveOrUpdate({
            Id: 0,
            Text: T,
          })
          .then(() => {
            MainAnalytic.send(
              MainAnalytic.CATEGORY.SMARTDESC,
              MainAnalytic.ACTIONS.SMARTDESC.ADDTASK, {
                label: isModal
                  ? MainAnalytic.LABELS.SMARTDESC.ALLTASK
                  : MainAnalytic.LABELS.SMARTDESC.MAINDISPLAY,
              });
            sendNotification(taskAddSuccess);
          });
        break;
      }
      case ToDoChangeEvents.EDIT: {
        promise = toDoService.saveOrUpdate(payload).then(() => {
          MainAnalytic.send(MainAnalytic.CATEGORY.SMARTDESC, payload.Status
            ? MainAnalytic.ACTIONS.SMARTDESC.COMPLETEDTODO
            : MainAnalytic.ACTIONS.SMARTDESC.RETURNTODO);
          sendNotification(taskEditSuccess);
        });
        break;
      }
      case ToDoChangeEvents.DELETE: {
        promise = toDoService.remove(payload as unknown as number).then(() => {
          MainAnalytic.send(
            MainAnalytic.CATEGORY.SMARTDESC,
            MainAnalytic.ACTIONS.SMARTDESC.DELETETODO,
          );
          sendNotification(taskRemoveSuccess);
        });
        break;
      }
      case ToDoChangeEvents.DELETEALL: {
        promise = toDoService.removeAll().then(() => {
          MainAnalytic.send(
            MainAnalytic.CATEGORY.SMARTDESC,
            MainAnalytic.ACTIONS.SMARTDESC.DELETEALLTODO,
          );
          sendNotification(allTaskRemoveSuccess);
        });
        break;
      }
      case ToDoChangeEvents.MODALOPENED: {
        MainAnalytic.send(
          MainAnalytic.CATEGORY.SMARTDESC,
          MainAnalytic.ACTIONS.SMARTDESC.OPENALLTODO,
          { value: todo.length },
        );
        break;
      }
    }

    if (promise) {
      promise.then(getTodo);
    }
  };

  const handleDownloadInvoice = (invoiceNumber: string) => {
    if (
      !!invoice &&
      !!invoice.Package &&
      !!invoice.Package.DocumentId &&
      !!invoice.Package.PackageId
    ) {
      const { PackageId, DocumentId } = invoice.Package;
      reportService.downloadDocument(
        companyId,
        PackageId,
        DocumentId,
        FORMATS.PDF,
      );
    } else {
      reportService.downloadInvoice(companyId, invoiceNumber);
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_DOWNLOAD_INVOICE_PRESSED);
  };

  const handleSendInvoice = (email: string) => {
    const isInvoice = invoice
        && invoice.Package
        && invoice.Package.PackageId
        && invoice.Package.DocumentId;

    reportService
      .sendInvoice({
        companyId,
        invoiceNumber: invoice.Number,
        email,
        format: FORMATS.PDF,
        packageId: isInvoice ? invoice.Package.PackageId : null,
        documentId: isInvoice ? invoice.Package.DocumentId : null,
      })
      .then(() =>
        sendNotification(
          LABELS.NOTIFICATIONS.sentOnEmailNotification(email),
        ),
      );

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMARTDESC.SMARTDESK_SEND_INVOICE_PRESSED);
  };

  const renderCustomBannerBurService = () => {
    if (accountId !== 34358) {
      return null;
    }

    return (
      <StyledWrapper className={ styles.custom_banner }>
        <Text type='NORMAL_16' >
          { LABELS.CUSTOM_BANNER_BURSERIVCE_START }
          <a href={ ROUTES.INTRODUCTORY_BRIEFING_BURSERVICE } target='_blank' rel='noreferrer'>
            { LABELS.CUSTOM_BANNER_BURSERIVCE_LINK }
          </a>
          { LABELS.CUSTOM_BANNER_BURSERIVCE_END }
        </Text>
      </StyledWrapper>
    );
  };

  const renderTrips = () => {
    const {
      accountSettingsService: {
        getNoMice,
      },
      uiSettingsProtocol: {
        isWhiteLabel,
      },
      aggregationId,
    } = props;

    if (isSmartAgent) return null;

    const showEventSticker = !isSmartAgent && !aggregationId && !getNoMice();

    return (
      <div className={ styles.trips }>
        <RecentTrips
          onTripClick={ handleOnTripClick }
          showEventSticker={ showEventSticker }
          isDispayInsurance={ isDispayInsurance.current }
          isWhiteLabel={ isWhiteLabel() }
          agentMode={ agentMode }
        />
      </div>
    );
  };

  const { cantLeave } = cloudPaymentsStore;
  const { settings: {
    showPaymentButton,
    showLastInvoice,
    copyrightName,
    phone,
    email: companyEmail,
    companyName: mainCompanyName,
  } } = uiSettingsStore;
  const balance = calcBalance();

  const {
    Companies: companies,
    CompanyId: accountCompanyId,
    Email: email,
    IsDemo,
    CompanyName: companyName,
    CompanyInn: companyInn,
  } = workspaceService.get();

  const preparedCompanies = companies.filter(({ IsShowReporting }) => IsShowReporting);

  const companiesItems = preparedCompanies
    ? preparedCompanies.map(
      ({
        ShortCompanyName,
        CompanyId,
        CompanyName,
      }) => ({
        label: ShortCompanyName || CompanyName,
        value: CompanyId,
      }),
    )
    : [];

  const banAccountView = companyFunds
    .some((el) => (el.MaxVisibleBookingAmount !== null));

  const showInvoicePay = canViewReports || !banAccountView;

  const isContur = mainCompanyName === withoutMentionSmartway[0];

  const renderHeroTitleSA = isSmartAgent
      && <HeroTitle customHeroTitle={ customHeroTitle } />;
  const renderCustomBannerSA = isSmartAgent
      && <CustomBanner isExternalLink={ isExternalLink.current } />;
  const renderPanelWorkPlace = !isSmartAgent
      && <Panel
        customHeroTitle={ customHeroTitle }
        isExternalLink={ isExternalLink.current }
        searchItems={ searchItems.current }
        handleSendToAmplitude={ handleSendToAmplitude }
      />;
  const renderPanelSmartagent = isSmartAgent && !getShowElementForSmartagent.current
      && <Panel
        customHeroTitle={ customHeroTitle }
        isExternalLink={ isExternalLink.current }
        searchItems={ searchItems.current }
        handleSendToAmplitude={ handleSendToAmplitude }
      />;
  const renderLinkTlg = !isSmartAgent ? <LinkTgAccount /> : null;

  const text = <>
    { LABELS.WARNING_CONTUR }
    <Text type='bold_16'><a href='https://kontur.ru/lp/travel' target='_blank' rel='noreferrer'>kontur.ru</a></Text>
  </>;

  const renderConturBlockSA = isContur && <WarningBlock text={ text } type='SEMIBOLD_16' color='default' />;

  return (
    <div className={ styles.wrap }>
      <Prompt when={ cantLeave } message={ PAYMENT.EXITMESSAGE } />
      { renderConturBlockSA }
      { renderHeroTitleSA }
      { renderCustomBannerBurService() }
      { renderCustomBannerSA }
      { renderPanelWorkPlace }
      <div className={ styles.panel }>
        <SmartdeskPanel
          showInvoicePay={ showInvoicePay }
          calendar={ calendar }
          todo={ todo }
          invoice={ invoice }
          showPaymentButton={ showPaymentButton }
          showLastInvoice={ showLastInvoice }
          showInvoice={ canViewReports }
          balance={ balance }
          onChangeMonth={ handleChangeCalendarMonth }
          onChangeTodo={ handleChangeTodo }
          onDownloadInvoice={ handleDownloadInvoice }
          onSendInvoice={ handleSendInvoice }
          company={ {
            companies: companiesItems,
            companyId: accountCompanyId,
            email,
            companyName,
            companyInn,
            allCompanies: companies,
          } }
          isDemo={ IsDemo }
          paymentService={ paymentService }
          featureFlagsService={ featureFlagsService }
        >
          { renderPanelSmartagent }
        </SmartdeskPanel>
      </div>
      { renderTrips() }
      { renderLinkTlg }
      <Footer
        copyrightName={ copyrightName }
        email={ companyEmail }
        phone={ phone }
        wiki={ ROUTES.SMARTWAY_WIKI }
        companyName={ mainCompanyName }
        handleOpenTravelChat={ handleOpenTravelChat }
      />
    </div>
  );
});

export default Smartdesk;
