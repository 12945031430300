import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Datepicker, Dialog, Select, Tooltip, Text, DotLoading, MultiSelect } from 'new-ui';
import { getText } from '../../../../i18n';

import { expenseReportsStore } from '../../../bi/services/expenseReports/stores/expenseReports';

import type { ICreateDialogProps } from '../../../bi/services/expenseReports/types';

import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';

import { FIELDS_DATE } from '../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import { EmployeeSuggest } from '../../../components/EmployeeSuggest';
import HelpIcon from '../../../components/HelpIcon';

import styles from '../styles/createDialog.module.css';

const LABELS = {
  HEADER: getText('expenseReports:createDialog.title'),
  EMPLOYEE: getText('expenseReports:createDialog.employee'),
  CREATE: getText('common:create'),
  CANCEL: getText('common:cancel'),
  EMPTY_TRIPS: getText('expenseReports:createDialog.emptyTrips'),
  DATES: getText('expenseReports:createDialog.dates'),
  TOOLTIP: getText('expenseReports:createDialog.tooltip'),
  FROM: getText('expenseReports:createDialog.from'),
  TO: getText('expenseReports:createDialog.to'),
  CHOOSE: getText('expenseReports:createDialog.choose'),
  MORE: getText('expenseReports:createDialog.more'),
  COMPANIES: getText('expenseReports:createDialog.companies'),
  DEMO_CREATE_TOOLTIP: getText('expenseReports:createDialog.demoCreateTooltip'),
  ORGANIZATION: getText('settings:employees.employee.steps.general.organization'),
  DEPARTMENT: getText('settings:employees.employee.steps.general.department'),
  SELECT_DEPARTMENT: getText('components:employeeList.selectDepartment'),
};

const { START_DATE, END_DATE } = FIELDS_DATE;

@observer
class CreateExpenseReportDialog extends Component<ICreateDialogProps> {
  storeReports = expenseReportsStore;
  componentDidMount() {
    const { dataUserEmployee: { onlyOwnTrips, employeeId }, getUserEmployee } = this.props;
    this.props.updateDepbox([]);
    this.props.setDataLoaded(false);

    if (onlyOwnTrips) {
      getUserEmployee(employeeId);
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  renderEmployees = () => {
    const { employee } = this.props.dialog;

    return (
      <>
        <Text className={ styles.employee_text }>{ LABELS.EMPLOYEE }</Text>
        <EmployeeSuggest
          value={ employee || {} }
          canAddEmployee={ false }
          onFetch={ this.props.getEmployees }
          onSelect={ this.props.onSelectEmployee }
          qaAttrInputEmployee={ QA_ATTRIBUTES.expenseReport.inputEmployee }
          qaAttrFirstEl={ QA_ATTRIBUTES.expenseReport.selectEmployee }
        />
      </>
    );
  };

  renderCompanies = () => {
    const { dataUserEmployee: { onlyOwnTrips }, dialog: { companies, currentCompany }, onSelectCompany } = this.props;

    const isStyles = !onlyOwnTrips ? styles.companies : '';

    return (
      <div className={ isStyles }>
        <Select
          excludeValue
          theme='default-border'
          placeholder={ LABELS.COMPANIES }
          value={ currentCompany }
          items={ companies }
          onChange={ onSelectCompany }
        />
      </div>
    );
  };

  renderDate = (field: 'startDate' | 'endDate' | string, label: string) => {
    const { dialog, onChangeDate } = this.props;

    return (
      <div className={ styles.wrapper_date }>
        <Text
          type='NORMAL_14'
          color='gray'
          className={ styles.label }
        >
          { label }
        </Text>
        <div className={ styles.date }>
          <Datepicker
            disabled={ !dialog.checkbox }
            min={ field === END_DATE ? dialog[START_DATE] : null }
            withLabel={ false }
            value={ dialog[field] }
            inputClassName={ styles.input }
            onChange={ value => onChangeDate(field, value) }
            isDuration
            durationDates={ [dialog.startDate, dialog.endDate] }
            modalClassName={ styles.wrapper }
          />
        </div>
      </div>
    );
  };

  renderTrips = () => {
    const { dialog: { trips, showButtonMore }, onChangeCheckboxTrip, onOpenAllTrips } = this.props;

    const tripsHtml = trips.map(({ TripId, CheckinDate, CheckoutDate, Name, selected }, index) => (
      <Checkbox
        key={ `trip_${index}_${TripId}` }
        value={ selected }
        className={ styles.trip }
        onChange={ value => onChangeCheckboxTrip(value, TripId) }
      >
        <div className={ styles.content }>
          <Text type='bold_16' className={ styles.text }>
            { Name },&nbsp;
          </Text>
          <Text className={ styles.text }>{formatRangeDateWithSimplicity(CheckinDate, CheckoutDate)}</Text>
        </div>
      </Checkbox>
    ));

    const buttonHtml = showButtonMore && (
      <Button
        type='textual'
        className={ styles.button }
        onClick={ onOpenAllTrips }
      >
        { LABELS.MORE }
      </Button>
    );

    return (
      <>
        <Text>
          { LABELS.CHOOSE }
        </Text>
        <div className={ styles.trips }>
          { tripsHtml }
        </div>
        { buttonHtml }
      </>
    );
  };

  renderDepartments = () => {
    const { updateDepbox, getupdateDepbox, isDataLoaded } = this.props;
    const departments = this.storeReports.dialog.departments || [];
    const getupdateDepboxX = getupdateDepbox() ? getupdateDepbox() : [];

    if (!departments.length) return null;

    const tripDepartments = departments.map((department: any) => ({
      value: department.Id,
      label: department.Name,
      headId: department.HeadId,
    }));

    return (
      <div className={ styles.row }>
        <div className={ styles.item }>
          { isDataLoaded ? (
            <MultiSelect
              theme='border'
              className={ styles.select }
              placeholder={ LABELS.DEPARTMENT }
              list={ tripDepartments }
              values={ getupdateDepboxX }
              onChange={ v => updateDepbox(v) }
              disabled
            />
          ) : (
            <Select
              theme='border'
              className={ styles.select }
              placeholder={ LABELS.SELECT_DEPARTMENT }
              items={ tripDepartments }
              value={ getupdateDepboxX[0] }
              onChange={ v => updateDepbox({ nested: [v] }) }
            />
          ) }
        </div>
      </div>
    );
  };

  renderLoading = () => (
    <div className={ styles.loader }>
      <DotLoading />
    </div>
  );

  renderDetails = () => {
    const { dialog: { trips, checkbox, loading }, onChangeCheckbox } = this.props;

    if (loading) {
      return this.renderLoading();
    }

    const tripsHtml = trips.length ? this.renderTrips() : (
      <Text type='NORMAL_16_130' qaAttr={ QA_ATTRIBUTES.expenseReport.emptyTrips }>
        { LABELS.EMPTY_TRIPS }
      </Text>
    );

    return (
      <div className={ styles.details }>
        { tripsHtml }
        <div className={ styles.trips } onClick={ e => e.stopPropagation() }>
          { this.renderDepartments() }
        </div>
        <Checkbox
          value={ checkbox }
          className={ styles.wrapper_checkbox }
          onChange={ onChangeCheckbox }
        >
          <div className={ styles.checkbox }>
            <Text className={ styles.checkbox_text }>{ LABELS.DATES }</Text>
            <HelpIcon text={ LABELS.TOOLTIP } />
          </div>
        </Checkbox>
        <div className={ styles.dates }>
          { this.renderDate(START_DATE, LABELS.FROM) }
          { this.renderDate(END_DATE, LABELS.TO) }
        </div>
      </div>
    );
  };

  render() {
    const {
      show,
      dataUserEmployee: { onlyOwnTrips, employeeId },
      dialog: { showTrips, isValid, showCompanies, employee, loading },
      onClose,
      onCreate,
      isDemo,
    } = this.props;

    const employeesHtml = !onlyOwnTrips && this.renderEmployees();
    const detailsHtml = showTrips && this.renderDetails();
    const companiesHtml = showCompanies && this.renderCompanies();

    const content = loading ? (
      <div className={ styles.loading }>
        <DotLoading />
      </div>
    ) : (
      <>
        { employeesHtml }
        { companiesHtml }
        { detailsHtml }
        <div className={ styles.actions }>
          <div className={ styles.submit }>
            <Tooltip
              show={ isDemo }
              renderContent={ () => (
                <Text
                  className={ styles.tooltip }
                  color='white'
                  type='NORMAL_14_130'
                >
                  { LABELS.DEMO_CREATE_TOOLTIP }
                </Text>
              ) }
            >
              <Button
                disabled={ !isValid || isDemo }
                onClick={ () => onCreate(null, null, employee, employeeId) }
                qaAttr={ QA_ATTRIBUTES.expenseReport.buttonDialogCreate }
              >
                { LABELS.CREATE }
              </Button>
            </Tooltip>
          </div>
          <Button
            type='textual-medium'
            onClick={ onClose }
            className={ styles.cancel }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </>
    );

    return (
      <Dialog
        showClosing
        className={ styles.wrapper }
        show={ show }
        onChange={ onClose }
      >
        <Text
          type='bold_20'
          className={ styles.header }
          qaAttr={ QA_ATTRIBUTES.expenseReport.dialogTitle }
        >
          { LABELS.HEADER }
        </Text>
        { content }
      </Dialog>
    );
  }
}

export { CreateExpenseReportDialog };
