import moment, { Moment } from 'moment';
import debounce from '../../api/debounce';

import CARTACTION from './action';
import CartStore, { ICartState } from './cartStore';
import Api from '../../api';

import { PaymentMethodsStore } from '../cloudPayment/store';

import { MainAnalytic } from '../../utils/analytics';
import { prepareAddEmployeeCompanyData } from '../../utils/companies';
import { alertDev } from '../../utils/alert';
import { withIM, withInternalMethod } from '../utils/dectrators';
import NetworkStatesStore from '../utils/network/networkStatesStore';

import { SERVICETYPE } from '../../constants/serviceType';
import { CART_STATUS } from '../../constants/cart';

import { PaymentMethodType } from '../../types/paymentMethod';
import {
  CartsType,
  IApprovedData,
  ICartWpResult,
  IItem,
  IUpdatedTransferItem,
  PreselectedRequest1c,
  ReservationFailedItems,
} from '../../types/cart';
import { IInfoByTripIds } from '../notepad/types';
import { ItemsType } from '../expenseReports/types';
import { StepsSettings } from '../../../page/ApprovalRequest/types';

const getMinMaxDate = ({ checkin, checkout, minDate, maxDate }:{
  checkin: Moment;
  checkout: Moment;
  minDate: Moment | null;
  maxDate: Moment;
}) => {
  let minDateValue = minDate === null ? checkin.clone() : minDate.clone();

  minDateValue = (checkin.isBefore(minDateValue)) ? checkin : minDateValue;
  const maxDateValue = (checkout.isAfter(maxDate)) ? checkout : maxDate;

  return { min: minDateValue, max: maxDateValue };
};

const DEBOUNCE_UPDATE_TIME = 500;

type ApiType = Api['cart'];

interface ICartStore extends ICartState {
  getState: () => {
    carts: CartsType[];
    preselectedRequest1c: PreselectedRequest1c | null;
    // Почему request1cList объект - непонятно
    request1cList: Record<number, any[]>;
    tripsByTripIds: {
      TripId: number,
      Name: string,
      CheckInDate: string,
      CheckOutDate: string,
    }[];
    alreadySentIds: number[],
  };
  dispatch: (action: { type: string, payload?: any }) => void;
  subscribe: (callback: () => void) => void;
}

class Cart {
  api: ApiType;
  apiFeatureFlags: Api['featureFlags'];
  apiAccountSettings: Api['accountSettings'];
  cartStore: ICartStore;
  mandatoryVerificationCode: boolean;
  networkStore = new NetworkStatesStore<'status1C' | 'removeOnlyCartItem'>();
  getDebounceUpdateCartItems: ApiType['getUpdateCartItems'];

  constructor(api: Api) {
    this.api = api.cart;
    this.apiFeatureFlags = api.featureFlags;
    this.apiAccountSettings = api.accountSettings;
    this.cartStore = CartStore();
    this.mandatoryVerificationCode = false;
    this.getDebounceUpdateCartItems = debounce(this.api.getUpdateCartItems, DEBOUNCE_UPDATE_TIME);
  }

  get = () => this.cartStore.getState();

  start = () => this.cartStore.dispatch({
    type: CARTACTION.CARTLOAD,
  });

  updateCartSuccess = (res: ICartWpResult[]) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATE,
      payload: res,
    });

    return res;
  };

  updateTripIds = (res: IInfoByTripIds) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATE_TRIP_BY_IDS,
      payload: res,
    });
  };

  updateCartError = (status: number) => {
    if (status === 404) {
      this.cartStore.dispatch({
        type: CARTACTION.UPDATEERROR,
        payload: [],
      });
    }
  };

  loadTripsByTripId = async (cart: ICartWpResult[]) => {
    const tripsWithTripIds = cart
      .flatMap((item) => item.Items.filter(trip => trip.TripId));

    if (tripsWithTripIds.length) {
      const tripIds = await this.api.getInfoByTripIds(tripsWithTripIds.map((item) => item.TripId) as number[]);
      this.updateTripIds(tripIds);
    }

    return Promise.resolve();
  };

  load = async () => {
    try {
      const res = await this.api.get();

      await this.loadTripsByTripId(res);
      await this.updateCartSuccess(res);

      const paymentSource = res[0]?.PaymentSource as PaymentMethodType | undefined;

      if (paymentSource) await PaymentMethodsStore.setPaymentMethod(paymentSource);
    } catch (err: any) {
      this.updateCartError(err.status);
    }
  };

  getNormalCarts = () => {
    const { carts } = this.cartStore.getState();

    return carts.filter((cart: { status: string }) => cart.status === CART_STATUS.NORMAL);
  };

  getUniqueEmployeesFromCart = () => {
    const normalCarts = this.getNormalCarts();

    const employeesIds: string[] = normalCarts
      .flatMap(({ sources }: { sources: IItem[] }) => sources
        .flatMap(({ Employees }) => Employees
          .flatMap(({ Employee: { ExternalId } }) => ExternalId)));

    return Array.from(new Set(employeesIds));
  };

  getItemsIdsToUpdate = (cart: CartsType[]): number[] =>
    cart.reduce<number[]>((pre, item) => {
      const idItemToChange = item.sources.reduce<number[]>((preSource, { ServiceType, HasChanges, Id }) => {
        if (HasChanges && ServiceType === SERVICETYPE.HOTEL) return [...preSource, Id];

        return [...preSource];
      }, []);

      return [...pre, ...idItemToChange];
    }, []);

  getUpdateCartItems = async () => {
    const normalCarts = this.getNormalCarts();

    if (!normalCarts) return;

    const itemsIds = this.getItemsIdsToUpdate(normalCarts);

    if (!itemsIds.length) return;

    const itemIdsToUpdate = {
      ids: itemsIds,
    };

    try {
      const res = await this.getDebounceUpdateCartItems(itemIdsToUpdate);

      this.cartStore.dispatch({
        type: CARTACTION.ITEMS_TO_UPDATE,
        payload: res,
      });
    } catch (err) {}
  };

  checkUnlockCart = () => {
    const normalCarts = this.getNormalCarts();

    return normalCarts.every(({ unlock }: { unlock: boolean }) => unlock);
  };

  checkVerificationCodeForEmployees = () => {
    const uniqueEmployeesIds = this.getUniqueEmployeesFromCart();
    const isCartUnlock = this.checkUnlockCart();

    if (uniqueEmployeesIds.length && isCartUnlock) {
      const data = {
        Employees: uniqueEmployeesIds,
      };

      this.checkVerificationCode(data)
        .then((res: { Available: boolean, AutoVerification: boolean }) => {
          this.cartStore.dispatch({
            type: CARTACTION.GETVERIFICATIONCODE,
            payload: res,
          });
        });
    }
  };

  setMandatoryVerificationCode = (value: boolean) => {
    this.mandatoryVerificationCode = value;

    if (value) {
      this.checkVerificationCodeForEmployees();
    }
  };

  cancellationInfo = async (itemId: number, cartId: number) => {
    try {
      const response = await this.api.cancellationInfo(itemId);

      this.cartStore.dispatch({
        type: CARTACTION.UPDATE_RULES,
        payload: {
          itemId,
          cartId,
          rules: response,
        },
      });
    } catch (error) {
      this.cartStore.dispatch({
        type: CARTACTION.UPDATE_RULES,
        payload: {
          itemId,
          cartId,
          rules: '',
        },
      });
    }
  };

  setAdditionalFeeSmartagentCart = (data: {
    AgentFee: number,
    TripItemId?: number,
    CartItemId?: number,
  }) => this.api.setAdditionalFeeSmartagentCart(data);

  removeAdditionalFeeSmartagentCart = (cartItemId: number | string) => this.api.deleteAdditionalFeeSmartagentCart(cartItemId);

  checkVerificationCode = (data: { Employees: string[], Code?: string }) => this.api.checkVerificationCode(data);

  // судя по поиску не используется;
  // @ts-ignore
  getBookingStatus = (id: number | string) => this.api.getBookingStatus(id);

  // судя по поиску не используется;
  // @ts-ignore
  getBookingError = (id: number | string) => this.api.getBookingError(id);

  // судя по поиску не используется;
  // @ts-ignore
  getBookingSuccess = (id: number | string) => this.api.getBookingSuccess(id);

  // судя по поиску не используется;
  checkout = (data: string) => this.api.checkout(data);

  saveVerificationCode = (id: number, code: string) => this.api.saveVerificationCode(id, code);

  sendToApprove = (
    data: {
      CartId: number,
      StepsSettings?: StepsSettings[],
      Users: string[],
      Message: { Comment: string },
      Type: string,
    },
    isPreSteps?: boolean,
    requestId?: string | string[] | null,
  ) => (isPreSteps ? this.api.sendToApproveRequest(data, requestId) : this.api.sendToApprove(data));

  sendToOfflineChat = (cartId: number) => this.api.sendToOfflineChat(cartId);

  defineSentFlag = (value: number) => {
    this.cartStore.dispatch({
      type: CARTACTION.SET_IS_ALREADY_SENT,
      payload: value,
    });
  };

  decideCartApprove = (data: IApprovedData, approveId: string | null) => this.api.decideApprove(data, approveId);

  getItem = (id: number) => this.api.getItem(id).then((res: IItem) => this.updateCartItem(res));

  updateCartItem = (cartItem: IItem) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATEITEM,
      payload: cartItem,
    });
  };

  updateCartItems = (cartItems: IItem[]) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATE_ITEMS,
      payload: cartItems,
    });
  };

  getCartById = async (id: string): Promise<ICartWpResult> => {
    this.start();

    const res: ICartWpResult = await this.api.getCartById(id);

    await this.loadTripsByTripId([res]);

    return res;
  };

  loadWaitingApproveList = () => this.api.waitingApproveList();

  getWaitingApproveList = () => this.loadWaitingApproveList()
    .then(
      (list: ICartWpResult[]) => this.cartStore.dispatch({
        type: CARTACTION.UPDATE,
        payload: list,
      }),
    );

  // судя по поиску не используется;
  getDataForCheckout = (cartItems: ItemsType[]) => {
    let minDate: null | Moment = null;
    let maxDate = moment();

    cartItems.forEach((item) => {
      switch (item.ServiceType) {
        case SERVICETYPE.AIR: {
          const data = JSON.parse(item.Data);

          const firstRoute = data.Routes[0];
          const lastRoute = data.Routes[data.Routes.length - 1];

          const firstSegment = firstRoute.Segments[0];
          const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

          const checkin = moment(firstSegment.DepartureTime_DateTime).utcOffset('+3:00'); // TODO: fucking timzone
          const checkout = moment(lastSegment.ArrivalTime_DateTime).utcOffset('+3:00'); // TODO: fucking timzone

          const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
        case SERVICETYPE.HOTEL: {
          const meta = JSON.parse(item.Data);

          const checkin = moment(meta.checkin);
          const checkout = moment(meta.checkout);

          const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
        case SERVICETYPE.TRANSFER: {
          const meta = JSON.parse(item.Data);

          const checkin = moment(meta.DateArrival);

          const { min, max } = getMinMaxDate({ checkin, checkout: checkin, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
        case SERVICETYPE.TRAIN: {
          const data = JSON.parse(item.Data);

          const checkin = moment(data.DateDeparture);
          const checkout = moment(data.DateArrive);

          const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
      }
    });

    return { minDate, maxDate };
  };

  moveToNotepad = (item: ItemsType) => {
    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.ADDTONOTE, {
      label: MainAnalytic.LABELS.CART[item.ServiceType],
    });

    return this.api.moveToNotepad(item);
  };

  moveToNotepadMulti = (items: ItemsType[]) => {
    const searchPromises = items.map(item => this.moveToNotepad(item));

    return Promise.all(searchPromises);
  };

  employeeToCart = (id: number, employeeId: number, action: string) => {
    if (action === 'delete') {
      return this.api.removeEmployeeToCart(id, employeeId);
    }

    return this.api.employeeToCart(id, employeeId);
  };

  changeEmployeePassport = (cartId: number, employeeId: number, passportId: number) =>
    this.api.changePassport(cartId, employeeId, passportId);

  clearCart = (id: number) => this.api.clear(id);

  removeCart = (id: number, airToNote: boolean) => this.api.remove(id, airToNote).then(() => this.cartStore.dispatch({
    type: CARTACTION.REMOVECART,
    payload: id,
  }));

  removeCartItem = (id: number, cartId: number) => this.api.removeCartItem(id).then(() => {
    this.cartStore.dispatch({
      type: CARTACTION.REMOVECARTITEM,
      payload: {
        id,
        cartId,
      },
    });

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }
  });

  @withIM((o) => o.networkStore.withErrorBoundary('removeOnlyCartItem'))
  removeOnlyCartItem(id: number) {
    return this.api.removeCartItem(id);
  }

  updateCartItemsAfterRemove = (id: number, cartId: number) => {
    this.cartStore.dispatch({
      type: CARTACTION.REMOVECARTITEM,
      payload: {
        id,
        cartId,
      },
    });

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }
  };

  removeCartMultiItem = (ids: number[], cartId: number) => ids.forEach(id => this.removeCartItem(id, cartId));

  rename = ({ name, id }: { name: string, id: number }) => this.api.rename({ name, id })
    .then(() => {
      this.cartStore.dispatch({
        type: CARTACTION.UPDATENAME,
        payload: name,
      });
    });

  addEmployee = (id: number, employeeId: number) =>
    this.api.employeeToCart(id, employeeId).then(async (res: Promise<void>) => {
      await this.getItem(id);

      if (this.mandatoryVerificationCode) {
        this.checkVerificationCodeForEmployees();
      }

      return res;
    });

  setEmployeeInCartItem = async (
    data: {
      EmployeeId: number,
      CartItemIds: string | number[],
      TravelApprovalRequestId?: string | number | null
    }) => {
    const res = await this.api.setEmployeeInCartItem(data);
    this.updateCartItems(res);

    return res;
  };

  addMultiEmployee = (ids: number[], employeeId: number) => {
    const searchPromises = ids.map(id => this.addEmployee(id, employeeId));

    return Promise.all(searchPromises);
  };

  removeEmployee = async (cartItemIds: number[], employeeId: number) => {
    const res = await this.api.removeEmployeeToCart(cartItemIds, employeeId);
    this.updateCartItems(res);

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }

    return res;
  };

  setDepartmentOnlyCompany = (Id: number, employeeId: number, companyId: string) => this.api.getItem(Id)
    .then((res: IItem) => {
      const departmentArray = res.Employees[0].Employee.Departments;
      const departmentId = departmentArray.length > 0 ? departmentArray[0].DepartmentId : null;
      const isAmountDepartments = departmentArray.length === 1 ? companyId + (departmentId as number) : companyId;

      return this.addEmployeeCompany(Id, employeeId, isAmountDepartments);
    });

  resetVerificationCodeError = () => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATEVERIFICATIONCODERROR,
      payload: false,
    });
  };

  checkCode = (code: string) => {
    const uniqueEmployeesIds = this.getUniqueEmployeesFromCart();

    if (uniqueEmployeesIds.length) {
      const data = {
        Code: code,
        Employees: uniqueEmployeesIds,
      };

      this.checkVerificationCode(data)
        .then((res: { Success: boolean, AutoVerification: boolean }) => {
          this.cartStore.dispatch({
            type: CARTACTION.CHECKVERIFICATIONCODE,
            payload: res,
          });
        });
    }
  };

  addEmployeeDocument = (id: number, employeeId: number, documentId: number) =>
    this.api.changePassport(id, employeeId, documentId).then(() => this.getItem(id));

  addEmployeeCompany = async (id: number, employeeId: number, companyId: string) => {
    const res = await this.api.employeeCompanyToCart(id, employeeId, prepareAddEmployeeCompanyData(companyId));
    this.updateCartItem(res);

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }

    return res;
  };

  addEmployeeProject = async (id: number, employeeId: number, projectId: number | null) => {
    const res = await this.api.employeeProjectToCart(id, employeeId, projectId);
    this.updateCartItem(res);

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }
  };

  delEmployeeProject = async (id: number, employeeId: number) => {
    const res = await this.api.employeeProjectToCart(id, employeeId, null);

    this.updateCartItem(res);
  };

  updateTransfer = (id: number, data: IUpdatedTransferItem) => this.api.updateTransfer(id, data);

  @withInternalMethod((o) => o.networkStore.withErrorBoundary('status1C'))
  async setRequestForItem(data: { cartItemId: number, requestId: string }) {
    const {
      requestId,
      cartItemId,
    } = data;

    if (requestId === 'remove') {
      await this.api.unsetRequestForItem(cartItemId);
    } else {
      await this.api.setRequestForItem(data);
    }

    this.cartStore.dispatch({
      type: CARTACTION.SET_AUTOCOMPLETED_ITEMS,
      payload: { [cartItemId]: requestId },
    });
    this.cartStore.dispatch({
      type: CARTACTION.SET_PRESELECT_REQUEST_1C,
      payload: null,
    });
  }

  @withInternalMethod((o) => o.networkStore.withErrorBoundary('status1C'))
  async loadSuggestedRequests(id: number, serviceType: string) {
    const preselect = this.get().preselectedRequest1c;
    const list = await this.api.loadSuggestedRequests(id);

    list.forEach((suggest: { RequestId: string, RequestItemIds: string[] }) => {
      const { RequestId, RequestItemIds } = suggest;

      if (serviceType === preselect?.type
        && preselect?.id === RequestId) {
        this.setRequestForItem({
          cartItemId: id,
          requestId: RequestItemIds[0],
        });
      }
    });

    this.cartStore.dispatch({
      type: CARTACTION.SET_REQUESTS_1C_LIST,
      payload: { id, list },
    });
  }

  suggestedRequests = (itemId: number, withPrefix: string) => {
    const list = this.get().request1cList[itemId];

    if (list?.length > 0) {
      return list.map(({ RequestId, RequestItemIds }: { RequestId: string, RequestItemIds: string[] }) => ({
        value: RequestItemIds[0],
        label: `${withPrefix} ${RequestId}`,
      }));
    }

    return [];
  };

  updateTransferAndTp = async (id: number, data: IUpdatedTransferItem, isNeedUpdateTp: boolean = false) => {
    try {
      const res = await this.updateTransfer(id, data);

      if (isNeedUpdateTp) {
        this.cartStore.dispatch({
          type: CARTACTION.UPDATE_ITEM_TP,
          payload: res,
        });
      }
    } catch (e: any) {
      alertDev(e);
    }
  };

  getRecommendedTime = (params: { DepartureTime: string, BookId: string }) => this.api.getRecommendedTime(params);

  getCheckTime = (params: {
    DepartureTime: string,
    PickUpTime: string,
    BookId: string,
  }) => this.api.getCheckTime(params);

  updateTimeIsUp = (item: { Id: number }) => this.cartStore.dispatch({
    type: CARTACTION.UPDATEFOUL,
    payload: item,
  });

  updateItemReason = (itemId: number, reason: string) =>
    this.api.updateReason(itemId, reason).then(() => this.getItem(itemId));

  // судя по поиску не используется;
  reserveItem = (itemId: number) => this.api.reserveItem(itemId);

  updateReservationFailedItems = (items: ReservationFailedItems[] = []) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATERESERVATIONFAILEDITEMS,
      payload: items,
    });
  };

  justSendUnderageCart = (cartId: number) => this.api.sendUnderageCart(cartId);

  sendUnderageCart = (cartId: number) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATEUNDERAGE,
      payload: {
        loading: true,
      },
    });

    return this.api.sendUnderageCart(cartId)
      .then(() => {
        this.load();
        this.cartStore.dispatch({
          type: CARTACTION.UPDATEUNDERAGE,
          payload: {
            loading: false,
          },
        });

        return true;
      })
      .catch(() => {
        this.cartStore.dispatch({
          type: CARTACTION.UPDATEUNDERAGE,
          payload: {
            loading: false,
          },
        });

        return false;
      });
  };

  bookFlightCertificateFromCart =
  debounce((tripItemId: number, isBook: boolean) => this.api.bookFlightCertificateFromCart(tripItemId, isBook), 300);

  setFirstNonTotallyFilledItem = (id: number | null) => {
    this.cartStore.dispatch({
      type: CARTACTION.SET_FIRST_NON_TOTALLY_FILLED_ITEM,
      payload: id,
    });
  };

  resetFirstNonTotallyFilledItem = () => {
    this.cartStore.dispatch({
      type: CARTACTION.RESET_FIRST_NON_TOTALLY_FILLED_ITEM,
    });
  };

  getTripsToAddOrder = async (Statuses: number) => {
    try {
      const res = await this.api.getTripsToAddOrder({
        Statuses: [Statuses],
      });

      this.cartStore.dispatch({
        type: CARTACTION.SET_TRIPS_TO_ADD_ORDER,
        payload: res.Trips,
      });
    } catch (err: any) {
      this.updateCartError(err.status);
    }
  };

  saveAddingTrip = async (data: { TripId: number, CartItemId: number }) => {
    try {
      await this.api.addingTripInCart(data);
      await this.load();
    } catch (err: any) {
      this.updateCartError(err.status);
    }
  };

  deleteAddedTrip = async (cartItemId: number) => {
    try {
      await this.api.deleteAddedTrip(cartItemId);

      await this.getItem(cartItemId);
    } catch (err: any) {
      this.updateCartError(err.status);
    }
  };

  setAnalytics = async (cartId: number, analyticsValueId: number) => {
    try {
      const res = await this.api.setCustomAnalytics(cartId, analyticsValueId);

      return this.updateCartSuccess([res]);
    } catch (err: any) {
      return this.updateCartError(err.status);
    }
  };

  setAnalyticsSpecificCart = async (cartId: number, analyticsValueId: number) => {
    try {
      return this.api.setCustomAnalytics(cartId, analyticsValueId);
    } catch (err: any) {
      return this.updateCartError(err.status);
    }
  };

  unsetAnalytics = async (cartId: number, analyticsValueId: number) => {
    try {
      const res = await this.api.unsetCustomAnalytics(cartId, analyticsValueId);

      return this.updateCartSuccess([res]);
    } catch (err: any) {
      return this.updateCartError(err.status);
    }
  };

  unsetAnalyticsSpecificCart = async (cartId: number, analyticsValueId: number) => {
    try {
      return this.api.unsetCustomAnalytics(cartId, analyticsValueId);
    } catch (err: any) {
      return this.updateCartError(err.status);
    }
  };

  setAnalyticsForItem = async (cartItemId: number, analyticsValueId: number) => {
    const res = await this.api.setCustomAnalyticsForItem(cartItemId, analyticsValueId);
    this.updateCartItem(res);

    return res;
  };

  unsetAnalyticsForItem = async (cartItemId: number, analyticsValueId: number) => {
    const res = await this.api.unsetCustomAnalyticsForItem(cartItemId, analyticsValueId);
    this.updateCartItem(res);

    return res;
  };

  // судя по поиску не используется;
  setRequest1c = (request: { id: number, list: number | number[] }) => {
    this.cartStore.dispatch({
      type: CARTACTION.SET_REQUESTS_1C_LIST,
      payload: request,
    });
  };

  setPreselectRequest1c = (request: PreselectedRequest1c) => {
    this.cartStore.dispatch({
      type: CARTACTION.SET_PRESELECT_REQUEST_1C,
      payload: request,
    });
  };

  subscribeCart = (cb: any) => this.cartStore.subscribe(cb);

  reset = () => this.cartStore.dispatch({
    type: CARTACTION.RESET,
  });

  init = () => {
    this.reset();
    this.start();
  };
}

export default Cart;
