import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { NotificationsLayout } from 'new-ui';
import {
  History,
  Pathname,
  UnregisterCallback,
  Location,
} from 'history';

import { getText } from '../../../i18n';

import Smartdesk from '../../page/Smartdesk';
import AirlineSearchPage from '../../page/AirlineSearch';
import AirlineResultPage from '../../page/AirlineResult';
import HotelsSearchPage from '../../page/HotelsSearch';
import HotelResultPage from '../../page/HotelResult';
import { TrainsSearchPage } from '../../page/TrainsSearch';
import { TrainResultPage } from '../../page/TrainResult';
import { TrainsResultWrap } from '../../page/TrainsResults';
import { TrainPage } from '../../page/Train';
import { TrainCarPage } from '../../page/TrainCar';
import { AeroexpressPage } from '../../page/Aeroexpress';
import { Trips } from '../../page/Trips';
import ReportPage from '../../page/Report';
import TransactionsReportPage from '../../page/TransactionsReport';
import { Booking } from '../../page/Booking';
import Favorites from '../../page/Favorites';
import CartPage from '../../page/Cart';
import Checkout from '../../page/Checkout';
import NotePage from '../../page/Note';
import NoteBooking from '../../page/NoteBooking';
import { SettingsPage } from '../../page/Settings';
import { EmployeePage } from '../../page/Employee';
import { TravelPolicyPage } from '../../page/TravelPolicy';
import { TravelPolicyQuiz } from '../../page/TravelPolicyQuiz';
import QualityPage from '../../page/Quality';
import TripDetails from '../../page/TripDetails';
import { ChangeAirTrip } from '../../page/ChangeAirTrip';
import { ChangeAirTripOther } from '../../page/ChangeAirTripOther';
import { ChangeAirTripPassport } from '../../page/ChangeAirTripPassport';
import { ChangeAirTripDateOrDirectionPage } from '../../page/ChangeAirTripDateOrDirection';
import { ChangeAirTripDateOrDirectionResultPage } from '../../page/ChangeAirTripDateOrDirectionResult';
import ExpenseReportsPage from '../../page/ExpenseReports';
import ExpenseReportPage from '../../page/ExpenseReport';
import Intercom from '../../components/Intercom';
import CarrotQuestChat from '../../components/CarrotQuestChat';
import SmartChat from '../../components/SmartChat';
import Search from '../../components/Search';
import { TripDocumentsPage } from '../../page/TripDocumentsPage';
import AppHeader from './components/AppHeader';

import { ChartsAnalyticsPage } from '../../page/ChartsAnalytics';
import { HotelSummaryFirstPage } from '../../page/ChartsAnalytics/components/hotel/summary';
import { HotelSummarySecondPage } from '../../page/ChartsAnalytics/components/hotel/summary/components/hotelSummarySecondPage';
import { ChartAnalyticsGeneral } from '../../page/ChartsAnalytics/components/general';
import { SmartSummary } from '../../page/ChartsAnalytics/components/smart/summary';
import { SmartCommonSecondPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonSecondPage';
import { SmartCommonThirdAirPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonThirdAirPage';
import { SmartCommonThirdTrainPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonThirdTrainPage';
import { SmartCommonThirdHotelPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonThirdHotelPage';
import { SmartCommonThirdTaxiPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonThirdTaxiPage';
import { SmartCommonFourthAirPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonFourthAirPage';
import { SmartCommonFourthTrainPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonFourthTrainPage';
import { SmartCommonFourthHotelPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonFourthHotelPage';
import { SmartCommonFourthTaxiPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonFourthTaxiPage';
import { TaxiCommonVoucher } from '../../page/ChartsAnalytics/components/smart/summary/components/taxiCommonVoucher';
import { SmartAirline } from '../../page/ChartsAnalytics/components/smart/airline';
import { SmartAirlineSecond } from '../../page/ChartsAnalytics/components/smart/airline/components/smartAirlineSecond';
import { HotelDirectionsFirstPage } from '../../page/ChartsAnalytics/components/hotel/directions';
import { SmartTrain } from '../../page/ChartsAnalytics/components/smart/train';
import { SmartTrainSecond } from '../../page/ChartsAnalytics/components/smart/train/components/smartTrainSecond';
import { SmartHotel } from '../../page/ChartsAnalytics/components/smart/hotel';
import { SmartHotelSecond } from '../../page/ChartsAnalytics/components/smart/hotel/components/smartHotelSecond';
import { SmartTaxi } from '../../page/ChartsAnalytics/components/smart/taxi';
import { SmartTaxiSecond } from '../../page/ChartsAnalytics/components/smart/taxi/components/smartTaxiSecond';
import { AirlineSummaryFirstPage } from '../../page/ChartsAnalytics/components/airline/summary';
import { AirlineSummarySecondPage } from '../../page/ChartsAnalytics/components/airline/summary/components/airlineSummarySecondPage';
import { AirlineDirectionsFirstPage } from '../../page/ChartsAnalytics/components/airline/directions';
import { AirlineDirectionsSecondPage } from '../../page/ChartsAnalytics/components/airline/directions/components/airlineDirectionsSecondPage';
import { AirlineCompaniesFirstPage } from '../../page/ChartsAnalytics/components/airline/companies';
import { AirlineCompaniesSecondPage } from '../../page/ChartsAnalytics/components/airline/companies/components/airlineCompaniesSeconPage';
import { AirlineEarlinessFirstPage } from '../../page/ChartsAnalytics/components/airline/earliness';
import { AirlineEarlinessSecondPage } from '../../page/ChartsAnalytics/components/airline/earliness/components/airlineEarlinessSecondPage';
import { TrainSummaryFirstPage } from '../../page/ChartsAnalytics/components/train/summary';
import { TrainSummarySecondPage } from '../../page/ChartsAnalytics/components/train/summary/components/trainSummarySecondPage';
import { TrainDirectionsFirstPage } from '../../page/ChartsAnalytics/components/train/directions';
import { TrainDirectionsSecondPage } from '../../page/ChartsAnalytics/components/train/directions/components/trainDirectionsSecondPage';
import { HotelDirectionsSecondPage } from '../../page/ChartsAnalytics/components/hotel/directions/components/hotelDirectionsSecondPage';
import { HotelPopularFirstPage } from '../../page/ChartsAnalytics/components/hotel/popular';
import { ChartAnalyticsTaxiSummary } from '../../page/ChartsAnalytics/components/taxi/summary';
import { TaxiSummaryNestedTable } from '../../page/ChartsAnalytics/components/taxi/summary/components/taxiSummaryNestedTable';
import { TaxiSummaryNestedDoubleTable } from '../../page/ChartsAnalytics/components/taxi/summary/components/taxiSummaryNestedDoubleTable';
import { TaxiDirectionsFirstPage } from '../../page/ChartsAnalytics/components/taxi/directions';
import { TaxiDirectionsSecondPage } from '../../page/ChartsAnalytics/components/taxi/directions/components/taxiDirectionsNestedTable';
import { TaxiDirectionNestedDoubleTable } from '../../page/ChartsAnalytics/components/taxi/directions/components/taxiDirectionNestedDoubleTable';
import { HotelPopularSecondPage } from '../../page/ChartsAnalytics/components/hotel/popular/components/hotelPopularSecondPage';
import { ChartAnalyticsStructureServiceType } from '../../page/ChartsAnalytics/components/structure/serviceType';
import { StructureServiceTypeNestedTable } from '../../page/ChartsAnalytics/components/structure/serviceType/components/structureServiceTypeNestedTable';
import { ChartAnalyticsStructureDepartments } from '../../page/ChartsAnalytics/components/structure/departments';
import { DepartmentsNestedTable } from '../../page/ChartsAnalytics/components/structure/departments/components/nestedTable';
import { DepartmentsDoubleNestedTable } from '../../page/ChartsAnalytics/components/structure/departments/components/doubleNestedTable';
import { ChartAnalyticsStructureProjects } from '../../page/ChartsAnalytics/components/structure/projects';
import { ProjectsNestedTable } from '../../page/ChartsAnalytics/components/structure/projects/components/nestedTable';
import { ProjectsDoubleNestedTable } from '../../page/ChartsAnalytics/components/structure/projects/components/doubleNestedTable';
import { ChartAnalyticsStructureCustomAnalytics } from '../../page/ChartsAnalytics/components/structure/customAnalytics';
import { CustomAnalyticsNestedTable } from '../../page/ChartsAnalytics/components/structure/customAnalytics/components/nestedTable';
import { CustomAnalyticsDoubleNestedTable } from '../../page/ChartsAnalytics/components/structure/customAnalytics/components/doubleNestedTable';
import { AeroexpressSummaryFirstPage } from '../../page/ChartsAnalytics/components/aeroexpress/summary';
import { AeroexpressSummarySecondPage } from '../../page/ChartsAnalytics/components/aeroexpress/summary/components/aeroexpressSummarySecondPage';
import { AeroexpressDirectionsFirstPage } from '../../page/ChartsAnalytics/components/aeroexpress/directions';
import { AeroexpressDirectionsSecondPage } from '../../page/ChartsAnalytics/components/aeroexpress/directions/components/aeroexpressDirectionsSecondPage';
import { SmartAeroexpress } from '../../page/ChartsAnalytics/components/smart/aeroexpress';
import { SmartAeroexpressSecond } from '../../page/ChartsAnalytics/components/smart/aeroexpress/components/smartAeroexpressSecond';
import { SmartCommonThirdAeroexpressPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonThirdAeroexpressPage';
import { SmartCommonFourthAeroexpressPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonFourthAeroexpressPage';
import { TransferSummaryFirstPage } from '../../page/ChartsAnalytics/components/transfer/summary';
import { TransferSummarySecondPage } from '../../page/ChartsAnalytics/components/transfer/summary/components/transferSummarySecondPage';
import { TransferDirectionsFirstPage } from '../../page/ChartsAnalytics/components/transfer/directions';
import { TransferDirectionsSecondPage } from '../../page/ChartsAnalytics/components/transfer/directions/components/transferDirectionsSecondPage';
import { SmartTransfer } from '../../page/ChartsAnalytics/components/smart/transfer';
import { SmartTransferSecond } from '../../page/ChartsAnalytics/components/smart/transfer/components/smartTransferSecond';
import { SmartCommonThirdTransferPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonThirdTransferPage';
import { SmartCommonFourthTransferPage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonFourthTransferPage';
import { SmartCommonThirdMicePage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonThirdMicePage';
import { SmartCommonFourthMicePage } from '../../page/ChartsAnalytics/components/smart/summary/components/smartCommonFourthMicePage';

import AdditionalServiceSearchPage from '../../page/AdditionalServiceSearch';
import SpecificCartPage from '../../page/SpecificCart';
import { ApprovePage } from '../../page/Approve';
import Requests from '../../page/Requests';
import Request from '../../page/Request';
import UserPopupsLayout from '../../components/UserPopups';
import { SidePanelWrapper } from './components/SidePanel/wrapper';
import { DepartmentPage } from '../../page/Department';
import { ApprovalSchemePage } from '../../page/ApprovalScheme';
import { ApplicationSchemeCreate } from '../../page/ApplicationScheme/CreateScheme';
import { ApplicationSchemeEdit } from '../../page/ApplicationScheme/EditScheme';
import { ApprovalERSchemePage } from '../../page/ApprovalERScheme';
import { OutdatedBrowserNotification } from '../../components/OutdatedBrowserNotification';
import { HotelsRegionResult } from '../../page/HotelsRegionResult';
import AnalyticsPage from '../../page/Analytics';
import ApprovalRequestPage from '../../page/ApprovalRequest';
import { Taxi } from '../../page/Taxi';
import PageOffer from '../../page/Offer';
import PageOfferSmartAgent from '../../page/OfferSmartAgent';
import { TransferSearch } from '../../page/TransferSearch';
import { TransferResultPage } from '../../page/TransferResult';
import { VipHallResult } from '../../page/VipHallResult';
import { VipHallOrder } from '../../page/VipHallOrder';
import { VipHallSelectRoute } from '../../page/VipHallSelectRoute';
import { VipHallSelectTerminal } from '../../page/VipHallSelectTerminal';
import { VipHallSelectDirection } from '../../page/VipHallSelectDirection';
import { VipHallFormPayment } from '../../page/VipHallFormPayment';
import { ApproveTravelPage } from '../../page/ApproveTravel';
import { ApproveExpenseReportPage } from '../../page/ApproveExpenseReports';
import { TravelersPage } from '../../page/TravelersPage';
import { AirlineAdditionalServices } from '../../page/AirlineAdditionalServices';
import { AirlineAdditionalChatRequest } from '../../page/AirlineAdditionalChatRequest';
import { AirlineAdditionalFightCertificate } from '../../page/AirlineAdditionalFightCertificate';
import { TravelPolicyUnique } from '../../page/TravelPolicy/components/TravelPolicyUnique';
import { EventPage } from '../../page/Event';
import { ApplicationSchemeRequests } from '../../page/ApplicationSchemeRequests';
import { SmartMiceSecond } from '../../page/ChartsAnalytics/components/smart/mice/components/smartMiceSecond';
import { SmartMice } from '../../page/ChartsAnalytics/components/smart/mice';
import { SmartdeskSA } from '../../page/SmartdeskSmartagent';

import { MiceSummaryFirstPage } from '../../page/ChartsAnalytics/components/mice/summary';
import { MiceSummarySecondPage } from '../../page/ChartsAnalytics/components/mice/summary/components/miceSummarySecondPage';

import { POPUP_GROUP_TYPES } from '../../bi/constants/popups';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import {
  SERVICETYPE,
  ANALYTIC_SERVICE_TYPES,
} from '../../bi/constants/serviceType';
import { SEARCHOPTION } from '../../bi/constants/app';
import { ROUTES_SMARTDESK_SA } from '../../page/SmartdeskSmartagent/const';
import { AGGREGATORS_ACCOUNT } from '../../bi/constants/accounts';

import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import { ErrorBoundary } from './components/ErrorBoundary';

import { ServiceConstructorsKeys, ServiceReturnType } from '../../bi/context/services';

import styles from './styles/layout.module.css';

const LABELS = {
  VIP_LOUNGE_TITLE: getText('search:titles.vipLoungeTitle'),
  CONFERENCE_HALL_TITLE: getText('search:titles.conferenceHallTitle'),
  EVENT_TITLE: getText('search:titles.eventTitle'),
  GROUP_CHECK_IN_TITLE: getText('search:titles.groupCheckInTitle'),
  MAIN_TEXT: getText('search:additional.mainTextForVZone'),
  MAIN_TEXT_TWO: getText('search:additional.mainTextForVZtwo'),
  SECONDARY_TEXT: getText('search:additional.secondaryTextForVZ'),
};

interface IMainLayoutProps {
  location: Location;
  history: History;
  browser: any;
  getService<S extends keyof ServiceReturnType>(
    serviceName: S,
  ): ServiceReturnType[S];
  getProtocol: any;
  onLogout(): void;
  updateChatSession(): void;
}

interface IMainLayoutState {
  showOutdatedNotification: boolean;
}

class MainLayout extends Component<
IMainLayoutProps,
IMainLayoutState
> {
  state = {
    showOutdatedNotification: false,
  };

  chatName: ServiceConstructorsKeys = 'ChatDecorator';

  intercomAppId = this.props.getService('Intercom').APPID;

  lastLocation: Pathname | null = null;

  unlisten: UnregisterCallback;

  componentDidUpdate() {
    this.lastLocation = this.props.location.pathname;
  }

  componentDidMount() {
    const { updateChatSession, getService, history } = this.props;

    updateChatSession();
    const isDemo = getService('Workspace').isDemo;
    const isCarrotQuestChat = getService('AccountSettings').isCarrotQuestChat();
    const isSmartChat = getService('AccountSettings').isSmartChat();
    getService('ChatDecorator').getChat(isDemo, isCarrotQuestChat, isSmartChat);

    this.unlisten = history.listen(() => {
      getService('Popups').closePopupsByType(
        POPUP_GROUP_TYPES.SEARCH_RESULT,
      );
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleShowOutdatedBrowserNotification = (value: boolean) =>
    this.setState({ showOutdatedNotification: value });

  isFavoriteLastPath = () => this.lastLocation === ROUTES.FAVORITES;

  renderSmartdeskSmartway = (props: any) => {
    const { getService, getProtocol } = this.props;

    return (
      <Search withPlanes>
        <Smartdesk
          { ...props }
          appService={ getService('App') }
          workspaceService={ getService('Workspace') }
          notificationService={ getService('Notification') }
          reportService={ getService('Report') }
          // @ts-ignore
          chatService={ getService(this.chatName) }
          smartdeskService={ getService('Smartdesk') }
          toDoService={ getService('ToDo') }
          companyId={ getService('Workspace').get().CompanyId }
          paymentService={ getService('Payment') }
          travelApprovalService={ getService('TravelApproval') }
          featureFlagsService={ getService('FeatureFlags') }
          popupsService={ getService('Popups') }
          travelPolicyService={ getService('TravelPolicy') }
          approvalRequestProtocol={ getProtocol('ApprovalRequest') }
          uiSettingsProtocol={ getProtocol('UiSettings') }
          productAnalyticsProtocol={ getProtocol('ProductAnalytics') }
          accountSettingsService={ getService('AccountSettings') }
          aggregationId={ getService('UiSettings').store.aggregationId }
        />
      </Search>
    );
  };

  renderSmartdeskSmartagent = (props: any) => {
    const { getService, getProtocol } = this.props;

    return (
      <Search withPlanes>
        <SmartdeskSA
          { ...props }
          history={ this.props.history }
          location={ this.props.location }
          airlineService={ getService('Airline') }
          userSessionService={ getService('UserSession') }
          workspaceService={ getService('Workspace') }
          requestsService={ getService('Requests') }
          travelApprovalService={ getService('TravelApproval') }
          featureFlagsService={ getService('FeatureFlags') }
          hotelsService={ getService('Hotels') }
          trainsService={ getService('Trains') }
          smartdeskService={ getService('Smartdesk') }
          transferService={ getService('Transfer') }
          // @ts-ignore
          chatService={ getService(this.chatName) } // TODO убрать интерфейс
          productAnalyticsProtocol={ getProtocol('ProductAnalytics') }
          notificationService={ getService('Notification') }
          aeroexpressService={ getService('Aeroexpress') }
          appService={ getService('App') }
          aggregationId={ getService('UiSettings').store.aggregationId }
          accountSettings={ getService('AccountSettings') }
          chartsAnalyticsProtocol={ getProtocol('ChartsAnalytics') }
          settingsService={ getService('Settings') }
          popupsService={ getService('Popups') }
        />
      </Search>
    );
  };

  renderSmartdesk = () => {
    const { getService } = this.props;

    const isRedesignSA =
      isSmartAgent &&
      getService('FeatureFlags').getShowElementForSmartagent();
    const renderContent = (props: any) =>
      (isRedesignSA
        ? this.renderSmartdeskSmartagent(props)
        : this.renderSmartdeskSmartway(props));

    const routes = isRedesignSA
      ? ROUTES_SMARTDESK_SA
      : ROUTES.SMARTDESK;

    return (
      <Route
        exact
        path={ routes }
        render={ props => renderContent(props) }
      />
    );
  };

  renderTaxi = () => {
    const { getService } = this.props;
    const noBookingTaxi =
      getService('AccountSettings').getBookingTaxi();

    if (isSmartAgent || noBookingTaxi) return null;

    return (
      <Route
        path={ ROUTES.SEARCH.TAXI }
        exact
        render={ props => (
          <Search positionStatic>
            <Taxi
              { ...props }
              featureFlagsService={ getService('FeatureFlags') }
              taxiService={ getService('Taxi') }
              notificationService={ getService('Notification') }
            />
          </Search>
        ) }
      />
    );
  };

  renderRequests = () => {
    const { getService } = this.props;
    const Salary1C = getService('AccountSettings').getSalary1C();

    if (isSmartAgent || !Salary1C) return null;

    return (
      <Route
        exact
        path='/requests'
        render={ props => (
          <Requests
            { ...props }
            requestsService={ getService('Requests') }
            formatService={ getService('Format') }
          />
        ) }
      />
    );
  };

  renderService = () => {
    const { getService, getProtocol } = this.props;
    const searchServiceTypeAll =
      getService('AccountSettings').getSearchServiceType();
    let searchServiceType = searchServiceTypeAll;

    if (isSmartAgent) {
      searchServiceType = searchServiceTypeAll.filter(
        item =>
          item !== SEARCHOPTION.SEARCH_CONFERENCE &&
          item !== SEARCHOPTION.SEARCH_GROUP &&
          item !== SEARCHOPTION.SEARCH_EVENT,
      );
    }

    const searchVipZalProps = {
      mainText: LABELS.MAIN_TEXT,
      mainTextTwo: LABELS.MAIN_TEXT_TWO,
      secondaryText: LABELS.SECONDARY_TEXT,
      history: this.props.history,
      analyticValue:
        MainAnalytic.ACTIONS.SEARCH.SEARCH_VIPHALL_SCREEN_OPENED,
      analyticPropOnSend: ANALYTIC_SERVICE_TYPES.VIP_ROOM,
      chatService: getService(this.chatName),
      workspaceService: getService('Workspace'),
      productAnalyticsProtocol: getProtocol('ProductAnalytics'),
      title: LABELS.VIP_LOUNGE_TITLE,
      featureFlagsService: getService('FeatureFlags'),
      accountSettings: getService('AccountSettings'),
    };

    return searchServiceType.map(type => {
      let htmlRout;

      switch (type) {
        case SERVICETYPE.AEROEXPRESS: {
          htmlRout = (
            <Route
              exact
              path={ ROUTES.SEARCH.AEROEXPRESS }
              key={ type }
              render={ props => (
                <Search>
                  <AeroexpressPage
                    { ...props }
                    key={ 0 }
                    featureFlagsService={ getService('FeatureFlags') }
                    aeroexpressService={ getService('Aeroexpress') }
                    aeroexpressStore={ getService('Aeroexpress').store }
                    notificationService={ getService('Notification') }
                    travelApprovalService={ getService(
                      'TravelApproval',
                    ) }
                  />
                </Search>
              ) }
            />
          );
          break;
        }

        case SERVICETYPE.TRANSFER: {
          htmlRout = (
            <Route
              exact
              key={ type }
              path='/search/transfer'
              render={ props => (
                <Search>
                  <TransferSearch
                    { ...props }
                    userSessionService={ getService('UserSession') }
                    transferService={ getService('Transfer') }
                    featureFlagsService={ getService('FeatureFlags') }
                    smartdeskService={ getService('Smartdesk') }
                    accountSettings={ getService('AccountSettings') }
                  />
                </Search>
              ) }
            />
          );
          break;
        }

        case SEARCHOPTION.SEARCHVIP: {
          htmlRout = (
            <Route
              exact
              path={ ROUTES.SEARCH.VIP }
              key={ type }
              render={ props => (
                <Search>
                  {/* @ts-ignore */}
                  <AdditionalServiceSearchPage
                    key='2'
                    { ...props }
                    { ...searchVipZalProps }
                  />
                </Search>
              ) }
            />
          );
          break;
        }

        case SEARCHOPTION.SEARCH_CONFERENCE: {
          htmlRout = (
            <Route
              exact
              key={ type }
              path='/search/conference'
              render={ props => (
                <Search>
                  <AdditionalServiceSearchPage
                    key='3'
                    { ...props }
                    history={ this.props.history }
                    // @ts-ignore
                    chatService={ getService(this.chatName) }
                    workspaceService={ getService('Workspace') }
                    productAnalyticsProtocol={ getProtocol(
                      'ProductAnalytics',
                    ) }
                    title={ LABELS.CONFERENCE_HALL_TITLE }
                    featureFlagsService={ getService('FeatureFlags') }
                    analyticPropOnSend={
                      ANALYTIC_SERVICE_TYPES.CONFERENCE_HALL
                    }
                  />
                </Search>
              ) }
            />
          );
          break;
        }

        case SEARCHOPTION.SEARCH_GROUP: {
          htmlRout = (
            <Route
              exact
              key={ type }
              path='/search/group'
              render={ props => (
                <Search>
                  <AdditionalServiceSearchPage
                    key='4'
                    { ...props }
                    history={ this.props.history }
                    // @ts-ignore
                    chatService={ getService(this.chatName) }
                    workspaceService={ getService('Workspace') }
                    productAnalyticsProtocol={ getProtocol(
                      'ProductAnalytics',
                    ) }
                    title={ LABELS.GROUP_CHECK_IN_TITLE }
                    featureFlagsService={ getService('FeatureFlags') }
                    analyticPropOnSend={
                      ANALYTIC_SERVICE_TYPES.GROUP_CHECK_IN_HOTEL
                    }
                  />
                </Search>
              ) }
            />
          );
          break;
        }

        case SEARCHOPTION.SEARCH_EVENT: {
          htmlRout = (
            <Route
              exact
              key={ type }
              path='/search/event'
              render={ props => (
                <Search>
                  <AdditionalServiceSearchPage
                    key='5'
                    { ...props }
                    history={ this.props.history }
                    // @ts-ignore
                    chatService={ getService(this.chatName) }
                    workspaceService={ getService('Workspace') }
                    productAnalyticsProtocol={ getProtocol(
                      'ProductAnalytics',
                    ) }
                    title={ LABELS.EVENT_TITLE }
                    featureFlagsService={ getService('FeatureFlags') }
                    analyticPropOnSend={ ANALYTIC_SERVICE_TYPES.EVENTS }
                  />
                </Search>
              ) }
            />
          );
          break;
        }

        default: {
          htmlRout = null;

          break;
        }
      }

      return htmlRout;
    });
  };

  renderChat = () => {
    const { getService } = this.props;
    // @ts-ignore
    const isCarrotQuest = getService('ChatDecorator').isCarrotQuestChat;
    const isSmartChat = getService('ChatDecorator').isSmartChat;

    if (isSmartChat) {
      return (
        <SmartChat
          workspaceService={ this.props.getService('Workspace') }
        />
      );
    }

    if (isCarrotQuest) {
      return (
        <CarrotQuestChat
          carrotQuestService={ this.props.getService('CarrotQuest') }
          workspaceService={ this.props.getService('Workspace') }
        />
      );
    }

    return (
      <Intercom
        appId={ this.intercomAppId }
        workspaceService={ this.props.getService('Workspace') }
        appService={ this.props.getService('App') }
        chatService={ this.props.getService('ChatDecorator') }
      />
    );
  };

  renderFavorites = () => {
    const { getService, getProtocol } = this.props;

    if (isSmartAgent) return null;

    return (
      <Route
        path='/favorites'
        render={ props => (
          <Favorites
            { ...props }
            store={ getService('Favorites').store }
            favoritesService={ getService('Favorites') }
            notificationService={ getService('Notification') }
            transferService={ getService('Transfer') }
            airlineService={ getService('Airline') }
            trainService={ getService('Trains') }
            hotelService={ getService('Hotels') }
            featureFlagsService={ getService('FeatureFlags') }
            accountSettingsService={ getService('AccountSettings') }
            uiSettingsProtocol={ getProtocol('UiSettings') }
          />
        ) }
      />
    );
  };

  renderEventPage = () => {
    const { getService } = this.props;

    const noMiceFlag = getService('AccountSettings').getNoMice();
    const aggregationId = getService('UiSettings').store.aggregationId;
    const isPPR = aggregationId === AGGREGATORS_ACCOUNT.PPR;

    if (isSmartAgent || (aggregationId && !isPPR) || noMiceFlag) return null;

    return (
      <Route
        exact
        path={ [ROUTES.EVENT, ROUTES.EVENT_CONTEXT] }
        render={ props => (
          <EventPage
            eventService={ getService('EventService') }
            chatService={ getService('ChatDecorator') }
            aggregationId={ aggregationId }
            workspaceService={ getService('Workspace') }
            { ...props }
          />
        ) }
      />
    );
  };

  renderExpenseReports = () => {
    const { getService } = this.props;
    const noExpenseReport =
      getService('AccountSettings').getNoExpenseReport();

    if (isSmartAgent || noExpenseReport) return null;

    return (
      <Route
        exact
        path={ ROUTES.EXPENSE.MAIN }
        render={ props => (
          // @ts-ignore
          <ExpenseReportsPage
            { ...props }
            expenseReportsService={ getService('ExpenseReports') }
            workspaceService={ getService('Workspace') }
            notificationService={ getService('Notification') }
            employeeService={ getService('Employee') }
            featureFlagsService={ getService('FeatureFlags') }
            popupsService={ getService('Popups') }
          />
        ) }
      />
    );
  };

  renderAnalyticsAndCostCenters = () => {
    const { getService } = this.props;

    if (isSmartAgent) return null;

    return [
      ROUTES.ANALYTICS,
      `${ROUTES.ANALYTICS}/:id`,
      ROUTES.COSTCENTERS,
    ].map(p => (
      <Route
        exact
        key={ p }
        path={ p }
        render={ props => (
          // @ts-ignore
          <AnalyticsPage
            { ...props }
            isCostCenter={ p === ROUTES.COSTCENTERS }
            userSessionService={ getService('UserSession') }
            employeeService={ getService('Employee') }
            customAnalyticsService={ getService('CustomAnalytics') }
            featureFlagsService={ getService('FeatureFlags') }
            settingsService={ getService('Settings') }
            cartService={ getService('Cart') }
            notepadService={ getService('Notepad') }
            workspaceService={ getService('Workspace') }
          />
        ) }
      />
    ));
  };

  renderApprovalScheme = () => {
    const { getService } = this.props;

    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ ROUTES.APPROVAL_SCHEME }
        render={ props => (
          <ApprovalSchemePage
            { ...props }
            approvalSchemesService={ getService('ApprovalSchemes') }
            sidePanelService={ getService('SidePanel') }
            workspaceService={ getService('Workspace') }
            popupsService={ getService('Popups') }
            departmentsService={ getService('Departments') }
          />
        ) }
      />
    );
  };

  renderRedirectSettingsSmartagent = (route: string) =>
    isSmartAgent && (
      <Redirect exact from={ route } to={ ROUTES.SMARTDESK } />
    );

  renderApplicationSchemeCreate = () => {
    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ `${ROUTES.APPLICATION_SCHEME_CREATE}` }
        render={ () => <ApplicationSchemeCreate /> }
      />
    );
  };

  renderApplicationSchemeEdit = () => {
    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ `${ROUTES.APPLICATION_SCHEME_EDIT}` }
        render={ () => <ApplicationSchemeEdit /> }
      />
    );
  };

  renderApplicationSchemeRequests = () => {
    const { getService, getProtocol } = this.props;

    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ `${ROUTES.APPLICATION_SCHEME_REQUEST}` }
        render={ props => (
          <ApplicationSchemeRequests
            { ...props }
            travelApprovalService={ getService('TravelApproval') }
            workspaceService={ getService('Workspace') }
            customAnalyticsService={ getService('CustomAnalytics') }
            approvalRequestProtocol={ getProtocol('ApprovalRequest') }
            uiSettingsProtocol={ getProtocol('UiSettings') }
            aggregationId={
              getService('UiSettings').store.aggregationId
            }
          />
        ) }
      />
    );
  };

  renderApprovalExpenseReport = () => {
    const { getService } = this.props;

    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ ROUTES.APPROVAL_EXPENSE_REPORT }
        render={ props => (
          <ApprovalERSchemePage
            { ...props }
            approvalERSchemesService={ getService('ApprovalERSchemes') }
            sidePanelService={ getService('SidePanel') }
            workspaceService={ getService('Workspace') }
            popupsService={ getService('Popups') }
          />
        ) }
      />
    );
  };

  renderApprovePage = () => {
    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ ROUTES.APPROVE.TRIPS }
        render={ () => <ApprovePage /> }
      />
    );
  };

  renderTravelersPage = () => {
    const { getService, getProtocol } = this.props;

    if (!isSmartAgent) return null;

    return (
      <Route
        exact
        path='/travelers'
        render={ props => (
          <TravelersPage
            { ...props }
            settingsService={ getService('Settings') }
            appService={ getService('App') }
            workspaceService={ getService('Workspace') }
            userSessionService={ getService('UserSession') }
            employeeService={ getService('Employee') }
            settingsProtocol={ getProtocol('SettingsProtocol') }
          />
        ) }
      />
    );
  };

  renderApproveTravelPage = () => {
    const { getService } = this.props;

    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ ROUTES.APPROVE.REQUESTS }
        render={ props => (
          <ApproveTravelPage
            { ...props }
            reportService={ getService('Report') }
            departmentsService={ getService('Departments') }
            travelApprovalService={ getService('TravelApproval') }
          />
        ) }
      />
    );
  };

  renderApproveExpenseReportPage = () => {
    const { getService } = this.props;

    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ ROUTES.APPROVE.EXPENSE_REPORTS }
        render={ props => (
          <ApproveExpenseReportPage
            { ...props }
            reportService={ getService('Report') }
            departmentsService={ getService('Departments') }
            expenseReportsService={ getService('ExpenseReports') }
          />
        ) }
      />
    );
  };

  renderApprovalRequestPage = () => {
    const { getService, getProtocol } = this.props;

    if (isSmartAgent) return null;

    return (
      <Route
        exact
        path={ ROUTES.NEW_REQUEST }
        render={ props => (
          // @ts-ignore
          <ApprovalRequestPage
            { ...props }
            isNew
            hotelsService={ getService('Hotels') }
            airlineService={ getService('Airline') }
            trainsService={ getService('Trains') }
            aeroexpressService={ getService('Aeroexpress') }
            travelApprovalService={ getService('TravelApproval') }
            employeeService={ getService('Employee') }
            userSessionService={ getService('UserSession') }
            approvalSchemesService={ getService('ApprovalSchemes') }
            workspaceService={ getService('Workspace') }
            approvalRequestProtocol={ getProtocol('ApprovalRequest') }
            notificationService={ getService('Notification') }
            accountSettingsService={ getService('AccountSettings') }
            settingsService={ getService('Settings') }
            uiSettingsProtocol={ getProtocol('UiSettings') }
            featureFlagsService={ getService('FeatureFlags') }
            customAnalyticsService={ getService('CustomAnalytics') }
            tripTagsService={ getService('TripTags') }
          />
        ) }
      />
    );
  };

  render() {
    const { getService, getProtocol, browser, onLogout } = this.props;
    const { showOutdatedNotification } = this.state;

    const sidePanelService = getService('SidePanel');

    const popupsProtocol = getProtocol('Popups');
    const approvalRequestProtocol = getProtocol('ApprovalRequest');
    const expenseReportsProtocol = getProtocol('ExpenseReports');

    const settingsTabProps = {
      travelPoliciesStore:
        getService('TravelPolicy').travelPoliciesListStore,
      settingsService: getService('Settings'),
      appService: getService('App'),
      employeeService: getService('Employee'),
      notificationService: getService('Notification'),
      travelPolicyService: getService('TravelPolicy'),
      workspaceService: getService('Workspace'),
      userSessionService: getService('UserSession'),
      requestsService: getService('Requests'),
      cartService: getService('Cart'),
      featureFlagsService: getService('FeatureFlags'),
      accountSettings: getService('AccountSettings'),
      tripTagsService: getService('TripTags'),
      departmentsService: getService('Departments'),
      notepadService: getService('Notepad'),
      approvalSchemesService: getService('ApprovalSchemes'),
      approvalERSchemesService: getService('ApprovalERSchemes'),
      bonusProgramService: getService('BonusProgram'),
      chatService: getService('ChatDecorator'),
      expenseReportsService: getService('ExpenseReports'),
      settingsExpenseStore:
        getService('ExpenseReports').storeSettingsExpense,
      customAnalyticsService: getService('CustomAnalytics'),
      productAnalyticsProtocol: getProtocol('ProductAnalytics'),
      settingsProtocol: getProtocol('SettingsProtocol'),
      employeePaymentService: getService('EmployeePaymentService'),
      cloudPaymentService: getService('CloudPayment'),
      reportService: getService('Report'),
      paymentService: getService('Payment'),
      personalPaymentProtocol: getProtocol('PersonalPaymentProtocol'),
      smartdeskService: getService('Smartdesk'),
    };

    const animatedPageClassName = showOutdatedNotification
      ? styles.animated
      : '';
    const isViewChartsAnalytics =
      getService('UiSettings').showChartsAnalytics();
    const noAnalytics = getService('AccountSettings').getAnalytics();

    const showChartsAnal =
      isViewChartsAnalytics && !(noAnalytics || isSmartAgent);
    const taxiHtml = this.renderTaxi();
    const requestsHtml = this.renderRequests();
    const serviceHtml = this.renderService();

    const chartsAnalyticsProps = {
      chartsAnalyticsService: getService('ChartsAnalytics'),
      popupsService: getService('Popups'),
      chartsAnalyticsProtocol: getProtocol('ChartsAnalytics'),
      lastLocation: this.lastLocation,
      chatService: getService(this.chatName),
      workspaceService: getService('Workspace'),
      metricsService: getService('Metrics'),
      showChartsAnal,
    };

    const renderChartsAnalytics = () => {
      if (isSmartAgent) {
        return null;
      }

      return (
        <Route
          exact
          path={ `${ROUTES.CHARTS_ANALYTICS.MAIN}:tabId` }
          render={ () => (
            <ChartsAnalyticsPage { ...chartsAnalyticsProps }>
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.GENERAL }
                render={ () => (
                  <ChartAnalyticsGeneral { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FIRST }
                render={ () => (
                  <SmartSummary { ...chartsAnalyticsProps } />
                ) }
              />
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.AIRLINE,
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.TRAIN,
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.HOTEL,
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.TAXI,
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND
                  .AEROEXPRESS,
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.TRANSFER,
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.MICE,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ p }
                  render={ () => (
                    <SmartCommonSecondPage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.AIRLINE
                }
                render={ () => (
                  <SmartCommonThirdAirPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.TRAIN
                }
                render={ () => (
                  <SmartCommonThirdTrainPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD
                    .AEROEXPRESS
                }
                render={ () => (
                  <SmartCommonThirdAeroexpressPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.TRANSFER
                }
                render={ () => (
                  <SmartCommonThirdTransferPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.MICE }
                render={ () => (
                  <SmartCommonThirdMicePage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.HOTEL
                }
                render={ () => (
                  <SmartCommonThirdHotelPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.TAXI }
                render={ () => (
                  <SmartCommonThirdTaxiPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FOURTH.AIRLINE,
                ROUTES.CHARTS_ANALYTICS.SMART.AIRLINE.THIRD,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `airline_${p}` }
                  render={ () => (
                    <SmartCommonFourthAirPage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FOURTH.TRAIN,
                ROUTES.CHARTS_ANALYTICS.SMART.TRAIN.THIRD,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `train_${p}` }
                  render={ () => (
                    <SmartCommonFourthTrainPage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FOURTH
                  .AEROEXPRESS,
                ROUTES.CHARTS_ANALYTICS.SMART.AEROEXPRESS.THIRD,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `earoexpress_${p}` }
                  render={ () => (
                    <SmartCommonFourthAeroexpressPage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FOURTH.TRANSFER,
                ROUTES.CHARTS_ANALYTICS.SMART.TRANSFER.THIRD,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `transfer_${p}` }
                  render={ () => (
                    <SmartCommonFourthTransferPage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FOURTH.MICE,
                ROUTES.CHARTS_ANALYTICS.SMART.MICE.THIRD,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `mice_${p}` }
                  render={ () => (
                    <SmartCommonFourthMicePage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FOURTH.HOTEL,
                ROUTES.CHARTS_ANALYTICS.SMART.HOTEL.THIRD,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `hotel_${p}` }
                  render={ () => (
                    <SmartCommonFourthHotelPage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              {[
                ROUTES.CHARTS_ANALYTICS.SMART.COMMON.FOURTH.TAXI,
                ROUTES.CHARTS_ANALYTICS.SMART.TAXI.THIRD,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `taxi${p}` }
                  render={ () => (
                    <SmartCommonFourthTaxiPage
                      { ...chartsAnalyticsProps }
                    />
                  ) }
                />
              ))}
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.AIRLINE.FIRST }
                render={ () => (
                  <SmartAirline { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.AIRLINE.SECOND }
                render={ () => (
                  <SmartAirlineSecond { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.TRAIN.FIRST }
                render={ () => (
                  <SmartTrain { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.TRAIN.SECOND }
                render={ () => (
                  <SmartTrainSecond { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.AEROEXPRESS.FIRST }
                render={ () => (
                  <SmartAeroexpress { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.SMART.AEROEXPRESS.SECOND
                }
                render={ () => (
                  <SmartAeroexpressSecond { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.TRANSFER.FIRST }
                render={ () => (
                  <SmartTransfer { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.TRANSFER.SECOND }
                render={ () => (
                  <SmartTransferSecond { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.MICE.SECOND }
                render={ () => (
                  <SmartMiceSecond { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.HOTEL.SUMMARY }
                render={ () => (
                  <HotelSummaryFirstPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.HOTEL.DIRECTIONS }
                render={ () => (
                  <HotelDirectionsFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.HOTEL.DIRECTIONS_NESTED }
                render={ () => (
                  <HotelDirectionsSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.HOTEL.POPULAR }
                render={ () => (
                  <HotelPopularFirstPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.HOTEL.POPULAR_NESTED }
                render={ () => (
                  <HotelPopularSecondPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TAXI.SUMMARY }
                render={ () => (
                  <ChartAnalyticsTaxiSummary
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TAXI.DIRECTIONS }
                render={ () => (
                  <TaxiDirectionsFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.STRUCTURE.SERVICE_TYPE }
                render={ () => (
                  <ChartAnalyticsStructureServiceType
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.STRUCTURE
                    .SERVICE_TYPE_NESTED
                }
                render={ () => (
                  <StructureServiceTypeNestedTable
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.STRUCTURE.DEPARTMENTS }
                render={ () => (
                  <ChartAnalyticsStructureDepartments
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.STRUCTURE.DEPARTMENTS_NESTED
                }
                render={ () => (
                  <DepartmentsNestedTable { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.STRUCTURE
                    .DEPARTMENTS_NESTED_SUB
                }
                render={ () => (
                  <DepartmentsDoubleNestedTable
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.STRUCTURE.PROJECTS }
                render={ () => (
                  <ChartAnalyticsStructureProjects
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.STRUCTURE.PROJECTS_NESTED
                }
                render={ () => (
                  <ProjectsNestedTable { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.STRUCTURE
                    .PROJECTS_NESTED_SUB
                }
                render={ () => (
                  <ProjectsDoubleNestedTable
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.STRUCTURE
                    .CUSTOM_ANALYTICS_NESTED
                }
                render={ () => (
                  <CustomAnalyticsNestedTable
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.STRUCTURE
                    .CUSTOM_ANALYTICS_NESTED_SUB
                }
                render={ () => (
                  <CustomAnalyticsDoubleNestedTable
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.HOTEL.FIRST }
                render={ () => (
                  <SmartHotel { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.HOTEL.SECOND }
                render={ () => (
                  <SmartHotelSecond { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.TAXI.FIRST }
                render={ () => <SmartTaxi { ...chartsAnalyticsProps } /> }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.MICE.FIRST }
                render={ () => <SmartMice { ...chartsAnalyticsProps } /> }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.SMART.TAXI.SECOND }
                render={ () => (
                  <SmartTaxiSecond { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.HOTEL.SUMMARY_NESTED }
                render={ () => (
                  <HotelSummarySecondPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AIRLINE.SUMMARY }
                render={ () => (
                  <AirlineSummaryFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AIRLINE.SUMMARY_NESTED }
                render={ () => (
                  <AirlineSummarySecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AIRLINE.DIRECTIONS }
                render={ () => (
                  <AirlineDirectionsFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.AIRLINE.DIRECTIONS_NESTED
                }
                render={ () => (
                  <AirlineDirectionsSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AIRLINE.COMPANY }
                render={ () => (
                  <AirlineCompaniesFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AIRLINE.COMPANY_NESTED }
                render={ () => (
                  <AirlineCompaniesSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AIRLINE.EARLINESS }
                render={ () => (
                  <AirlineEarlinessFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.AIRLINE.EARLINESS_NESTED
                }
                render={ () => (
                  <AirlineEarlinessSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TRAIN.SUMMARY }
                render={ () => (
                  <TrainSummaryFirstPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TRAIN.SUMMARY_NESTED }
                render={ () => (
                  <TrainSummarySecondPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TRAIN.DIRECTIONS }
                render={ () => (
                  <TrainDirectionsFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TRAIN.DIRECTIONS_NESTED }
                render={ () => (
                  <TrainDirectionsSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AEROEXPRESS.SUMMARY }
                render={ () => (
                  <AeroexpressSummaryFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.AEROEXPRESS.SUMMARY_NESTED
                }
                render={ () => (
                  <AeroexpressSummarySecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.AEROEXPRESS.DIRECTIONS }
                render={ () => (
                  <AeroexpressDirectionsFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.AEROEXPRESS
                    .DIRECTIONS_NESTED
                }
                render={ () => (
                  <AeroexpressDirectionsSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TAXI.SUMMARY_NESTED }
                render={ () => (
                  <TaxiSummaryNestedTable { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.TAXI.SUMMARY_NESTED_DOUBLE
                }
                render={ () => (
                  <TaxiSummaryNestedDoubleTable
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TAXI.DIRECTIONS_NESTED }
                render={ () => (
                  <TaxiDirectionsSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.TAXI
                    .DIRECTIONS_NESTED_DOUBLE
                }
                render={ () => (
                  <TaxiDirectionNestedDoubleTable
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.MICE.SUMMARY }
                render={ () => (
                  <MiceSummaryFirstPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.MICE.SUMMARY_NESTED }
                render={ () => (
                  <MiceSummarySecondPage { ...chartsAnalyticsProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TRANSFER.SUMMARY }
                render={ () => (
                  <TransferSummaryFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TRANSFER.SUMMARY_NESTED }
                render={ () => (
                  <TransferSummarySecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={ ROUTES.CHARTS_ANALYTICS.TRANSFER.DIRECTIONS }
                render={ () => (
                  <TransferDirectionsFirstPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.CHARTS_ANALYTICS.TRANSFER.DIRECTIONS_NESTED
                }
                render={ () => (
                  <TransferDirectionsSecondPage
                    { ...chartsAnalyticsProps }
                  />
                ) }
              />
              {[
                ROUTES.CHARTS_ANALYTICS.TAXI.SMART_VOUCHER,
                ROUTES.CHARTS_ANALYTICS.TAXI.SMART_VOUCHER_COMMON,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ `taxi${p}` }
                  render={ () => (
                    <TaxiCommonVoucher { ...chartsAnalyticsProps } />
                  ) }
                />
              ))}
            </ChartsAnalyticsPage>
          ) }
        />
      );
    };

    return (
      <div className={ styles.layout }>
        <SidePanelWrapper sidePanelService={ sidePanelService } />
        <AppHeader
          location={ this.props.location }
          history={ this.props.history }
          appService={ getService('App') }
          accountSettingsService={ getService('AccountSettings') }
          workspaceService={ getService('Workspace') }
          smartdeskService={ getService('Smartdesk') }
          featureFlagsService={ getService('FeatureFlags') }
          // @ts-ignore
          chatService={ getService(this.chatName) }
          requestsService={ getService('Requests') }
          reportService={ getService('Report') }
          bonusProgramService={ getService('BonusProgram') }
          uiSettingsProtocol={ getProtocol('UiSettings') }
          onLogout={ onLogout }
          productAnalyticsProtocol={ getProtocol('ProductAnalytics') }
          paymentService={ getService('Payment') }
          airlineService={ getService('Airline') }
        />
        <OutdatedBrowserNotification
          browser={ browser }
          onRender={ this.handleShowOutdatedBrowserNotification }
        />
        <ErrorBoundary history={ this.props.history }>
          <div className={ `${styles.page} ${animatedPageClassName}` }>
            <Switch>
              <Redirect exact from='/' to={ ROUTES.SMARTDESK } />
              {this.renderSmartdesk()}
              <Route
                exact
                path={ `${ROUTES.CHARTS_ANALYTICS.STRUCTURE.CUSTOM_ANALYTICS}/:id` }
                render={ () => (
                  <ChartsAnalyticsPage { ...chartsAnalyticsProps }>
                    <ChartAnalyticsStructureCustomAnalytics
                      { ...chartsAnalyticsProps }
                    />
                  </ChartsAnalyticsPage>
                ) }
              />
              {renderChartsAnalytics()}
              <Route
                exact
                path='/cart'
                render={ props => (
                  <CartPage
                    { ...props }
                    insuranceService={ getService('Insurance') }
                    airlineSeatsService={ getService('AirlineSeats') }
                    // @ts-ignore
                    airlineSeatsStore={
                      getService('AirlineSeats').store
                    }
                    airlineBaggageService={ getService(
                      'AirlineBaggage',
                    ) }
                    airlineBaggageStore={
                      getService('AirlineBaggage').store
                    }
                    airlineAdditionalServices={ getService(
                      'AirlineAdditionalServices',
                    ) }
                    airlineAdditionalServicesStore={ getService(
                      'AirlineAdditionalServices').store
                    }
                    cartService={ getService('Cart') }
                    cartData={ getService('Cart').get() }
                    employeeService={ getService('Employee') }
                    formatService={ getService('Format') }
                    notificationService={ getService('Notification') }
                    userSessionService={ getService('UserSession') }
                    userData={ getService('UserSession').get() }
                    settingsService={ getService('Settings') }
                    workspaceService={ getService('Workspace') }
                    workspaceData={ getService('Workspace').get() }
                    airlineService={ getService('Airline') }
                    hotelsService={ getService('Hotels') }
                    trainsService={ getService('Trains') }
                    transferService={ getService('Transfer') }
                    aeroexpressService={ getService('Aeroexpress') }
                    favoritesService={ getService('Favorites') }
                    featureFlagsService={ getService('FeatureFlags') }
                    ffList={ getService(
                      'FeatureFlags',
                    ).getFeatureFlags() }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    tripTagsService={ getService('TripTags') }
                    approvalSchemesService={ getService(
                      'ApprovalSchemes',
                    ) }
                    popupsService={ getService('Popups') }
                    sidePanelService={ getService('SidePanel') }
                    bonusProgramService={ getService('BonusProgram') }
                    customAnalyticsService={ getService(
                      'CustomAnalytics',
                    ) }
                    travelApprovalService={ getService(
                      'TravelApproval',
                    ) }
                    requestsService={ getService('Requests') }
                    uiSettingsProtocol={ getProtocol('UiSettings') }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                    isTravelApproval={
                      approvalRequestProtocol.approveRequestExists
                    }
                    metricsService={ getService('Metrics') }
                    eventService={ getService('EventService') }
                    cloudPaymentService={ getService(
                      'CloudPayment',
                    ) }
                    personalPaymentProtocol={ getProtocol(
                      'PersonalPaymentProtocol',
                    ) }
                    appService={ getService('App') }
                  />
                ) }
              />
              <Route
                exact
                path='/cart/checkout/:id'
                render={ props => (
                  // @ts-ignore
                  <Checkout
                    { ...props }
                    checkoutService={ getService('Checkout') }
                    cartService={ getService('Cart') }
                    insuranceService={ getService('Insurance') }
                    appService={ getService('App') }
                    userSessionService={ getService('UserSession') }
                    workspaceService={ getService('Workspace') }
                    notepadService={ getService('Notepad') }
                    customAnalyticsService={ getService(
                      'CustomAnalytics',
                    ) }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    featureFlagsService={ getService('FeatureFlags') }
                    eventService={ getService('EventService') }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                    cloudPaymentService={ getService(
                      'CloudPayment',
                    ) }
                    bookingService={ getService('Booking') }
                    employeePaymentService={ getService(
                      'EmployeePaymentService',
                    ) }
                    chatService={ getService('Intercom') }
                    personalPaymentProtocol={ getProtocol(
                      'PersonalPaymentProtocol',
                    ) }
                    airlineBaggageService={ getService(
                      'AirlineBaggage',
                    ) }
                    airlineBaggageStore={
                      getService('AirlineBaggage').store
                    }
                  />
                ) }
              />
              <Route
                exact
                path='/cart/booking/:id'
                render={ props => (
                  <Booking
                    { ...props }
                    cartService={ getService('Cart') }
                    bookingService={ getService('Booking') }
                      // @ts-ignore
                    chatService={ getService(this.chatName) }
                    formatService={ getService('Format') }
                    workspaceService={ getService('Workspace') }
                    userSessionService={ getService('UserSession') }
                    employeeService={ getService('Employee') }
                    hotelsService={ getService('Hotels') }
                    accountSettingsService={ getService('AccountSettings') }
                    approvalSchemesService={ getService('ApprovalSchemes') }
                    featureFlagsService={ getService('FeatureFlags') }
                    isTravelApproval={ approvalRequestProtocol.approveRequestExists }
                    aggregationId={ getService('UiSettings').store.aggregationId }
                    cloudPaymentService={ getService('CloudPayment') }
                    appService={ getService('App') }
                  />
                ) }
              />
              <Route
                exact
                path='/note'
                render={ props => (
                  // @ts-ignore
                  <NotePage
                    { ...props }
                    notepadService={ getService('Notepad') }
                    reportService={ getService('Report') }
                    notificationService={ getService('Notification') }
                    formatService={ getService('Format') }
                    userSessionService={ getService('UserSession') }
                    hotelsService={ getService('Hotels') }
                    trainService={ getService('Trains') }
                    workspaceService={ getService('Workspace') }
                    tripTagsService={ getService('TripTags') }
                    airlineService={ getService('Airline') }
                    favoritesService={ getService('Favorites') }
                    accountSettingsService={ getService('AccountSettings') }
                    popupsProtocol={ popupsProtocol }
                    uiSettingsProtocol={ getProtocol('UiSettings') }
                    aggregationId={ getService('UiSettings').store.aggregationId }
                    featureFlagsService={ getService('FeatureFlags') }
                    appService={ getService('App') }
                  />
                ) }
              />
              <Route
                exact
                path='/note/booking/:id'
                render={ (props) => (
                  // @ts-ignore
                  <NoteBooking
                    { ...props }
                    notepadService={ getService('Notepad') }
                    employeeService={ getService('Employee') }
                    userSessionService={ getService('UserSession') }
                    settingsService={ getService('Settings') }
                    workspaceService={ getService('Workspace') }
                    featureFlagsService={ getService('FeatureFlags') }
                    accountSettingsService={ getService('AccountSettings') }
                    tripTagsService={ getService('TripTags') }
                    hotelService={ getService('Hotels') }
                    appService={ getService('App') }
                  />
                ) }
              />
              <Route
                exact
                path='/reports/main'
                render={ props => (
                  <ReportPage
                    { ...props }
                    reportService={ getService('Report') }
                    appService={ getService('App') }
                    notificationService={ getService('Notification') }
                    workspaceService={ getService('Workspace') }
                    paymentService={ getService('Payment') }
                    featureFlagsService={ getService('FeatureFlags') }
                  />
                ) }
              />
              <Route
                exact
                path='/reports/transactions/:companyId'
                render={ props => (
                  // @ts-ignore
                  <TransactionsReportPage
                    { ...props }
                    reportService={ getService('Report') }
                    notificationService={ getService('Notification') }
                    workspaceService={ getService('Workspace') }
                    paymentService={ getService('Payment') }
                  />
                ) }
              />
              {requestsHtml}
              <Route
                exact
                path='/requests/:requestId'
                render={ props => (
                  <Request
                    { ...props }
                    requestsService={ getService('Requests') }
                    formatService={ getService('Format') }
                    hotelsService={ getService('Hotels') }
                    trainService={ getService('Trains') }
                    cartService={ getService('Cart') }
                  />
                ) }
              />
              {this.renderEventPage()}
              {this.renderExpenseReports()}
              {[
                `${ROUTES.EXPENSE.EXPENSE_REPORT}:id`,
                ROUTES.EXPENSE.EXPENSE_REPORT,
              ].map(p => (
                <Route
                  exact
                  path={ p }
                  key={ p }
                  render={ props => (
                    <ExpenseReportPage
                      { ...props }
                      expenseReportsService={ getService(
                        'ExpenseReports',
                      ) }
                      expenseReportsStore={
                        getService('ExpenseReports').storeReports
                      }
                      expenseReportStore={
                        getService('ExpenseReports').storeReport
                      }
                      notificationService={ getService('Notification') }
                      workspaceService={ getService('Workspace') }
                      featureFlagsService={ getService('FeatureFlags') }
                      expenseReportsProtocol={ expenseReportsProtocol }
                      accountSettingsService={ getService(
                        'AccountSettings',
                      ) }
                    />
                  ) }
                />
              ))}
              <Route
                exact
                path={ `${ROUTES.TRAVEL_APPROVAL}/:id` }
                render={ props => (
                  // @ts-ignore
                  <ApprovalRequestPage
                    { ...props }
                    hotelsService={ getService('Hotels') }
                    airlineService={ getService('Airline') }
                    trainsService={ getService('Trains') }
                    aeroexpressService={ getService('Aeroexpress') }
                    travelApprovalService={ getService(
                      'TravelApproval',
                    ) }
                    employeeService={ getService('Employee') }
                    userSessionService={ getService('UserSession') }
                    approvalSchemesService={ getService(
                      'ApprovalSchemes',
                    ) }
                    workspaceService={ getService('Workspace') }
                    approvalRequestProtocol={ getProtocol(
                      'ApprovalRequest',
                    ) }
                    notificationService={ getService('Notification') }
                    uiSettingsProtocol={ getProtocol('UiSettings') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    settingsService={ getService('Settings') }
                    featureFlagsService={ getService('FeatureFlags') }
                    customAnalyticsService={ getService(
                      'CustomAnalytics',
                    ) }
                    tripTagsService={ getService('TripTags') }
                  />
                ) }
              />
              {this.renderApprovalRequestPage()}
              <Route
                exact
                path='/trips'
                render={ props => (
                  <Trips
                    { ...props }
                    getNoMice={ getService(
                      'AccountSettings',
                    ).getNoMice() }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                    featureFlagsService={ getService('FeatureFlags') }
                    appService={ getService('App') }
                    isWhiteLabel={
                      getService('UiSettings').store.isWhiteLabel
                    }
                    uiSettingsService={ getService('UiSettings') }
                    orderService={ getService('Order') }
                    personalPaymentProtocol={ getProtocol(
                      'PersonalPaymentProtocol',
                    ) }
                  />
                ) }
              />
              <Route
                exact
                path='/trip/:tripId'
                render={ props => (
                  <TripDetails
                    { ...props }
                    // @ts-ignore
                    chatService={ getService(this.chatName) }
                    reportService={ getService('Report') }
                    notificationService={ getService('Notification') }
                    orderService={ getService('Order') }
                    workspaceService={ getService('Workspace') }
                    userSessionService={ getService('UserSession') }
                    // @ts-ignore
                    tripTagsService={ getService('TripTags') }
                    hotelsService={ getService('Hotels') }
                    favoritesService={ getService('Favorites') }
                    departmentsService={ getService('Departments') }
                    appService={ getService('App') }
                    settingsService={ getService('Settings') }
                    featureFlagsService={ getService('FeatureFlags') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    customAnalyticsService={ getService(
                      'CustomAnalytics',
                    ) }
                    requestsService={ getService('Requests') }
                    tripCommentsService={ getService('TripComments') }
                    uiSettingsService={ getService('UiSettings') }
                    companyNameSettings={ getService(
                      'UiSettings',
                    ).getCompanyName() }
                    uiSettingsProtocol={ getProtocol('UiSettings') }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                    popupsService={ getService('Popups') }
                    eventService={ getService('EventService') }
                    // @ts-ignore
                    insuranceService={ getService('Insurance') }
                  />
                ) }
              />
              <Route
                exact
                path='/trip/:tripId/documents'
                render={ props => (
                  <TripDocumentsPage
                    { ...props }
                    bookingService={ getService('Booking') }
                    workspaceService={ getService('Workspace') }
                    // @ts-ignore
                    orderService={ getService('Order') }
                    // @ts-ignore
                    reportService={ getService('Report') }
                    userSessionService={ getService('UserSession') }
                    notificationService={ getService('Notification') }
                    // @ts-ignore
                    departmentsService={ getService('Departments') }
                    customAnalyticsService={ getService(
                      'CustomAnalytics',
                    ) }
                    // @ts-ignore
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    settingsService={ getService('Settings') }
                    appService={ getService('App') }
                    popupsService={ getService('Popups') }
                    eventService={ getService('EventService') }
                  />
                ) }
              />
              <Route
                path={ '/trip/:tripId/changeAirTrip/:ticketId' }
                render={ props => (
                  <ChangeAirTrip
                    { ...props }
                    // @ts-ignore
                    orderService={ getService('Order') }
                    breadCrumbsService={ getService('BreadCrumbs') }
                    workspaceService={ getService('Workspace') }
                    store={ getService('BreadCrumbs').store }
                  >
                    <Switch>
                      <Route
                        exact
                        path={
                          '/trip/:tripId/changeAirTrip/:ticketId/other'
                        }
                        render={ propsChildren => (
                          <ChangeAirTripOther
                            { ...propsChildren }
                            // @ts-ignore
                            chatService={ getService(this.chatName) }
                            // @ts-ignore
                            orderService={ getService('Order') }
                            workspaceService={ getService('Workspace') }
                            breadCrumbsService={ getService(
                              'BreadCrumbs',
                            ) }
                          />
                        ) }
                      />
                      <Route
                        exact
                        path={
                          '/trip/:tripId/changeAirTrip/:ticketId/passport/:personId'
                        }
                        render={ propsChildren => (
                          <ChangeAirTripPassport
                            { ...propsChildren }
                            // @ts-ignore
                            chatService={ getService(this.chatName) }
                            // @ts-ignore
                            orderService={ getService('Order') }
                            // @ts-ignore
                            employeeService={ getService('Employee') }
                            userSessionService={ getService(
                              'UserSession',
                            ) }
                            workspaceService={ getService('Workspace') }
                            breadCrumbsService={ getService(
                              'BreadCrumbs',
                            ) }
                            notificationService={ getService(
                              'Notification',
                            ) }
                          />
                        ) }
                      />
                      {[
                        '/trip/:tripId/changeAirTrip/:ticketId/date',
                        '/trip/:tripId/changeAirTrip/:ticketId/direction',
                      ].map(path => (
                        <Route
                          exact
                          key={ path }
                          path={ path }
                          render={ propsChildren => (
                            <ChangeAirTripDateOrDirectionPage
                              { ...propsChildren }
                              airlineService={ getService('Airline') }
                              workspaceService={ getService(
                                'Workspace',
                              ) }
                              userSessionService={ getService(
                                'UserSession',
                              ) }
                              // @ts-ignore
                              orderService={ getService('Order') }
                              breadCrumbsService={ getService(
                                'BreadCrumbs',
                              ) }
                            />
                          ) }
                        />
                      ))}
                    </Switch>
                  </ChangeAirTrip>
                ) }
              />
              {[
                '/trip/:tripId/changeAirTripResult/:ticketId/date/:guid',
                '/trip/:tripId/changeAirTripResult/:ticketId/direction/:guid',
              ].map(path => (
                <Route
                  exact
                  key={ path }
                  path={ path }
                  render={ props => (
                    <ChangeAirTripDateOrDirectionResultPage
                      { ...props }
                      // @ts-ignore
                      chatService={ getService(this.chatName) }
                      // @ts-ignore
                      orderService={ getService('Order') }
                      airlineService={ getService('Airline') }
                      workspaceService={ getService('Workspace') }
                      userSessionService={ getService('UserSession') }
                      // @ts-ignore
                      featureFlagsService={ getService('FeatureFlags') }
                      requestsService={ getService('Requests') }
                      appService={ getService('App') }
                    />
                  ) }
                />
              ))}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.INTEGRATION,
              )}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.ANALYTICS,
              )}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.APPROVAL_SCHEMES,
              )}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.DEPARTMENTS,
              )}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.APPROVAL_SCHEMES_EXPENSE_REPORTS,
              )}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.S7,
              )}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.EXPENSE_REPORT,
              )}
              {this.renderRedirectSettingsSmartagent(
                ROUTES.SETTINGS.TRAVEL_POLICIES,
              )}
              <Route
                exact
                path='/settings/:tabId'
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path='/settings/employees/:user'
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.DEPARTMENTS }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.ANALYTICS }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.TRAVEL_POLICIES }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.APPROVAL_SCHEMES }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={
                  ROUTES.SETTINGS.APPROVAL_SCHEMES_EXPENSE_REPORTS
                }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.APPLICATION_SCHEME }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.COMPANIES }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.INTEGRATION }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.S7 }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.EXPENSE_REPORT }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.PROFILE }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              {this.renderApprovalScheme()}
              <Route
                exact
                path={ ROUTES.SETTINGS.DOCUMENTS_FOR_SIGNATURE }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.DOCUMENTS_GOS_SIGN }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.SETTINGS.VOUCHERS }
                render={ props => (
                  // @ts-ignore
                  <SettingsPage { ...props } { ...settingsTabProps } />
                ) }
              />
              {['/employee/:id', '/employee'].map(p => (
                <Route
                  path={ p }
                  key={ p }
                  render={ props => (
                    <EmployeePage
                      { ...props }
                      employeeService={ getService('Employee') }
                      appService={ getService('App') }
                      cartService={ getService('Cart') }
                      notificationService={ getService('Notification') }
                      travelPolicyService={ getService('TravelPolicy') }
                      travelPoliciesStore={
                        getService('TravelPolicy')
                          .travelPoliciesListStore
                      }
                      accountSettingsService={ getService(
                        'AccountSettings',
                      ) }
                      rightsStore={ getService('Employee').rightsStore }
                      simpleSignatureStore={
                        getService('Employee').simpleSignatureStore
                      }
                      formatService={ getService('Format') }
                      userSessionService={ getService('UserSession') }
                      workspaceService={ getService('Workspace') }
                      // @ts-ignore
                      chatService={ getService(this.chatName) }
                      // @ts-ignore
                      tripTagsService={ getService('TripTags') }
                      notepadService={ getService('Notepad') }
                      departmentsService={ getService('Departments') }
                      approvalSchemesService={ getService(
                        'ApprovalSchemes',
                      ) }
                      approvalERSchemesService={ getService(
                        'ApprovalERSchemes',
                      ) }
                      featureFlagsService={ getService('FeatureFlags') }
                      settingsService={ getService('Settings') }
                      productAnalyticsProtocol={ getProtocol(
                        'ProductAnalytics',
                      ) }
                      uiSettingsProtocol={ getProtocol('UiSettings') }
                    />
                  ) }
                />
              ))}
              <Route
                exact
                path={ ROUTES.TRAVEL_POLICY }
                render={ props => (
                  <TravelPolicyPage
                    { ...props }
                    travelPolicyService={ getService('TravelPolicy') }
                    workspaceService={ getService('Workspace') }
                    notificationService={ getService('Notification') }
                    userSessionService={ getService('UserSession') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                  />
                ) }
              />
              <Route
                exact
                path={ '/travel-policy-quiz/:id' }
                render={ props => (
                  // @ts-ignore
                  <TravelPolicyQuiz { ...props } />
                ) }
              />
              <Route
                exact
                path={ ROUTES.TRAVEL_POLICY_UNIQUE }
                render={ props => (
                  <TravelPolicyUnique
                    { ...props }
                    travelPolicyService={ getService('TravelPolicy') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    popupsService={ getService('Popups') }
                  />
                ) }
              />
              {[
                ROUTES.DEPARTMENT.NEW,
                `${ROUTES.DEPARTMENT.MAIN}/:id`,
              ].map(path => (
                <Route
                  exact
                  key={ path }
                  path={ path }
                  render={ props => (
                    <DepartmentPage
                      { ...props }
                      // @ts-ignore
                      departmentsService={ getService('Departments') }
                      workspaceService={ getService('Workspace') }
                      employeeService={ getService('Employee') }
                      notificationService={ getService('Notification') }
                      newDepartment={ path === ROUTES.DEPARTMENT.NEW }
                    />
                  ) }
                />
              ))}
              {this.renderAnalyticsAndCostCenters()}
              {this.renderApprovalScheme()}
              {this.renderApplicationSchemeCreate()}
              {this.renderApplicationSchemeEdit()}
              {this.renderApplicationSchemeRequests()}
              {this.renderApprovalExpenseReport()}
              <Route
                path={ ROUTES.QUALITY.GENERAL }
                render={ props => (
                  <QualityPage
                    { ...props }
                    store={ getService('Quality').store }
                    qualityService={ getService('Quality') }
                    notificationService={ getService('Notification') }
                    workspaceService={ getService('Workspace') }
                    uiSettingsService={ getService('UiSettings') }
                    uiSettingsProtocol={ getProtocol('UiSettings') }
                  />
                ) }
              />
              <Route
                path={ ROUTES.QUALITY.SUCCESS }
                render={ props => (
                  <QualityPage
                    { ...props }
                    store={ getService('Quality').store }
                    qualityService={ getService('Quality') }
                    notificationService={ getService('Notification') }
                    workspaceService={ getService('Workspace') }
                    uiSettingsService={ getService('UiSettings') }
                    uiSettingsProtocol={ getProtocol('UiSettings') }
                  />
                ) }
              />
              {this.renderFavorites()}
              <Route
                exact
                path='/search/airline'
                render={ props => (
                  <Search>
                    <AirlineSearchPage
                      { ...props }
                      airlineService={ getService('Airline') }
                      userSessionService={ getService('UserSession') }
                      workspaceService={ getService('Workspace') }
                      requestsService={ getService('Requests') }
                      travelApprovalService={ getService(
                        'TravelApproval',
                      ) }
                      featureFlagsService={ getService('FeatureFlags') }
                      smartdeskService={ getService('Smartdesk') }
                    />
                  </Search>
                ) }
              />
              <Route
                exact
                path='/search/airline/:guid'
                render={ props => (
                  <AirlineResultPage
                    { ...props }
                    airlineService={ getService('Airline') }
                    favoriteService={ getService('Favorites') }
                    notificationService={ getService('Notification') }
                    userSessionService={ getService('UserSession') }
                    workspaceService={ getService('Workspace') }
                    requestsService={ getService('Requests') }
                    popupsService={ getService('Popups') }
                    featureFlagsService={ getService('FeatureFlags') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    appService={ getService('App') }
                    popupsProtocol={ popupsProtocol }
                    uiSettingsProtocol={ getProtocol('UiSettings') }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                  />
                ) }
              />
              {serviceHtml}
              <Route
                path={ ROUTES.SEARCH.HOTEL }
                exact
                render={ props => (
                  <Search>
                    <HotelsSearchPage
                      { ...props }
                      featureFlagsService={ getService('FeatureFlags') }
                      hotelsService={ getService('Hotels') }
                      travelApprovalService={ getService(
                        'TravelApproval',
                      ) }
                      aggregationId={
                        getService('UiSettings').store.aggregationId
                      }
                      smartdeskService={ getService('Smartdesk') }
                    />
                  </Search>
                ) }
              />
              <Route
                path={ ROUTES.SEARCH.HOTELS_REGION }
                render={ props => (
                  // @ts-ignore
                  <HotelsRegionResult
                    { ...props }
                    hotelsService={ getService('Hotels') }
                    newHotelsService={ getService('NewHotels') }
                    favoriteService={ getService('Favorites') }
                    notificationService={ getService('Notification') }
                    userSessionService={ getService('UserSession') }
                    workspaceService={ getService('Workspace') }
                    isFavoriteLastPath={ this.isFavoriteLastPath() }
                    requestsService={ getService('Requests') }
                    appService={ getService('App') }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                    featureFlagsService={ getService('FeatureFlags') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    popupsService={ getService('Popups') }
                  />
                ) }
              />
              <Route
                path='/search/hotel/:hotelId'
                render={ props => (
                  // @ts-ignore
                  <HotelResultPage
                    { ...props }
                    hotelsService={ getService('Hotels') }
                    employeeService={ getService('Employee') }
                    chatService={ getService(this.chatName) }
                    notificationService={ getService('Notification') }
                    favoriteService={ getService('Favorites') }
                    workspaceService={ getService('Workspace') }
                    requestsService={ getService('Requests') }
                    sidePanelService={ getService('SidePanel') }
                    userSessionService={ getService('UserSession') }
                    featureFlagsService={ getService('FeatureFlags') }
                    appService={ getService('App') }
                    uiSettingsService={ getService('UiSettings') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    popupsService={ getService('Popups') }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                  />
                ) }
              />
              <Route
                path='/search/transfers/:guid'
                render={ props => (
                  <TransferResultPage
                    { ...props }
                    transferService={ getService('Transfer') }
                    userSessionService={ getService('UserSession') }
                    favoriteService={ getService('Favorites') }
                    notificationService={ getService('Notification') }
                    requestsService={ getService('Requests') }
                    featureFlagsService={ getService('FeatureFlags') }
                  />
                ) }
              />
              <Route
                path={ ROUTES.ADDITIONALS.MAIN }
                render={ props => (
                  <AirlineAdditionalServices
                    { ...props }
                    orderService={ getService('Order') }
                    breadCrumbsService={ getService('BreadCrumbs') }
                    vipHallService={ getService('VipHall') }
                    workspaceService={ getService('Workspace') }
                    // @ts-ignore
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    travelPolicyService={ getService('TravelPolicy') }
                    store={ getService('BreadCrumbs').store }
                    featureFlagsService={ getService('FeatureFlags') }
                    settingsService={ getService('Settings') }
                  >
                    <Switch>
                      {[
                        ROUTES.ADDITIONALS.VIP_HALL_RESULT,
                        ROUTES.ADDITIONALS.ESCORT_RESULT,
                      ].map(path => (
                        <Route
                          key={ path }
                          path={ path }
                          render={ propsChildren => (
                            // @ts-ignore
                            <VipHallResult
                              { ...propsChildren }
                              vipHallService={ getService('VipHall') }
                              notificationService={ getService(
                                'Notification',
                              ) }
                              orderService={ getService('Order') }
                              breadCrumbsService={ getService(
                                'BreadCrumbs',
                              ) }
                            />
                          ) }
                        />
                      ))}
                      {[
                        ROUTES.ADDITIONALS.VIP_HALL_ORDER,
                        ROUTES.ADDITIONALS.ESCORT_ORDER,
                      ].map(path => (
                        <Route
                          key={ path }
                          path={ path }
                          render={ propsChildren => (
                            <VipHallOrder
                              { ...propsChildren }
                              vipHallService={ getService('VipHall') }
                              orderService={ getService('Order') }
                              breadCrumbsService={ getService(
                                'BreadCrumbs',
                              ) }
                              workspaceService={ getService(
                                'Workspace',
                              ) }
                            />
                          ) }
                        />
                      ))}
                      {[
                        ROUTES.ADDITIONALS.PAYMENT_VIP,
                        ROUTES.ADDITIONALS.PAYMENT_ESCORT,
                      ].map(path => (
                        <Route
                          key={ path }
                          path={ path }
                          render={ propsChildren => (
                            <VipHallFormPayment
                              { ...propsChildren }
                              vipHallService={ getService('VipHall') }
                              travelPolicyService={ getService(
                                'TravelPolicy',
                              ) }
                              orderService={ getService('Order') }
                              notificationService={ getService(
                                'Notification',
                              ) }
                              breadCrumbsService={ getService(
                                'BreadCrumbs',
                              ) }
                            />
                          ) }
                        />
                      ))}
                      {[
                        ROUTES.ADDITIONALS.VIP_HALL,
                        ROUTES.ADDITIONALS.ESCORT,
                      ].map(path => (
                        <Route
                          key={ path }
                          path={ path }
                          render={ propsChildren => (
                            // @ts-ignore
                            <VipHallSelectRoute
                              { ...propsChildren }
                              orderService={ getService('Order') }
                              breadCrumbsService={ getService(
                                'BreadCrumbs',
                              ) }
                              vipHallService={ getService('VipHall') }
                            />
                          ) }
                        />
                      ))}
                      <Route
                        path={ ROUTES.ADDITIONALS.OTHER }
                        render={ propsChildren => (
                          <AirlineAdditionalChatRequest
                            { ...propsChildren }
                            chatService={ getService(this.chatName) }
                            workspaceService={ getService('Workspace') }
                            productAnalyticsProtocol={ getProtocol(
                              'ProductAnalytics',
                            ) }
                            breadCrumbsService={ getService(
                              'BreadCrumbs',
                            ) }
                            orderService={ getService('Order') }
                          />
                        ) }
                      />
                      <Route
                        path={ ROUTES.ADDITIONALS.FLIGHT_CERTIFICATE }
                        render={ propsChildren => (
                          <AirlineAdditionalFightCertificate
                            { ...propsChildren }
                            chatService={ getService(this.chatName) }
                            workspaceService={ getService('Workspace') }
                            // @ts-ignore
                            productAnalyticsProtocol={ getProtocol(
                              'ProductAnalytics',
                            ) }
                            breadCrumbsService={ getService(
                              'BreadCrumbs',
                            ) }
                            orderService={ getService('Order') }
                            userSessionService={ getService(
                              'UserSession',
                            ) }
                            settingsStore={
                              getService('Settings').settingsStore
                            }
                          />
                        ) }
                      />
                      <Route
                        path={ ROUTES.ADDITIONALS.TERMINAL }
                        render={ propsChildren => (
                          <VipHallSelectTerminal
                            { ...propsChildren }
                            breadCrumbsService={ getService(
                              'BreadCrumbs',
                            ) }
                            vipHallService={ getService('VipHall') }
                          />
                        ) }
                      />
                      <Route
                        path={ ROUTES.ADDITIONALS.DIRECTION }
                        render={ propsChildren => (
                          <VipHallSelectDirection
                            { ...propsChildren }
                            breadCrumbsService={ getService(
                              'BreadCrumbs',
                            ) }
                            vipHallService={ getService('VipHall') }
                          />
                        ) }
                      />
                    </Switch>
                  </AirlineAdditionalServices>
                ) }
              />
              <Route
                path={ ROUTES.SEARCH.TRAIN }
                render={ props => (
                  <Search>
                    {/* @ts-ignore */}
                    <TrainsSearchPage
                      { ...props }
                      featureFlagsService={ getService('FeatureFlags') }
                      trainsService={ getService('Trains') }
                      travelApprovalService={ getService(
                        'TravelApproval',
                      ) }
                      smartdeskService={ getService('Smartdesk') }
                    />
                  </Search>
                ) }
              />
              <Route
                path={ ROUTES.SEARCH.TRAIN_RESULTS }
                render={ props => (
                  // @ts-ignore
                  <TrainsResultWrap
                    { ...props }
                    trainsService={ getService('Trains') }
                    favoritesService={ getService('Favorites') }
                    notificationService={ getService('Notification') }
                    userSessionService={ getService('UserSession') }
                    workspaceService={ getService('Workspace') }
                    isFavoriteLastPath={ this.isFavoriteLastPath() }
                    requestsService={ getService('Requests') }
                    appService={ getService('App') }
                    featureFlagsService={ getService('FeatureFlags') }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    popupsService={ getService('Popups') }
                  />
                ) }
              />
              <Route
                exact
                path='/search/train/:searchId/:trainId'
                render={ props => (
                  // @ts-ignore
                  <TrainResultPage
                    { ...props }
                    orderService={ getService('Order') }
                    trainsService={ getService('Trains') }
                    favoritesService={ getService('Favorites') }
                    notificationService={ getService('Notification') }
                    requestsService={ getService('Requests') }
                    qaAttrCartNotification={
                      // @ts-ignore
                      QA_ATTRIBUTES.search.buttonCartNotification
                    }
                    qaAttrs={ QA_ATTRIBUTES.train.result }
                    featureFlagsService={ getService('FeatureFlags') }
                  >
                    {/* @ts-ignore */}
                    <TrainPage
                      { ...props }
                      trainsService={ getService('Trains') }
                      userSessionService={ getService('UserSession') }
                      workspaceService={ getService('Workspace') }
                      appService={ getService('App') }
                    />
                  </TrainResultPage>
                ) }
              />
              {[
                '/search/train/:searchId/:trainId/car/:number/:carId',
                '/search/train/:searchId/:trainId/car/:number/:carId/:service',
              ].map(path => (
                <Route
                  key={ path }
                  exact
                  path={ path }
                  render={ props => (
                    // @ts-ignore
                    <TrainResultPage
                      { ...props }
                      isCarPage
                      trainsService={ getService('Trains') }
                      orderService={ getService('Order') }
                      favoritesService={ getService('Favorites') }
                      notificationService={ getService('Notification') }
                      requestsService={ getService('Requests') }
                      featureFlagsService={ getService('FeatureFlags') }
                      qaAttrs={ QA_ATTRIBUTES.train.current }
                      qaAttrCartNotification={
                        QA_ATTRIBUTES.train.current.notifications.buy
                      }
                    >
                      {/* @ts-ignore */}
                      <TrainCarPage
                        { ...props }
                        trainsService={ getService('Trains') }
                        userSessionService={ getService('UserSession') }
                        workspaceService={ getService('Workspace') }
                        featureFlagsService={ getService(
                          'FeatureFlags',
                        ) }
                        appService={ getService('App') }
                      />
                    </TrainResultPage>
                  ) }
                />
              ))}
              <Route
                exact
                path='/cart/:id'
                render={ props => (
                  <SpecificCartPage
                    { ...props }
                    airlineSeatsService={ getService('AirlineSeats') }
                    airlineSeatsStore={
                      getService('AirlineSeats').store
                    }
                    airlineBaggageService={ getService(
                      'AirlineBaggage',
                    ) }
                    airlineBaggageStore={
                      getService('AirlineBaggage').store
                    }
                    airlineAdditionalServices={ getService(
                      'AirlineAdditionalServices',
                    ) }
                    airlineAdditionalServicesStore={ getService(
                      'AirlineAdditionalServices',
                    ).store }
                    formatService={ getService('Format') }
                    cartService={ getService('Cart') }
                    transferService={ getService('Transfer') }
                    trainsService={ getService('Trains') }
                    workspaceService={ getService('Workspace') }
                    userSessionService={ getService('UserSession') }
                    notificationService={ getService('Notification') }
                    tripTagsService={ getService('TripTags') }
                    hotelsService={ getService('Hotels') }
                    sidePanelService={ getService('SidePanel') }
                    featureFlagsService={ getService('FeatureFlags') }
                    accountSettingsService={ getService(
                      'AccountSettings',
                    ) }
                    customAnalyticsService={ getService(
                      'CustomAnalytics',
                    ) }
                    metricsService={ getService('Metrics') }
                    appService={ getService('App') }
                    cloudPaymentService={ getService(
                      'CloudPayment',
                    ) }
                    aggregationId={
                      getService('UiSettings').store.aggregationId
                    }
                  />
                ) }
              />
              {this.renderTravelersPage()}
              <Route
                exact
                path={ ROUTES.OFFER }
                render={ () => <PageOffer /> }
              />
              <Route
                exact
                path={ ROUTES.OFFER_SMARTAGENT }
                render={ () => <PageOfferSmartAgent /> }
              />
              {this.renderApprovePage()}
              {this.renderApproveTravelPage()}
              {this.renderApproveExpenseReportPage()}
              {taxiHtml}
              <Redirect
                from='/settings'
                to='/settings/employees/all'
              />
              <Route
                path={ ROUTES.NO_MATCH }
                render={ () => <Redirect to='/' /> }
              />
            </Switch>
          </div>
        </ErrorBoundary>
        {this.renderChat()}
        <NotificationsLayout />
        {/* Пока что все существующие баннеры скрываются, по надобности раскомментить */}
        {/* <BannersLayout
          workspaceService={ getService('Workspace') }
          bannersService={ getService('Banners') }
          uiSettingsService={ getService('UiSettings') }
          showTaxi={ getService('UiSettings').store.settings.showTaxi }
          accountSettings={ getService('AccountSettings') }
          store={ getService('Banners').store }
          popupsStore={ getService('Popups').store }
        /> */}
        <UserPopupsLayout
          // @ts-ignore
          store={ getService('Popups').store }
        />
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(MainLayout);
