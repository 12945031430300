import { Moment } from 'moment';
import { getText } from '../../../../../i18n';

import Store from '../../../store';
import EMPLOYEEACTION from '../action';

import { mainInputLanguage, validateField, validateValue } from '../utils/validateDocumentField';
import getInvalidDocumentsTypes from '../utils/getInvalidDocumentsTypes';
import transliterate from '../../../utils/transliterate';
import { getMoment, isMoment, momentObject } from '../../../utils/formatDate';
import { isEmail, isNumeric } from '../../../utils/validation';
import { checkTwoForeignPassports, employeeMayHavePassport } from '../../../utils/employees';

import {
  CHARSDOUBLESPACEHYPHEN,
  INTEGER_NUMBER_SIX_SYBMOL,
  RUCHARSDOUBLESPACEHYPHEN,
} from '../../../constants/regExp';
import PASSPORTS from '../../../constants/passport';
import DOCUMENTSFIELDS, { IFieldUser } from '../../../constants/documentsFields';
import { SERVICETYPE } from '../../../constants/serviceType';
import STATUS, { EMPLOYEEFIELD, LANGUAGES } from '../../../constants/employee';

import {
  BonusesType,
  IDepartments,
  IDocsNames,
  IDocumentUser,
  IEmployee,
  IEmployeKey,
  IErrorsValidate,
  IPassportError,
  IPassportUser,
  IPassportView,
  IValidateErrors,
  TCitizenship,
} from '../types';

const ERRORS = {
  EMPTY: getText('services:employee.store.employee.empty'),
  ONLYRUSPACEANDHYPHEN: getText('services:employee.store.employee.onlyRuAndHyphen'),
  ONLYCHARSSPACEANDHYPHEN: getText('services:employee.store.employee.onlyCharsSpaceAndHyphen'),
  ONLYRU: getText('services:employee.store.employee.onlyRu'),
  EMAILFORMAT: getText('services:employee.store.employee.emailFormat'),
  PHONELENGTH: getText('services:employee.store.employee.phoneLength'),
  PHONEFORMAT: getText('services:employee.store.employee.phoneFormat'),
  EMPTYCODE: getText('services:employee.store.employee.emptyCode'),
  EMPTYBITHDAY: getText('services:employee.store.employee.emptyBirthday'),
  WRONGBIRTHDAY: getText('services:employee.store.employee.wrongBirthday'),
  EMPLOYEE_NUMBER_EMPTY: getText('services:employee.store.employee.employeeNumberEmpty'),
  EMPLOYEE_NUMBER_SIX_DIGITS: getText('services:employee.store.employee.employeeNumberSixDigits'),
};

const DEFAULTEMPLOYEE = () => ({
  Id: 0,
  Companies: [],
  Sex: '',
  Surname: '',
  Name: '',
  Patronymic: '',
  MobilePhone: '',
  EmailEditable: true,
  Email: '',
  Birthday: '',
  Underage: false,
  Code: 'RU',
  Code3: 'RUS',
  Bonuses: [],
  Documents: [],
  Status: '',
  TpRights: {
    Id: '',
    PolicyId: '',
  },
  EmployeeNumber: '',
  EmployeeNumSixDig: '',
  ProjectId: null,
  employeeHasCyrillicDomesticPassport: 0,
});

const defaultModule = () => ({
  loading: true,
  title: getText('services:employee.store.employee.title'),
  employee: { ...DEFAULTEMPLOYEE() },
  employeePrevious: { ...DEFAULTEMPLOYEE() },
  isValid: false,
  readonlyLogin: false,
  rightsFetched: false,
  userIdentityFetched: false,
  maxBirthDate: getMoment().add(-18, 'y'),
  currentDate: getMoment(),
  bonus: {
    Company: null,
    Code: '',
  },
  chosenTP: '',
  citizenshipSuggestions: {
    label: getText('services:employee.store.employee.russia'),
    selected: {},
    suggestions: [],
  },
  errors: {
    Name: '',
    Surname: '',
    MobilePhone: '',
    Patronymic: '',
    Email: '',
    Code: '',
    Birthday: '',
    Login: '',
    Sex: '',
    TravelPolicy: '',
    EmployeeNumber: '',
    EmployeeNumSixDig: '',
    Documents: [],
  },
  requiredFieldsErrors: {
    empty: [],
    emptyDocuments: [],
  },
  isTgLinked: null,
  // флаг дает понять что стор используется на другой странице
  isChangeAirTripPage: false,
});

const DOCUMENT_MODEL = {
  Id: 0,
  Type: '',
  Number: '',
  Surname: '',
  Name: '',
  Patronymic: '',
  DueDate: '',
  LastName: '',
  FirstName: '',
  MiddleName: '',
  NoPatronymic: false,
};

const ERRORS_DOCUMENT_MODEL = {
  Number: '',
  Surname: '',
  Name: '',
  Patronymic: '',
  DueDate: '',
  LastName: '',
  FirstName: '',
  MiddleName: '',
  TravelPolicy: '',
  EmployeeNumber: '',
  EmployeeNumSixDig: '',
};

const VALID_AMOUNT_SYMBOL = {
  SIX: 6,
};

const genPassport = (type: string) => ({
  ...DOCUMENT_MODEL,
  Type: type,
});

const resetDocs = (
  type: string,
  { Surname, Name, Patronymic, LastName, FirstName, MiddleName }: IDocsNames,
) => ({
  ...DOCUMENT_MODEL,
  Type: type,
  Surname,
  Name,
  Patronymic,
  LastName,
  FirstName,
  MiddleName,
});

const genErrorsPassport = () => ({
  ...ERRORS_DOCUMENT_MODEL,
});

const checkRuPassport = (
  type: string,
  currentType: {
    DomesticPassport: string,
  },
  code: string,
) => type === currentType.DomesticPassport && code === 'RU';

const checkEmployeeUnderAge = (date: Moment) => getMoment().diff(date, 'years') < 18;

const canEmployeeHavePassport = (date: Moment) => getMoment().diff(date, 'years') >= 14;

const updateRuPassport = (value: string) => {
  const result = value.trim().replace(/\s+/g, '').replace(/_+/g, '');

  if (result.length <= 10 && (result.length === 0 || isNumeric(result))) {
    return result;
  }

  return null;
};

const updateCertificateNumber = (value: string) => value;

const updatePassportNumber = (
  value: string,
  number: string,
  type: string,
  documentType: { ForeignPassport: string },
) => {
  const result = value.trim().replace(/\s+/g, '');

  if (type === documentType.ForeignPassport && result.length <= 9) {
    return result;
  }

  if (
    type !== documentType.ForeignPassport &&
    result.length <= 10 &&
    (result.length === 0 || isNumeric(result))
  ) {
    return result;
  }

  return number;
};

const validateDocumentInput = (
  document: IPassportUser,
  field: keyof IFieldUser,
  code: string,
  documentType: IPassportView,
  isDoubleDoc = false,
) => {
  if (checkRuPassport(document.Type, documentType, code)) {
    return validateField.RuPassport(document, field, code, isDoubleDoc);
  }

  const dType = document.Type as keyof typeof validateField;

  return validateField[dType](document, field, code, isDoubleDoc);
};

const validationInput = (
  employee: IEmployee,
  field: string,
  employeeNumberFlag: boolean,
  employeeNumSixDigFlag: boolean,
) => {
  let errorMsg = '';

  switch (field) {
    case EMPLOYEEFIELD.SURNAME:
    case EMPLOYEEFIELD.NAME: {
      if ((employee[field as keyof IEmployee] as string).trim() === '') {
        errorMsg = ERRORS.EMPTY;
      }

      if (!CHARSDOUBLESPACEHYPHEN.test((employee[field as keyof IEmployee] as string))) {
        errorMsg = ERRORS.ONLYCHARSSPACEANDHYPHEN;
      }

      break;
    }

    case EMPLOYEEFIELD.EMAIL: {
      if ((employee[field as keyof IEmployee] as string).trim() === '' || !isEmail((employee[field as keyof IEmployee] as string))) {
        errorMsg = ERRORS.EMAILFORMAT;
      }

      break;
    }

    case EMPLOYEEFIELD.PATRONYMIC: {
      const value = (employee[field as keyof IEmployee] as string).trim();

      if (value && !RUCHARSDOUBLESPACEHYPHEN.test(value)) {
        errorMsg = ERRORS.ONLYRU;
      }

      break;
    }

    case EMPLOYEEFIELD.EMPLOYEE_NUMBER: {
      const value = (employee[field as keyof IEmployee] as string).trim();
      const conditionSixChar = value.length === VALID_AMOUNT_SYMBOL.SIX;
      const isInteger = INTEGER_NUMBER_SIX_SYBMOL.test(value);

      if (employeeNumberFlag && value === '') {
        errorMsg = ERRORS.EMPLOYEE_NUMBER_EMPTY;
      }

      if (employeeNumSixDigFlag && (!conditionSixChar || (conditionSixChar && !isInteger))) {
        errorMsg = ERRORS.EMPLOYEE_NUMBER_SIX_DIGITS;
      }

      break;
    }

    case EMPLOYEEFIELD.CODE: {
      if (!employee.Code.length) {
        errorMsg = ERRORS.EMPTYCODE;
      }

      break;
    }

    case EMPLOYEEFIELD.BIRTHDAY: {
      const date = (employee[field as keyof IEmployee] as Moment);

      if (!date) {
        errorMsg = ERRORS.EMPTYBITHDAY;
      }

      if (date && isMoment(date) && Number(date.format('YYYY')) < 1900) {
        errorMsg = ERRORS.WRONGBIRTHDAY;
      }

      break;
    }
  }

  return errorMsg;
};
const clearBonus = (type: string) => {
  let result = null;

  if (type === SERVICETYPE.TRAIN) {
    result = {
      Id: 0,
      Type: type,
      Code: '',
      Number: '',
    };
  } else if (type === SERVICETYPE.AIR) {
    result = {
      Id: 0,
      Type: type,
      Code: '',
      Number: '',
      airlineSuggestions: {
        label: '',
        selected: {},
        suggestions: [],
      },
    };
  }

  return result;
};

const getEmptyRequiredFields = (
  employee: IEmployee,
  travelPolicyFlag: boolean,
  employeeNumberFlag: boolean,
) => {
  const list: string[] = [];

  Object.keys(EMPLOYEEFIELD).forEach((prop) => {
    const propValue = EMPLOYEEFIELD[prop];
    // @ts-ignore
    const employeeValue = employee[propValue];

    let valid: boolean;

    switch (propValue) {
      case EMPLOYEEFIELD.BIRTHDAY: {
        const birthDate = typeof employeeValue === 'string' ? momentObject(employeeValue) : employeeValue;

        valid = birthDate.isValid();

        break;
      }
      case EMPLOYEEFIELD.TRAVEL_POLICY: {
        valid = travelPolicyFlag ? !!employeeValue.PolicyId : true;

        break;
      }
      case EMPLOYEEFIELD.PATRONYMIC: {
        valid = true;

        break;
      }
      case EMPLOYEEFIELD.EMPLOYEE_NUMBER: {
        valid = employeeNumberFlag ? employeeValue.trim().length : true;

        break;
      }
      default: {
        valid = employeeValue.trim().length;

        break;
      }
    }

    if (!valid) {
      list.push(EMPLOYEEFIELD[prop]);
    }
  });

  return list;
};

const validateDocument = (documents: IPassportUser[], documentType: string) => {
  const intDocuments = documents.filter(({ Type }: { Type: string }) => Type === documentType);
  const document = intDocuments[intDocuments.length - 1];
  const currentCode = document.Number.trim().replace(/\s+/g, '');

  return intDocuments.length === 1
    && document.Number.length > 0
    && document.Number.length === currentCode.length
    && document.Surname.length > 0
    && document.Name.length > 0
    && ((document.Type === 'ForeignPassport' && document.DueDate) || document.Type === 'DomesticPassport');
};

const validateErrors = ({
  Documents,
  Surname,
  Name,
  MobilePhone,
  Email,
  Code,
  Birthday,
  Login,
  Patronymic,
  TravelPolicy,
  EmployeeNumber,
}: IValidateErrors) => {
  let errorsInDocuments = false;

  Documents.forEach((document: IEmployeKey) => {
    Object.keys(document).forEach((field) => {
      if (document[field].length) {
        errorsInDocuments = true;
      }
    });
  });

  return !Surname.length
    && !Name.length
    && !MobilePhone.length
    && !Email.length
    && !Code.length
    && !Birthday.length
    && !Login.length
    && !Patronymic.length
    && !TravelPolicy.length
    && !EmployeeNumber.length
    && !errorsInDocuments;
};

const validateAllForm = (
  employee: IEmployee,
  errors: IErrorsValidate,
  documentType: IPassportView,
  needTravelPolicy = false,
  needEmployeeNumber = false,
  employeeNumSixDigFlag = false,
  isChangeAirTripPage = false,
) => {
  const {
    Birthday,
    Code,
    Documents,
    Name,
    Surname,
    MobilePhone,
    Email,
    Sex,
    TpRights,
    EmployeeNumber,
  } = employee;
  const { Login } = errors;

  const mayHavePassport = employeeMayHavePassport(Birthday);

  let isValid = false;

  const birthDate = typeof employee.Birthday === 'string' ? momentObject(Birthday) : Birthday as Moment;

  const accessRights = errors ? Login.length === 0 : true;
  const travelPolicy = needTravelPolicy ? !!TpRights.PolicyId : true;
  const employeeNumber = needEmployeeNumber ? !!EmployeeNumber.length : true;
  const employeeNumberSix = employeeNumSixDigFlag ? EmployeeNumber.length > 5 : true;
  const invalidDocumentsTypes = getInvalidDocumentsTypes(Code, Documents, documentType, mayHavePassport, isChangeAirTripPage);

  const isErrors = validateErrors(errors);

  if (Surname.trim().length
    && Name.trim().length
    && MobilePhone.trim().length
    && (Email.trim().length && isEmail(Email))
    && Code.length
    && birthDate.isValid()
    && Sex.length
    && !invalidDocumentsTypes.length
    && accessRights
    && travelPolicy
    && employeeNumber
    && employeeNumberSix
    && isErrors) {
    isValid = true;
  }

  return {
    isValid,
    invalidDocumentsTypes,
  };
};

const newBonus = (state: any[], type: string) => state.push(clearBonus(type));

const newDocument = (state: IDocumentUser[], type: string) => state.push(genPassport(type) as IDocumentUser);

const newErrorsDocuments = (state: IDocumentUser[] | any) => state.push(genErrorsPassport());

const toCheckRusLanguage = (value: string) => {
  if (value.length > 0 && RUCHARSDOUBLESPACEHYPHEN.test(value)) {
    return value;
  }

  return '';
};

const reducer = ({ type, payload }: { type: string, payload: any }, state: any) => {
  let newState = { ...state };
  switch (type) {
    case EMPLOYEEACTION.EMPLOYEESET: {
      const {
        employee,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;

      newState.employee = {
        ...defaultModule().employee,
        ...employee,
        Companies: employee
          ? employee.Companies.map(({ CompanyId, Departments = [] }: { CompanyId: number, Departments: IDepartments [] }) => ({
            main: CompanyId, nested: Departments.map(({ Id }: { Id: number }) => Id) }))
          : [],
        Underage: employee ? checkEmployeeUnderAge(employee.Birthday) : false,
      };
      newState.employeePrevious = {
        ...defaultModule().employee,
        ...employee,
      };

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEELOAD: {
      newState.loading = true;
      break;
    }

    case EMPLOYEEACTION.EMPLOYEESTOPLOAD: {
      newState.loading = false;
      break;
    }

    case EMPLOYEEACTION.EMPLOYEEVALIDATEREQUIREDFIELDS: {
      newState.requiredFieldsErrors.empty = getEmptyRequiredFields(
        newState.employee,
        payload.travelPolicyFlag,
        payload.employeeNumberFlag,
      );
      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATE: {
      const documentType = payload;
      const documents = newState.employee.Documents;
      const bonuses = newState.employee.Bonuses;
      const errors = newState.errors.Documents;
      const code = newState.employee.Code;

      if (documents.length === 0) {
        if (code === 'RU') {
          newDocument(documents, documentType.BirthCertificate);
          newDocument(documents, documentType.DomesticPassport);
          newDocument(documents, documentType.ForeignPassport);

          newErrorsDocuments(errors);
          newErrorsDocuments(errors);
          newErrorsDocuments(errors);
        } else {
          newDocument(documents, documentType.DomesticPassport);
          newErrorsDocuments(errors);
        }
      } else {
        if (documents.length !== errors.length) {
          newState.employee.Documents = newState.employee.Documents.map((item: IDocumentUser) => {
            newErrorsDocuments(errors);

            return ({
              ...item,
            });
          });
        }

        if (code === 'RU') {
          const ruDocuments = newState.employee.Documents.filter(({ Type }: { Type: string }) => Type === documentType.DomesticPassport).length;
          const notRuDocuments = newState.employee.Documents.filter(({ Type }: { Type: string }) => Type === documentType.ForeignPassport).length;

          if (!ruDocuments) {
            newDocument(newState.employee.Documents, documentType.DomesticPassport);
            newErrorsDocuments(errors);
          }

          if (!notRuDocuments) {
            newDocument(newState.employee.Documents, documentType.ForeignPassport);
            newErrorsDocuments(errors);
          }
        }
      }

      if (bonuses.length === 0) {
        newBonus(bonuses, SERVICETYPE.TRAIN);
        newBonus(bonuses, SERVICETYPE.AIR);
      } else {
        newState.employee.Bonuses = bonuses.map((item: BonusesType) => {
          let result = null;

          if (item.Type === SERVICETYPE.TRAIN) {
            result = { ...item };
          } else if (item.Type === SERVICETYPE.AIR) {
            result = {
              ...item,
              airlineSuggestions: {
                label: item.Name || '',
                selected: item || {},
                suggestions: [],
              },
            };
          }

          return result;
        });

        const trainBonuses = newState.employee.Bonuses.filter(({ Type }: { Type: string }) => Type === SERVICETYPE.TRAIN).length;
        const airBonuses = newState.employee.Bonuses.filter(({ Type }: { Type: string }) => Type === SERVICETYPE.AIR).length;

        if (!trainBonuses) {
          newBonus(newState.employee.Bonuses, SERVICETYPE.TRAIN);
        }

        if (!airBonuses) {
          newBonus(newState.employee.Bonuses, SERVICETYPE.AIR);
        }
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEVALIDATECOMMONINFOREQUIREDFIELDS: {
      const { errors, employee } = newState;
      const { documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag } = payload;
      const fieldsToBeChecked = ['Code', 'MobilePhone', 'Email', 'Name', 'Surname', 'Birthday'];
      const requiredEmptyFields = ['Name', 'Surname', 'Email'];

      if (requiredEmptyFields.some(field => employee[field] && employee[field].trim().length)) {
        fieldsToBeChecked.forEach((field) => {
          newState.errors[field] = validationInput(employee, field, employeeNumberFlag, employeeNumSixDigFlag);
        });

        const { isValid, invalidDocumentsTypes } = validateAllForm(
          employee,
          errors,
          documentType,
          travelPolicyFlag,
          employeeNumberFlag,
          employeeNumSixDigFlag,
          newState.isChangeAirTripPage,
        );
        newState.isValid = isValid;
        newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEVALIDATERUSSIANPASSPORTREQUIREDFIELDS: {
      const { documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag } = payload;

      const ruPassport = newState.employee.Documents.find(({ Type }: { Type: string }) => Type === documentType.DomesticPassport);
      const ruPassportIndex = newState.employee.Documents.findIndex(({ Type }: { Type: string }) => Type === documentType.DomesticPassport);

      if (ruPassport &&
        (ruPassport.Name.trim().length || ruPassport.Surname.trim().length || ruPassport.Number.trim().length)) {
        const fieldsToBeChecked = [
          DOCUMENTSFIELDS.NAME,
          DOCUMENTSFIELDS.SURNAME,
          DOCUMENTSFIELDS.NUMBER,
        ];
        const code = newState.employee.Code;

        fieldsToBeChecked.forEach((field) => {
          newState.errors.Documents[ruPassportIndex][field] = validateDocumentInput(ruPassport, field as keyof IFieldUser, code, documentType);
        });
      }

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATEINPUT: {
      const {
        field,
        value,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;

      const isBirthdayField = field === EMPLOYEEFIELD.BIRTHDAY;
      const isUnderage = isBirthdayField && checkEmployeeUnderAge(value);

      const previousName = state.employee.Name;
      const previousSurname = state.employee.Surname;
      const previousPatronymic = state.employee.Patronymic;

      if (isBirthdayField) {
        newState.employee.Underage = isUnderage;

        // blyat prosto ybeite
        if (value) {
          newState.employee.Documents = newState.employee.Documents.map((d: IDocumentUser) => {
            if (!canEmployeeHavePassport(value) && d.Type === documentType.DomesticPassport) {
              return resetDocs(documentType.DomesticPassport, d);
            }

            if (canEmployeeHavePassport(value) && d.Type === documentType.BirthCertificate) {
              return resetDocs(documentType.BirthCertificate, d);
            }

            return d;
          });
        }
      }

      newState.employee[field] = value;
      newState.errors[field] = validationInput(newState.employee, field, employeeNumberFlag, employeeNumSixDigFlag);

      if (newState.employee.Code === 'RU' && Object.values(DOCUMENTSFIELDS).includes(field)) {
        const documents = newState.employee.Documents.filter((d: IDocumentUser) =>
          d.Type === documentType.DomesticPassport || d.Type === documentType.BirthCertificate);

        // просто убейте меня пожалуйста, any из-за строчки ниже
        documents.forEach((document: any) => {
          // eslint-disable-next-line no-param-reassign
          document[field] = toCheckRusLanguage(newState.employee[field]);

          if (document.Type === documentType.DomesticPassport) {
            switch (field) {
              case EMPLOYEEFIELD.SURNAME: {
                if (previousSurname !== value || document.LastName === '') {
                  // eslint-disable-next-line no-param-reassign
                  document.LastName = transliterate(value);
                }

                break;
              }
              case EMPLOYEEFIELD.NAME: {
                if (previousName !== value || document.FirstName === '') {
                  // eslint-disable-next-line no-param-reassign
                  document.FirstName = transliterate(value);
                }

                break;
              }

              case EMPLOYEEFIELD.PATRONYMIC: {
                if (previousPatronymic !== value || document.MiddleName === '') {
                  // eslint-disable-next-line no-param-reassign
                  document.MiddleName = transliterate(value);
                }

                break;
              }
            }
          }
        });
      }

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEETRANSLITERATEPATRONYMIC: {
      const { Patronymic, Code } = newState.employee;
      const transliteratePatronymic = (passport: IPassportUser, patronymic: string) => {
        const middleName = passport.MiddleName;
        const passportPatronymic = passport.Patronymic;

        if (middleName) {
          return middleName;
        }

        if (passportPatronymic) {
          return transliterate(passportPatronymic);
        }

        if (patronymic) {
          return transliterate(patronymic);
        }

        return '';
      };

      const returnPatronymic = (passport: IPassportUser, patronymic: string) => {
        const passportPatronymic = passport.Patronymic;

        if (passportPatronymic) {
          return passportPatronymic;
        }

        if (patronymic && /^[А-ЯЁа-яё]*$/.test(patronymic)) {
          return patronymic;
        }

        return '';
      };

      const ruPassport = newState.employee.Documents.find(({ Type }: { Type: string }) => checkRuPassport(Code, payload.documentType, Type));

      const passportFields = [
        DOCUMENTSFIELDS.NAME,
        DOCUMENTSFIELDS.SURNAME,
        DOCUMENTSFIELDS.NUMBER,
      ];

      if (ruPassport && passportFields.every(field => ruPassport[field] && ruPassport[field].trim().length)) {
        ruPassport.MiddleName = transliteratePatronymic(ruPassport, Patronymic);
        ruPassport.Patronymic = returnPatronymic(ruPassport, Patronymic);
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEDOCUMENTSUPDATEINPUT: {
      const {
        field,
        value,
        index,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;

      const { employee: { employeeHasCyrillicDomesticPassport: cyrillicPassportId } } = newState;
      const documentsList = newState.employee.Documents;
      const document = newState.employee.Documents[index];
      const errorDocument = newState.errors.Documents[index];

      const documentName = document.Name;
      const documentSurname = document.Surname;
      const documentPatronymic = document.Patronymic;

      const foreigner = newState.employee.Code !== LANGUAGES.RU;

      let mayLatinish = true;
      let mayCyrillic = true;
      let languages: string[][] = [];

      languages = documentsList.map(({ Surname, Name, Patronymic }: { Surname: string, Name: string, Patronymic: string }) => [
        mainInputLanguage.Surname(Surname),
        mainInputLanguage.Name(Name),
        mainInputLanguage.Patronymic(Patronymic),
      ]);

      if (documentsList.length > 1 && cyrillicPassportId) {
        mayCyrillic = document.Id === cyrillicPassportId;
      }

      if (documentsList.length > 1 && cyrillicPassportId === 0 && !foreigner) {
        documentsList.forEach((documentIndex: number) => {
          if (documentIndex !== index) {
            const language = Object.values(languages[documentIndex] || []);

            mayCyrillic = !language.includes(LANGUAGES.RU);
            mayLatinish = !language.includes(LANGUAGES.EN);
          }
        });
      }

      const isRuPassport = checkRuPassport(document.Type, documentType, newState.employee.Code);

      const dType = document.Type as keyof typeof validateValue;

      errorDocument[field] = isRuPassport
        ? validateValue.RuPassport(field, value)
        : validateValue[dType](
          field,
          value,
          false,
          mayCyrillic,
          languages[index],
          mayLatinish,
        );

      document[field] = value;
      document.mayCyrillic = mayCyrillic;

      switch (field) {
        case EMPLOYEEFIELD.SURNAME: {
          if (documentSurname !== value || document.LastName === '') {
            document.LastName = transliterate(value);
          }

          break;
        }
        case EMPLOYEEFIELD.NAME: {
          if (documentName !== value || document.FirstName === '') {
            document.FirstName = transliterate(value);
          }

          break;
        }
        case EMPLOYEEFIELD.PATRONYMIC: {
          if (documentPatronymic !== value || document.MiddleName === '') {
            document.MiddleName = transliterate(value);
          }

          break;
        }
      }

      if (!document.FirstName && EMPLOYEEFIELD.NAME === field) {
        document.FirstName = value;
      }

      if (!document.LastName && EMPLOYEEFIELD.SURNAME === field) {
        document.LastName = value;
      }

      if (!document.MiddleName && EMPLOYEEFIELD.PATRONYMIC === field) {
        document.MiddleName = value;
      }

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.CHANGE_NO_PATRONYMIC_VALUE: {
      const { documentType, index } = payload;

      const document = newState.employee.Documents[index];

      document.NoPatronymic = !document.NoPatronymic;
      document.MiddleName = '';
      document.Patronymic = '';

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.NOT_VALIDATION_PATRONYMIC: {
      newState = {
        ...state,
        isChangeAirTripPage: true,
      };

      break;
    }

    case EMPLOYEEACTION.RESET_NOT_VALIDATION_PATRONYMIC: {
      newState = {
        ...state,
        isChangeAirTripPage: false,
      };

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATERUPASSPORT: {
      const {
        value,
        index,
        documentType,
        isDoubleDoc,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;
      const { Documents, Code } = newState.employee;

      const ruPassport = Documents.find(({ Type }: { Type: string }) => Type === documentType.DomesticPassport);

      newState.employee.Documents[index].Number = updateRuPassport(value);
      newState.errors.Documents[index].Number = validateDocumentInput(
        ruPassport,
        DOCUMENTSFIELDS.NUMBER as keyof IFieldUser,
        Code,
        documentType,
        isDoubleDoc,
      );

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATECERTIFICATENUMBER: {
      const {
        value,
        index,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;
      const { Documents, Code } = newState.employee;
      const document = Documents[index];

      document.Number = updateCertificateNumber(value);
      newState.errors.Documents[index].Number = validateDocumentInput(document, DOCUMENTSFIELDS.NUMBER as keyof IFieldUser, Code, documentType);

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATEINTPASSPORTNUMBER: {
      const {
        value,
        index,
        documentType,
        isDoubleDoc,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;
      const { Documents, Code } = newState.employee;
      const document = Documents[index];

      document.Number = updatePassportNumber(value, document.Number, document.Type, documentType);
      newState.errors.Documents[index].Number = validateDocumentInput(
        document,
        DOCUMENTSFIELDS.NUMBER as keyof IFieldUser,
        Code,
        documentType,
        isDoubleDoc,
      );

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATEDOMESTICPASSPORT: {
      const { value, index, isDoubleDoc } = payload;
      const dType = PASSPORTS.DOMESTIC_PASSPORT.TYPE as keyof typeof validateValue;

      newState.errors.Documents[index].Number = (validateValue[dType] as any)(
        DOCUMENTSFIELDS.NUMBER,
        value,
        isDoubleDoc,
      );

      if (newState.errors.Documents[index].Number) {
        break;
      }

      newState.employee.Documents[index].Number = value;
      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATECOMPANIES: {
      newState.employee.Companies = payload;
      break;
    }

    case EMPLOYEEACTION.EMPLOYEEFORCEUPDATEBONUS: {
      newState.employee.Bonuses = newState.employee.Bonuses.map((item: BonusesType) => {
        const { Number, Type } = item;

        if (Type === SERVICETYPE.TRAIN) {
          if (Number.length <= 13 && (Number.length === 0 || /^[-+]?[0-9]+$/.test(Number))) {
            return {
              ...item,
              Number,
              Code: 'FPK',
            };
          }
        } else {
          return {
            ...item,
            Number: Number.replace(/\s+/g, ''),
          };
        }

        return item;
      });
      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATEBONUS: {
      const { value, index } = payload;

      const currentItem = newState.employee.Bonuses;

      if (currentItem[index].Type === SERVICETYPE.TRAIN) {
        if (value.length <= 13 && (value.length === 0 || /^[-+]?[0-9]+$/.test(value))) {
          currentItem[index].Number = value;
          currentItem[index].Code = 'FPK'; // пока один код у ж/д
        }
      } else {
        currentItem[index].Number = value.replace(/\s+/g, '');
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEADDBONUS: {
      const airBonuses = newState.employee.Bonuses.filter(({ Type }: { Type: string }) => Type === SERVICETYPE.AIR);
      const bonus = airBonuses[airBonuses.length - 1];
      const currentCode = bonus.Number.trim().replace(/\s+/g, '');

      if (bonus.Number.length > 0 && bonus.Number.length === currentCode.length && bonus.Code.length) {
        newBonus(newState.employee.Bonuses, SERVICETYPE.AIR);
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEDELETEBONUS: {
      const bonuses = newState.employee.Bonuses;
      const isBonusAir = bonuses[payload].Type === SERVICETYPE.AIR;

      if (bonuses.length === 2 && isBonusAir) {
        bonuses[payload] = clearBonus(SERVICETYPE.AIR);
      } else if (bonuses.length > 2 && isBonusAir) {
        bonuses.splice(payload, 1);
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEECITIZENSHIP: {
      const {
        value,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;

      if (state.employee.Code === value.Code) {
        break;
      }

      newState.citizenshipSuggestions.label = value.Name;
      newState.citizenshipSuggestions.selected = value.Code;
      newState.citizenshipSuggestions.suggestions = [];

      newState.employee.Code = newState.citizenshipSuggestions.selected;
      newState.employee.Code3 = value.Code3;

      newState.errors.Code = '';

      newState.employee.Documents = [];
      newState.errors.Documents = [];

      const documents = newState.employee.Documents;
      const errors = newState.errors.Documents;

      if (newState.employee.Code === 'RU') {
        newDocument(documents, documentType.DomesticPassport);
        newDocument(documents, documentType.ForeignPassport);

        newErrorsDocuments(errors);
        newErrorsDocuments(errors);
      } else {
        newDocument(documents, documentType.DomesticPassport);
        newErrorsDocuments(errors);
      }

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEECITIZENSHIPSUGGEST: {
      const { query, citizenship } = payload;

      newState.citizenshipSuggestions.suggestions = citizenship.filter((item: TCitizenship) =>
        item.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Code3.toLowerCase().indexOf(query.toLowerCase()) !== -1,
      );
      newState.citizenshipSuggestions.label = query;
      newState.citizenshipSuggestions.selected = null;

      // TODO: ВЫПИЛИТЬ, ЕСЛИ НИЧЕГО НЕ ПОЛОМАЕТСЯ
      // newState.employee.Code = '';
      // newState.employee.Code3 = '';

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEAIRLINE: {
      const { value, index } = payload;

      const bonus = newState.employee.Bonuses[index];

      bonus.airlineSuggestions.label = value.Name;
      bonus.airlineSuggestions.selected = value;
      bonus.airlineSuggestions.suggestions = [];

      bonus.Code = bonus.airlineSuggestions.selected.Code;

      break;
    }

    case EMPLOYEEACTION.SET_PROJECT_VALUE: {
      newState.employee.ProjectId = payload;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEAIRLINESUGGEST: {
      const { query, index, airlines } = payload;

      const bonus = newState.employee.Bonuses[index];

      bonus.airlineSuggestions.suggestions = airlines.filter(({ Name, Code }: { Name: string, Code: string }) =>
        Name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        Code.toLowerCase().indexOf(query.toLowerCase()) !== -1,
      );
      bonus.airlineSuggestions.label = query;
      bonus.airlineSuggestions.selected = null;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEVALIDATIONINPUT: {
      newState.errors[payload] = validationInput(newState.employee, payload, false, false);

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEVALIDATIONDOCUMENTINPUT: {
      const { field, index, documentType, isDoubleDoc } = payload;

      const document = newState.employee.Documents[index];
      const errorDocument = newState.errors.Documents[index];
      const code = newState.employee.Code;
      errorDocument[field] = validateDocumentInput(document, field, code, documentType, isDoubleDoc);

      break;
    }

    case EMPLOYEEACTION.EMPLOYEERESETERRORS: {
      newState.errors[payload] = '';

      if (newState.employee.Code === 'RU') {
        newState.errors.Documents = newState.errors.Documents.map((error: IEmployeKey, index: number) => {
          const err = { ...error };

          if (newState.employee.Documents[index] && newState.employee.Documents[index].Type !== 'ForeignPassport') {
            err[payload] = '';
          }

          return err;
        });
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEESETERRORS: {
      const {
        field,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      } = payload;

      if (field === EMPLOYEEFIELD.MOBILEPHONE) {
        newState.errors[field] = ERRORS.PHONEFORMAT;
      }

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEERESETERRORSDOCUMENT: {
      const { field, index } = payload;

      newState.errors.Documents[index][field] = '';

      break;
    }

    case EMPLOYEEACTION.SETIDENTITY: {
      if (payload) {
        newState.employee.UserIdentity = {
          ...payload,
        };
      }

      newState.userIdentityFetched = true;

      break;
    }

    case EMPLOYEEACTION.UPDATE_CHOOSEN_TP: {
      const { id } = payload;

      if (id) {
        newState.employee.TpRights = {
          Id: id,
          PolicyId: id,
        };
        newState.chosenTP = newState.employee.TpRights.PolicyId;
      }

      break;
    }

    case EMPLOYEEACTION.SETTPRIGHTS: {
      const { id, documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag } = payload;

      if (id) {
        newState.employee.TpRights = {
          Id: id,
          PolicyId: id,
        };
        newState.chosenTP = newState.employee.TpRights.PolicyId;
      }

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );

      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATECHOSENTP: {
      const { id = null, documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag } = payload;

      newState.chosenTP = id;
      newState.employee.TpRights = {
        ...newState.employee.TpRights,
        PolicyId: id,
      };

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );

      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEADDDOCUMENT: {
      const { ForeignPassport, DomesticPassport } = payload;
      const documents = newState.employee.Documents;
      const citizenship = newState.employee.Code;

      if (citizenship === 'RU' && validateDocument(documents, ForeignPassport)) {
        newDocument(documents, ForeignPassport);
        newErrorsDocuments(newState.errors.Documents);
      } else if (checkTwoForeignPassports(citizenship) && validateDocument(documents, DomesticPassport)) {
        newDocument(documents, DomesticPassport);
        newErrorsDocuments(newState.errors.Documents);
      }

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUPDATETRIPTAGS: {
      newState.employee.Tags = payload;
      break;
    }

    case EMPLOYEEACTION.EMPLOYEEDELETEDOCUMENT: {
      const { index, documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag } = payload;

      const updateCollection = (
        collection: IDocumentUser[] | IPassportError[],
        i: number,
        data?: IDocumentUser | IPassportError,
      ) => {
        const result = collection;

        if (!data) {
          result.splice(i, 1);
        } else {
          result[i] = data;
        }

        return result;
      };

      let documents = [...newState.employee.Documents];
      let errorsDocuments = [...newState.errors.Documents];

      const updateDocument = (docType: string) => {
        const currentDocument = documents.filter(({ Type }) => Type === documentType[docType]);

        documents = currentDocument.length > 1 ?
          updateCollection(documents, index) :
          updateCollection(documents, index, genPassport(documentType[docType]) as IDocumentUser);

        errorsDocuments = currentDocument.length > 1 ?
          updateCollection(errorsDocuments, index) :
          updateCollection(errorsDocuments, index, genErrorsPassport());
      };

      const docType = documents[index].Type;

      updateDocument(docType);

      newState = {
        ...newState,
        employee: {
          ...newState.employee,
          Documents: documents,
        },
        errors: {
          ...newState.errors, Documents: errorsDocuments,
        },
      };

      const { isValid, invalidDocumentsTypes } = validateAllForm(
        newState.employee,
        newState.errors,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
        newState.isChangeAirTripPage,
      );
      newState.isValid = isValid;
      newState.requiredFieldsErrors.emptyDocuments = invalidDocumentsTypes;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEARCHIVED: {
      newState.employee.Status = STATUS.ARCHIVE;

      break;
    }

    case EMPLOYEEACTION.EMPLOYEEUNARCHIVED: {
      newState.employee.Status = STATUS.ACCESS.ACTIVE;

      newState.employee = {
        ...newState.employee,
        Status: 'restore',
      };

      break;
    }

    case EMPLOYEEACTION.EMPLOYEECLEAR: {
      newState = {
        ...defaultModule(),
      };
      break;
    }

    case EMPLOYEEACTION.SET_EMPLOYEES_CURRENT_DOCUMENT: {
      newState.employee = {
        ...newState.employee,
        Documents: [...payload.Documents],
      };
      newState.errors = {
        ...defaultModule().errors,
      };
      newState.isValid = true;

      break;
    }

    case EMPLOYEEACTION.SET_EMPLOYEES_PREVIOUS_DOCUMENT: {
      newState.employeePrevious = {
        ...newState.employeePrevious,
        Documents: [...payload.Documents],
      };

      break;
    }

    case EMPLOYEEACTION.SET_IS_TG_LINKED_ACCOUNT: {
      newState.isTgLinked = payload;

      break;
    }
  }

  return newState;
};

// @ts-ignore
const createStore = () => new Store(reducer, defaultModule());
export default createStore;
