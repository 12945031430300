import React, { Component } from 'react';
import { History, Location } from 'history';
import { observer } from 'mobx-react';
import {
  Text,
  Input,
  Button,
  Tooltip,
  Checkbox,
  IconButton,
  PageLoader,
} from 'new-ui';

import { getText } from '../../../i18n';

import { withStores } from '../../bi/context';
import { ISettingsStore } from '../../bi/services/settings/stores/settings';
import { MOBX_STORES } from '../../bi/context/stores';
import { UseServicesReturnType } from '../../bi/context/services';

import { EditableText } from '../../components/EditableText';
import ProjectDialog from './components/ProjectDialog';
import CostDialog from './components/CostDialog';
import DeleteDialog from './components/DeleteDialog';

import { parseSearchString } from '../../bi/utils/convertSearchParams';
import getAccountId from '../../bi/utils/getAccountId';
import guid from '../../bi/utils/guid';
import { MainAnalytic } from '../../bi/utils/analytics';
import { formatEmployeeName } from '../../bi/utils/employees';

import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { EmployeeType } from '../../bi/services/employee/consts';
import { IProject } from '../../bi/types/userSession';
import { IMatch } from '../../bi/types/shared';
import {
  IAnalyticsType,
  NewAnalyticsValueType,
  AnalyticsValueType,
  IValuesToAdd,
  ISearchParam,
  ICompaniesList,
} from '../../bi/services/customAnalytics/types';

import styles from './styles.module.css';

const LABELS = {
  BACK: getText('common:back'),
  CUSTOM_ANALYTICS: getText('analytics:customAnalytics'),
  COST_CENTER: getText('analytics:costCenters'),
  AVAILABLE_OPTION: getText('analytics:availableOptions'),
  DEFINE_ALIST: getText('analytics:defineAList'),
  ADD: getText('analytics:add'),
  SELECT_COMPANIS: getText('analytics:selectCompanies'),
  REQUIRED: getText('analytics:required'),
  IS_ALLOWED_FOR_ALL: getText('analytics:isAllowedForAll'),
  TO_SET_FOR_THE_ENTIRE_TRIP: getText('analytics:toSetForTheEntireTrip'),
  ANALYTICS_NAME: getText('analytics:analyticsName'),
  SAVE: getText('common:save'),
  CANCEL: getText('common:undo'),
  PLEASE_SET_ATLEAST_TWO: getText('analytics:pleaseSetAtleastTwo'),
  PLACEHOLDER: getText('analytics:placeholder'),
  DEMO: getText('analytics:demo'),
  MANAGER_NAME: (employee: string) => getText('analytics:managerName', { employee }),
  ARCHIEVE: getText('analytics:projectDialog.archive'),
};

const DEFAULT_SEARCH_PARAMS = {
  cartItem: null,
  employeeId: null,
  noteItemId: null,
};

const DEFAULT_PROJECT: IProject = {
  Id: null,
  Name: null,
  IsArchival: false,
  ResponsibleEmployeeId: null,
};

const parseSearchParams = (search: string) => {
  const searchParams = parseSearchString(search);

  if (!searchParams) {
    return {
      ...DEFAULT_SEARCH_PARAMS,
    };
  }

  return {
    cartItem: searchParams.cartItem || null,
    employeeId: searchParams.employeeId || null,
    noteItemId: searchParams.noteItemId || null,
  };
};

interface IServices extends UseServicesReturnType<'CustomAnalytics'
| 'UserSession'
| 'Employee'
| 'FeatureFlags'
| 'Settings'
| 'Cart'
| 'Notepad'
| 'Workspace'
> {}

type AnalyticsPageProps = IServices & {
  history: History
  isCostCenter: boolean,
  location: Location,
  match: IMatch,
  stores: {
    settingsStore: ISettingsStore,
  },
};

interface IAnalyticsPageState {
  accountId: number,
  isLoadingProjects: boolean,
  projectDialog: boolean,
  showDeleteDialog: boolean,
  costDialog: boolean,
  currentId: string,
  Multi: boolean,
  project: IProject,
  employee: Partial<EmployeeType> | null,
  companiesList: ICompaniesList[],
  searchParams: ISearchParam,
  valuesToAdd: IValuesToAdd[],
  valueIdsToDelete: string[],
  draftValue: string,
}

@withStores([MOBX_STORES.SETTINGS_STORE])
@observer
class AnalyticsPage extends Component<AnalyticsPageProps, IAnalyticsPageState> {
  constructor(props: AnalyticsPageProps) {
    super(props);

    const { Companies, Multi } = this.props.workspaceService.get();

    const companiesList = () => Companies.map(({
      ShortCompanyName,
      CompanyName,
      CompanyId,
    }) =>
      ({
        label: ShortCompanyName || CompanyName,
        value: CompanyId,
      }),
    );

    this.state = {
      accountId: getAccountId(),
      isLoadingProjects: false,
      projectDialog: false,
      showDeleteDialog: false,
      costDialog: false,
      currentId: '',
      Multi,
      project: {
        ...DEFAULT_PROJECT,
      },
      employee: null,
      companiesList: companiesList(),
      searchParams: {
        ...DEFAULT_SEARCH_PARAMS,
      },
      valuesToAdd: [],
      valueIdsToDelete: [],
      draftValue: '',
    };
  }

  componentDidMount() {
    this.load();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_OPEN);
  }

  componentWillUnmount() {
    this.props.customAnalyticsService.reset();
  }

  redirectToAnalytics = () => {
    this.props.history.push(ROUTES.ANALYTICS);
  };

  loadProjects = async () => {
    const {
      settingsService,
      userSessionService,
      featureFlagsService,
      location: {
        search,
      },
    } = this.props;

    this.setState({
      isLoadingProjects: true,
    });

    try {
      await Promise.all([
        settingsService.getEmployeesList(),
        userSessionService.getProjects(),
        featureFlagsService.loadFeatureFlags(),
        settingsService.getCompaniesWithCostCenter(this.state.accountId),
      ]);
    } catch {}

    const searchParams = parseSearchParams(search) as ISearchParam;
    const projectDialog = searchParams.cartItem !== null
      || searchParams.noteItemId !== null;

    this.setState({
      isLoadingProjects: false,
      searchParams,
      projectDialog,
    });
  };

  loadAnalytics = async () => {
    const {
      customAnalyticsService,
      match: { params: { id } },
    } = this.props;

    customAnalyticsService.setLoading(true);

    await customAnalyticsService.getAnalyticsById(id);

    customAnalyticsService.setLoading(false);

    if (customAnalyticsService.store?.analytics?.Id !== null) {
      return;
    }

    this.redirectToAnalytics();
  };

  load = () => {
    const {
      isCostCenter,
      match: { params: { id } },
    } = this.props;

    if (isCostCenter) {
      this.loadProjects();

      return;
    }

    if (!id) {
      return;
    }

    if (!isNaN(id)) {
      this.loadAnalytics();

      return;
    }

    this.redirectToAnalytics();
  };

  setMandatoryProject = (value = false) => {
    const { settingsService } = this.props;
    const { companiesList } = this.state;

    const companiesMapper = companiesList.map((
      company: ICompaniesList,
    ) => ({
      companyId: company.value,
      isMandatory: value,
    }));

    if (value) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_MANDATORY_CHOSEN);
    }

    settingsService.updateCostCenters(companiesMapper, this.state.accountId);
  };

  setProjectDialogVisibility = (value: boolean = true) => {
    const { settingsService: { setInterNameCostCenter } } = this.props;

    this.setState({
      projectDialog: value,
    });

    setInterNameCostCenter();
  };

  setCostDialogVisibility = (value = true) => {
    this.setState({
      costDialog: value,
    });
  };

  handleDeleteDialog = (id: string, value: boolean) => {
    this.setState({
      showDeleteDialog: value,
      currentId: id,
    });
  };

  setProjectForCartOrNoteItem = (
    { Id }: IProject | { Id: null } = { Id: null }, // жесть
  ) => {
    const { cartService, notepadService } = this.props;
    const {
      searchParams: {
        noteItemId,
        cartItem,
        employeeId,
      },
    } = this.state;

    if (noteItemId) {
      return notepadService.addEmployeeProject(
        noteItemId,
        employeeId as number,
        Id as number,
      );
    }

    return cartService.addEmployeeProject(
      cartItem as number,
      employeeId as number,
      Id as number,
    );
  };

  addProject = async (project: IProject) => {
    const { userSessionService } = this.props;
    const {
      searchParams: {
        noteItemId,
        cartItem,
      },
    } = this.state;

    try {
      const newProject = await userSessionService.saveProject(project);
      await userSessionService.getProjects();

      if (cartItem || noteItemId) {
        await this.setProjectForCartOrNoteItem(newProject);
      }
    } catch {}

    this.setProjectDialogVisibility(false);
  };

  editProject = async (project: IProject) => {
    const { userSessionService } = this.props;

    try {
      await userSessionService.saveProject(project);
      await userSessionService.getProjects();
    } catch {}

    this.setProjectDialogVisibility(false);
  };

  showEditProjectDialog = ({
    Id: projectId,
    ResponsibleEmployeeId: employeeId,
  }: {
    Id: number | null,
    ResponsibleEmployeeId: number | null,
  } = {
    Id: null,
    ResponsibleEmployeeId: null,
  }) => {
    const {
      userSessionService,
      stores: {
        settingsStore: { employees },
      },
    } = this.props;

    const { projects = [] } = userSessionService.store.getState();

    const project = projectId === null
      ? DEFAULT_PROJECT
      : projects.find(({ Id }: IProject) => projectId === Id);

    const employee = employeeId === null
      ? null
      : employees.find(({ Id }) => employeeId === Id) as unknown as Partial<EmployeeType> | null;

    this.setState({
      employee,
      project,
      projectDialog: true,
    });
  };

  onEmployeeInputChange = (value: string) => this.props.employeeService.autocomplete(value);

  onEmployeeSelect = (employee: Partial<EmployeeType> | null) => { this.setState({ employee }); };

  onProjectFieldChange = (
    field: string,
    value: string,
  ) => {
    const { project } = this.state;
    this.setState({
      project: {
        ...project,
        [field]: value,
      },
    });
  };

  handleSave = (projectWithEmployeeId: IProject) => {
    this.addProject(projectWithEmployeeId)
      .then(() => MainAnalytic
        .sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_ADD_PROJECT_SAVE));
  };

  onSave = () => {
    const { project, employee } = this.state;

    const ResponsibleEmployeeId = employee?.Id || null;
    const projectWithEmployeeId = {
      ...project,
      ResponsibleEmployeeId,
    } as IProject;

    if (projectWithEmployeeId?.Id) {
      return this.editProject(projectWithEmployeeId);
    }

    return this.handleSave(projectWithEmployeeId);
  };

  createOrUpdateAnalytics = () => {
    const {
      customAnalyticsService: {
        store: {
          analytics,
        },
        updateAnalytics,
        createAnalytics,
      },
    } = this.props;
    const { valuesToAdd, valueIdsToDelete, draftValue } = this.state;

    /*
      Если пользователь вместо галочки в EditableText нажал "Сохранить" на самой форме,
      то содержимое EditableText (draftValue) тоже сохраняется как одно из значений аналитики
    */
    const valuesToAddWithDraftValueName = [
      ...valuesToAdd,
      draftValue
        ? {
          Value: draftValue,
        }
        : null,
    ].filter(Boolean);

    if (analytics?.Id !== null) {
      return updateAnalytics(
        analytics as IAnalyticsType,
        valuesToAddWithDraftValueName as NewAnalyticsValueType[],
        valueIdsToDelete as unknown as number[],
      );
    }

    return createAnalytics(analytics, valuesToAddWithDraftValueName as NewAnalyticsValueType[]);
  };

  onSaveAnalytics = async () => {
    const {
      history,
    } = this.props;

    await this.createOrUpdateAnalytics();
    history.goBack();
  };

  setDraftValue = (draftValue: string) => {
    this.setState({ draftValue });
  };

  handleDeleteItem = (id: string) => {
    this.addValueIdToToDeleteList(id);
    // @ts-ignore // TODO поправить, тк функция хочет 2 аргумента
    this.handleDeleteDialog(false);
  };

  handleAddNewProject = () => {
    this.showEditProjectDialog();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_ADD_PROJECT);
  };

  handleEditRequired = (Required: boolean) => {
    if (Required) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_CHECKBOX_1);
    }

    this.onEditAnalytics({ Required });
  };

  handleEditEditable = (Editable: boolean) => {
    if (Editable) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_CHECKBOX_2);
    }

    this.onEditAnalytics({ Editable });
  };

  handleEditApplyToTrip = (ApplyToTrip: boolean) => {
    if (ApplyToTrip) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_CHECKBOX_3);
    }

    this.onEditAnalytics({ ApplyToTrip });
  };

  renderProjectDialog = () => {
    const { projectDialog, project, employee } = this.state;
    const {
      isCostCenter,
      settingsService: {
        settingsStore: {
          interNameCostCenter,
        },
      },
    } = this.props;

    if (!isCostCenter || !projectDialog) {
      return null;
    }

    return (
      <ProjectDialog
        show={ projectDialog }
        project={ project }
        employee={ employee }
        onChangeField={ this.onProjectFieldChange }
        onChange={ this.setProjectDialogVisibility }
        onFetch={ this.onEmployeeInputChange }
        onSelect={ this.onEmployeeSelect }
        onSave={ this.onSave }
        initialValue={ interNameCostCenter }
      />
    );
  };

  saveCostCenter = async (companies: number[]) => {
    const { settingsService } = this.props;
    const { companiesList, accountId } = this.state;

    const companiesMapper = companiesList.map((company: ICompaniesList) =>
      ({
        companyId: company.value,
        isMandatory: companies.some(id => id === company.value),
      }));

    if (companies.length) {
      await settingsService.updateCostCenters(companiesMapper, accountId);
    } else {
      this.setMandatoryProject();
    }

    this.setCostDialogVisibility(false);
  };

  renderCostDialog = () => {
    const {
      isCostCenter,
      stores: {
        settingsStore: { companiesWithCostCenter },
      },
    } = this.props;
    const { costDialog, companiesList } = this.state;

    if (!isCostCenter || !costDialog) {
      return null;
    }

    return (
      <CostDialog
        show={ costDialog }
        selected={ companiesWithCostCenter }
        companies={ companiesList }
        onSave={ this.saveCostCenter }
        onChange={ this.setCostDialogVisibility }
      />
    );
  };

  renderDeleteDialog = () => {
    const { showDeleteDialog, currentId } = this.state;

    return (
      <DeleteDialog
        onDelete={ () => this.handleDeleteItem(currentId) }
        // @ts-ignore // TODO поправить, тк функция хочет 2 аргумента
        onChange={ () => this.handleDeleteDialog(false) }
        showDialog={ showDeleteDialog }
      />
    );
  };

  renderBackButton = () => {
    const { history } = this.props;

    return (
      <IconButton
        className={ styles.back }
        iconType='arrowsBack'
        iconColor='accent'
        onClick={ () => { history.goBack(); } }
      >
        <Text type='NORMAL_14'>
          { LABELS.BACK }
        </Text>
      </IconButton>
    );
  };

  renderTitle = () => {
    const {
      isCostCenter,
      customAnalyticsService: {
        store: { analytics },
      },
    } = this.props;

    const customAnalyticsLabel = analytics?.Name || LABELS.CUSTOM_ANALYTICS;

    const title = isCostCenter
      ? LABELS.COST_CENTER
      : customAnalyticsLabel;

    return (
      <Text
        className={ styles.title }
        type='bold_32'
        qaAttr={ QA_ATTRIBUTES.settings.analytics.title }
      >
        { title }
      </Text>
    );
  };

  renderCostCentersSettings = () => {
    const {
      userSessionService,
      stores: {
        settingsStore: { companiesWithCostCenter },
      },
    } = this.props;
    const { Multi, companiesList } = this.state;

    const { projects = [] } = userSessionService.store.getState();

    const companies = companiesList
      .some((companyItem: ICompaniesList) => companiesWithCostCenter
        .some(item => companyItem.value === item));

    const multiCompanyContent = Multi && (
      <IconButton
        className={ styles.options_button }
        onClick={ () => { this.setCostDialogVisibility(); } }
        iconType='plusRound'
        disabled={ !companies }
      >
        {LABELS.SELECT_COMPANIS}
      </IconButton>
    );

    const disabledRules = !projects.length && !companies;

    return (
      <div
        className={ `${styles.settings_checkboxes} ${styles.cost_center_row}` }
      >
        <Checkbox
          value={ companies }
          onChange={ () => this.setMandatoryProject(!companies) }
          disabled={ disabledRules }
          qaAttr={ QA_ATTRIBUTES.settings.analytics.centerCreate.mustBuy }
        >
          { LABELS.REQUIRED }
        </Checkbox>
        {multiCompanyContent}
      </div>
    );
  };

  onEditAnalytics = (newAnalytics = {}) => {
    const {
      customAnalyticsService: {
        store: { analytics },
        setAnalytics,
      },
    } = this.props;

    setAnalytics({
      ...analytics,
      ...newAnalytics,
    } as IAnalyticsType);
  };

  addValueIdToToDeleteList = (valueId: string) => {
    this.setState({
      valueIdsToDelete: [
        ...this.state.valueIdsToDelete,
        valueId,
      ],
    });
  };

  addValueToToAddList = (value = '') => {
    if (!value?.trim()) {
      return;
    }

    this.setState({
      valuesToAdd: [
        ...this.state.valuesToAdd,
        { Value: value.trim() },
      ],
    });
  };

  removeValueFromToAddList = (value: IValuesToAdd | null = null) => {
    const { valuesToAdd } = this.state;

    const id = valuesToAdd.indexOf(value as IValuesToAdd);

    if (id < 0) {
      return;
    }

    this.setState({
      valuesToAdd: [
        ...valuesToAdd.slice(0, id),
        ...valuesToAdd.slice(id + 1),
      ],
    });
  };

  renderCustomAnalyticsSettings = () => {
    const {
      customAnalyticsService: {
        store: { analytics },
      },
    } = this.props;

    const name = analytics?.Name || '';
    const required = analytics?.Required || false;
    const editable = analytics?.Editable || false;
    const appliesToATrip = analytics?.ApplyToTrip || false;

    return (
      <>
        <div className={ styles.settings_name }>
          <div className={ styles.settings_name_label }>
            <Text
              color='gray'
              type='NORMAL_16'
            >
              { LABELS.ANALYTICS_NAME }
            </Text>
            <Text
              color='red'
              type='NORMAL_16'
            >
              *
            </Text>
          </div>
          <Input
            onChange={ (analyticsName = '') => { this.onEditAnalytics({ Name: analyticsName }); } }
            value={ name }
            className={ styles.settings_name_input }
            onBlur={ () => { this.onEditAnalytics({ Name: analytics?.Name.trim() || '' }); } }
            placeholder={ LABELS.PLACEHOLDER }
            qaAttr={ QA_ATTRIBUTES.settings.analytics.analyticCreate.inputName }
          />
        </div>
        <div className={ styles.settings_checkboxes }>
          <Checkbox
            onChange={ (Required) => this.handleEditRequired(Required) }
            value={ required }
            className={ styles.settings_checkbox }
            qaAttr={ QA_ATTRIBUTES.settings.analytics.analyticCreate.everyBuy }
          >
            { LABELS.REQUIRED }
          </Checkbox>
          <Checkbox
            onChange={ (Editable) => this.handleEditEditable(Editable) }
            value={ editable }
            className={ styles.settings_checkbox }
            qaAttr={ QA_ATTRIBUTES.settings.analytics.analyticCreate.allowAdd }
          >
            { LABELS.IS_ALLOWED_FOR_ALL }
          </Checkbox>
          <Checkbox
            onChange={ (ApplyToTrip) => this.handleEditApplyToTrip(ApplyToTrip) }
            value={ appliesToATrip }
            className={ styles.settings_checkbox }
            qaAttr={ QA_ATTRIBUTES.settings.analytics.analyticCreate.allTrip }
          >
            { LABELS.TO_SET_FOR_THE_ENTIRE_TRIP }
          </Checkbox>
        </div>
      </>
    );
  };

  renderCostCentersList = () => {
    const {
      userSessionService,
      stores: {
        settingsStore: { employees },
      },
    } = this.props;

    const { projects = [] } = userSessionService.store.getState();

    projects.sort((
      first: IProject,
      second: IProject,
    ) => Number(first.IsArchival) - Number(second.IsArchival));

    const projectsList = (projects as IProject[]).map(({
      Id,
      Name,
      ResponsibleEmployeeId,
      IsArchival,
    }) => {
      const employee = employees.find(({ Id: employeeId }) => employeeId === ResponsibleEmployeeId);

      const hasManager = ResponsibleEmployeeId !== null && employee;
      const managersName = hasManager
        ? LABELS.MANAGER_NAME(formatEmployeeName(employee as Partial<EmployeeType>))
        : '';

      const archival = IsArchival
        ? `— ${LABELS.ARCHIEVE}`
        : '';

      return (
        <div
          key={ Id }
          className={ styles.options_item }
        >
          <Button
            onClick={ () => {
              this.showEditProjectDialog({
                Id, ResponsibleEmployeeId,
              });
            } }
            type='textual'
            className={ styles.options_item__name }
          >
            { Name } { managersName } { archival }
          </Button>
        </div>
      );
    });

    const addButton = (
      <IconButton
        className={ styles.options_button }
        onClick={ this.handleAddNewProject }
        iconType='plusRound'
        qaAttr={ QA_ATTRIBUTES.settings.analytics.centerCreate.addButton }
      >
        { LABELS.ADD }
      </IconButton>
    );

    return (
      <>
        { projectsList }
        { addButton }
      </>
    );
  };

  renderCustomAnalyticsList = () => {
    const {
      customAnalyticsService: {
        store: { analytics },
      },
    } = this.props;
    const { valuesToAdd, valueIdsToDelete } = this.state;

    const list = analytics?.Values || [];

    const customAnalyticsList = [
      ...list.filter(({ Id }) => !valueIdsToDelete.includes(Id as unknown as string)),
      ...valuesToAdd,
    ]
      .sort((a, b) =>
        Number(Boolean(a.Used)) - Number(Boolean(b.Used)) // это треш
          || a.Value.toLowerCase().localeCompare(b.Value.toLowerCase()),
      )
      .map((item, index) => {
        const { Value, Id, Used } = item;

        const onDelete = Id
          ? () => { this.addValueIdToToDeleteList(Id as string); }
          : () => { this.removeValueFromToAddList(item as IValuesToAdd); };

        const onDeleteItem = Used
          ? () => { this.handleDeleteDialog(Id as string, true); }
          // @ts-ignore
          : () => { onDelete(Id); };

        return (
          <div
            key={ `${index}_${guid()}` }
            className={ styles.options_item }
          >
            <IconButton
              className={ styles.options_item__delete }
              iconType='closeButton'
              iconColor='accent'
              onClick={ onDeleteItem }
            />
            <Button
              type='textual'
              className={ styles.options_item__name }
            >
              { Value }
            </Button>
          </div>
        );
      });

    const addButton = (
      <div className={ styles.options_add }>
        <EditableText
          toggleModeOnChange={ false }
          renderPreview={ () => (
            <IconButton
              onClick={ () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_ADD_BUTTON_PRESSED) }
              iconType='plusRound'
              qaAttr={ QA_ATTRIBUTES.settings.analytics.analyticCreate.addValue }
            >
              { LABELS.ADD }
            </IconButton>
          ) }
          placeholder=''
          iconType='plusRound'
          onChange={ this.addValueToToAddList }
          onInputChange={ this.setDraftValue }
          qaAttrInput={ QA_ATTRIBUTES.settings.analytics.analyticCreate.inputValue }
          qaAttrSave={ QA_ATTRIBUTES.settings.analytics.analyticCreate.saveValue }
        />
      </div>
    );

    return (
      <>
        { customAnalyticsList }
        { addButton }
      </>
    );
  };

  renderContent = () => {
    const { isCostCenter } = this.props;

    if (isCostCenter) {
      return {
        settings: this.renderCostCentersSettings(),
        list: this.renderCostCentersList(),
      };
    }

    return {
      settings: this.renderCustomAnalyticsSettings(),
      list: this.renderCustomAnalyticsList(),
    };
  };

  renderTooltipContent = () => {
    const label = this.props.workspaceService.isDemo
      ? LABELS.DEMO
      : LABELS.PLEASE_SET_ATLEAST_TWO;

    return (
      <div className={ styles.tooltip }>
        <Text
          type='NORMAL_14_130'
          color='white'
        >
          { label }
        </Text>
      </div>
    );
  };

  renderPanel = () => {
    const {
      isCostCenter,
      customAnalyticsService: {
        store: { analytics },
      },
      workspaceService,
    } = this.props;
    const { valuesToAdd, valueIdsToDelete } = this.state;

    if (isCostCenter) {
      return null;
    }

    const analyticsValuesWithoutDeleted = analytics?.Values.filter(({ Id }) => !valueIdsToDelete.includes(Id as unknown as string));

    const notEnoughValues = !analytics?.Editable
      && ((analyticsValuesWithoutDeleted as AnalyticsValueType[]).length + valuesToAdd.length < 2);

    const emptyName = !analytics?.Name || !analytics?.Name.trim();

    const disabled = notEnoughValues || emptyName || workspaceService.isDemo;

    return (
      <div className={ styles.panel }>
        <div className={ styles.panel_content }>
          <Tooltip
            show={ disabled }
            position='right'
            renderContent={ this.renderTooltipContent }
          >
            <Button
              disabled={ disabled }
              onClick={ this.onSaveAnalytics }
              type='secondary'
              className={ styles.panel_button }
              qaAttr={ QA_ATTRIBUTES.settings.analytics.analyticCreate.buttonSave }
            >
              { LABELS.SAVE }
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  renderLoading = () => (
    <PageLoader />
  );

  render() {
    const { isLoadingProjects } = this.state;
    const {
      customAnalyticsService: {
        store: { loading },
      },
    } = this.props;

    if (loading || isLoadingProjects) {
      return this.renderLoading();
    }

    const backButton = this.renderBackButton();
    const title = this.renderTitle();
    const { settings, list } = this.renderContent();
    const panel = this.renderPanel();
    const projectDialog = this.renderProjectDialog();
    const costDialog = this.renderCostDialog();
    const delDialog = this.renderDeleteDialog();

    return (
      <>
        <div className={ styles.wrap }>
          { backButton }
          { title }
          { settings }
          <Text
            className={ styles.options_title }
            type='bold_24'
          >
            { LABELS.AVAILABLE_OPTION }
          </Text>
          <Text
            className={ styles.options_tip }
            color='gray'
            type='NORMAL_14'
          >
            { LABELS.DEFINE_ALIST }
          </Text>
          { list }
          { panel }
        </div>
        { projectDialog }
        { costDialog }
        { delDialog }
      </>
    );
  }
}

export default AnalyticsPage;
