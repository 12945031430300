import React from 'react';

import { Text, TextColor } from 'new-ui';

import { formatDate } from '../../../../bi/utils/formatDate';
import toDecline from '../../../../bi/utils/toDecline';

import { TRAVELLERS } from '../../../../bi/constants/airline';

import { AirlineHistoryItem } from '../../../../bi/types/airline';

import styles from '../../styles/history.module.css';

interface AirlineHistoryItemProps {
  item: AirlineHistoryItem;
}

const AirlinesHistoryItem = ({ item: { dates, label, details: { TravellersCount } } }: AirlineHistoryItemProps) => {
  const dateHtml = dates.map(date => formatDate(date)).join(' – ');
  const travellers = toDecline(TravellersCount, TRAVELLERS);

  const textHtml = (text: string, color: TextColor = 'gray', style: string = '') => (
    <Text
      type='NORMAL_14'
      color={ color }
      className={ style }
    >
      { text }
    </Text>
  );

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.route }>
        {textHtml(dateHtml)}
        {textHtml(label, 'default', styles.label)}
      </div>
      {textHtml(`${TravellersCount} ${travellers}`)}
    </div>
  );
};

export { AirlinesHistoryItem };
